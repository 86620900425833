import * as React from 'react';
import { styled } from '@naan/stitches.config';

const RowWrapper = styled('div', {
  padding: '12px 0',
  margin: '0 16px',
  '& .controls': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'top',
    gap: 16,
    '& > .left': {},
    '& > .right': {},
  },
  '& .hint': {
    paddingTop: 8,
    textStyle: 'small-text',
    color: '$textSecondary',
    '& a': {
      marginTop: 8,
      color: '$textSecondary',
    },
  },
});

export const Row = ({
  label,
  hint,
  control,
}: {
  label: string;
  hint?: React.ReactNode;
  control: React.ReactElement;
}) => {
  return (
    <RowWrapper>
      <div className="controls">
        <div className="left">
          {label}
          {hint ? <div className="hint">{hint}</div> : null}
        </div>
        <div className="right">{control}</div>
      </div>
    </RowWrapper>
  );
};
