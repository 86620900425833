import * as React from 'react';
import { observer } from 'mobx-react';
import { isNil } from 'lodash';
import { Assignment } from '@core/models/user-manager/assignment';
import { notEmpty } from '@utils/conditionals';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { AppFactory } from '@app/app-factory';
import { AssignmentDetailsDialog } from './assignment-details-dialog';
import { CenterColumnLayout } from '../layouts/center-column-layout';
import { Sticky } from '../story-detail/sticky.styles';
import { elementIds } from 'components/dom-utils/element-ids';

import __ from '@core/lib/localization';

const Wrapper = styled(Sticky, {
  // borderBottom: '1px solid $gray-100',
  $$textColor: '$colors$textPrimary',
  $$backgroundColor: '$colors$yellow-100',
  backgroundColor: '$$backgroundColor',
  top: '$$smallHeaderHeight',

  '@medium': {
    top: '$$mediumHeaderHeight',
  },

  '& .inner': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px 0',
    justifyContent: 'space-between',
    textStyle: 'body-bold',
    color: '$$textColor',
    '@medium': {
      justifyContent: 'flex-start',
    },
    '& > .details-button': {
      '@medium': {
        marginLeft: '$space$4',
      },
    },
  },

  variants: {
    pastDue: {
      true: {
        $$backgroundColor: '$colors$gray-25',
        $$textColor: '$colors$textSecondary',
      },
    },
  },
});

const StoryDetailCardAssignmentContent = observer(
  ({ assignment }: { assignment: Assignment }) => {
    const assignmentDateText = notEmpty(assignment.displayDueDateShort)
      ? __('Due %{date}', 'due_date_', { date: assignment.displayDueDateShort })
      : null;

    const assignmentInfo = notEmpty(assignmentDateText)
      ? assignmentDateText + ' • ' + assignment.classroom?.label
      : assignment.classroom?.label;

    const showDetailsButton = notEmpty(assignment.details);

    const showDetails = React.useCallback(() => {
      AppFactory.dialogPresenter.present(onDismiss => (
        <AssignmentDetailsDialog
          assignment={assignment}
          onDismiss={onDismiss}
        />
      ));
    }, [assignment]);

    return (
      <Wrapper
        pastDue={assignment.isPastDueDate}
        className="story-assignment"
        id={elementIds.STORY_ASSIGNMENT_BAR}
      >
        <CenterColumnLayout>
          <div className="inner">
            {assignmentInfo}
            {showDetailsButton ? (
              <div className="details-button">
                <Button
                  label={__('Details', 'details')}
                  onClick={showDetails}
                  presentation={
                    assignment.isPastDueDate ? 'grayLight' : 'yellow'
                  }
                  size={'small'}
                />
              </div>
            ) : null}
          </div>
        </CenterColumnLayout>
      </Wrapper>
    );
  }
);

export const StoryDetailCardAssignment = observer(
  ({ assignment }: { assignment: Assignment }) => {
    if (isNil(assignment)) {
      // we need the element to exists because the tab bar component requires it to exist
      return <div id={elementIds.STORY_ASSIGNMENT_BAR} />;
    }

    return <StoryDetailCardAssignmentContent assignment={assignment} />;
  }
);
