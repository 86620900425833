import * as React from 'react';
import { observer } from 'mobx-react';
import { Soundbite } from '@core/models/story-manager/soundbite';
import { SoundbiteCategory } from 'components/soundbites/soundbite-categories';
import { SoundbiteCategoriesInfo } from 'components/soundbites/soundbite-categories';
import { icons } from 'components/soundbites/soundbites-icons';
import { UncontrolledTooltip } from '@naan/primitives/tooltip';
import { styled } from '@naan/stitches.config';
import { createLogger } from '@common/log';

const log = createLogger('sb:script-header-category-info');

const TipContentWrapper = styled('div', {
  width: 'min(100%-48px, 300px)',
  textAlign: 'left',
  textStyle: 'body',
  whiteSpace: 'break-spaces',
});

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  cursor: 'pointer',
  color: '$white',
  textStyle: 'small-text',
  '& svg': {
    width: 20,
    height: 20,
  },
  '@widerThanScript': {
    textStyle: 'body',
    '& svg': {
      width: 24,
      height: 24,
    },
  },
});

export const ScriptHeaderCategoryInfo = observer(
  ({ soundbite }: { soundbite: Soundbite }) => {
    const category = soundbite?.category as SoundbiteCategory;
    const categoryInfo = SoundbiteCategoriesInfo[category];

    if (!categoryInfo) {
      log.error(`missing categoryInfo for ${String(category)}`);
      return null;
    }

    const iconSet = icons[soundbite.category as SoundbiteCategory] ?? null;
    const SmallIcon = iconSet?.small ?? null;

    return (
      <UncontrolledTooltip
        event="click"
        placement={'bottom'}
        offset={[0, 20]}
        tipContent={
          <TipContentWrapper>{categoryInfo.tooltip}</TipContentWrapper>
        }
      >
        <Wrapper>
          <SmallIcon presentation="dark" />
          {categoryInfo.label}
        </Wrapper>
      </UncontrolledTooltip>
    );
  }
);
