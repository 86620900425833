/// FIXME: This is a mixed bag of unrelated functions, we should probably move them to separate files.

import {
  get,
  isNil,
  isString,
  isNumber,
  isDate,
  deburr,
  memoize,
} from 'lodash';

export const sleep = (ms: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

export const SORT_ORDER = Object.freeze({
  ASC: 'ASC',
  DESC: 'DESC',
});

// removes leading punctuaction symbols which are common in Spanish
// so they don't affect sorting
// e.g. "¿Dónde están la biblioteca?"
export const compareNormalize = memoize(str =>
  deburr(str).replace(/^[^a-zA-Z0-9]+/, '')
);

// utility for string comparison
export const localeCompare = (a: string, b: string) => {
  return compareNormalize(a).localeCompare(compareNormalize(b));
};

/**
 * Most typical fuzzy a:string === to b:string comparison
 */
export const normalizedEqual = (a: string, b: string) => {
  return a.trim().toLowerCase().localeCompare(b.trim().toLowerCase()) === 0;
};

/**
 *
 * uses lodash.get to create a comparator function
 * for a particular field that can be used with Array.prototype.sort
 *
 * @param {string} abPath
 */
export const makePathSortFn =
  (abPath: string, sortOrder = SORT_ORDER.ASC) =>
  (a: string, b: string) => {
    const x = get(a, abPath, null);
    const y = get(b, abPath, null);
    // beware, the `x && y` check was resulting in erratic sorting of `0`s
    if (!isNil(x) && !isNil(y)) {
      // log.debug(`simpleComparator - ${abPath} y: ${JSON.stringify(y)}`);
      // we assume x and y are the same type. We have bigger issues if they aren't.

      if (isString(y)) {
        return sortOrder === SORT_ORDER.ASC
          ? localeCompare(x, y)
          : localeCompare(y, x);
      }

      if (isNumber(y)) {
        return sortOrder === SORT_ORDER.ASC ? x - y : y - x;
      }

      // i think this is only used for the download queue sorting.
      // looks to have been broken for a very long time, until fixed apr 5, 2021
      if (isDate(y)) {
        if (x === y) {
          return 0;
        }
        if (sortOrder === SORT_ORDER.ASC) {
          return x > y ? 1 : -1;
        } else {
          return x > y ? -1 : 1;
        }
      }

      return 0;
    }

    if (y === x) {
      return 0;
    }

    return x === null ? 1 : -1;
  };

/**
 * takes a field path (uses lofash.get internally) and returns a story sorting function
 * @param {string} abPath
 */
export const sortBy = (abPath: string, sortOrder = SORT_ORDER.ASC) => {
  const sortFn = makePathSortFn(abPath, sortOrder);
  return function sortFunction<T>(stories: T[]) {
    return [...stories].sort(sortFn as any);
  };
};

const makePathSortFnWithArrayIndex =
  (absPath: string, sortingArr: any[]) => (a: string, b: string) => {
    const x = get(a, absPath, null);
    const y = get(b, absPath, null);

    const ix = sortingArr.indexOf(x);
    const iy = sortingArr.indexOf(y);
    return ix - iy;
  };

export const sortByArrayIndex = (absPath: string, sortingArr: any[]) => {
  const sortFn = makePathSortFnWithArrayIndex(absPath, sortingArr);

  return function sortFunction<T>(items: T[]) {
    return [...items].sort(sortFn as any);
  };
};

// adapted from: https://stackoverflow.com/questions/872310/javascript-swap-array-elements
export const swapArrayElements = (arr: any[], i1: number, i2: number): void => {
  [arr[i1], arr[i2]] = [arr[i2], arr[i1]];
};

/**
 * Wraps `fn` into a promise.
 * @param {Function} fn
 */
// const promisify = fn => (...args) =>
//   new Promise((resolve, reject) => {
//     try {
//       resolve(fn(...args));
//     } catch (error) {
//       reject(error);
//     }
//   });

export const fetchJson = async <T>(url: string): Promise<T> => {
  const response = await fetch(url);
  const text = await response.text();
  try {
    const result = JSON.parse(text) as T;
    return result;
  } catch (error) {
    const head = text?.substring(0, 30);
    throw Error(`Failed to parse json response: ${url} -> ${head}...`);
  }
};

// mutate given object, removing null, undefined and empty string prop values
export const removeEmptyStringProperties = (obj: {
  [key: string]: any;
}): void => {
  for (let key in obj) {
    const value = obj[key];
    if (value === '' || value === null || value === undefined) {
      delete obj[key];
    }
  }
};

export const clearMapWithFalseValues = (map: Map<string, any>) => {
  // debugger;
  // console.log(`clear map - keys: ${JSON.stringify(map.keys())}`);
  for (const key of map.keys()) {
    map.set(key, false);
  }
  // debugger;
};
