/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { TryFirstSoundbiteButton } from './try-first-soundbite-button';
import aliceWalkingImgSrc from './assets/alice-walking.png';

import __ from '@core/lib/localization';

const WaveWrapper = styled('div', {
  margin: 0,
  padding: 0,
  height: 'auto',
  position: 'relative',
  bottom: -4,
  '& svg': {
    width: '100%',
    // height: 75,
  },
});

const wave = (
  <svg viewBox="0 0 1416 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9795_422282)">
      <path
        d="M-188 0C-496 0 -496 64 -804 64V72H1660V64C1352 64 1352 0 1044 0C736 0 736 64 428 64C120 64 120 0 -188 0Z"
        fill="#ABE0F7"
      />
    </g>
    <defs>
      <clipPath id="clip0_9795_422282">
        <rect width={1416} height={75} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Wrapper = styled('section', {
  backgroundColor: '#abe0f7',
  backgroundImage: `url(${aliceWalkingImgSrc})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center bottom',
  backgroundSize: 1780,
  position: 'relative',

  '@medium': {
    backgroundSize: 2960,
  },

  '& .inner': {
    padding: '40px 16px 374px 16px',
    width: 'min( $$maxWidth, calc(100% - 32px) )',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,

    '@medium': {
      textAlign: 'center',
      alignItems: 'center',
      maxWidth: 684,
      padding: '64px 16px 600px 16px',
    },

    '& .take-steps-title': {
      all: 'unset',
      textStyle: 'medium-heading',

      '@medium': {
        textStyle: 'large-heading',
      },
    },
    '& .take-steps-body': {
      all: 'unset',
      textStyle: 'serif',
    },
    '& .cta': {
      paddingTop: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
});

export const TakeStepsSection = ({
  continuePath,
}: {
  continuePath: string;
}) => {
  return (
    <>
      <WaveWrapper className="wave">{wave}</WaveWrapper>
      <Wrapper className="take-steps">
        <div className="inner">
          <h2 className="take-steps-title">
            {__(
              'Take small steps, and giant leaps',
              'takeSmallStepsAndGiantLeaps'
            )}
          </h2>
          <p className="take-steps-body">
            {__(
              "The free Daily Soundbite is your everyday habit, something you can do, even on busy days. When you're ready, try the full Jiveworld story with more of everything. Stroll or hike — both trails lead to real-world Spanish fluency.",
              'theFreeDailySoundbiteIsYourEverydayHabit'
            )}
          </p>
          <div className="cta">
            <TryFirstSoundbiteButton
              id="spa-sb-btn-3"
              continuePath={continuePath}
            />
          </div>
        </div>
      </Wrapper>
    </>
  );
};
