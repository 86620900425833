import { daysSinceEpoch } from '@utils/date-utils';
import { AppFactory } from '@app/app-factory';

const messages = {
  firstEver: [
    'Complete a soundbite, or work on a story to grow your daily streak',
  ],
  dayOne: [
    'The secret of getting ahead is getting started | Mark Twain',
    'The journey of a thousand miles starts with a single step',
    'The only way is up, baby --- for you and me now | Yazz',
    'Do not wait until the conditions are perfect to begin. Beginning makes the conditions perfect | Alan Cohen',
    "You're back on track. See how far you can go this time.",
    'The hardest part of a journey is taking the first step.',
    'There are only two mistakes one can make along the road to truth; not going all the way, and not starting | Buddha',
    "Great to see you again! Let's get that streak going.",
    'Come back tomorrow and double your daily streak',
    'There are no shortcuts to any place worth going | Beverly Sills',
    'The road to success is always under construction | Lily Tomlin',
    "It's a long way to the top if you wanna rock 'n' roll | AC/DC",
  ],
  other: [
    'Cada persona debe inventar su propio camino. | Gabriel García Márquez',
    'It does not matter how slowly you go as long as you do not stop | Confucius',
    'No llores porque ya se terminó, sonríe porque sucedió. | Gabriel García Márquez',
    "You're on fire! Keep the streak alive!",
    'La risa es el lenguaje del alma | Pablo Neruda',
    'Persistence is key! See you tomorrow for another one.',
    'La sabiduría nos llega cuando ya no sirve de nada | Gabriel García Márquez',
    "You're crushing it! Come back tomorrow for more.",
    'El tango es un sentimiento que se baila. | Astor Piazzolla',
    "You're building a tower of knowledge, brick by brick.",
    'No hay nada que temer en la vida, solo es necesario entender. | Isabel Allende',
    'Success is the sum of small efforts, repeated day-in and day-out. | Robert Collier',
    'El tiempo es la sustancia de la que estoy hecho. El tiempo es un río que me arrebata, pero yo soy el río. | Jorge Luis Borges',
    'Another day, another step closer to fluency',
    'El que lee mucho y anda mucho, ve mucho y sabe mucho. | Cervantes',
    'Your dedication is paying off! See you tomorrow for more.',
    'Pies, para qué los quiero si tengo alas para volar. | Frida Kahlo',
    'Keep going! Your future self will thank you.',
    'El secreto de una buena vejez no es otra cosa que un pacto honrado con la soledad. | Gabriel García Márquez',
    'Amazing consistency! Your Spanish skills are soaring.',
    'Podrán cortar todas las flores, pero no podrán detener la primavera. | Pablo Neruda',
    "Wow, you're on a roll! Keep the momentum going.",
    'Cada acto de comunicación es un acto de traducción. | Octavio Paz',
    "Don't stop now! You're on your way to mastery.",
    'Si uno no cuenta su historia, los demás se encargarán de hacerlo por uno. | Isabel Allende',
    "You're turning learning into a yearning. Keep it up!",
    'La verdadera belleza es la honestidad. Ser auténtico. Eso es lo que muestra en el rostro. | Dolores del Río',
    'Consistency is the secret to success. See you tomorrow!',
    'La vida es un carnaval, porque la vida es una fiesta si se sabe disfrutar. | Celia Cruz',
    'Your life does not get better by chance, it gets better by change | Jim Rohn',
    'Siempre imaginé que el Paraíso sería algún tipo de biblioteca. | Jorge Luis Borges',
    'Action is the foundational key to all success | Pablo Picasso',
    'I find that the harder I work, the more luck I seem to have | Thomas Jefferson',
    'Despertar es un naufragio del sueño | Octavio Paz',
    "Don't count the days, make the days count. | Muhammad Ali",
    'No habrá nunca una puerta. Estás adentro. | Jorge Luis Borges',
    'The only way to do great work is to love what you do | Steve Jobs',
    'Hay voces que uno no puede callar. Si se callan, hacen ruido. | Juan Rulfo',
    "Don't watch the clock; do what it does. Keep going. | Sam Levenson",
    'La vida no es la que uno vivió, sino la que uno recuerda, y cómo la recuerda para contarla. | Gabriel García Márquez',
  ],
} as const;

// @frankharrison I added this because we were getting widows in the streak messages and to me they look bad.
function unwidow(text: string) {
  return text.replace(/ ([^ ]*)$/, '\u00A0$1');
}

function parseMessage(message: string) {
  const [text, author] = message.split('|') as [text: string, author?: string];
  return {
    text: unwidow(text.trim()),
    author: author?.trim() ?? undefined,
  };
}

export type StreakMessageType = keyof typeof messages;

export function getStreakMessage(type: StreakMessageType) {
  const today = new Date(AppFactory.root.storyManager.currentDate);
  const n = daysSinceEpoch(today);
  switch (type) {
    case 'firstEver':
      return parseMessage(messages.firstEver[0]);
    case 'dayOne':
      return parseMessage(messages.dayOne[n % messages.dayOne.length]);
    case 'other':
      return parseMessage(messages.other[n % messages.other.length]);
    default:
      break;
  }
}
