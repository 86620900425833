import * as React from 'react';
import { VSpacer } from 'naan/primitives/spacer';
import * as Dialog from 'naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { VStack } from 'naan/primitives/stack';
import { Text } from 'naan/primitives/text';
import { styled } from 'naan/stitches.config';
import { useComboForm } from 'common/hooks/use-comboform';
import { classNameSchema } from 'core/lib/validation-rules';
import { AppFactory } from 'app/app-factory';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';

import __ from 'core/lib/localization';

const focusSelector = '&:focus-visible:not(.disabled)';

const Input = styled('input', {
  all: 'unset',
  backgroundColor: '$gray-50',
  textStyle: 'body',
  color: '$textPrimary',
  py: '$space$3',
  px: '$space$4',
  borderRadius: '$space$2',
  '&::placeholder': {
    color: '$gray-300',
  },
  [focusSelector]: {
    boxShadow: 'inset 0px 0px 0px 3px $colors$teal-200',
    boxSizing: 'border-box',
  },
});

type FormInput = {
  label: string;
};

type ClassCreationModalProps = {
  onSelectClass: (classId: string) => void;
  onClose: () => void;
};
const ClassCreationBody = observer(
  ({ onSelectClass, onClose }: ClassCreationModalProps) => {
    const { userManager } = AppFactory.root;
    const formRef = React.useRef<HTMLFormElement>(null);
    const defaultValues = { label: '' };
    const {
      register,
      handleSubmit,
      errors,
      clearValidationErrors,
      handleValidationErrors,
      watch,
    } = useComboForm<FormInput>({
      validationSchema: classNameSchema,
      defaultValues,
    });

    const handleCreate = React.useCallback(
      ({ label }: FormInput) => {
        clearValidationErrors();

        userManager.createClassroom(label).then(result => {
          AppFactory.toastService.open({
            message: result.message,
            type: 'success',
          });

          onSelectClass(result.classroom.id);
        }, handleValidationErrors);
      },
      [
        clearValidationErrors,
        userManager,
        handleValidationErrors,
        onSelectClass,
      ]
    );

    const valueWatch = watch('label');

    React.useEffect(() => {
      if (formRef.current) {
        const input = formRef.current.querySelector('input[type=text]');
        if (input instanceof HTMLInputElement) {
          input.focus();
          input.select();
        }
      }
    }, [userManager]);

    return (
      <>
        <Dialog.Container open={true} onDismiss={onClose}>
          <form onSubmit={handleSubmit(handleCreate)} noValidate ref={formRef}>
            <Dialog.Heading>{__('Add a class', 'addAClass')}</Dialog.Heading>
            <Dialog.Body>
              <VStack>
                <VSpacer size={4} />
                <label htmlFor="label">
                  <Text textStyle="small-text" color="textSecondary">
                    {__('Class name', 'className')}
                  </Text>
                </label>
                <VSpacer size={2} />
                <Input
                  type="text"
                  id="label"
                  {...register('label')}
                  placeholder="E.g. Advanced Spanish"
                />
                <VSpacer size={4} />
                <CatchAllFormErrors errors={errors} />
              </VStack>
            </Dialog.Body>
            <Dialog.ButtonsContainer>
              <Dialog.Button
                label={'Cancel'}
                presentation="cancel"
                type="button"
                onClick={onClose}
              />
              <Dialog.Button
                label="Create class"
                presentation="teal"
                disabled={!valueWatch}
              />
            </Dialog.ButtonsContainer>
          </form>
        </Dialog.Container>
      </>
    );
  }
);

export const ClassCreationModal = observer(
  ({ onSelectClass, onClose }: ClassCreationModalProps) => {
    return (
      <ClassCreationBody onSelectClass={onSelectClass} onClose={onClose} />
    );
  }
);
