import * as React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { useSoundbitesTabScrollingFx } from '../scrolling-fx';
import { SoundbiteCard, SoundbiteCardsContainer } from './soundbites-card';
import { VSpacer } from '@naan/primitives/spacer';
import { MediumGap } from '@naan/primitives/spacer/gaps';

export const SoundbitesTab = observer(() => {
  // track when it's safe to pop back from study view
  ReturnNavState.storyDetailVisited = true;

  const { storyManager } = AppFactory.root;
  // const { soundbitesEnabled } = storyManager;
  const { slug } = useParams<{ slug: string }>();
  const story = storyManager.story(slug);

  useSoundbitesTabScrollingFx();

  const soundbites = story.visibleSoundbites;

  return (
    <CenterColumnLayout>
      {story.multiUnit ? null : <VSpacer size={8} />}
      <SoundbiteCardsContainer>
        {soundbites.map(soundbite => (
          <SoundbiteCard soundbite={soundbite} />
        ))}
      </SoundbiteCardsContainer>
      <MediumGap />
    </CenterColumnLayout>
  );
});
