import { isEmpty } from 'lodash';
import { createLogger } from 'app/logger';
import {
  UserData,
  UserDataSnapshot,
} from '@core/models/user-manager/user-data';
import { CatalogMeta } from './catalog-meta-sync';
import { GlobalSettings } from '@core/models/global-settings';
import { MixpanelProperties } from '@common/analytics/analytics-utils';
import { deepNoEmptyObject } from '@utils/deep-merge-diff';
// import { bugsnagNotify } from '@app/notification-service';

const log = createLogger('cali-server-invoker');

// duplicated from cali-app-server mailer-service.ts
export interface ExportVocabParams {
  email: string;
  name: string;
  storySlug: string;
  storyTitle: string;
  vocabData: string[][];
}

// parallel to cali-app-server reporting-service.ts
// derived from browser sdk typing
//   https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/mixpanel/index.d.ts

export interface TrackBatchParams {
  // clientKey: string; // todo: app api security
  agentPlatform?: string;
  agentVersion?: string;
  events: MixpanelEvent[];
}

export interface MixpanelEvent {
  event: string;
  properties: MixpanelProperties;
}

/**
 * Helper object to provide an abstraction to access the api
 * with baked-in loading flag.
 */
export class CaliServerInvoker {
  apiEnv: string;

  constructor({ apiEnv }: { apiEnv: string }) {
    this.apiEnv = apiEnv;
    // this.authToken = authToken;
  }

  async exportVocabPoc() {
    const email = 'joseph@jiveworld.com';
    const name = 'JE';
    const storyTitle = 'El coyote';
    const storySlug = 'el-coyote';
    const vocabData = [
      ['how', 'now'],
      ['brown', 'frog'],
    ];
    await this.exportVocab({ email, name, storyTitle, storySlug, vocabData });
  }

  async exportVocab(params: ExportVocabParams) {
    const apiUrl = `${this.baseUrl}/mailer/vocab`;
    log.info(`invoking: POST ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    log.info(`export vocab resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  // todo: consider always fetching and merging before persist
  // async syncUserData(uuid: string, data: object): Promise<void> {
  //   const remoteData = await this.fetchUserData(uuid);
  // }

  async fetchUserData(
    uuid: string
  ): Promise<UserData> /* todo: snapshot typing */ {
    return await this.fetchResource('userDatas', uuid);
  }

  async storeUserData(uuid: string, data: object): Promise<void> {
    const apiUrl = `${this.baseUrl}/userDatas/${uuid}`;
    log.info(`invoking: PUT ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(data),
    });
    const json = await response.json();
    // log.info(`store user data resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async mergeSyncUserData(
    uuid: string,
    delta: object
  ): Promise<UserDataSnapshot> {
    const apiUrl = `${this.baseUrl}/userDataAux/${uuid}/mergeSync`;
    log.info(`invoking: POST ${apiUrl}`);
    if (!deepNoEmptyObject(delta)) {
      // still possible to have nested empty properties with current diff logic
      log.debug(
        `found empty subobject in mergeSyncUserData: ${JSON.stringify(delta)}`
      );
      // bugsnagNotify(`mergeSyncUserData - empty subobject`);

      // can't be fatal until deep diff is behaving correctly
      // throw Error('found empty subobject in mergeSyncUserData');
    }
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ delta }),
    });
    const json = await response.json();
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async fetchCatalogMeta(slug: string): Promise<CatalogMeta> {
    return await this.fetchResource('catalogs', slug);
  }

  async fetchGlobalSettings(): Promise<GlobalSettings> {
    return await this.fetchResource('settings', 'global');
  }

  async fetchResource<T>(collectionPath: string, docId: string): Promise<T> {
    const apiUrl = `${this.baseUrl}/${collectionPath}/${docId}`;
    log.info(`invoking: GET ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'GET',
      // headers: {
      //   'Content-Type': 'application/json',
      //   'Access-Control-Allow-Origin': '*',
      // }
    });
    const json = await response.json();
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  // todo: better factor
  async listCatalogMetas(): Promise<CatalogMeta[]> {
    const apiUrl = `${this.baseUrl}/catalogs`;
    log.info(`invoking: GET ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'GET',
      // headers: {
      //   'Content-Type': 'application/json',
      //   'Access-Control-Allow-Origin': '*',
      // }
    });
    const json = await response.json();
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async trackBatch(params: TrackBatchParams) {
    const apiUrl = `${this.baseUrl}/reporting/trackBatch`;
    log.info(`invoking: POST ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    log.info(`track batch resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  // async track(event: MixpanelEvent) {
  //   const apiUrl = `${this.baseUrl}/reporting/track`;
  //   log.info(`invoking: POST ${apiUrl}`);
  //   const response = await fetch(apiUrl, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //     },
  //     body: JSON.stringify({ event }),
  //   });
  //   const json = await response.json();
  //   log.info(`track resp: ${JSON.stringify(json)}`);
  //   if (!isEmpty(json.error)) {
  //     throw Error(JSON.stringify(json));
  //   }
  //   return json.result;
  // }

  async backupPriorData(uuid: string): Promise<{ timestamp: string }> {
    const apiUrl = `${this.baseUrl}/userDataBackups/${uuid}/backupPriorData`;
    log.info(`invoking: POST ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
    const json = await response.json();
    log.info(`backupPriorData resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async createBackup(
    uuid: string,
    data: object
  ): Promise<{ timestamp: string }> {
    const apiUrl = `${this.baseUrl}/userDataBackups/${uuid}/backup`;
    log.info(`invoking: POST ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ data }),
    });
    const json = await response.json();
    log.info(`createBackup resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async listBackups(
    uuid: string,
    limit: number = 10
  ): Promise<{ timestamps: string[] }> {
    const apiUrl = `${this.baseUrl}/userDataBackups/${uuid}/list?limit=${limit}`;
    log.info(`invoking: GET ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
    const json = await response.json();
    log.info(`listBackups resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async fetchBackup(
    uuid: string,
    timestamp: string
  ): Promise<{ data: UserDataSnapshot }> {
    const apiUrl = `${this.baseUrl}/userDataBackups/${uuid}/fetch?timestamp=${timestamp}`;
    log.info(`invoking: GET ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
    const json = await response.json();
    // log.info(`fetchBackup resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async removeBackup(
    uuid: string,
    timestamp: string
  ): Promise<UserDataSnapshot> {
    const apiUrl = `${this.baseUrl}/userDataBackups/${uuid}/remove?timestamp=${timestamp}`;
    log.info(`invoking: POST ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
    const json = await response.json();
    log.info(`removeBackup resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  get baseUrl() {
    const result = apiUrlForEnv(this.apiEnv);
    if (isEmpty(result)) {
      throw Error(`invalid apiEnv key: ${this.apiEnv}`);
    }
    return result;
  }
}

const apiHosts: { [index: string]: string } = {
  local: 'http://localhost:3030',
  // jfedev: 'https://jfedev.ngrok.io',
  devtest: 'https://cali-app-server-devtest.jiveworld.app',
  staging: 'https://cali-app-server-staging.jiveworld.app',
  beta: 'https://cali-app-server-beta.jiveworld.app',
  LIVE: 'https://node.jiveworld.com',
};

const apiUrlForEnv = (apiEnv: string): string => {
  return apiHosts[apiEnv];
};
