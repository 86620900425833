import * as React from 'react';
import { Button } from '@naan/primitives/button';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { SettingsModal } from './settings-modal';
import { GearIcon } from '@naan/icons/gear-icon';

export const AdvancedSettingsButton = observer(() => {
  return (
    <Button
      label={'Advanced Settings'}
      leftIcon={<GearIcon />}
      presentation={'grayLight'}
      size={'small'}
      onClick={() => {
        AppFactory.dialogPresenter.present(onDismiss => (
          <SettingsModal onDismiss={onDismiss} />
        ));
      }}
    />
  );
});
