import * as React from 'react';
import * as Sentry from '@sentry/react';
import { appConfig } from '@app/env';

import { ErrorBoundary } from 'components/error-boundary';
import { globalErrorHandler } from 'global-error-handler';
import { createLogger } from '@common/log';
import { AppFactory } from '@app/app-factory';

const log = createLogger('bug-reporting');

const errorReportingEnabled = appConfig.sentry.enabled;

if (errorReportingEnabled) {
  const {
    tracesSampleRate,
    enableReplay,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  } = appConfig.sentry;

  const beforeSend = (event: Sentry.Event, hint: Sentry.EventHint) => {
    const { originalException } = hint;
    if (originalException) {
      globalErrorHandler(originalException);
    }
    event.contexts = event.contexts || {};
    // capture properties which might change since the last call to setReportingContext
    event.contexts.miscState = {
      offline: AppFactory.root?.offline,
      firebaseStatus: AppFactory.firebaseConnection?.status,
    };
    return event;
  };

  const integrations = [];
  integrations.push(new Sentry.BrowserTracing());

  if (enableReplay) {
    integrations.push(new Sentry.Replay());
  }

  log.debug('Sentry.init');
  Sentry.init({
    dsn: appConfig.sentry.dsn,
    integrations,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    beforeSend,
    tracesSampleRate,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
    // todo: understand `autoSessionTracking`
    // https://docs.sentry.io/platforms/javascript/configuration/options/?original_referrer=https%3A%2F%2Fjiveworld.sentry.io%2F#ignore-errors

    // https://docs.sentry.io/platforms/javascript/configuration/options/?original_referrer=https%3A%2F%2Fjiveworld.sentry.io%2F#ignore-errors
    // ignoreErrors - can provide regex patterns to ignore
  });
} else {
  log.debug('Sentry disabled');
  window.onerror = globalErrorHandler; // revisit this someday, API doesn't quite match
  window.onunhandledrejection = globalErrorHandler;
}

export const BugReportingBoundary = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    if (errorReportingEnabled === true) {
      /// we will use Sentry's ErrorBoundary if errorReportingEnabled is true
      return (
        <Sentry.ErrorBoundary
          fallback={({ error, componentStack, resetError }) => (
            <div>
              <h1>{error.message}</h1>
              <button onClick={resetError}>Try again</button>
            </div>
          )}
        >
          {children}
        </Sentry.ErrorBoundary>
      );
    }

    /// or use our own ErrorBoundary if errorReportingEnabled is false
    return <ErrorBoundary>{children}</ErrorBoundary>;
  }
);
