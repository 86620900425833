import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { HeroSectionVideoPlayer } from './hero-video-player';
import { HeroSectionPreview } from './hero-section-preview';

const Wrapper = styled('div', {
  // backgroundImage:
  //   'radial-gradient(72% 72% at 50% 75%, #2D597E 50%, #20405A 100%)',
  // backgroundSize: 'cover',

  '& > .inner': {
    // backgroundImage: `url(${videoThumbMobileImgSrc})`,
    backgroundImage:
      'radial-gradient(72% 72% at 50% 75%, #2D597E 50%, #20405A 100%)',
    backgroundSize: 'cover',
    position: 'relative',

    '@medium': {
      borderRadius: 16,
      width: 'min( $$maxWidth, 100% - 32px )',
      margin: '0 auto',
    },

    '& > .video-container': {
      position: 'absolute',
      inset: 0,
      background: 'blue',
      zIndex: 1,
      overflow: 'auto',
      '@medium': {
        borderRadius: 16,
      },
    },
  },
});

export const HeroSection = ({ continuePath }: { continuePath: string }) => {
  const showingVideo = useSwitch2(false);
  return (
    <Wrapper className="hero">
      <div className="inner">
        {showingVideo.value ? (
          <div className="video-container">
            <HeroSectionVideoPlayer continuePath={continuePath} />
          </div>
        ) : null}
        <HeroSectionPreview
          toggleVideo={showingVideo.toggle}
          showingVideo={showingVideo.value}
        />
      </div>
    </Wrapper>
  );
};
