import React from 'react';
import { observer } from 'mobx-react';
import { makeFilterLink } from '@common/filtering/make-filter-link';
import { learnStoriesPath } from 'components/nav/path-helpers';
import { AppFactory } from '@app/app-factory';
import { TFilter, filterTypes } from '@common/filtering-core';
import { TFilterKey } from '@common/filtering/filter-keys';
import { FilterWidgetData } from '@tikka/client/catalog-types';
import {
  SectionHeading,
  SectionHeadingLink,
} from './dashboard-section-heading';
import { Responsive } from '@naan/primitives/responsive';
import { StoryRow } from './story-row';
import __ from '@core/lib/localization';

// interface TagWidgetParams {
//   key: TFilterKey;
//   value: string;
//   heading: string;
// }
// // hack needed data here until masala side flushed out
// const tagDataMap: { [index: string]: TagWidgetParams } = {
//   shorts: {
//     key: 'topics',
//     value: 'Shorts',
//     heading: 'Shorter stories',
//   },
//   uplifting: {
//     key: 'topics',
//     value: 'Uplifting',
//     heading: 'Uplifting stories',
//   },
//   arg: {
//     key: 'countries',
//     value: 'Argentina',
//     heading: 'Stories from Argentina',
//   },
// };

export const FilterWidget = observer(
  ({ widgetData }: { widgetData: FilterWidgetData }) => {
    if (!widgetData) {
      return null; // guard against hard brakage on incomplete catalogs
    }

    const { storyManager } = AppFactory.root;
    const { key, value, heading } = widgetData;
    const values = [value];
    const filterPath = makeFilterLink(
      learnStoriesPath(),
      key as TFilterKey,
      values
    );
    const filter: TFilter = {
      queryType: key,
      key: key,
      value: values,
      method: filterTypes.INCLUDES_ANY,
    };
    const filteredStories = storyManager.filteredList(filter);

    return (
      <>
        <SectionHeading
          title={heading}
          renderLinks={() => (
            <SectionHeadingLink to={filterPath}>
              {__('Show all', 'showAll')}
            </SectionHeadingLink>
          )}
        />

        <Responsive
          renderDefault={() => <StoryRow stories={filteredStories} n={2} />}
          renderSmallAndUp={() => <StoryRow stories={filteredStories} n={3} />}
          renderMediumAndUp={() => <StoryRow stories={filteredStories} n={4} />}
        />
      </>
    );
  }
);
