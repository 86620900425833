import * as React from 'react';
// import rg4js from 'raygun4js';
import App from './app';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { BugReportingBoundary } from 'common/bugsnag/bug-reporting-boundary';
import { SpaGlobalStyles } from 'components/ui/spa-global-styles';
import { createLogger } from '@common/log';
import { UpdateManager } from 'pwa/window/update-manager';
// import { appConfig } from '@app/config';

// only imported now when localization is enabled
// import 'lib/services/localization-service';

const log = createLogger('index');

// const raygunEnabled = appConfig.raygun.enabled;
// if (raygunEnabled) {
//   rg4js('apiKey', appConfig.raygun.apiKey);
//   rg4js('enablePulse', true);
// }

/// hack to mock window.process, for some reason our markdown library requires it
(window as any).process = { cwd: () => '' };
// (window as any).Bugsnag = Bugsnag;

// always attempt to listen for updates except for dev build
const updateManagerEnabled = import.meta.env.PROD;

log.debug(`updateManagerEnabled: ${String(updateManagerEnabled)}`);

// disable mobx strict-mode
configure({
  enforceActions: 'never',
});

ReactDOM.render(
  <BugReportingBoundary>
    <SpaGlobalStyles />
    <App />
    {updateManagerEnabled ? <UpdateManager /> : null}
  </BugReportingBoundary>,
  document.getElementById('root')
);

// // useful for isolated scratch code testing
// ReactDOM.render(
//   <BugReportingBoundary bugsnagStatic={Bugsnag} bugsnagEnabled={bugsnagEnabled}>
//     <h2>scratch</h2>
//   </BugReportingBoundary>,
//   document.getElementById('root')
// );

// (window as any).initMp = () => {
//   (window as any).mp = new MixpanelAnalytics();
// };
