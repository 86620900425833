import React from 'react';
import { observer } from 'mobx-react';
import { MarkSimple } from '@naan/primitives/text';
import { AppFactory } from '@app/app-factory';
import { Link } from 'react-router-dom';
import { styled } from '@naan/stitches.config';
import { ComingSoonWidget } from '../coming-soon-widget';
import { Soundbite } from '@core/models/story-manager/soundbite';
import { SoundbiteCategory } from 'components/soundbites/soundbite-categories';
import {
  headerColors,
  headerImages,
} from 'components/soundbites/soundbite-headers';
import { SoundbiteIconExtraSmall } from '@naan/icons/soundbite-icon';
import { formatDate } from '@utils/format-date';
import { ScriptHeaderCategoryInfo } from 'soundbite/views/info/script-header-category-info';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { Image } from '@naan/primitives/image';
import {
  dailySoundbitePath,
  learnStorySlugPath,
} from 'components/nav/path-helpers';
import { stripUnderscores } from '@utils/content-string-utils';
import { useCountdown } from 'components/ui/player-end-card/countdown';

import __ from '@core/lib/localization';
import classNames from 'classnames';
import { ZeroStateSoundbiteWidget } from './zero-state';

const WidgetContainer = styled('div', {
  position: 'relative',
  $$backgroundColor: '$colors$white',
  $$backgroundImage: '',
  $$thumbSize: '48px',

  backgroundColor: '$$backgroundColor',
  backgroundImage: '$$backgroundImage',
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

  flex: 1,

  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',

  isolation: 'isolate',

  borderRadius: '12px',
  padding: '$space$3 $space$4 $space$4 $space$4',

  '@medium': {
    padding: '$space$5 $space$6 $space$6 $space$6',
    $$thumbSize: '92px',
  },

  '& > .link': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
  },

  '& > .header': {
    color: '$white',
    textStyle: 'small-caps',
    paddingBottom: '$space$3',
    display: 'flex',
    gap: 6,
    '& > .date': {
      marginLeft: 'auto',
    },
    '@medium': {
      paddingBottom: '15px', //The slightly quirky 15px margin below the header compensates for the 1px rule below it, to ensure that the final height is 280px
    },
    borderBottom: '1px solid $white-alpha-30',
  },

  '& > .teaser': {
    color: '$white',
    textStyle: 'small-caps',
    paddingBottom: '$space$3',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 6,
    '@medium': {
      paddingBottom: '15px', //The slightly quirky 15px margin below the header compensates for the 1px rule below it, to ensure that the final height is 280px
    },
    borderBottom: '1px solid $white-alpha-30',
  },

  '& > .body': {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '$space$3',
    flex: 1,
    '@medium': {
      marginTop: '$space$4',
    },
    '& > .body-text': {
      color: '$white',
      textStyle: 'small-text',
      $$lineHeight: '20px', // this assumes small-text style
      '& > .single-line': {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
      },
      '& > .title': {
        textStyle: 'small-heading',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        '@medium': {
          textStyle: 'medium-heading',
        },
      },
      '& > .prompt': {
        $$numberOfLines: 2,
        '@medium': {
          $$numberOfLines: 3,
        },
        overflow: 'hidden',
        maxHeight: 'calc($$lineHeight *  $$numberOfLines)',
        textOverflow: 'ellipsis',
        /// this next lines are not standard, so it may not work in all browsers
        /// or stop working on some point. they are being used to render ... at the end
        // of text. the height limit set above will work regardless of these props
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '$$numberOfLines',
      },
    },
  },

  '& > .category-container': {
    display: 'flex',
    alignItems: 'flex-start',
    // marginTop: 'auto',

    color: '$white',
    zIndex: 1,
    width: 'fit-content',
    marginTop: 24,
    '@medium': {
      marginTop: 0,
    },
  },

  '& > .story-card': {
    all: 'unset',
    cursor: 'pointer',
    display: 'grid',
    gridTemplateColumns: '$$thumbSize 1fr',
    gap: 12,
    zIndex: 1,
    marginTop: 24,
    '@medium': {
      gap: 16,
      marginTop: 0,
    },
    '& > img': {
      width: '$$thumbSize',
      height: '$$thumbSize',
      borderRadius: '8px',
      filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3))',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      '& > .story-card-heading': {
        color: '$white',
        textStyle: 'small-caps',
      },
      '& > .story-card-title': {
        color: '$white',
        textStyle: 'small-heading',
      },
      '& > .story-card-tagline': {
        color: '$white',
        textStyle: 'small-text',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '2',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'none',
        '@medium': {
          display: '-webkit-box',
        },
      },
    },
  },

  '&.completed': {
    backgroundImage:
      'linear-gradient(360deg, rgba(0, 0, 0, 0.35) 30%, rgba(0, 0, 0, 0) 77%), $$backgroundImage',
  },
});

export const SoundbiteWidget = observer(
  // observer probably not needed here
  ({ soundbite }: { soundbite: Soundbite }) => {
    if (!soundbite) {
      return <ComingSoonWidget />;
    }

    const { storyManager, userManager } = AppFactory.root;

    if (userManager.userData.needsSoundbiteOrientation) {
      return <ZeroStateSoundbiteWidget />;
    }

    const story = storyManager.story(soundbite.volumeSlug);
    if (!story) return null; // should figure out why this happens sometimes

    const country = story.countries?.join(', ') || '';

    const date = soundbite?.releaseDate;
    // important to get the category from the excerpt data so the masala preview links works
    const category = soundbite.category as SoundbiteCategory;

    const $$backgroundColor = headerColors[category] ?? '#000';
    const $$backgroundImage = headerImages[category];

    return (
      <WidgetContainer
        css={{ $$backgroundColor, $$backgroundImage }}
        className={classNames({
          completed: soundbite.completed,
        })}
      >
        <Link to={dailySoundbitePath()} className="link" />
        {soundbite.completed ? (
          <div className="teaser">
            <SoundbiteIconExtraSmall />
            <span>{__('Next Soundbite in', 'nextSoundbiteIn')}</span>
            <MiniCountdown />
          </div>
        ) : (
          <div className="header">
            <SoundbiteIconExtraSmall />
            {__("Today's Soundbite", 'todaysSoundbite')}
            <span className="date">{date && formatDate(date)}</span>
          </div>
        )}
        <div className="body">
          <div className="body-text">
            <div className="title single-line">
              {soundbite.completed ? (
                <span className="icon">
                  <CheckmarkCircleIcon />
                </span>
              ) : null}
              <MarkSimple source={soundbite.title} />
            </div>
            {soundbite.completed ? null : (
              <div className="single-line">
                <MarkSimple source={`from ${story.title} • ${country}`} />
              </div>
            )}
          </div>
        </div>
        {soundbite.completed ? (
          <Link
            className="story-card"
            to={learnStorySlugPath(story.slug, 'story') + '?scroll=top'}
          >
            <Image src={story.listImageUrl} alt={story.title} />
            <div>
              <span className="story-card-heading">
                {__('View the full story', 'viewTheFullStory')}
              </span>
              <span className="story-card-title">
                <MarkSimple source={story.title} />
              </span>
              <span className="story-card-tagline">
                <MarkSimple source={stripUnderscores(story.tagline)} />
              </span>
            </div>
          </Link>
        ) : (
          <div className="category-container">
            <ScriptHeaderCategoryInfo soundbite={soundbite} />
          </div>
        )}
      </WidgetContainer>
    );
  }
);

const MiniCountdown = () => {
  const { countdown } = useCountdown();

  return <span>{countdown}</span>;
};
