import dayjs, { Dayjs } from 'dayjs';
import __ from '@core/lib/localization';

export function getRelativeTime(date: Dayjs, now: Dayjs = dayjs()): string {
  const diff = Math.round(now.diff(date, 'day'));

  if (diff < 1) {
    return __('today', 'relativeTime.today');
  } else if (diff === 1) {
    return __('yesterday', 'relativeTime.yesterday');
  } else if (diff < 7) {
    return __('%{count} days ago', 'relativeTime.xDaysAgo', {
      count: diff,
    });
  } else if (diff < 30) {
    return __(
      { one: '1 week ago', other: '%{count} weeks ago' },
      'relativeTime.xWeeksAgo',
      {
        count: Math.floor(diff / 7),
      }
    );
  } else if (diff < 365) {
    return __(
      { one: '1 month ago', other: '%{count} months ago' },
      'relativeTime.xMonthsAgo',
      {
        count: Math.floor(diff / 30),
      }
    );
  } else {
    return __(
      { one: '1 year ago', other: '%{count} years ago' },
      'relativeTime.xYearsAgo',
      {
        count: Math.floor(diff / 365),
      }
    );
  }
}
