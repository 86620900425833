import * as React from 'react';

import { RaLogo } from 'components/branding/ra-logo';
import { aboutRaId } from '../about-ra';
import { scrollContainerToElementId } from 'lib/scroll-utils';
import { styled } from '@naan/stitches.config';
import { germanMode } from '@core/lib/app-util';

function scrollToAboutRa() {
  const id = aboutRaId;
  scrollContainerToElementId(id);
}

const Wrapper = styled('div', {
  cursor: 'pointer',
});

// placeholder for later when we add interaction for the logo
export const StoriesByRaNav = () => {
  return (
    <Wrapper onClick={scrollToAboutRa}>
      {germanMode() ? '[stories by placeholder]' : <RaLogo width={280} />}
    </Wrapper>
  );
};

export const StoriesByRaNavSmall = () => {
  return (
    <Wrapper onClick={scrollToAboutRa}>
      {germanMode() ? (
        '[stories by placeholder]'
      ) : (
        <RaLogo width={177} presentation="onDark" />
      )}
    </Wrapper>
  );
};
