import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { FullScreenLoader } from 'components/ds/modals';
// import { usePageTracker } from 'lib/hooks/use-page-tracker';
import { useQueryMessageDispatcher } from 'components/account/use-query-message-dispatcher';
import { StreakInterstitial } from 'components/ui/streak-interstitial';

// for both the signed-in and anonymous app screens (everything outside of auth and admin screens)
export const AppRouteLayout = ({
  /// this allows us to have the signed layout, but different design layout
  component: Component = null /*required now*/,
}) => {
  // usePageTracker();
  useQueryMessageDispatcher();

  return (
    <Component>
      <React.Suspense fallback={<FullScreenLoader />}>
        <Outlet />
      </React.Suspense>
      <StreakInterstitial />
    </Component>
  );
};
