import * as React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { getStartedPath } from 'components/nav/path-helpers';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { Image } from '@naan/primitives/image';

import __ from 'core/lib/localization';

import storiesByRaImage from './stories-by-ra.png';
import dancersLargeImage from './dancers-large.svg';
import dancersSmallImage from './dancers-small.svg';
import jwLogoImage from './jiveworld-espanol.png';

const Wrapper = styled('div', {
  background: '$blue-800',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: 'var(--sab)',
  paddingTop: 'var(--sat)',
  '& .branding': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 64,
    flex: 1,
    width: '100%',
    position: 'relative',
    '& .bg-image': {
      backgroundImage: `url(${dancersSmallImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      maxHeight: 480,
      '@small': {
        backgroundImage: `url(${dancersLargeImage})`,
        width: 'min( calc(100% - 22px), 770px )',
        backgroundSize: 'contain',
      },
    },
    '& .logo-jw': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 282,
      transform: 'translate(-50%, -50%)',
    },
  },
  '& .inner': {
    width: 'min(calc(100% - 48px), 400px)',
    // background: '$blue-900',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: 24,

    '& > .logo-ra': {
      width: 236,
      margin: '40px 0 48px 0',
    },

    '@small': {
      paddingBottom: 48,
    },
  },
});

export const WelcomeScreen = observer(() => {
  // const { accountCreationDisabled, userManager } = AppFactory.root;

  // allow deep linking for now at least (makes dev / review easier)
  // if (userManager.authenticated || !accountCreationDisabled) {
  //   return <Navigate to={homePath()} />;
  // }

  const navigate = useNavigate();

  // todo: german version

  return (
    <Wrapper>
      <div className="branding">
        <Image
          className="logo-jw"
          src={jwLogoImage}
          alt={__('Jiveworld Español', 'jiveworldEspanol')}
        />
        <div className="bg-image"></div>
      </div>
      <div className="inner">
        <Image
          className="logo-ra"
          src={storiesByRaImage}
          alt={__('Stories by Radio Ambulante', 'storiesByRadioAmbulante')}
        />
        <Button
          onClick={() => navigate(getStartedPath())}
          label={__('Get started', 'getStarted')}
          presentation="teal"
          size="large"
          fullWidth
        />
      </div>
    </Wrapper>
  );
});
