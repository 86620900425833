import * as React from 'react';

import { observer } from 'mobx-react';
import { VocabRowData } from '@core/models/ui/vocab-list-model';
import { styled } from '@naan/stitches.config';
import { MinusCircleSmallIcon } from '@naan/icons/minus-circle-icon';
import { PlusCircleSmallIcon } from '@naan/icons/plus-circle-icon';
import { IconButton } from '@naan/primitives/button';
import classNames from 'classnames';
import { PlainMarkdown } from '@naan/primitives/text';
import { vocabUsageText } from '@core/models/user-manager/story-progress';

const Wrapper = styled('div', {
  marginLeft: 40,
  padding: '8px 56px 12px 0',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  '& > .headword': {
    textStyle: 'serif',
  },
  '& > .note': {
    textStyle: 'small-text',
    color: '$textSecondary',
  },
  '& > .action': {
    position: 'absolute',
    right: 8,
    top: 14,
  },
  '&.removing > .headword': {
    color: '$gray-100',
  },
  '&.removing > .note': {
    color: '$gray-100',
  },
});

export const VocabRow = observer(({ data }: { data: VocabRowData }) => {
  // const text = data.hasUsageText
  //   ? `${data.usageText} (${data.headword})`
  //   : data.headword;
  const text = vocabUsageText(data.notation);

  return (
    <Wrapper className={classNames({ removing: data.remove })}>
      <PlainMarkdown className="headword" source={text} />
      <PlainMarkdown className="note" source={data.note} />
      <div className="action">
        <IconButton
          onClick={() => data.toggle()}
          icon={
            data.remove ? <PlusCircleSmallIcon /> : <MinusCircleSmallIcon />
          }
        />
      </div>
    </Wrapper>
  );
});
