// import { runInAction } from 'mobx';
import fetch from 'cross-fetch';
import { groupBy, uniq, flatten, floor, isEmpty } from 'lodash';
import moment from 'moment'; // TODO: remove 'moment' usage in favor of dayjs
import Dayjs from 'dayjs';

import {
  applySnapshot,
  Bindery,
  identifier,
  ModelTreeNode,
  // snap,
  volatile,
} from 'ts-state-tree/tst-core';
import { createLogger } from 'app/logger';

import { Story } from './story';
import {
  ActivityGuide,
  Author,
  ChapterCatalogData,
  UnitCatalogData,
} from '../catalog';
import {
  TFilter,
  TSortingCallback,
  TSortingDescription,
  filterList,
} from 'common/filtering-core';
import { StoryCollection } from '../catalog/featured-release';
import { Root } from '../root';
import { sortBy, SORT_ORDER } from 'utils/util';
import { isClassroomFilterKey } from 'core/lib/constants/vars';
import filterNewStories from 'core/lib/filter-new-stories';
import { getBaseRoot } from '../app-root';
import { notEmpty } from '@utils/conditionals';
import { Credit } from '../catalog/credit';
import { Speaker } from '../catalog/speaker';
import { FilterWidgetData } from '@tikka/client/catalog-types';
import {
  adjustDate,
  dayOfMondayBasedWeek,
  firstDayOfWeek,
  MILLIS_PER_DAY,
} from '@utils/date-utils';
import { VideoGuide } from '../catalog/video-guide';
import { AppFactory } from '@app/app-factory';
import { alertWarningError, bugsnagNotify } from '@app/notification-service';
import { SoundbiteEdition } from './soundbite-edition';
import { Soundbite } from './soundbite';
import { appConfig } from '@app/config';
import { germanMode } from '@core/lib/app-util';

const log = createLogger('story-manager');

const LOCAL_DATA_CACHE_KEY = 'catalog-raw'; // appStateCacher key

// duplicate strings with UserManager.storyFilterLabels
export const enum PrimaryFilterKeys {
  // eslint-disable-next-line no-unused-vars
  ALL = 'all',
  // eslint-disable-next-line no-unused-vars
  UNPLAYED = 'unplayed',
  // eslint-disable-next-line no-unused-vars
  QUEUED = 'queued',
  // eslint-disable-next-line no-unused-vars
  IN_PROGRESS = 'inProgress',
  // STARTED = 'started',
  // eslint-disable-next-line no-unused-vars
  COMPLETED = 'completed',
  // classroom filters are dynamic and don't need code refs
}

// const __currentDate = moment().format('YYYY-MM-DD');
const resolveCurrentDate = () => moment().format('YYYY-MM-DD');

const millisUntilMidnight = () => {
  const midnight = new Date();
  midnight.setHours(24, 0, 5 /* 5 seconds past for paranoia */, 0);
  const result = midnight.getTime() - new Date().getTime();
  return result;
};

const DATE_REFRESH_INTERVAL_MILLIS = 5 * 60 * 1000; // minimum time to sleep between refresh checks

// these are only relevant to the classroom discovery view
// todo: rename to classroomStoryGroups
export const unitGroups = {
  FREE: 'FREE',
  WITH_GUIDES: 'WITH_GUIDES',
  PREMIUM: 'PREMIUM',
};

const unitGroupBy = (unit: Story) => {
  if (unit.trial === true) {
    return unitGroups.FREE;
  }
  if (unit.activityGuideUrl) {
    return unitGroups.WITH_GUIDES;
  }

  return unitGroups.PREMIUM;
};

// todo: rename to classroomStoryGrouped
const grouped = (collection: Story[]) => {
  return groupBy(collection, unitGroupBy);
};

// end-user story groups
export const storyGroups = {
  FREE: 'FREE',
  PREMIUM: 'PREMIUM',
};

const storyGroupBy = (story: Story) => {
  if (story.trial === true) {
    return unitGroups.FREE;
  }
  return unitGroups.PREMIUM;
};

const storyGrouped = (collection: Story[]) => {
  return groupBy(collection, storyGroupBy);
};

const releaseDateSortFn = (a: Story, b: Story) => {
  return a.releaseDate > b.releaseDate ? -1 : 1;
};

// populates from the CatalogCaliData masala schema
export class StoryManager extends ModelTreeNode {
  static CLASS_NAME = 'StoryManager' as const;

  static bindModels(bindery: Bindery): void {
    bindery.bind(StoryManager);
    bindery.bind(Story);
    bindery.bind(UnitCatalogData);
    bindery.bind(ChapterCatalogData);
    bindery.bind(Speaker);
    bindery.bind(Credit);
    bindery.bind(StoryCollection);
    bindery.bind(ActivityGuide);
    bindery.bind(Author);
    bindery.bind(Soundbite);
    bindery.bind(SoundbiteEdition);
    bindery.bind(VideoGuide);
  }

  static create(snapshot: any) {
    return super.create(StoryManager, snapshot) as StoryManager;
  }

  @identifier
  catalogUrl: string = null;

  // catalog slug
  slug: string;

  version: number = 0;
  generatedAt: string;

  l1: string = '';
  l2: string = '';
  mode: string; // not used, assumed to be 'cali'

  stories: Story[] = [];
  collections: StoryCollection[] = [];

  // note, can't import ExceptData until we replace the schema generator
  // soundbites: ExcerptData[];

  soundbites: Soundbite[] = [];
  soundbiteEditions: SoundbiteEdition[] = []; // drives weekly calendar view

  featuredFilterSets: FilterWidgetData[][] = [];

  videoGuides: VideoGuide[] = [];

  @volatile
  currentDate: string = resolveCurrentDate(); //__currentDate;

  get root(): Root {
    return getBaseRoot(this);
  }

  get isEmpty(): boolean {
    return isEmpty(this.stories);
  }

  // get featuredReleases(): FeaturedRelease[] {
  //   return this.catalog.featuredReleases || [];
  // }

  story(slug: string): Story {
    return this.stories.find(story => {
      return story.slug === slug;
    });
  }

  storyForUnitSlug(slug0: string): Story {
    // if (slug === 'ateo-milagroso-1') {
    //   log.warn(`storyForUnitSlug - hack ateo-milagroso-1 -> ra-ateo-1`);
    //   slug = 'ra-ateo-1';
    // }
    // if (slug === 'pending') {
    //   log.warn(`storyForUnitSlug - hack pending -> punto-cielo-1`);
    //   slug = 'punto-cielo-1';
    // }
    const slug = fixButcheredUnitSlugs(slug0);
    return this.stories.find(story => {
      return story.includesUnit(slug);
    });
  }

  storySlugForUnitSlug(unitSlug: string): string {
    const story = this.storyForUnitSlug(unitSlug);
    if (story) {
      return story.slug;
    } else {
      log.warn(
        `storySlugForUnitSlug - story not found for unit slug: ${unitSlug} - passing through original slug`
      );
      return unitSlug;
    }
  }

  storyForVolumeOrUnitSlug(slug: string): Story {
    return this.story(slug) ?? this.storyForUnitSlug(slug);
  }

  // used for data migration
  unitForSlug(slug: string): UnitCatalogData {
    for (const story of this.stories) {
      const unit = story.unitDataBySlug(slug);
      if (unit) {
        return unit;
      }
    }
    return null;
  }

  async loadLocal() {
    // const data = this.root.loadLocalCatalogData();
    const data = await AppFactory.appStateCacher.fetchObject(
      LOCAL_DATA_CACHE_KEY
    );
    if (data) {
      applySnapshot(this, data);
      log.info(
        `loadLocal - catalog version: ${this.version}, url: ${this.catalogUrl}`
      );
    } else {
      log.info('loadLocal - no data');
      // will need to fetch later after authentication is complete and latest account data is available
    }
  }

  subscribeToCatalogSlug(catalogSlug: string) {
    log.debug(`subscribeToCatalogSlug(${catalogSlug})`);
    AppFactory.catalogMetaSync.subscribe(catalogSlug, data => {
      log.debug(
        `subscribeToCatalogSlug(${catalogSlug}) - received data: ${JSON.stringify(
          data
        )}`
      );
      const url = data?.url;
      if (url) {
        this.ensureCatalogUrl(url).catch(error => alertWarningError({ error }));
      }
    });
  }

  // // version temporarliy used by app-root appInit to isolate from firestore startup fragility
  // async ensureCatalogNoFirestore(catalogSlug: string) {
  //   log.debug(`ensureCatalogNoFirestore: ${catalogSlug}`);
  //   if (this.slug === catalogSlug || this.slug === DEFAULT_CATALOG_SLUG) {
  //     return;
  //   }
  //   await this.ensureCatalogUrl(DEFAULT_CATALOG_URL);
  // }

  // // blocks if needed to load initial catalog
  // async ensureCatalog(catalogSlug: string) {
  //   log.debug(`ensureCatalog: ${catalogSlug}`);
  //   if (this.slug === catalogSlug) {
  //     return;
  //   }
  //   let url: string;
  //   // note, this will hit a fatal firebase error when attempting to load offline firefix private mode
  //   // but that's reasonable since there's no local data to render anything useful
  //   try {
  //     const data = await AppFactory.catalogMetaSync.fetch(catalogSlug);
  //     url = data?.url;
  //     if (!url) {
  //       log.warn(
  //         `couldn't find catalog data for slug: ${catalogSlug} - resetting to default`
  //       );
  //       const root = this.root;
  //       await root.userManager.overrideCatalogSlug(null);
  //       const data = await AppFactory.catalogMetaSync.fetch(root.catalogSlug);
  //       url = data?.url;
  //     }
  //   } catch (error) {
  //     log.error(`ensureCatalog(${catalogSlug}) error: ${error}`);
  //     bugsnagNotify(error as Error);
  //   }
  //   if (!url) {
  //     log.warn('falling back to hardwired default catalog');
  //     // hack in a hardwired default catalog url until we can better trust firebase
  //     url = DEFAULT_CATALOG_URL;
  //   }
  //   await this.ensureCatalogUrl(url);
  // }

  async ensureCatalogUrl(url: string) {
    log.info(
      `entering ensureCatalogUrl(${url}) - current url: ${this.catalogUrl}`
    );
    if (url === this.catalogUrl) return;

    try {
      if (!url) {
        throw Error(
          `Missing catalogUrl - check firestore setting doc for slug: ${String(
            this.root.catalogSlug
          )}`
        );
      }

      const response = await fetch(url);
      const text = await response.text();
      let data = undefined;
      try {
        data = JSON.parse(text) as StoryManager;
      } catch (error) {
        const head = text?.substring(0, 30);
        throw Error(
          `Failed to parse catalog json; ${String(
            url
          )} -> ${head}...; slug: ${String(this.root.catalogSlug)}`
        );
      }
      if (data?.catalogUrl === url) {
        // always apply directly when just fetched. don't realy on cache existing
        log.info('before catalog applySnapshot');
        applySnapshot(this, data);
        log.info('before storing local catalog data');
        await AppFactory.appStateCacher.storeRawJson(
          LOCAL_DATA_CACHE_KEY,
          text
        );
        log.info('after storing local catalog data');
      } else {
        throw Error(
          `Failed to load expected catalog data from ${String(
            url
          )}, mismatched slug: ${String(this.root.catalogSlug)}`
        );
      }
    } catch (error) {
      alertWarningError({ error, note: 'sm.ensureCatalogUrl' });
    }
  }

  get today(): Dayjs.Dayjs {
    return Dayjs(this.currentDate);
  }

  get currentDayOfWeek(): number {
    return dayOfMondayBasedWeek(this.today.toDate());
  }

  isTodaySameOrAfter(dayjs: Dayjs.Dayjs) {
    const { today } = this;
    return (
      dayjs &&
      // `Dayjs.isSameOrAfter ` requires a plugin
      (today.isSame(dayjs) || today.isAfter(dayjs))
    );
  }

  get soundbiteEpoch(): Dayjs.Dayjs {
    // hack in dynamic epoch for the lupa-devtest catalog
    // since we only have 2 weeks of soundbite data
    if (this.slug?.includes('devtest')) {
      return firstDayOfWeek().subtract(7, 'day');
    }

    const { epochDate } = appConfig.soundbites;
    if (!epochDate) {
      return null;
    }

    return Dayjs(epochDate);
  }

  get soundbitesEnabled(): boolean {
    if (appConfig.soundbites.disabled || germanMode()) {
      return false;
    }
    // const { today, soundbiteEpoch } = this;
    // return (
    //   soundbiteEpoch &&
    //   // `isSameOrAfter ` requires a plugin
    //   (today.isSame(soundbiteEpoch) || today.isAfter(soundbiteEpoch))
    // );
    return (
      notEmpty(this.soundbiteEditions) &&
      this.isTodaySameOrAfter(this.soundbiteEpoch)
    );
  }

  editionForSoundbite(soundbite: Soundbite): SoundbiteEdition {
    // todo: need to consider implications one soundbite potentially
    // belonging to multiple editions
    return this.soundbiteEditions.find(edition =>
      edition.hasSoundbite(soundbite)
    );
  }

  // reverse sorted list of weekly editions, starting with current week
  get calendarEditions(): SoundbiteEdition[] {
    return this.soundbiteEditions
      .filter(edition => edition.visible)
      .sort(
        (a, b) =>
          b.visibilityWeekPostEpochNumber - a.visibilityWeekPostEpochNumber
      );
  }

  async assimilateVolumeData(url: string): Promise<string> {
    log.info(`assimilateVolumeData - ${url}`);
    // const story = await this.loadVolumeDataUrl(url);
    const response = await fetch(url);
    const data = await response.json();
    log.info(`loaded volume data - slug: ${data?.slug}, title: ${data?.title}`);

    const existing = this.story(data.slug);
    if (existing) {
      log.info(`updating exising story`);
      applySnapshot(existing, data);
    } else {
      log.info('adding new story');
      const story = Story.create(data);
      this.stories.push(story);
    }
    return data.slug;
  }

  // loads a standalone volume data url
  // provides story level data for a soundbite and vocab lookup data needed for story detail screen
  async loadVolumeDataUrl(url: string): Promise<Story> {
    if (!url) {
      return null;
    }
    log.debug(`loadVolumeDataUrl - ${url}`);
    // const fetchUrl = await AssetCacher.maybeCachedUrl(url);
    // const response = await fetch(fetchUrl);
    const response = await AppFactory.assetCacher.maybeCachedResponse(url);
    const data = await response.json();
    // const { volume: volumeData, units } = data;
    // return Story.create({ slug: volumeData.slug, units, volumeData });
    return Story.create(data);
  }

  get availableStories() {
    // const { showFutureStories } = this.root.userManager.accountData;
    // if (showFutureStories) {
    //   return this.stories;
    // } else {
    //   // for everybody else, suppress stories with a future release date
    //   return this.stories.filter(story => story.isReleased);
    // }
    return this.stories.filter(story => story.isAvailable);
  }

  primaryFilteredStories(key: PrimaryFilterKeys): Story[] {
    if (isClassroomFilterKey(key)) {
      const classroom =
        this.root.userManager.accountData.joinedClassroomForFilterKey(key);
      if (classroom) {
        return classroom.stories;
      } else {
        bugsnagNotify(`classroom not found for filter key: ${key}`);
        return [];
      }
    }

    const result = (this as any)[key] ?? this.all;
    return result;
  }

  filteredList(
    filter: TFilter,
    sorting?: TSortingDescription | TSortingCallback<Story>
  ) {
    // const result = filterList(this.primaryFilteredStories, filter, sorting);
    const result = filterList(this.availableStories, filter, sorting);
    log.trace(`filteredList - count: ${result?.length}`);
    return result;
  }

  get featuredVideoGuide(): VideoGuide {
    return this.videoGuides[0];
  }

  get availableCollections() {
    if (!this.collections) return null;

    // for users with `showFutureStories` flag enabled, show latest FR
    if (this.root?.userManager?.accountData?.showFutureStories) {
      return sortByReleaseDate(this.collections);
    } else {
      // for everybody else, suppress FRs with a future release date
      const releasedFeaturedReleases = this.collections.filter(
        entry => entry.isReleased
      );
      return sortByReleaseDate(releasedFeaturedReleases);
    }
  }

  get latestCollection() {
    if (!this.availableCollections) return null;
    return this.availableCollections[0];
  }

  collection(slug: string) {
    return this.availableCollections.find(fr => fr.slug === slug);
  }

  get featuredCollection(): StoryCollection {
    // return this.collection(this.featuredCollectionSlug);
    return this.chooseByWeek(this.availableCollections);
  }

  get countryList() {
    return uniq(flatten(this.all.map(story => story.countries))).sort();
  }

  get topicsList() {
    return uniq(flatten(this.all.map(story => story.topics))).sort();
  }

  get apTagsList() {
    return uniq(flatten(this.all.map(story => story.apTags))).sort();
  }

  get ibTagsList() {
    return uniq(flatten(this.all.map(story => story.ibTags))).sort();
  }

  // to be called after midnight
  refreshCurrentDate() {
    this.currentDate = resolveCurrentDate();
    log.info(`refreshCurrentDate - new currentDate: ${this.currentDate}`);
  }

  refreshDateAtMidnight() {
    const intervalMillis = Math.min(
      millisUntilMidnight(),
      DATE_REFRESH_INTERVAL_MILLIS
    );
    log.debug(
      `refreshDateAtMidnight - hours until midnight: ${
        millisUntilMidnight() / 3600 / 1000
      }, timeout interval: ${intervalMillis}`
    );
    setTimeout(() => {
      this.refreshCurrentDate();
      this.refreshDateAtMidnight();
    }, intervalMillis);
  }

  // debug ui for testing
  setCurrentDate(currentDate: string = null) {
    if (currentDate === null) {
      currentDate = resolveCurrentDate();
    }
    this.currentDate = currentDate;
  }

  // testing convenience adjusts the "current date" by given days
  adjustCurrentDate(deltaDays: number) {
    this.currentDate = adjustDate(this.currentDate, deltaDays);
  }

  // used for daily cycled calculations
  get daysSinceEpoch(): number {
    return new Date(this.currentDate).getTime() / MILLIS_PER_DAY;
  }

  // featured collection cycle per week
  get weeksSinceEpoch(): number {
    return floor((this.daysSinceEpoch - 4) /* start week on monday */ / 7);
  }

  chooseByDate<T>(choices: T[]): T {
    if (!choices) return undefined;
    const result = choices[this.daysSinceEpoch % choices.length];
    return result;
  }

  chooseByWeek<T>(choices: T[]): T {
    if (!choices) return undefined;
    const result = choices[this.weeksSinceEpoch % choices.length];
    return result;
  }

  get all() {
    return this.availableStories;
  }

  get unplayed() {
    const filtered = this.availableStories.filter(story => {
      return story.unstarted;
    });
    // hardwire sorting for now
    return sortByDuration(filtered);
  }

  get queued() {
    const filtered = this.availableStories.filter(story => {
      return story.queued;
    });
    // hardwire sorting for now
    return sortByDuration(filtered);
  }

  get inProgress() {
    const filtered = this.availableStories.filter(story => {
      return story.inProgress;
    });
    return sortByLastListened(filtered);
  }

  // most recently listened which isn't a featured assignment
  get featuredInProgress(): Story {
    const omittedSlugs = this.featuredAssignmentSlugs;
    const result = this.inProgress.find(
      story => !omittedSlugs.includes(story.slug)
    );
    return result;
  }

  // list of classroom widget featured stories, to be excluded from other lists
  // todo: consider making calculated
  get featuredAssignments(): Story[] {
    const classrooms =
      this.root.userManager.accountData.joinedClassroomsWithAssignments;
    const result = classrooms
      .map(classroom => classroom.featuredAssignment?.story)
      .filter(Boolean);
    return result;
  }

  get featuredAssignmentSlugs(): string[] {
    return this.featuredAssignments.map(story => story.slug);
  }

  // // the most recently listened story
  // get lastListened(): Story {
  //   return this.inProgress[0];
  // }

  get featuredQueued(): Story {
    // return this.queued[0];
    const omittedSlugs = this.featuredAssignmentSlugs;
    const result = this.queued.find(
      story => !omittedSlugs.includes(story.slug)
    );
    return result;
  }

  get featuredFilters(): FilterWidgetData[] {
    const result = this.featuredFilterSets.map(filterSet =>
      this.chooseByDate(filterSet)
    );
    return result;
  }

  // get startedShortList() {
  //   return this.inProgress.slice(0, STORY_SHORT_LIST_LIMIT);
  // }

  get completed() {
    return this.availableStories.filter(story => {
      return story.completed;
    });
  }

  get newThisWeek(): Story[] {
    const { storyManager } = this.root;
    if (!storyManager) return [];
    return filterNewStories(this.stories /* todo, storyManager.currentDate*/);
  }

  get availableToSubscribers() {
    // const sortFn = (a: Story, b: Story) => {
    //   return a.releaseDate > b.releaseDate ? -1 : 1;
    // };

    const rec = this.availableStories
      .filter(story => {
        return (
          // todo: better factor this with 'recommended'
          story.unqueued && !story.isNew
        );
      })
      .sort(releaseDateSortFn);

    if (rec) {
      return rec.slice(0, 4);
    }

    return this.stories.sort(releaseDateSortFn).slice(0, 6);
  }

  get trial() {
    return (
      this.stories
        .filter(story => {
          return story.trial && story.isAvailable;
        })
        // release date sorting happens to match our current need.
        // in the future can support an ordered list defined within masala
        .sort(releaseDateSortFn)
    );
  }

  get notTrial() {
    return this.stories.filter(story => {
      return !story.trial;
    });
  }

  /**
   * number of stories matching the current filter
   */
  get currentFilterCount() {
    return this.primaryFilteredStories.length;
  }

  get favoritedUnits() {
    return this.stories.filter(unit => unit.isClassroomFavorited);
  }

  classroomGroupedStories(filter: TFilter, sorting?: TSortingDescription) {
    return grouped(filterList(this.availableStories, filter, sorting));
  }

  // this can likely be removed, but waiting for final dashboard spec
  groupedStories(filter: TFilter, sorting?: TSortingDescription) {
    return storyGrouped(filterList(this.availableStories, filter, sorting));
  }

  //
  // dashboard support
  //

  get featuredSoundbite(): Soundbite {
    if (!this.soundbitesEnabled) {
      return null;
    }
    const byDate = this.soundbiteByDate(this.currentDate);
    // if (byDate) {
    return byDate;
    // }

    // if (!this.featuredSoundbiteSlug) return null;
    // return this.soundbite(this.featuredSoundbiteSlug);
  }

  // driving story/sb data caching logic
  get featuredSoundbiteStory(): Story {
    return this.featuredSoundbite?.story;
  }

  soundbite(slug: string): Soundbite {
    return this.soundbites.find(soundbite => soundbite.slug === slug);
  }

  soundbiteByDate(date: string): Soundbite {
    return this.soundbites.find(soundbite => soundbite.releaseDate === date);
  }

  soundbiteReleaseDateForSlug(slug: string): string {
    return this.soundbite(slug)?.releaseDate;
  }

  //
  // download managment
  //

  // too aggressive. needs more thought
  // async ensureCacheState() {
  //   log.info('ensureCacheState started');
  //   const profileStart = Date.now();
  //   for (const story of this.stories) {
  //     await story.ensureCacheState();
  //   }
  //   log.info(`ensureCacheState complete - ${Date.now() - profileStart}ms`);
  // }
}

export const fixButcheredUnitSlugs = (slug: string) => {
  if (slug === 'ateo-milagroso-1') {
    log.warn(`storyForUnitSlug - hack ateo-milagroso-1 -> ra-ateo-1`);
    return 'ra-ateo-1';
  }
  if (slug === 'cassettes') {
    log.warn(`storyForUnitSlug - hack cassettes -> los-cassettes-del-exilio`);
    return 'los-cassettes-del-exilio';
  }
  if (slug === 'pending') {
    log.warn(`storyForUnitSlug - hack pending -> punto-cielo-1`);
    return 'punto-cielo-1';
  }
  return slug;
};

// // manually trimmed down version of ExceptData
// // we can't import the tikka version because of its ts complexity seems to be
// // incompatible with the jscon schema generation
// export interface SoundbiteCatalogData {
//   slug: string; // primary key
//   volumeSlug: string;
//   dataUrl: string;

//   // category: string;
//   ingestVersion: number;
//   ingestedAt: string;

//   // will be used by client to resolve "today's" soundbite from the catalog list
//   releaseDate: string; // iso8601 date

//   title: string;
//   prompt: string;

//   category: string; // todo

//   // these are non-trivial to denormalize during ingestion,
//   // so client-side logic will look up the story from the volumeSlug
//   // storyTitle: string;
//   // // will default to story list joined with commas during ingestion
//   // // will potentially support a soundbite level override if/when needed
//   // country: string;
//   // imageUrl: string;
// }

// export interface SoundbiteEditionCatalogData {
//   slug: string; // primary key
//   volumeSlug: string;
//   calendarSummary: string;
//   calendarSoundbiteSlugs: string[];
//   auxilarySoundbiteSlugs: string[];
//   visibilityWeekPostEpoch: number;
// }

export const hasPrimaryFilter = (key: PrimaryFilterKeys): boolean => {
  return notEmpty(key) && key !== PrimaryFilterKeys.ALL;
};

// const classroomFiltered(key: PrimaryFilterKeys): boolean {
//   return isClassroomFilterKey(key);
// }

// sorting behaviors now controlled in sorting-definitions.ts

// const sortByTitle = sortBy('sortTitle');
// const sortByDuration = sortBy('durationMinutes');
// // switch to millis once schema update propagated to local store
// // const sortByDuration = sortBy('catalogData.durationMillis');

// // const sortByDownloadedAt = sortBy('download.downloadedAt'); // date sort

// // beware this below are probably doing a string sorts, not date sorts
// const sortByMostRecent = sortBy('releaseDate', SORT_ORDER.DESC);
// const sortByOriginalBroadcastDate = sortBy('originalBroadcastDate');
// const sortByAssignmentDueDate = sortBy('dueDate');

const sortByLastListened = sortBy('lastListened', SORT_ORDER.DESC);

// use to sort lupa feature release
const sortByReleaseDate = sortBy('releaseDate', SORT_ORDER.DESC);

const sortByDuration = sortBy('durationMillis', SORT_ORDER.ASC);

// const STORY_SHORT_LIST_LIMIT = 2;

// type SectionKey = keyof UnitCatalogData | 'all';

// /**
//  * ApplyMetadata + create and object with the key provide
//  *
//  * @param {array} collection
//  * @param {object} metadata
//  * @param {function} sortFn
//  * @param {string} key
//  * @returns {object}
//  */
// const sectionize = (
//   collection: Story[],
//   sortFn: (collection: Story[]) => any,
//   sectionKey: SectionKey
// ) => {
//   if ((sectionKey as string) === 'all') {
//     return [{ title: 'all', data: sortFn(collection) }];
//   }

//   const key = sectionKey as keyof UnitCatalogData;

//   const sectionized = collection.reduce((acc: any, current) => {
//     current.catalogData[key].forEach((item: string | number) => {
//       if (Array.isArray(acc[item])) {
//         acc[item].push(current);
//       } else {
//         acc[item] = [current];
//       }
//     });

//     return acc;
//   }, {});
//   return Object.keys(sectionized)
//     .sort()
//     .map(_key => ({
//       title: _key,
//       data: sortFn(sectionized[_key]),
//     }));
// };

// // Note, this map is a peer of storySortLabels, using the same storySortKeys
// export const storySortParams = {
//   assignmentDueDate: { sortFn: sortByAssignmentDueDate, sectionKey: 'all' },
//   mostRecent: { sortFn: sortByMostRecent, sectionKey: 'all' },
//   title: { sortFn: sortByTitle, sectionKey: 'all' },
//   duration: { sortFn: sortByDuration, sectionKey: 'all' },
//   country: { sortFn: sortByMostRecent, sectionKey: 'countries' },
//   topic: { sortFn: sortByMostRecent, sectionKey: 'topics' },
//   originalBroadcast: { sortFn: sortByOriginalBroadcastDate, sectionKey: 'all' },
//   lastListened: { sortFn: sortByLastListened, sectionKey: 'all' },
// };

// const applySort = (
//   collection: Story[],
//   sortKey: keyof typeof storySortParams
// ) => {
//   const params = storySortParams[sortKey];
//   return sectionize(collection, params.sortFn, params.sectionKey as SectionKey);
// };
