import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';

import classnames from 'classnames';
import { observer } from 'mobx-react';
import { SectionBreak } from '@naan/primitives/spacer/gaps';
import { Classroom } from 'core/models/user-manager';
import { keyframes, styled } from 'naan/stitches.config';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { IconButton } from '@naan/primitives/button';
import { AppFactory } from '@app/app-factory';
import { storiesPrimaryFiltered } from 'components/nav/path-helpers';
import { Link } from 'react-router-dom';
import { alertSevereError } from '@app/notification-service';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';

import __ from 'core/lib/localization';
// import { Text } from '@naan/primitives/text';
// import { ExternalLink } from '@naan/primitives/text/external-link';

const fade = keyframes({
  '0%': { background: '#FFEEAA' },
  '50%': { background: '#FFEEAA' },
  '100%': { background: 'transparent' },
});

const Wrapper = styled('div', {
  $$borderColor: '$colors$gray-100',

  textStyle: 'body',

  '& .title': {
    textStyle: 'medium-heading',
    marginBottom: 16,
  },

  '& .class-list': {
    borderBottom: '1px solid $$borderColor',
  },

  '& .class-row': {
    borderTop: '1px solid $$borderColor',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.highlighted': {
      animationName: `${fade}`,
      animationDuration: '2.5s',
    },

    '& .name': {
      flex: '1',
      padding: '20px 0',

      '& a': {
        color: '$colors$teal-500',
      },
    },

    'div.menu': {
      marginLeft: 'auto',
    },
  },
});

const DropClassroomConfirmationDialog = observer(
  ({
    classroom,
    onOk,
    onDismiss,
  }: {
    classroom: Classroom;
    onOk: () => void;
    onDismiss: () => void;
  }) => {
    return (
      <DialogHtmlContainer onDismiss={onDismiss}>
        <Dialog.HtmlHeading>
          {__('Leave %{class}?', 'leaveClassroomDialogTitle', {
            class: classroom.label,
          })}
        </Dialog.HtmlHeading>
        <Dialog.Body>
          {__(
            'You will no longer see class assignments, and your progress will not be visible to the teacher.',
            'leaveClassroomDialogBody'
          )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Cancel', 'cancel')}
            presentation="grayTransparent"
          />
          <Dialog.Button
            onClick={onOk}
            label={__('OK', 'ok')}
            presentation="teal"
          />
        </Dialog.ButtonsContainer>
      </DialogHtmlContainer>
    );
  }
);

export const JoinedClassrooms = observer(() => {
  // const flash: any = store.flash;
  const { joinedClassrooms } = AppFactory.root.userManager.accountData;

  const handleDropClassroom = (classroom: Classroom) => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <DropClassroomConfirmationDialog
        classroom={classroom}
        onOk={async () => {
          classroom
            .drop()
            .catch(error =>
              alertSevereError({ error, note: 'joined-classrooms - drop' })
            );
          onDismiss();
        }}
        onDismiss={onDismiss}
      />
    ));
  };

  if (joinedClassrooms?.length === 0) {
    return null;
  }
  return (
    <Wrapper>
      <h3 className="title">{__('My classes', 'myClasses')}</h3>
      <div className="class-list">
        {joinedClassrooms?.map(classroom => {
          const storiesPath = storiesPrimaryFiltered(classroom.filterKey);

          return (
            <div
              className={classnames('class-row', {
                highlighted: false,
                // @armando, this was never getting set in the current code. is there a regression that's worth capturing?
                /*flash?.messageParams?.classroomLabel === classroom.label*/
              })}
              key={`class-${classroom.id}`}
            >
              <div className="name">
                {classroom.label}{' '}
                <Link to={storiesPath}>
                  {__('View assignments', 'viewAssignments')}
                </Link>
              </div>

              <div className="menu">
                <Menu
                  trigger={
                    <IconButton
                      presentation={'grayTransparent'}
                      icon={<OverflowIcon />}
                    />
                  }
                >
                  <MenuItem
                    label={__('Leave classroom...', 'leaveClassroom')}
                    action={() => {
                      handleDropClassroom(classroom);
                    }}
                  />
                </Menu>
              </div>
            </div>
          );
        })}
      </div>
      <SectionBreak />
    </Wrapper>
  );
});
