import * as React from 'react';
import { VideoModalCard } from '../video-modal-card';
import { namedStyled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { HelpletsProgressBar } from '../helplets-progress-bar';
import { PlainMarkdown } from '@naan/primitives/text';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';
import { ReplayIcon } from '@naan/icons/replay-icon';
import { namedObserver } from 'lib/named-observer';
import { PlayerViewController } from 'components/ui/video-player/player-view-controller';
import { VideoPlayerModel } from '../video-player-model';
import { track } from '@app/track';
import { Image } from '@naan/primitives/image';

import __ from '@core/lib/localization';

import nextBgPortrait from './svg/petals-background-portrait.svg';
import nextBgLandscape from './svg/petals-background-landscape.svg';

const Wrapper = namedStyled('EndCardWrapper', VideoModalCard, {
  $$image: '',
  backgroundColor: '$blue-700',
  display: 'flex',
  alignItems: 'center',
  padding: '$space$4',
  '@medium': {
    padding: '$space$10',
  },
  '&.next-card': {
    backgroundSize: 'cover',
    backgroundImage: `url(${nextBgPortrait})`,
    '@smallLandscape': {
      backgroundImage: `url(${nextBgLandscape})`,
    },
    '@medium': {
      backgroundImage: `url(${nextBgLandscape})`,
      imageSize: 'cover',
    },
  },

  // justifyContent: 'center',
  '& .next-inner': {
    width: '100%',
    '@medium': {
      marginTop: 56,
    },
  },
  '& .copy': {
    textAlign: 'center',
    width: '100%',
    textStyle: 'medium-heading',
    color: '$colors$white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > strong': {
      display: 'block',
      color: '$colors$pink-300',
      fontWeight: 'inherit',
    },
    '& > .yellow': {
      display: 'block',
      color: '$colors$yellow-300',
    },
  },
  '& .next': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    '@smallLandscape': {
      justifyContent: 'flex-end',
    },
    '@medium': {
      justifyContent: 'flex-end',
    },
    button: {
      all: 'unset',
      display: 'flex',
      backgroundColor: '$colors$black-alpha-10',
      alignItems: 'center',
      padding: '8px 12px 8px 8px',
      borderRadius: '$space$3',
      width: '100%',
      maxWidth: 360,
      '@medium': {
        width: 440,
        maxWidth: 'unset',
      },
      img: {
        objectFit: 'cover',
        borderRadius: '$space$2',
        width: 68,
        height: 68,
        '@medium': {
          width: 170,
          height: 96,
          aspectRatio: 'auto',
        },
      },
      '& .text': {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 16px',
        flexGrow: 1,
      },
      '& .next-up': {
        textStyle: 'small-caps',
        color: '$colors$white',
      },
      '& .title': {
        textStyle: 'small-heading',
        color: '$colors$white',
      },
      '& .icon': {
        color: '$colors$white',
      },
      '&:hover': {
        backgroundColor: '$colors$black-alpha-20',
      },
    },
  },

  '& .progress': {
    '& .progress-bar-container': {
      padding: '16px 0 20px',
    },
    '& .label': {
      textStyle: 'medium-heading',
      color: '$colors$white',
      '& strong': {
        color: '$colors$pink-300',
      },
    },
  },

  '& .replay-container': {
    position: 'absolute',
    left: 16,
    bottom: 'calc(16px + env(safe-area-inset-bottom))',
  },
  '& .close-container': {
    position: 'absolute',
    right: 16,
    bottom: 'calc(16px + env(safe-area-inset-bottom))',
  },
});

type EndCardProps = {
  player: PlayerViewController;
  model: VideoPlayerModel;
  onDismiss: () => void;
};

export const NextCard = namedObserver(
  'NextCard',
  ({ player, model }: EndCardProps) => {
    const readablePercentage = model.onboardingProgressPercentage;
    const next = model.nextOnboardingVideo;

    const handleNext = () => {
      track('helplet__play_next', { videoSlug: next?.slug });
      // we need this, otherwise we'll flash the next card for the next video
      // because the player will not be ready yet
      player.startTransitioning();
      model.setCurrent(next);
    };

    return (
      <Wrapper className="next-card" onTop>
        <div className="next-inner">
          <div className="progress">
            <span className="label">
              <PlainMarkdown
                source={__(
                  'You are **%{readablePercentage}%** enlightened',
                  'dashboard.youAreStrongPercentEnlightened',
                  { readablePercentage }
                )}
              />
            </span>
            <div className="progress-bar-container">
              <HelpletsProgressBar
                css={{
                  $$current: `${readablePercentage}%`,
                  $$currentColor: '$colors$pink-300',
                }}
              />
            </div>
          </div>

          <div className="next">
            <button onClick={handleNext}>
              <Image src={next.imageUrlLandscape} alt="" />
              <div className="text">
                <span className="next-up">
                  {__('Next up', 'dashboard.nextUp')}
                </span>
                <span className="title">{next.title}</span>
              </div>
              <div className="icon">
                <ArrowRightIcon />
              </div>
            </button>
          </div>
        </div>
        <div className="replay-container">
          <Button
            onClick={player.play} // todo: track replay
            label={__('Replay', 'dashboard.replay')}
            presentation={'blue'}
            size="large"
            leftIcon={<ReplayIcon />}
          />
        </div>
      </Wrapper>
    );
  }
);
