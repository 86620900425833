import React from 'react';
import * as PricingCard from './pricing-cards/pricing-card';
import { Plan } from 'core/models/user-manager';
import { useStripeCheckout } from 'common/stripe-context';
import { launchCheckoutInterstitial } from './account-utils/launch-checkout-interstitial';
import { CheckmarkIcon } from '@naan/icons/checkmark-icon';
import { VSpacer } from '@naan/primitives/spacer';
import { formatMoney } from '@utils/format-money';

import __ from 'core/lib/localization';
import { alertSevereError } from '@app/notification-service';
import { AppFactory } from '@app/app-factory';

export const PlanPricingCard = ({ plan }: { plan: Plan }) => {
  const {
    slug,
    price,
    // applePrice,
    normalPrice,
    pricingDescription,
    hasSpecialPricing,
    discountPercentage,
  } = plan;

  const { userManager } = AppFactory.root;
  const isCurrent = userManager.accountData.currentPlan === slug;
  const isMonthly = slug === 'monthly';
  const hasDiscountPerc = discountPercentage !== null && discountPercentage > 0;
  const isDiscounted = hasDiscountPerc || (!isMonthly && normalPrice !== price);

  const stripeCheckout = useStripeCheckout();
  const handleCheckout = React.useCallback(() => {
    plan
      .select()
      .then(result => {
        if (result.interstitialMessageKey) {
          launchCheckoutInterstitial(result, () =>
            stripeCheckout(result.stripeSessionId)
          );
        } else {
          return stripeCheckout(result.stripeSessionId);
        }
      })
      .catch(error => {
        alertSevereError({ error, note: 'PlanPricingCard.handleCheckout' });
      });
  }, [plan, stripeCheckout]);

  let pricingExplanation = isMonthly
    ? __('Billed monthly', 'billedMonthly')
    : /// removed the inline localized price for design reasons
      __('one-time purchase', 'oneTimePurchase');

  if (isDiscounted) {
    if (isMonthly) {
      pricingExplanation = __('Usually $%{price}/mo', 'usuallyNMonthly', {
        price: normalPrice,
      });
    }
  }
  const displayPrice = formatMoney(isMonthly ? price : (price as number) / 12);

  let ribbonTitle = pricingDescription;

  if (hasSpecialPricing && isDiscounted) {
    ribbonTitle = __(
      '%{pricingDescription}: save %{discountPercentage}%',
      'discountedRibbonTitle',
      {
        pricingDescription,
        discountPercentage,
      }
    );
  } else if (isDiscounted) {
    ribbonTitle = __(
      'Save %{discountPercentage}%',
      'savePercentageRibbonTitle',
      {
        discountPercentage,
      }
    );
  }

  const showRibbon = hasSpecialPricing || isDiscounted;
  const showOriginalPrice = isDiscounted && !isMonthly;

  return (
    <PricingCard.Container presentation={isMonthly ? 'monthly' : 'one-year'}>
      {showRibbon ? <PricingCard.Ribbon ribbonTitle={ribbonTitle} /> : null}

      <PricingCard.Heading>
        {isMonthly ? 'Monthly' : '1 year'}
      </PricingCard.Heading>

      <PricingCard.PriceContainer>
        <PricingCard.PricePrefix>US</PricingCard.PricePrefix>
        <PricingCard.PriceAmount>{displayPrice}</PricingCard.PriceAmount>
        <PricingCard.PriceInterval>{'/mo'}</PricingCard.PriceInterval>
      </PricingCard.PriceContainer>

      <PricingCard.Description>
        {showOriginalPrice ? (
          <PricingCard.Bold>
            <PricingCard.Scratch className="cross">
              {formatMoney(normalPrice)}{' '}
            </PricingCard.Scratch>
          </PricingCard.Bold>
        ) : null}
        {isMonthly ? null : (
          <PricingCard.Bold>{formatMoney(price)} </PricingCard.Bold>
        )}
        {pricingExplanation}
      </PricingCard.Description>

      {isCurrent ? (
        <p className="pricing-card__your-plan">
          <CheckmarkIcon />
          {__('My plan', 'myPlan')}
        </p>
      ) : (
        <>
          <VSpacer size={5} mediumSize={11} />
          <PricingCard.Button
            onClick={handleCheckout}
            label={isMonthly ? 'Subscribe' : 'Purchase'}
          />
        </>
      )}
    </PricingCard.Container>
  );
};
