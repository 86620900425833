import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { PageHeading } from 'components/page-heading';
import { isEmpty } from 'lodash';
import __ from '@core/lib/localization';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { PointsNav } from './unauthenticated-dashboard-nav/points-nav';
import { createLogger } from '@common/log';

const log = createLogger('learn-dashboard-heading');

export const LearnDashboardHeading = observer(() => {
  const { userManager } = AppFactory.root;
  const { accountData, userData, authenticated } = userManager;

  if (!authenticated) {
    return null;
  }

  let title =
    // todo: make sure accountData properly reset when logged out
    userManager.authenticated && !isEmpty(accountData.name)
      ? __('Hola, %{name}', 'dashboard.holaName', {
          name: accountData.name,
        })
      : __('¡Hola!', 'dashboard.hola');

  if (accountData.showAffiliateWelcome) {
    title = accountData.affiliateWelcomeHeading;
  }

  // for some reason it's important to reference userData.totalPoints here.
  // when inlined into the component params, it wasn't reacting after logout.
  const totalPoints = userData.totalPoints;
  log.debug(`totalPoints: ${totalPoints}`);

  return (
    <CenterColumnLayout>
      <PageHeading
        title={title}
        renderAccessory={() => <PointsNav />}
        showAccessoryInSmall={true}
      />
    </CenterColumnLayout>
  );
});
