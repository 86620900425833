import * as React from 'react';
import { VSpacer } from 'naan/primitives/spacer';
import { AccountData } from 'core/models/user-manager';
import { AuthenticatedFlow } from '../subscription-flow/authenticated-flow';
import { styled } from '@naan/stitches.config';
import { Footer } from '../subscription-flow/footer';

import __ from '@core/lib/localization';
import { MediumGap } from '@naan/primitives/spacer/gaps';

type Props = {
  accountData: AccountData;
};

const Wrapper = styled('div', {
  gridColumn: '1/-1',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '$colors$gray-25',
  padding: '32px 16px',
  borderRadius: 12,
  '& h2': {
    textStyle: 'medium-heading',
    marginBottom: '$space$8',
  },
  '& .footer-container': {
    maxWidth: 632,
  },
  '@medium': {
    padding: '48px 16px',
    '& h2': {
      textStyle: 'extra-large-heading',
      marginBottom: '$space$12',
    },
  },
});

export const TrialCta = ({ accountData }: Props) => {
  return (
    <>
      <Wrapper>
        <h2>{__('Unlock all stories', 'unlockAllStories')}</h2>

        <AuthenticatedFlow presentation="light" />
        <VSpacer size={8} />
        <div className="footer-container">
          <Footer presentation={'light'} showLinks={false} />
        </div>
      </Wrapper>
      <MediumGap />
    </>
  );
};
