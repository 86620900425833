import * as React from 'react';
import { Image } from '@naan/primitives/image';
import { PlainMarkdownWithLinks } from '@naan/primitives/text';
import { MarkdownStyleWrapper } from '@naan/primitives/text/markdown/markdown-style-wrapper';

import { styled } from '@naan/stitches.config';

import imageSrc from './ra-feature-panel-image.png';

import __ from '@core/lib/localization';
import { germanMode } from '@core/lib/app-util';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16,

  backgroundColor: '$blue-50',
  borderRadius: 12,
  padding: '24px 16px',

  '& h2': {
    textStyle: 'small-heading',
    marginBottom: 16,
  },

  '& img': {
    width: '100%',
    maxWidth: 396,
  },

  '@medium': {
    flexDirection: 'row',
    '& img': {
      padding: '0 24px',
      maxWidth: '50%',
    },
  },
});

const title = __('Uniquely Latin American Stories', 'aboutRa.title');

const blurb = __(
  `Radio Ambulante is a Spanish-language podcast, distributed by NPR,
that tells Latin American stories from everywhere Spanish is spoken,
including the United States. With more than 200 stories,
reported from over 20 countries, Radio Ambulante is a sonic portrait
of a region in all its complexity. Their journalism has won numerous
accolades, including the prestigious Gabriel García Márquez Prize.

Discover more at [radioambulante.org](http://radioambulante.org).
`,
  'blurb'
);

export const aboutRaId = 'about-ra';

export const AboutRadioAmbulante = () => {
  if (germanMode()) {
    return <AboutJiveworldGerman />;
  }
  return (
    <Wrapper id={aboutRaId}>
      <Image src={imageSrc} alt="Radio Ambulante" />
      <div className="text">
        <h2>{title}</h2>
        <MarkdownStyleWrapper>
          <PlainMarkdownWithLinks source={blurb} />
        </MarkdownStyleWrapper>
      </div>
    </Wrapper>
  );
};

export const AboutJiveworldGerman = () => {
  return (
    <Wrapper id={aboutRaId}>
      {/* <Image src={imageSrc} alt="[tbd]" /> */}
      <div className="text">
        {/* <h2>{title}</h2> */}
        <MarkdownStyleWrapper>
          <PlainMarkdownWithLinks source="[german mode about panel TBD]" />
        </MarkdownStyleWrapper>
      </div>
    </Wrapper>
  );
};
