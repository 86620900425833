import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import {
  getMainScrollingContainer,
  scrollContainerToTop,
} from 'lib/scroll-utils';
import { STORY_TITLE_ID } from 'components/story-detail/story-detail-card';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';
import { createLogger } from '@common/log';
import { StoryNavState } from './story-detail-screen/story-nav-state';
import {
  getElementById,
  getElementsCombinedHeight,
} from 'components/dom-utils';
import { elementIds } from 'components/dom-utils/element-ids';

const log = createLogger('learn/scrolling-fx');

const CURRENT_CHAPTER_ID = 'current-chapter';
const CHAPTER_LIST_ID = 'chapter-list';

// function getCSSVar(
//   variableName: string,
//   elementId?: string
// ): string | undefined {
//   const element = elementId
//     ? document.getElementById(elementId)
//     : document.documentElement;

//   if (element) {
//     const styles = window.getComputedStyle(element);
//     const value = styles.getPropertyValue(variableName).trim();
//     return value;
//   }

//   return undefined;
// }

// function getPropertyValue(
//   propertyName: string,
//   element: string | HTMLElement = document.documentElement
// ): string | undefined {
//   const elementToUse =
//     typeof element === 'string' ? document.getElementById(element) : element;
//   if (elementToUse) {
//     const styles = window.getComputedStyle(elementToUse);
//     const value = styles.getPropertyValue(propertyName).trim();
//     return value;
//   }

//   return undefined;
// }

// function getPixelValue(value: string): number | undefined {
//   if (value && value.endsWith('px')) {
//     const parsedValue = parseFloat(value);
//     if (!isNaN(parsedValue)) {
//       return parsedValue;
//     }
//   }

//   return undefined;
// }

export function maybeCurrentId(chapter: ChapterCatalogData) {
  if (chapter.isCurrent) {
    return { id: CURRENT_CHAPTER_ID };
  }

  return {};
}

export function scrollToCurrentChapter(smooth = false) {
  const scrollingContainer = getMainScrollingContainer();
  if (!scrollingContainer) {
    log.warn(`scrollToCurrentChapter - scrollingContainer not found, ignoring`);
    return;
  }

  const currentChapter = document.getElementById(CURRENT_CHAPTER_ID);

  if (!currentChapter) {
    scrollContainerToTop();
    return false;
    // scrollingContainer.scrollTo({ top: 0, behavior: 'auto' });
  }

  const currentChapterTop = currentChapter?.offsetTop;

  // combined height in pixels of the top sticky elements
  const topCompensation = getElementsCombinedHeight([
    elementIds.STORY_HEADER_CONTAINER,
    elementIds.STORY_ASSIGNMENT_BAR,
    elementIds.STORY_TAB_BAR,
  ]);

  const windowHeight = scrollingContainer.clientHeight;
  const chapterList = document.getElementById(CHAPTER_LIST_ID);
  const chapterListTop = chapterList?.offsetTop ?? 0;

  scrollingContainer.scrollTo({
    top: Math.max(
      currentChapterTop - topCompensation - windowHeight / 2,
      chapterListTop - topCompensation
    ),
    left: 0,
    behavior: smooth ? 'smooth' : ('instant' as any), /// typescript is wrong here. 'instant' is a valid value
  });

  return true;
}

export function useStoryTitleScrollingFx() {
  React.useLayoutEffect(() => {
    const scrollingContainer = getMainScrollingContainer();

    const intersectionObserver = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          storyDetailUiModel.setShowTitleInHeader(false);
        } else {
          storyDetailUiModel.setShowTitleInHeader(true);
        }
      },
      {
        root: scrollingContainer,
      }
    );

    const titleElement = document.getElementById(STORY_TITLE_ID);
    if (!titleElement) {
      log.error('missing titleElement');
      return;
    }

    intersectionObserver.observe(titleElement);

    return () => {
      intersectionObserver.unobserve(titleElement);
      intersectionObserver.disconnect();
    };
  }, []);
}

function scrollToTabBar() {
  const scrollingContainer = getMainScrollingContainer();
  if (!scrollingContainer) {
    log.warn(`scrollToCurrentChapter - scrollingContainer not found, ignoring`);
    scrollContainerToTop();
    return false;
  }

  /// here's the weirdest thing: if we don't scroll to top first,
  /// the tabbar scroll position is wrong. I don't know exactly why, but it
  /// must have something to do with the fact that the tabbar is sticky.
  /// So the fix is to scroll to top first, then scroll to the tabbar.
  scrollContainerToTop();

  const tabBar = getElementById(elementIds.STORY_TAB_BAR);

  if (!tabBar) {
    // scrollContainerToTop();
    return false;
  }

  const topCompensation = getElementsCombinedHeight([
    elementIds.STORY_HEADER_CONTAINER,
    elementIds.STORY_ASSIGNMENT_BAR,
    // elementIds.STORY_TAB_BAR,
  ]);

  const tabBarTop = tabBar?.offsetTop - topCompensation;

  scrollingContainer.scrollTo({
    top: tabBarTop,
    left: 0,
    behavior: 'instant' as any, /// typescript is wrong here. 'instant' is a valid value
  });

  return true;
}

export function useStoryTabScrollingFx({
  unstarted = false,
}: {
  unstarted?: boolean;
}) {
  React.useLayoutEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const forceScollToTop = queryParams.get('scroll') === 'top';
    if (unstarted) {
      scrollContainerToTop();
    } else if (StoryNavState.externalLanding || forceScollToTop) {
      scrollToCurrentChapter();
    } else {
      scrollToTabBar();
    }
  }, [unstarted]);
}

export function useSoundbitesTabScrollingFx() {
  React.useLayoutEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    if (StoryNavState.externalLanding && !queryParams.has('scroll')) {
      scrollContainerToTop();
    } else {
      scrollToTabBar();
    }
  }, []);
}
