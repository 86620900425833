import * as React from 'react';
import { Root, Portal } from '@radix-ui/react-dialog';
import { Content } from './dialog-content';
import { Overlay } from './dialog-overlay';

type RootProps = React.ComponentProps<typeof Root>;
type ContentProps = React.ComponentProps<typeof Content>;

type ContainerProps = { onDismiss: () => void } & Omit<
  RootProps,
  'onOpenChange'
> &
  Pick<ContentProps, 'css'>;

type OverlayProps = React.ComponentProps<typeof Overlay>;

export const Container: React.FC<
  ContainerProps & {
    zIndex?: OverlayProps['css']['zIndex'];
    overlayCss?: OverlayProps['css'];
  }
> = ({ open, onDismiss, children, zIndex = 1001, overlayCss, ...props }) => {
  const handleOpenChange = React.useCallback(
    (open: boolean) => {
      if (open === false) {
        onDismiss();
      }
    },
    [onDismiss]
  );

  return (
    <Root open={open} onOpenChange={handleOpenChange}>
      <Portal>
        <Overlay css={{ zIndex, ...overlayCss }}>
          <Content onInteractOutside={onDismiss} {...props}>
            {children}
          </Content>
        </Overlay>
      </Portal>
    </Root>
  );
};
