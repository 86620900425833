import React from 'react';
import { observer } from 'mobx-react';
import { TrialCta } from './trial-cta';
import { ExpiringSoon } from './expiring-soon';
import { AppFactory } from '@app/app-factory';
// import { PurchaseFlowDisabled } from './purchase-flow-disabled';

export const AccountCta = observer(() => {
  const { accountData, purchaseFlowDisabled } = AppFactory.root.userManager;

  if (purchaseFlowDisabled) {
    // dashboard and detail screen notices are probably sufficient
    return null; //<PurchaseFlowDisabled />;
  }

  const isTrial = accountData.membershipState === 'trial';
  if (isTrial) {
    return <TrialCta accountData={accountData} />;
  }

  if (accountData.shouldPromoteRenewal) {
    return <ExpiringSoon accountData={accountData} />;
  }

  return null;
});
