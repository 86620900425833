// import { nanoid } from 'nanoid';
import {
  /*frozen,*/ applySnapshot,
  frozen,
  ModelTreeNode,
  snap,
  TSTStringMap,
} from 'ts-state-tree/tst-core';
// import { appConfig } from 'app/env';
import { createLogger } from '@common/log';
import { AppFactory } from '@app/app-factory';
import { bugsnagNotify } from '@app/notification-service';
import { setVideoAutoplay } from 'components/ui/video-player/player-view-controller';
import { UaService } from 'lib/services/ua-service';
import { appConfig } from '@app/config';
import { embeddedMode, nativeWakeLockSupported } from '@core/lib/app-util';
import { track } from '@app/track';
import { setLocale } from '@core/lib/localization';
import { isEmpty } from 'lodash';
import { DEFAULT_CATALOG_SLUG } from './global-settings';
const log = createLogger('local-state');

//
// device specific state which should be preserved through
// logging in/out
//

const APP_STATE_CACHE_KEY = 'local-state';

export interface UtmData {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmTerm: string;
  utmContent: string;
  referrer: string;
}

export type DismissableLocalMessageKey =
  | 'brown-listed-browser-warning'
  | 'example-future-key';

export class LocalState extends ModelTreeNode {
  static CLASS_NAME = 'LocalState' as const;

  dismissedMessageSet: TSTStringMap<boolean> = snap({});

  // installation id now handled via local storage in a separate module so that it
  // can be resolved synchronously
  // installationId?: string = null; // local storage persisted ui used for anonymous metrics

  locale: string; // provisional testing of localization

  // advanced setting. when true, suppress use of the no-sleep hidden video hack
  dontHackKeepAwake: boolean = false;

  @frozen
  latestUtmData: UtmData;

  // hardwired default is sufficient for the foreseeable future
  // note, per user override is stored in userData.overrideCatalogSlug
  defaultCatalogSlug: string = DEFAULT_CATALOG_SLUG;

  embeddedPlatform?: string;
  embeddedBuildNumber?: string;

  validatedInviteCode: string;

  // expose the dev menu even when anonymous
  forceDevToolsEnabled: boolean = false;

  // easier debugging of disconnected mode
  forceFirebaseError: boolean = false;

  logglyEnabled: boolean = false;

  videoAutoplay: boolean = true; // let's try turning this on by default to see how it feels

  debugStatusBarEnabled: boolean = false;

  // TODO
  // locale: string = 'en'; // ln.systemDefaultLocale;

  // TODO
  // @frozen
  // appInstallAttribution: object = {}; //TODO types.maybeNull(types.map(types.string)),

  static create(snapshot: any = {}): LocalState {
    return super.create(LocalState, snapshot) as LocalState;
  }

  // async initialize() {
  //   await this.load();
  // }

  async load() {
    const data = await AppFactory.appStateCacher.fetchObject(
      APP_STATE_CACHE_KEY
    );
    if (data) {
      applySnapshot(this, data);
    } else {
      log.info('load - no data');
    }
  }

  async persist() {
    log.info(`persist`);
    const data = this.snapshot;
    return AppFactory.appStateCacher.storeObject(APP_STATE_CACHE_KEY, data);
  }

  // async reset() {
  //   await AppFactory.appStateCacher.remove(APP_STATE_CACHE_KEY);
  //   applySnapshot(this, {});
  // }

  async dismissMessage(key: DismissableLocalMessageKey) {
    this.dismissedMessageSet.set(key, true);
    await this.persist();
  }

  messageIsDismissed(key: DismissableLocalMessageKey): boolean {
    return this.dismissedMessageSet.has(key);
    // local data doesn't need merging
    // return !!this.dismissedMessageSet.get(key);
  }

  async resetMessage(key: DismissableLocalMessageKey) {
    this.dismissedMessageSet.delete(key);
    // local data doesn't need merging
    // this.dismissedMessageSet.set(key, false);
    await this.persist();
  }

  async resetAllDismissed() {
    // safe usage of clear() since not merged data
    this.dismissedMessageSet.clear();
    await this.persist();
  }

  get needsBrownListedBrowserWarning(): boolean {
    return (
      appConfig.brownListedBrowserWarningEnabled &&
      !this.messageIsDismissed('brown-listed-browser-warning') &&
      UaService.brownListed
    );
  }

  dismissBrownListedBrowserWarning() {
    this.dismissMessage('brown-listed-browser-warning').catch(bugsnagNotify);
  }

  async storeLocale(locale: string) {
    if (this.locale !== locale) {
      this.locale = locale;
      this.applyLocale();
      await this.persist();
    }
  }

  applyLocale() {
    if (this.locale) {
      setLocale(this.locale);
    } else {
      // todo: honor system default once translations are flushed out
      const defaultLocale = this.systemDefaultLocale;
      log.info(`system default locale: ${defaultLocale} - ignoring for now`);
    }
  }

  get systemDefaultLocale(): string {
    const result = navigator.language.toLowerCase().replace(/-.+$/, '') ?? 'en';
    // todo: validate against available locales
    return result;
  }

  // async storeDefaultCatalogSlug(slug: string) {
  //   if (slug && slug !== this.defaultCatalogSlug) {
  //     this.defaultCatalogSlug = slug;
  //     await this.persist();
  //   }
  // }

  get keepAwakeHackApplicable(): boolean {
    return !embeddedMode() && !nativeWakeLockSupported();
  }

  async toggleKeepAwakeHack() {
    this.dontHackKeepAwake = !this.dontHackKeepAwake;
    await this.persist();
    track('settings__dont_hack_keep_awake', {
      enabled: this.dontHackKeepAwake,
    });
  }

  // async storeAffiliateCode(code: string) {
  //   this.affiliateCode = code;
  //   await this.persist();
  // }

  // async resetAffiliateCode() {
  //   this.affiliateCode = null;
  //   await this.persist();
  // }

  async storeLatestUtmData(data: UtmData) {
    this.latestUtmData = data;
    await this.persist();
  }

  get emptyLatestUtmData(): boolean {
    return isEmpty(this.latestUtmData);
  }

  async storeValidatedInviteCode(code: string) {
    this.validatedInviteCode = code;
    await this.persist();
  }

  async resetInviteCode() {
    this.validatedInviteCode = null;
    await this.persist();
  }

  async storeForceDevToolsEnabled(value: boolean) {
    if (this.forceDevToolsEnabled !== value) {
      this.forceDevToolsEnabled = value;
      await this.persist(); // consider marking dirty and persisting later
    }
  }

  // async toggleForceDevToolsEnabled() {
  //   await this.storeForceDevToolsEnabled(!this.forceDevToolsEnabled);
  // }

  async storeLogglyEnabled(value: boolean) {
    this.logglyEnabled = value;
    await this.persist();
  }

  async toggleVideoAutoplay() {
    this.videoAutoplay = !this.videoAutoplay;
    setVideoAutoplay(this.videoAutoplay);
    await this.persist();
  }

  async storeDebugStatusBarEnabled(value: boolean) {
    this.debugStatusBarEnabled = value;
    await this.persist();
  }

  async toggleDebugStatusBar() {
    await this.storeDebugStatusBarEnabled(!this.debugStatusBarEnabled);
  }

  async toggleForceFirebaseError() {
    this.forceFirebaseError = !this.forceFirebaseError;
    await this.persist();
  }

  // should be able to refactor away from the window var hack now to just localState
  async resetEmbedded() {
    this.embeddedPlatform = undefined;
    this.embeddedBuildNumber = undefined;
    window.embeddedPlatform = undefined;
    window.embeddedBuildNumber = undefined;
    await this.persist();
  }
}
