import * as React from 'react';

import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { useCurrentStory } from '../current-story-context';
import { VocabListModalContent } from './vocablist-content';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';

const Container = styled(DialogHtmlContainer, {
  width: 'min( 600px, calc(100% - 32px) )',
});

export const VocabListModal = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const story = useCurrentStory();
    return (
      <Container
        /// this disables the backdrop click to dismiss
        /// because at this point in the tree we don't have access to the model to save.
        /// we can find a better way to slice the model stuff
        onDismiss={() => {}}
      >
        <VocabListModalContent story={story} onDismiss={onDismiss} />
      </Container>
    );
  }
);
