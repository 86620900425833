import { bugsnagNotify } from '@app/notification-service';
import NoSleep from 'nosleep.js';
import { embeddedMode, isEmbeddedPreV8, postNativeMessage } from './app-util';
import { createLogger } from '@common/log';
import { AppFactory } from '@app/app-factory';

const log = createLogger('wake-lock');

// Can be enabled for the study player and left disabled for the soundbite player
// Allows the low-level audio transport to signal play/pauses and only honor for the study player
// expected to be used as a singleton
export class WakeLock {
  // screen level state
  handlerEnabled: boolean = false;

  noSleepHandler: NoSleepHandler = null;

  ensureNoSleepHandler() {
    if (!this.noSleepHandler) {
      this.noSleepHandler = createNoSleepHandler();
    }
  }

  // screen level controls

  enableHandler(): void {
    if (AppFactory.root.localState.dontHackKeepAwake) {
      log.info('no-sleep hack disabled');
    } else {
      this.ensureNoSleepHandler();
      this.handlerEnabled = true;
    }
  }

  disableHandler(): void {
    this.deactivate(); // just in case not paused before player exited
    this.handlerEnabled = false;
  }

  // transport level controls - only honored when enabled at screen level

  // nosleep.js enable() will only work when triggered in response to an input event handler
  // but that seems to be naturally the case for our 'play' actions
  activate(): void {
    if (this.handlerEnabled) {
      try {
        this.noSleepHandler.enable();
      } catch (error) {
        // an 'The requesting page is not visible' error was seen here on windows, but not sure how to reproduce
        // https://app.bugsnag.com/jiveworld/cali-spa-beta/errors/641f44571ee4e40009c67388?filters%5Bevent.since%5D=30d&filters%5Berror.status%5D=open
        log.warn(`ignoring noSleepHandler.enable() error: ${error}`);
        bugsnagNotify(error as Error);
      }
    }
  }

  deactivate(): void {
    if (this.handlerEnabled) {
      try {
        this.noSleepHandler.disable();
      } catch (error) {
        log.warn(`ignoring noSleepHandler.disable() error: ${error}`);
        bugsnagNotify(error as Error);
      }
    }
  }
}

interface NoSleepHandler {
  enable: () => void;
  disable: () => void;
}

const createNoSleepHandler = () => {
  if (embeddedMode()) {
    return createEmbeddedNoSleep();
  } else {
    return new NoSleep();
  }
};

const createEmbeddedNoSleep = () => {
  return {
    enable() {
      if (isEmbeddedPreV8()) {
        // to be removed once 5.5.3 build is irrelevant
        postNativeMessage({
          type: 'string',
          payload: 'WEB-VIEW-NO-SLEEP-ENABLE',
        });
      } else {
        postNativeMessage({ type: 'ACTIVATE_KEEP_AWAKE' });
      }
    },

    disable() {
      if (isEmbeddedPreV8()) {
        // to be removed once 5.5.3 build is irrelevant
        postNativeMessage({
          type: 'string',
          payload: 'WEB-VIEW-NO-SLEEP-DISABLE',
        });
      } else {
        postNativeMessage({ type: 'DEACTIVATE_KEEP_AWAKE' });
      }
    },
  };
};
