import __ from '@core/lib/localization';
import { StringToString } from '@utils/util-types';
import { createLogger } from '@common/log';
import { notEmpty } from '@utils/conditionals';

const log = createLogger('api-messages');

const apiMessages: StringToString = {
  // @armando, ideas on how to best avoid duplicating the message keys?
  'api.auth.user_not_found': __('User not found', 'api.auth.user_not_found'),
  'api.auth.invalid_password': __(
    'Invalid password',
    'api.auth.invalid_password'
  ),
  'api.auth.account_closed': __('Account closed', 'api.auth.account_closed'),
  'api.auth.invalid_code': __('Invalid code', 'api.auth.invalid_code'),
  'api.auth.expired_code': __('Expired code', 'api.auth.expired_code'),
  'api.auth.short_code_emailed': __(
    'Email sent',
    'api.auth.short_code_emailed'
  ),
};

export const resolveApiMessage = ({
  messageKey,
  message,
}: {
  messageKey?: string;
  message: string; // server provided string
}): string => {
  if (notEmpty(messageKey)) {
    const result = apiMessages[messageKey];
    if (result) {
      return result;
    } else {
      log.warn(`messageKey not resolved: ${messageKey}`);
    }
  }
  return message; // fall back to server provided message if key not provided or resolved
};
