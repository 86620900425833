import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { observer } from 'mobx-react';

import {
  Table,
  TableRow,
  TableLabelCell,
  TableValueCell,
} from 'components/account/account-form/table';
import { /*Link,*/ MediumHeading, Text } from 'naan/primitives/text';
import { SectionBreak } from '@naan/primitives/spacer/gaps';
import { VSpacer } from 'naan/primitives/spacer';
import { formatDate } from 'utils/format-date';
import { Button } from 'naan/primitives/button';
import { HStack, VStack } from 'naan/primitives/stack';
import { Box } from 'naan/primitives/box';
// import { useStripeCheckout } from 'common/stripe-context';
// import { launchCheckoutInterstitial } from '../account-utils/launch-checkout-interstitial';
import { AppFactory } from 'app/app-factory';
import { styled } from 'naan/stitches.config';
import { AlertIcon } from '@naan/icons/alert-icon';
import { colors } from '@naan/tokens/colors';
// import { ActionLink } from 'common/ui/action-link';
import __ from 'core/lib/localization';
import { openUrl } from '@naan/primitives/text/external-link';
import { formatMoney } from '@utils/format-money';
import { alertSevereError } from '@app/notification-service';
import { ButtonLabel } from '@naan/primitives/button/button.styles';
// import { createSevereError } from '@core/lib/errors';

// import { createLogger } from '@common/log';
// const log = createLogger('full-auto-renew');

export const TextButton = styled('button', {
  all: 'unset',
  color: '$colors$teal-500',
  textStyle: 'body',
  textDecoration: 'underline',
  cursor: 'pointer',
  display: 'flex',
  '& .icon': {
    color: '$red-400',
  },
});

// const capitalizeFirstLetter = (str: string) => {
//   if (typeof str === 'string') {
//     return str.charAt(0).toUpperCase() + str.slice(1);
//   }

//   return '';
// };

// const formatCardDate = (date: string) => {
//   if (date) {
//     const [month, year] = date.split(' / ');
//     return `${month.length > 1 ? month : '0' + month}/${year.slice(2)}`;
//   }
//   return '';
// };

// TODO: should be able to remove
const CancelAppleAutoRenewDialog = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__('Cancel auto-renew', 'cancelAutoRenew')}
        </Dialog.Heading>
        <Dialog.Body>
          {__(
            'Your subscription is managed by the Apple App Store. You can cancel from your device’s Settings.',
            'yourSubscriptionIsManagedByTheAppleAppStore'
          )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Go back', 'goBack')}
            presentation="cancel"
          />
          <Dialog.Button
            onClick={() => {
              onDismiss();
              setTimeout(() => {
                openUrl('https://support.apple.com/en-us/HT202039');
              }, 100);
            }}
            label={__('Learn more', 'learnMore')}
            presentation={'teal'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

const CancelAutoRenewDialog = observer(
  ({
    onOk,
    onDismiss,
    fullAccessUntil,
  }: {
    onOk: () => void;
    onDismiss: () => void;
    fullAccessUntil: string;
  }) => {
    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__('Cancel auto-renew', 'cancelAutoRenew')}
        </Dialog.Heading>
        <Dialog.Body>
          {__(
            "By canceling your subscription you won't receive any further charges, and your access will end on %{date}",
            'byCancelingYourSubscriptionYouWontReceiveAnyFurther',
            {
              date: formatDate(fullAccessUntil),
            }
          )}{' '}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Go back', 'goBack')}
            presentation="cancel"
          />
          <Dialog.Button
            onClick={() => {
              onOk();
              setTimeout(() => {
                onDismiss();
              }, 100);
            }}
            label={__('Cancel subscription', 'cancelSubscription')}
            presentation={'teal'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

export const FullAutoRenew = observer(() => {
  const { userManager } = AppFactory.root;
  const { accountData } = userManager ?? {};
  const { membershipState, hasApplePaidAccess /* autoRenew */ } = accountData;
  const isSuspended = membershipState === 'suspended';
  const { paymentData } = accountData;

  const gotoStripePortal = React.useCallback(() => {
    userManager
      .createStripePortalSession(null /*default returnUrl*/)
      .then(result => {
        // console.log(`stripe portal: ${JSON.stringify(result)}`);
        const { url } = result;
        window.location.href = url;
      })
      .catch(error => {
        alertSevereError({ error, note: 'gotoStripePortal' });
      });
  }, [userManager]);

  const handleAutoRenew = () => {
    if (hasApplePaidAccess) {
      AppFactory.dialogPresenter.present(onDismiss => (
        <CancelAppleAutoRenewDialog onDismiss={onDismiss} />
      ));
    } else {
      AppFactory.dialogPresenter.present(onDismiss => (
        <CancelAutoRenewDialog
          onOk={() => {
            onDismiss();
            userManager
              .cancelAutoRenew()
              .then(result => {
                if (result.message) {
                  AppFactory.toastService.open({
                    type: 'success',
                    message: result.message,
                  });
                }
              })
              .catch(error => {
                alertSevereError({ error, note: 'cancelAutoRenew' });
              });
            // setTimeout(() => {
            //   DialogService.closeDialog();
            // }, 100);
          }}
          onDismiss={onDismiss}
          fullAccessUntil={accountData?.fullAccessUntil}
        />
      ));
    }
  };

  if (membershipState === 'full-auto-renew' || isSuspended) {
    return (
      <>
        <MediumHeading>{__('My membership', 'myMembership')}</MediumHeading>
        <VSpacer size="4" />
        <Table>
          <tbody>
            <TableRow>
              <TableLabelCell>
                {__('Membership type', 'membershipType')}
              </TableLabelCell>
              <TableValueCell>{accountData.membershipDisplay}</TableValueCell>
            </TableRow>

            <TableRow>
              <TableLabelCell>
                {__('Recurring billing', 'recurringBilling')}
              </TableLabelCell>
              <TableValueCell>
                <HStack justify={'space'}>
                  <VStack
                    css={{
                      maxWidth: '66%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {accountData.paymentData?.autoRenewFailed ? (
                      <Text textStyle="body-bold" color="red-400">
                        {__('Failed', 'failed')}
                      </Text>
                    ) : (
                      accountData.recurringBillingDisplay
                    )}
                  </VStack>
                  <Button
                    onClick={handleAutoRenew}
                    label={__('Turn off', 'turnOff')}
                    size={'small'}
                    presentation={'redLight'}
                    data-test-id="auto-renew-off"
                  />
                </HStack>
              </TableValueCell>
            </TableRow>

            <TableRow>
              <TableLabelCell>{__('Renews', 'renews')}</TableLabelCell>
              <TableValueCell>
                {formatDate(accountData.fullAccessUntil)}
                {accountData.paymentData?.autoRenewFailed
                  ? ' ' + __('(past due)', 'pastDue')
                  : null}
              </TableValueCell>
            </TableRow>
            <TableRow>
              <TableLabelCell>
                {__('Renewal amount', 'renewalAmount')}
              </TableLabelCell>
              <TableValueCell>
                {paymentData && formatMoney(paymentData.autoRenewAmount)}
              </TableValueCell>
            </TableRow>
            <TableRow>
              <TableLabelCell>
                {__('Payment method', 'paymentMethod')}
              </TableLabelCell>
              {hasApplePaidAccess ? (
                <TableValueCell error={isSuspended}>
                  {isSuspended ? <AlertIcon color={colors['red-400']} /> : null}
                  Apple subscription
                </TableValueCell>
              ) : (
                <TableValueCell error={isSuspended}>
                  <HStack justify={'space'}>
                    <Box
                      css={{
                        maxWidth: '66%',
                        overflow: 'hidden',
                      }}
                    >
                      <TextButton onClick={gotoStripePortal}>
                        {isSuspended ? (
                          <div className="icon">
                            <AlertIcon />
                          </div>
                        ) : null}
                        <ButtonLabel>
                          {__('View / update', 'viewUpdate')}
                        </ButtonLabel>
                      </TextButton>

                      {/* the button didn't quite render as desired */}
                      {/* <Button
                        label={__(
                          'View / update',
                          'viewUpdate'                        )}
                        onClick={gotoStripePortal}
                        presentation="tealTransparent"
                        css={{ textDecoration: 'underline' }}
                      /> */}
                    </Box>
                    {/* <FormError error={serverError} /> */}
                  </HStack>
                </TableValueCell>
              )}
            </TableRow>
          </tbody>
        </Table>
        <SectionBreak />
      </>
    );
  }

  return null;
});
