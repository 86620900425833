import React from 'react';
// import __ from 'core/lib/localization';
import { Story } from '@core/models/story-manager/story';
import __ from '@core/lib/localization';
import { styled } from '@naan/stitches.config';

const MetaWrapper = styled('div', {
  '--meta-text': 'var(--text-secondary)',

  marginBottom: '56px',

  '& .meta-set': {
    display: 'flex',
    marginBottom: '8px',

    '& svg': {
      margin: '2px 8px 0 0',
    },

    '& .p': {
      font: 'var(--body-font)',
      color: 'var(--meta-text)',
    },
  },

  '@media print': {
    '--meta-text': '#777',
  },
});

const ClockIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
      stroke="#999999"
      strokeWidth="2"
    />
    <path d="M14 10H10V5" stroke="#999999" />
  </svg>
);

const FlagIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.66667 3.6C6.11111 3.6 3 2.4 3 2.4V9.99997V11.2V18H4V11.5573C5.04684 11.911 6.67368 12.4 7.66667 12.4C8.44444 12.4 9.51389 12 10.5833 11.6C11.6528 11.2 12.7222 10.8 13.5 10.8C15.0556 10.8 17 12 17 12V3.2C17 3.2 15.0556 2 13.5 2C12.7222 2 11.6528 2.4 10.5833 2.8C9.51389 3.2 8.44444 3.6 7.66667 3.6Z"
      fill="#999999"
    />
  </svg>
);

const TagIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.94174 2.96105L2.39051 10.5016C2.13013 10.7763 2 11.0967 2 11.4628C2 11.836 2.13013 12.1525 2.39066 12.4132L7.57625 17.6092C7.8508 17.8698 8.17143 18 8.53727 18C8.9107 18 9.2273 17.8698 9.48782 17.6092L17.0389 10.0475C17.3066 9.78693 17.5336 9.43157 17.7202 8.98084C17.9068 8.53011 18 8.11837 18 7.74521V3.35182C18 2.98565 17.8663 2.66882 17.5987 2.40127C17.3312 2.13387 17.0143 2 16.6482 2H12.2547C11.8815 2 11.4697 2.09329 11.0189 2.27983C10.5684 2.46634 10.2094 2.69331 9.94174 2.96105ZM14.2818 7.08974C13.9032 7.08974 13.5801 6.95573 13.3121 6.68787C13.0441 6.41996 12.9103 6.09686 12.9103 5.71829C12.9103 5.33972 13.0443 5.01646 13.3121 4.74871C13.58 4.4808 13.9032 4.3468 14.2818 4.3468C14.6603 4.3468 14.9835 4.4808 15.2514 4.74871C15.5192 5.01646 15.6531 5.33968 15.6531 5.71829C15.6531 6.09686 15.5192 6.41996 15.2514 6.68787C14.9835 6.95573 14.6603 7.08974 14.2818 7.08974Z"
      fill="#999999"
    />
  </svg>
);

export const StoryMeta = ({ story }: { story: Story }) => {
  return (
    <MetaWrapper>
      <div className="meta-set">
        <ClockIcon />
        <div className="p">{story.classroomDurationDescription}</div>
      </div>
      <div className="meta-set">
        <FlagIcon />
        <p className="p">{story.countries.join(__(', ', 'listSeparator'))}</p>
      </div>
      <div className="meta-set">
        <TagIcon />
        <p className="p">{story.topics.join(__(', ', 'listSeparator'))}</p>
      </div>
    </MetaWrapper>
  );
};
