import * as React from 'react';
import { isFunction } from 'lodash';
import { Root, Trigger } from '@radix-ui/react-dropdown-menu';
import { IconButton } from '../button';
import { OverflowIcon } from '../../icons/overflow-icon';
import { AfterActionCallback, MenuItem, MenuItemProps } from './menu-item';
import { MenuContent } from './menu-content';

type MenuProps = {
  items?: MenuItemProps[];
  children?: React.ReactNode;
  afterAction?: AfterActionCallback;
  trigger?: React.ReactNode;
};

const defaultTrigger = (
  <IconButton presentation="grayTransparent" icon={<OverflowIcon />} />
);

export const Menu = ({
  items = [],
  children,
  afterAction = () => {},
  trigger = defaultTrigger,
}: MenuProps) => {
  const treatItemsAsRenderFunction = isFunction(items);

  const mapOverItemsArray = () =>
    items.map((item, index) => {
      const props = item;

      return (
        <MenuItem
          {...props}
          key={`menu-item-${index}`}
          afterAction={afterAction}
        />
      );
    });

  return (
    <Root>
      <Trigger asChild>{trigger}</Trigger>
      <MenuContent
        style={{
          zIndex: 5,
        }}
      >
        {treatItemsAsRenderFunction
          ? items({ afterAction })
          : mapOverItemsArray()}
        {children}
      </MenuContent>
    </Root>
  );
};
