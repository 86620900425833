import React from 'react';

import { useTitle } from 'common/hooks/use-title';
import { PageHeading } from 'components/page-heading';
import { FilteringProvider } from 'common/filtering/filtering-provider';
import { FiltersUi } from 'components/discover/filters-ui';
import { FilteredUnits } from 'components/discover/filtered-units/filtered-units';
// import { TeacherResources } from 'components/teacher-resources';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';

// import { OldHeaderContainer } from 'components/header';
import {
  ContentGrid,
  ContentGridInnerContainer,
} from 'components/global-layout';
import { TeachWelcomeMessage } from 'components/welcome-message';
import { useLocation } from 'react-router-dom';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';

import __ from 'core/lib/localization';

const LayoutWrapper = styled('section', {
  gridColumn: '1/-1',
});

export const DiscoverScreen = observer(() => {
  useTitle(__('Discover', 'discover'));

  const location = useLocation();

  // useScrollRestoration(location.pathname);

  return (
    <>
      <DeepScrollRestoration pageKey={location.pathname} />
      <CenterColumnLayout isolate>
        <FilteringProvider>
          <TeachWelcomeMessage />
          <ContentGridInnerContainer>
            <PageHeading title={__('Discover', 'discover')} />
            <FiltersUi />
            <ContentGrid>
              <LayoutWrapper>
                <FilteredUnits />
              </LayoutWrapper>
            </ContentGrid>
          </ContentGridInnerContainer>
        </FilteringProvider>
      </CenterColumnLayout>
    </>
  );
});

export default DiscoverScreen;
