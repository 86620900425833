import { appConfig } from 'app/env';
import { createLogger } from '@common/log';
import {
  version,
  curEnv,
  buildTimestampIso,
  commit,
  commitTimestampIso,
  hash,
  // @ts-expect-error
} from '@jw-spa-version';
import { AppFactory } from '@app/app-factory';
import { bugsnagNotify } from '@app/notification-service';
import { objectFromUrlQuery } from '@common/object-from-url-query';
import { track } from '@app/track';

const log = createLogger('app-util');

export const devMode = !!import.meta.env.DEV;

export const getProductVersion = () => `${version}-spa`;

export const appSlug = () => appConfig.appSlug;

export const germanMode = () => appSlug() === 'german';

// todo: revisit this
export const subBrand = () => (germanMode() ? 'deutsch' : 'español');

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ReactNativeWebView: any;
    embeddedPlatform: string;
    embeddedBuildNumber: string;
  }
}

// todo: cleanup redundancy with app-root init logic
const handleEmbeddedParams = () => {
  const { embeddedPlatform, embeddedBuildNumber } = objectFromUrlQuery();
  if (embeddedPlatform) {
    window.embeddedPlatform = embeddedPlatform;
  }
  if (embeddedBuildNumber) {
    window.embeddedBuildNumber = embeddedBuildNumber;
  }
};

handleEmbeddedParams();

// const w = window; // as Window; // any

export const embeddedPlatform = () => window.embeddedPlatform;
export const embeddedBuildNumber = () => window.embeddedBuildNumber;

export const formattedEmbeddedBuildNumber = () => {
  const raw = embeddedBuildNumber();
  try {
    const major = Number(raw[0]);
    const minor = Number(raw.substring(1, 3));
    const patch = Number(raw.substring(3, 5));
    const rev = raw.substring(5);
    return `${major}.${minor}.${patch}-${rev}`;
  } catch (error) {
    return raw;
  }
};

export const buildInfoDisplay = () => {
  const env = appConfig.apiEnv === 'LIVE' ? '' : ` (${appConfig.apiEnv})`;

  if (embeddedMode()) {
    return `${embeddedPlatform()}: ${formattedEmbeddedBuildNumber()}, web: ${version}${env}`;
  } else {
    return `v${version}${env}`;
  }
};

export const updateStoreUrl = () => {
  return embeddedAndroid()
    ? appConfig.website.downloadUrls.playStore
    : appConfig.website.downloadUrls.appStore;
};

export const embeddedMode = () => {
  return /(ios|android)/.test(embeddedPlatform());
};

export const isEmbeddedPreV8 = () => {
  const buildNumber = Number(embeddedBuildNumber() || 0);
  return buildNumber && buildNumber < 8000000;
};

export const embeddedIos = () => embeddedPlatform() === 'ios';
export const embeddedAndroid = () => embeddedPlatform() === 'android';

// dev tools support
export const setEmbeddedPlatform = (platform: string) => {
  window.embeddedPlatform = platform;
  const { localState } = AppFactory.root;
  localState.embeddedPlatform = platform;
  localState.persist().catch(bugsnagNotify);
};

export const setEmbeddedBuildNumber = (buildNumber: string) => {
  window.embeddedBuildNumber = buildNumber;
  const { localState } = AppFactory.root;
  localState.embeddedBuildNumber = buildNumber;
  localState.persist().catch(bugsnagNotify);
};

export const postNativeMessage = ({
  type,
  payload,
  data,
}: {
  type: string;
  payload?: any;
  data?: any;
}) => {
  log.info(
    `postNativeMessage: ${type}, payload: ${JSON.stringify(
      payload
    )}, data: ${JSON.stringify(data)}`
  );
  if (!window.ReactNativeWebView?.postMessage) {
    log.error(`ReactNativeWebView.postMessage unexpectedly missing`);
    track('error__native_post_message', {
      type,
      payload,
      data,
      flavor: 'guarded',
      error: 'postMessage function missing',
    });
  } else {
    // TODO, for some android devices we're hitting "postMessage is not a function" here
    // https://app.bugsnag.com/jiveworld/cali-spa-live/errors/642385976797fd000939a822?filters[event.since]=30d&filters[error.status]=open
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type,
          payload,
          data,
        })
      );
      track('system__native_post_message', {
        type,
        payload,
        data,
      });
    } catch (error) {
      track('error__native_post_message', {
        type,
        payload,
        data,
        flavor: 'caught',
        error: String(error),
      });
    }
  }
};

// used in error reporting and metrics
export const buildInfo = () => {
  return {
    version,
    curEnv,
    buildTimestampIso,
    commit,
    commitTimestampIso,
    hash,
    embedded: {
      mode: embeddedMode(),
      platform: embeddedPlatform(),
      buildNumber: embeddedBuildNumber(),
    },
    // distinguishes german version of site
    appSlug: appSlug(),
  };
};

// Detect native Wake Lock API support
export const nativeWakeLockSupported = (): boolean => 'wakeLock' in navigator;
