import React, { ComponentProps } from 'react';
import { observer } from 'mobx-react';
// import { IconButton } from '@naan/primitives/button';
import { usePlayerModel } from 'player/views/player-model-context';
import { RedactionMode } from 'player/models/redaction-modes';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { CheckmarkIcon } from '@naan/icons/checkmark-icon';
import { IconButtonStyled } from '@naan/primitives/button/icon-button-styles';
import {
  LeftButtonIcon,
  RightButtonIcon,
  ButtonLabel,
  ButtonStyled,
} from '@naan/primitives/button/button.styles';
import { ChevronDownExtraSmallIcon } from '@naan/icons/chevron-down-icon';
import { SettingsExtraSmallIcon } from '@naan/icons/settings-icon';
import { StudyModel } from 'study/models/study-model';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { Responsive } from '@naan/primitives/responsive';
import __ from '@core/lib/localization';
import { keyframes, styled } from '@naan/stitches.config';

const ShowAllIcon = ({ color = 'currentColor' }) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.96009 15.9521C6.52236 15.9521 6.97218 15.7447 7.35774 15.1223L7.03644 14.7713C6.85973 14.9787 6.68301 15.1223 6.40991 15.1223C6.05648 15.1223 5.83157 14.867 5.83157 14.1809V10.7819C5.83157 8.77128 5.02832 8.00532 3.34151 8.00532C1.70288 8.00532 0.514078 8.77128 0.208844 9.98404C0.257039 10.3989 0.514078 10.6383 0.963896 10.6383C1.39765 10.6383 1.65469 10.367 1.79927 9.87234L2.10451 8.83511C2.44187 8.75532 2.73104 8.73936 2.97201 8.73936C4.0323 8.73936 4.48212 9.12234 4.48212 10.6702V11.0691C3.83952 11.2128 3.16479 11.4202 2.66678 11.5957C0.498013 12.3777 0 13.0957 0 14.0053C0 15.3298 0.979961 16 2.1527 16C3.10053 16 3.63067 15.5851 4.51425 14.7234C4.64277 15.4734 5.18897 15.9521 5.96009 15.9521ZM1.42978 13.766C1.42978 13.2872 1.60649 12.6809 3.00414 12.1383C3.34151 12.0106 3.91984 11.8191 4.48212 11.6596V14.117C3.56641 14.7553 3.26118 14.9468 2.71497 14.9468C1.97599 14.9468 1.42978 14.6117 1.42978 13.766Z"
        fill={color}
      />
      <path
        d="M12.4309 15.0904C11.8365 15.0904 11.1778 14.8351 10.5191 14.2128V9.84043C11.3866 9.12234 12.0132 8.91489 12.5112 8.91489C13.7161 8.91489 14.5514 10.016 14.5514 12.0585C14.5514 14.117 13.5715 15.0904 12.4309 15.0904ZM7.93269 15.7926L10.3424 15.9521L10.4709 14.883C11.1617 15.7447 11.9329 16 12.6879 16C14.5675 16 15.9973 14.3883 15.9973 11.9309C15.9973 9.45745 14.6478 8.00532 12.9449 8.00532C12.0614 8.00532 11.2421 8.3883 10.4709 9.23404V6.39362L10.5352 4.12766L10.3103 4L7.85236 4.71809V5.2766L9.16969 5.43617V12.4415C9.16969 13.1596 9.15362 14.2128 9.13756 15.0106L7.93269 15.2181V15.7926Z"
        fill={color}
      />
      <path
        d="M21.1726 8.73936C21.4457 8.73936 21.7348 8.77128 22.0401 8.86702L22.3292 9.92021C22.4417 10.367 22.6666 10.6862 23.2128 10.6862C23.6144 10.6862 23.8715 10.4947 23.9518 10.0798C23.743 8.89894 22.5542 8.00532 21.1083 8.00532C19.1805 8.00532 17.3973 9.50532 17.3973 12.0266C17.3973 14.484 18.811 16 21.0119 16C22.522 16 23.5502 15.2819 24 13.9574L23.5984 13.75C23.1004 14.5319 22.2971 14.9628 21.3814 14.9628C19.8713 14.9628 18.811 13.8777 18.811 11.867C18.811 9.82447 19.8071 8.73936 21.1726 8.73936Z"
        fill={color}
      />
    </svg>
  );
};
const ShowSomeIcon = ({ color = 'currentColor' }) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5191 14.2128C10.6337 14.321 10.7483 14.4182 10.8626 14.5049L10.5735 15.0059C10.5392 14.9663 10.505 14.9254 10.4709 14.883L10.4253 15.2628L10.0392 15.932L7.93269 15.7926V15.2181L9.13756 15.0106C9.15362 14.2128 9.16969 13.1596 9.16969 12.4415V5.43617L7.85236 5.2766V4.71809L10.3103 4L10.5352 4.12766L10.4709 6.39362V9.23404C11.2421 8.3883 12.0614 8.00532 12.9449 8.00532C13.4672 8.00532 13.9561 8.14188 14.3831 8.40257L13.774 9.45839C13.4365 9.09972 13.0048 8.91489 12.5112 8.91489C12.0132 8.91489 11.3866 9.12234 10.5191 9.84043V14.2128ZM5.96009 15.9521C6.52236 15.9521 6.97218 15.7447 7.35774 15.1223L7.03644 14.7713C6.85973 14.9787 6.68301 15.1223 6.40991 15.1223C6.05648 15.1223 5.83157 14.867 5.83157 14.1809V10.7819C5.83157 8.77128 5.02832 8.00532 3.34151 8.00532C1.70288 8.00532 0.514078 8.77128 0.208844 9.98404C0.257039 10.3989 0.514078 10.6383 0.963896 10.6383C1.39765 10.6383 1.65469 10.367 1.79927 9.87234L2.10451 8.83511C2.44187 8.75532 2.73104 8.73936 2.97201 8.73936C4.0323 8.73936 4.48212 9.12234 4.48212 10.6702V11.0691C3.83952 11.2128 3.16479 11.4202 2.66678 11.5957C0.498013 12.3777 0 13.0957 0 14.0053C0 15.3298 0.979961 16 2.1527 16C3.10053 16 3.63067 15.5851 4.51425 14.7234C4.64276 15.4734 5.18897 15.9521 5.96009 15.9521ZM1.42978 13.766C1.42978 13.2872 1.60649 12.6809 3.00414 12.1383C3.34151 12.0106 3.91984 11.8191 4.48212 11.6596V14.117C3.56641 14.7553 3.26118 14.9468 2.71497 14.9468C1.97599 14.9468 1.42978 14.6117 1.42978 13.766ZM21 8H15.4828L11 16H21C22.6569 16 24 14.6569 24 13V11C24 9.34315 22.6569 8 21 8Z"
        fill={color}
      />
    </svg>
  );
};
const ShowNoneIcon = ({ color = 'currentColor' }) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y={8} width={24} height={8} rx={3} fill={color} />
    </svg>
  );
};

const RedactionModeToIconMap = {
  [RedactionMode.SHOW_NONE]: ShowNoneIcon,
  [RedactionMode.SHOW_SOME]: ShowSomeIcon,
  [RedactionMode.SHOW_ALL]: ShowAllIcon,
};

const RedacttionModeToLabelMap = {
  [RedactionMode.SHOW_NONE]: __('Hide all words', 'hideAllWord'),
  [RedactionMode.SHOW_SOME]: __('Show some words', 'showSomeWord'),
  [RedactionMode.SHOW_ALL]: __('Show all words', 'showAllWord'),
};

const RedactionModeMenuItem = observer(({ mode }: { mode: RedactionMode }) => {
  const model = usePlayerModel();
  const Icon = RedactionModeToIconMap[mode];
  const label = RedacttionModeToLabelMap[mode];

  return (
    <MenuItem
      action={() => {
        model.setRedactionMode(mode);
        // return false;
      }}
      label={label}
      leftIcon={<Icon />}
      rightIcon={model.redactionMode === mode ? <CheckmarkIcon /> : null}
      isSelected={model.redactionMode === mode}
    />
  );
});

const fadeIn = keyframes({
  from: { opacity: 0, filter: 'blur(2px)' },
  to: { opacity: 1, blur: 'blur(0)' },
});

const Wrapper = styled('div', {
  animation: `${fadeIn} 0.3s ease-out`,
});

type ButtonPickedPresentation = Pick<
  ComponentProps<typeof ButtonStyled>,
  'presentation'
>;

type IconButtonPickedPresentation = Pick<
  ComponentProps<typeof IconButtonStyled>,
  'presentation'
>;

const LargeTrigger = observer(
  ({
    presentation = 'grayTransparent',
    label,
  }: { label: string } & ButtonPickedPresentation) => {
    /// The only way to prevent this from gaining focus is
    /// to not use a button element. This will make it inaccessible
    /// for keyboard users, but we don't care about that.
    return (
      <ButtonStyled presentation={presentation} size="small" as="div">
        <LeftButtonIcon>
          <SettingsExtraSmallIcon />
        </LeftButtonIcon>
        <ButtonLabel size="small">{label}</ButtonLabel>
        <RightButtonIcon size={'small'}>
          <ChevronDownExtraSmallIcon width={9} height={9} />
        </RightButtonIcon>
      </ButtonStyled>
    );
  }
);

const SmallTrigger = observer(
  ({ presentation = 'grayTransparent' }: IconButtonPickedPresentation) => {
    /// The only way to prevent this from gaining focus is
    /// to not use a button element. This will make it inaccessible
    /// for keyboard users, but we don't care about that.
    return (
      <IconButtonStyled presentation={presentation} size="small" as="div">
        <span className="button-inner">
          <SettingsExtraSmallIcon />
        </span>
      </IconButtonStyled>
    );
  }
);

const ResponsiveTrigger = (props: any) => {
  return (
    <Responsive
      renderDefault={() => <SmallTrigger {...props} />}
      renderSmallAndUp={() => <LargeTrigger {...props} />}
    />
  );
};

export const GlobalRedactionButton = observer(
  ({
    presentation = 'grayTransparent',
    responsive = false,
  }: { responsive?: boolean } & ButtonPickedPresentation) => {
    const model = usePlayerModel() as StudyModel | SoundbiteModel;

    if (model.fluentListenMode === true) {
      return null;
    }

    const label = RedacttionModeToLabelMap[model.redactionMode];

    return (
      <Menu
        trigger={
          /// The only way to prevent this from gaining focus is
          /// to not use a button element. This will make it inaccessible
          /// for keyboard users, but we don't care about that.
          <Wrapper>
            {responsive ? (
              <ResponsiveTrigger
                presentation={presentation as any}
                label={label}
              />
            ) : (
              <LargeTrigger label={label} presentation={presentation} />
            )}
          </Wrapper>
        }
      >
        <RedactionModeMenuItem mode={RedactionMode.SHOW_NONE} />
        <RedactionModeMenuItem mode={RedactionMode.SHOW_SOME} />
        <RedactionModeMenuItem mode={RedactionMode.SHOW_ALL} />
      </Menu>
    );
  }
);
