import { AppFactory } from 'app/app-factory';
import { appConfig } from 'app/env';
import __ from 'core/lib/localization';
// import { bugsnagNotify } from './services/notification-service';
// @ts-expect-error
import { versionCheck } from '@jw-spa-version';

import { createLogger } from '@common/log';
import { hasServiceWorker } from './update-manager';
const log = createLogger('update-checker');

//
// used for native ios webview
//

let pollIntervalMillis = appConfig.updateCheckIntervalMs;
log.info(
  `poll interval: ${pollIntervalMillis}ms - build version: ${versionCheck}`
);

// when doing a dump reload and we see the mismatched version.txt immediately upon startup
// we need to deplay the user prompt
const BLIND_RELOAD_DELAY_MS = 15 * 1000;

let firstPoll = true;

export const backoff = () => {
  pollIntervalMillis = pollIntervalMillis * 2;
  log.info(`back off poll interval to ${pollIntervalMillis}ms`);
};

export const pollForUpdate = () => {
  checkForUpdate({ poll: true }).catch(
    error => log.error(error) // shouldn't happen
  );
};

export const checkForUpdate = async ({
  poll = false,
}: {
  poll: boolean;
}): Promise<boolean> => {
  try {
    let mismatched = false;
    if (hasServiceWorker()) {
      await checkSwForUpdate(); // we don't directly know if an update was found or not
    } else {
      mismatched = await checkVersionTextForUpdate();
    }

    if (poll && !mismatched) {
      firstPoll = false; // used to delay second reload prompt for blind version.txt poll
      setTimeout(() => checkForUpdate({ poll }), pollIntervalMillis);
    }
    return mismatched;
  } catch (error) {
    log.warn(`poll failed: ${error}`);
    backoff();
  }
};

export const checkSwForUpdate = async (): Promise<void> => {
  log.info('checking for sw update');
  await AppFactory.workbox.update();
};

export const checkVersionTextForUpdate = async (): Promise<boolean> => {
  const res = await fetch('/version.txt');
  const text = await res.text();
  const fetchedVersion = text.trim();
  const mismatched = fetchedVersion !== versionCheck;
  log.debug(
    `buildVersion: ${versionCheck}, fetchedVersion: ${fetchedVersion}, mismatched: ${String(
      mismatched
    )}, firstPoll: ${String(firstPoll)}`
  );
  if (mismatched) {
    if (firstPoll) {
      log.info(`immedialy mismatched version.txt - pausing before prompt`);
      setTimeout(showReloadToast, BLIND_RELOAD_DELAY_MS);
    } else {
      showReloadToast();
    }
  }
  return mismatched;
};

// dumb reload, used for version text mismatch flow
export const showReloadToast = () => {
  AppFactory.toastService.open({
    message: __('This site has been updated', 'thisSiteHasBeenUpdated'),
    type: 'info',
    action: {
      label: __('Refresh', 'refresh'),
      callback: () => {
        AppFactory.root?.userManager?.persistLocal();
        window.location.reload();
      },
    },
    timeout: null,
  });
};
