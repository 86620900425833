import deepmerge from 'deepmerge';
import { config as baseConfig } from './base-devtest';

//
// german-test.jiveworld.app
//
// nps deploy.german.test
//

export const config = deepmerge(baseConfig, {
  appSlug: 'german',

  // pwaCheckIntervalMs: 1 * 60 * 1000, // 1 min check interval for devtest and preview deploys
  // showUnhandledErrors: true,

  // authShortCodeEnabled: false,

  analytics: {
    // googleAnalyticsKey: 'G-PSM9E3F4ZF',
  },

  website: {
    // urls: {
    //   marketingSite: 'https://www.staging.jiveworld.app',
    // },
    // allows sharing of jw-traffic-source data to rails api
    // cookieDomain: '.test.jiveworld.app',
  },

  // lambdaFunctions: {
  //   enabled: true,
  // },
});
