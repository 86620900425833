import React, { useEffect } from 'react';
import { createLogger } from '@common/log';
import {
  alertWarningError,
  bugsnagNotify,
  notifySuccess,
} from '@app/notification-service';
import { AppFactory } from '@app/app-factory';
import __ from '@core/lib/localization';
import { appConfig } from '@app/env';
import {
  versionCheck,
  // @ts-expect-error
} from '@jw-spa-version';
import { RELOAD_PROMPTED_MESSAGE_TYPE } from 'pwa/shared';
import { pollForUpdate } from './update-checker';
import { messageSW, Workbox } from 'workbox-window';

const log = createLogger('sw:update-manager');

const swMode: string = '__SW_MODE__';
const promptEnabled = swMode === 'prompt';
log.info(
  `swMode: ${swMode}, promptEnabled: ${String(
    promptEnabled
  )}, build: ${versionCheck}`
);

export const UpdateManager = () => {
  // const serviceWorker = navigator.serviceWorker;

  useEffect(() => {
    log.debug(`inside useEffect - build: ${versionCheck}`);
    initializeUpdateManager().catch(error =>
      alertWarningError({ error, note: 'UpdateManager.useEffect' })
    );
  }, []);

  return <></>;
};

const initializeUpdateManager = async () => {
  log.info(
    `initializeUpdateManager - nav.sw: ${String(
      hasServiceWorker()
    )}, sw.con: ${String(hasSwController())}, promptEnabled: ${String(
      promptEnabled
    )}`
  );
  if (hasServiceWorker()) {
    const wb = new Workbox('/sw.js');
    AppFactory.setWorkbox(wb);

    const firstTimeInstall = !navigator.serviceWorker?.controller;
    log.info(`firstTimeInstall: ${String(firstTimeInstall)}`);

    // we always want to refresh when we detected a new controller
    wb.addEventListener('controlling', async event => {
      try {
        const userManager = AppFactory.root?.userManager;
        log.debug(
          `received controlling event, isUpdate: ${String(event.isUpdate)}`
        );
        // if (event.isUpdate) { // beware, isUpdate not populated as expected
        if (firstTimeInstall) {
          log.info('initial install complete - offline ready');
          if (userManager?.hasAdminAccess) {
            notifySuccess('[service worker: offline ready]');
          }
        } else {
          log.info(
            `about to reload, will persist local - user email: ${String(
              userManager?.accountData?.email
            )}`
          );
          await userManager?.persistLocal();
          window.location.reload();
        }
      } catch (error) {
        alertWarningError({ error });
      }
    });

    if (promptEnabled) {
      wb.addEventListener('waiting', async event => {
        log.debug(
          `received waiting event, isUpdate: ${String(
            event.isUpdate
          )}, isExternal: ${String(event.isExternal)}`
        );
        // if (event.isUpdate) { // beware, isUpdate not populated as expected
        presentSwUpdateToast(wb);
        informPrompted().catch(error => alertWarningError({ error }));
      });
    }

    await wb.register();

    log.debug(
      `reg.active: ${!!wb.active}, sw.con: ${String(
        !!navigator.serviceWorker?.controller
      )}`
    );
  } else {
    log.warn(
      'initializeUpdateManager - service worker not found - using version.txt poll mode'
    );
  }

  // adaptively polls either sw.js or version.txt
  // will perhaps switch to a firestore subscription
  pollForUpdate();
};

const presentSwUpdateToast = (wb: Workbox) => {
  log.debug('presentSwUpdateToast');
  const { toastService } = AppFactory;
  toastService.open({
    message: __('This site has been updated', 'thisSiteHasBeenUpdated'),
    type: 'info',
    action: {
      label: __('Reload', 'reload'),
      callback: () => {
        setTimeout(() => {
          // seems possible to require this flow if an update is detected before
          // the initial install is complete
          log.warn(
            `presentSwUpdateToast: skip waiting didn't reload window yet - will trigger directly`
          );
          window.location.reload();
        }, 2000);
        log.debug(`before updateServiceWorker`);
        wb.messageSkipWaiting();
      },
    },
    timeout: null,
  });
};

const informPrompted = async () => {
  // this didn't reliably return the installed and waiting SW
  // const waitingSW = await wb.getSW();

  // can't figure out how to access the registration through the documented workbox api
  // (it seems to be stuffed into a private property)
  const registration = await navigator.serviceWorker.getRegistration();
  log.info(`registration: ${String(!!registration)}`);
  // not yet sure when this can happen
  if (!registration) {
    throw Error('sw.register failed');
  }

  // @jason, please see if you can figure what else we might be able to do here
  const pendingSW = registration.waiting || registration.installing;
  log.info(`has pendingSW: ${String(!!pendingSW)}`);

  // tell the new service worker to be paitent
  // pendingSW.postMessage({ type: RELOAD_PROMPTED_MESSAGE_TYPE }); // postMessage doesn't work on ios pwa

  // messageSW uses 'channel' api
  if (pendingSW) {
    messageSW(pendingSW, { type: RELOAD_PROMPTED_MESSAGE_TYPE }).catch(
      bugsnagNotify
    );
  } else {
    bugsnagNotify(
      Error('UpdateManager.informPrompted - failed to resolve pendingSW')
    );
  }

  // messageSW(waitingSW, { type: RELOAD_PROMPTED_MESSAGE_TYPE }).catch(error =>
  //   log.error(error)
  // );
};

// for manual trigger from dev-tools
export const trySkipWaiting = async () => {
  AppFactory.workbox.messageSkipWaiting();
};

// one-off check from dev-tools
export const checkForSwUpdate = async () => {
  log.info(`checkForSwUpdate (one-off)`);
  const registration = await resolveRegistration();
  if (registration) {
    registration.update().catch(error =>
      alertWarningError({
        error,
        note: 'checkForSwUpdate - registration.update() failed',
      })
    );
  }
};

export const resolveRegistration = async ({
  alert = true,
}: { alert?: boolean } = {}): Promise<ServiceWorkerRegistration> => {
  const serviceWorker = navigator.serviceWorker;
  if (!serviceWorker) {
    log.error(`no serviceWorker - ignoring`);
    return null;
  }

  const registration = await serviceWorker.getRegistration();
  if (!registration) {
    const message = 'failed to resolve sw.registration';
    if (alert) {
      alertWarningError({
        error: Error(message),
      });
    } else {
      log.warn(message);
    }
    return null;
  }

  return registration;
};

export const attemptReregistration = async () => {
  const serviceWorker = navigator.serviceWorker;
  const oldRegistration = await resolveRegistration();
  log.info(
    ` old reg: ${String(!!oldRegistration)}, sw.controller: ${String(
      !!serviceWorker?.controller
    )}`
  );
  if (oldRegistration) {
    await oldRegistration.unregister();
  }
  const registration = await serviceWorker.register('/sw.js');
  log.info(
    `new reg: ${String(!!registration)}, sw.controller: ${String(
      !!serviceWorker?.controller
    )}`
  );
};

const { simulateMissingServiceWorker } = appConfig;

export const hasServiceWorker = (): boolean => {
  return !!navigator.serviceWorker && !simulateMissingServiceWorker;
};

export const hasSwController = (): boolean => {
  return !!navigator.serviceWorker?.controller;
};
