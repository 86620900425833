import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { namedStyled } from '@naan/stitches.config';
import { PreviewCard } from './preview-card';
import { EndCard } from './end-card';
import { CloseSmallIcon } from '@naan/icons/close-icon';
import { IconButton } from '@naan/primitives/button';
import { VideoPlayerModel } from './video-player-model';
import { VideoPlayer } from 'components/ui/video-player';
import { VideoGuideEngagementStatus } from '@core/models/user-manager/video-guide-engagement';
import { getSourceForId } from 'components/ui/video-player/player-view-controller';
import { useThemeColor } from '@common/pwa/set-theme-color';
import { track } from '@app/track';
import { Insets } from 'native-support/insets';

const Wrapper = namedStyled('VideoModalWrapper', 'div', {
  width: '100%',
  height: '100%',
  // paddingTop: (16 / 9) * 100 + '%',
  backgroundColor: '$blue-700',
  overflow: 'hidden',
  // borderRadius: '$space$3',
  position: 'relative',
  '@medium': {
    height: 'auto',
    width: 'min(960px, calc(100vw - 32px), calc((100vh / 0.5625) - 32px))',
    paddingTop: (9 / 16) * 100 + '%',
    borderRadius: '$space$3',
  },

  '& > .card': {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    '@medium': {
      borderRadius: '$space$3',
    },
  },

  '& > .preview': {
    backgroundColor: '$blue-700',
    zIndex: 3,
    backgroundSize: 'cover',
  },

  '& > .close-btn-container': {
    position: 'absolute',
    right: 16,
    top: Insets.top ? 16 + Insets.top : 'calc(16px + env(safe-area-inset-top))',
    zIndex: 4,
    touchEvents: 'none',
  },
});

export const CloseButton = observer(({ onClick }: { onClick: () => void }) => {
  return (
    <div className="close-btn-container">
      <IconButton
        presentation={'whiteDarken'}
        size={'small'}
        icon={<CloseSmallIcon />}
        onClick={onClick}
      />
    </div>
  );
});

export const VideoModal = observer(
  ({
    model,
    onDismiss,
  }: {
    model: VideoPlayerModel;
    onDismiss: () => void;
  }) => {
    useThemeColor('black');

    const video = model.current;
    const onVideoEnd = React.useCallback(() => {
      track('helplet__on_video_end', { videoSlug: model?.current?.slug });
      model.userData.recordEngagement({
        slug: model.current.slug,
        status: VideoGuideEngagementStatus.PLAYED,
      });
    }, [model]);

    if (!video) {
      return null;
    }

    // console.log('VIDEO ID:', video.videoIdLandscape);
    // const src = getCloudflareDashUrlFromId(video.videoIdLandscape);
    const src = getSourceForId(video.videoIdLandscape);

    // console.log('VIDEO SRC:', src);

    return (
      <Dialog.Container
        open
        onDismiss={onDismiss}
        css={{
          $$maxWidth: 'none',
          maxHeight: 'none',
          '@smallOnly': {
            width: '100%',
            height: '100%',
            borderRadius: 0,
          },
        }}
        overlayCss={{
          backgroundColor: '$colors$black-alpha-60',
        }}
      >
        <Wrapper className="video-modal-wrapper">
          <VideoPlayer
            src={src}
            onEnded={onVideoEnd}
            renderEndCard={player => (
              <EndCard player={player} model={model} onDismiss={onDismiss} />
            )}
            renderPreviewCard={player => (
              <PreviewCard model={model} player={player} />
            )}
          />
          <CloseButton onClick={onDismiss} />
        </Wrapper>
      </Dialog.Container>
    );
  }
);
