import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';

function formatTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`;
}

function padZero(value: number): string {
  return value.toString().padStart(2, '0');
}

function timeTillMidnight() {
  const now = dayjs();
  const midnight = now.endOf('day');
  const remainingTime = midnight.diff(now, 'second');
  const formattedTime = formatTime(remainingTime);
  return { formattedTime, remainingTime };
}

export function useCountdown() {
  const [countdown, setCountdown] = useState(timeTillMidnight().formattedTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const { formattedTime, remainingTime } = timeTillMidnight();

      setCountdown(formattedTime);

      if (remainingTime <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return { countdown };
}

const CountdownWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
  textStyle: 'body-bold',
  // padding: '40px 0',
  '& > .label': {
    color: '$textSecondary',
  },
});

export const Countdown = React.memo(() => {
  const { countdown } = useCountdown();

  return (
    <CountdownWrapper>
      <span className="label">{__('New Soundbite in', 'newSoundbiteIn')}</span>
      {countdown}
    </CountdownWrapper>
  );
});
