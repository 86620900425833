import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { PointsCard } from '../../components/ui/player-end-card/points-card';
import { Button } from '@naan/primitives/button';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { homePath } from 'components/nav/path-helpers';
import { useNavigate } from 'react-router-dom';
import { useSoundbiteCompletionState } from 'components/ui/player-end-card/use-soundbite-completion-state';
import { SoundbiteEndCardHeader } from 'components/ui/player-end-card/end-card-header';
import { AnonSection } from 'components/ui/player-end-card/anon-section';
import { VSpacer } from '@naan/primitives/spacer';

import __ from '@core/lib/localization';

// TODO: move into element-ids.ts, but only after we have merged the other open PRs
export const FINAL_CARD_ID = 'final-card;';

const OutWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$transparent',
  '@widerThanScript': {
    paddingTop: '$space$4',
    paddingBottom: '$space$5',
  },
});

const CardWrapper = styled('div', {
  margin: '0 auto',
  background: '$white',
  padding: 16,
  width: '100%',
  '@widerThanScript': {
    width: 600,
    borderRadius: 12,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  },
});

export const SbFinalCard = observer(() => {
  const { anonymous } = AppFactory.root.userManager;
  const model = AppFactory.soundbiteModel;
  const soundbite = model.soundbite;
  const completionState = useSoundbiteCompletionState();
  const navigate = useNavigate();

  if (completionState === 'uncompleted') {
    return null;
  }

  return (
    <OutWrapper id={FINAL_CARD_ID}>
      <CardWrapper>
        <SoundbiteEndCardHeader
          soundbite={soundbite}
          completionState={completionState}
        />
        {completionState !== 'previously-completed' ? <PointsCard /> : null}
        <VSpacer size={4} />

        {anonymous ? (
          <AnonSection usePumpkinRemark={soundbite.isFeatured} />
        ) : (
          <Button
            label={__('Done', 'done')}
            presentation={'teal'}
            size={'large'}
            fullWidth
            onClick={() => {
              if (ReturnNavState.canPopFromStudy()) {
                navigate(-1);
              } else {
                navigate(homePath());
              }
            }}
          />
        )}
      </CardWrapper>
    </OutWrapper>
  );
});
