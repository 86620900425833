import * as React from 'react';
import { observer } from 'mobx-react';
import { IconButton } from 'naan/primitives/button';
import { Menu, MenuItemProps } from '@naan/primitives/menus';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { Story } from '@core/models/story-manager';
import { AppFactory } from '@app/app-factory';
import { ResetStoryDialog } from './reset-story-dialog';
import { MarkStoryCompleteDialog } from './mark-story-complete-dialog';
import { scrollToCurrentChapter } from 'routes/learn/scrolling-fx';
import { presentVocabExportDialog } from './export-vocab-dialog';

type Props = { story: Story; cardFlavor?: boolean } & Pick<
  React.ComponentProps<typeof IconButton>,
  'presentation'
>;

export const StoryOverflowMenu = observer(
  ({ story, presentation = 'whiteTransparent', cardFlavor = false }: Props) => {
    const { started, completed, inProgress, unqueued, queued } = story;

    const menuItems: MenuItemProps[] = [];

    if (started) {
      menuItems.push({
        label: 'Reset story…',
        action: () => {
          AppFactory.dialogPresenter.present(onDismiss => (
            <ResetStoryDialog
              onReset={() => {
                story.progress.resetStory();
                scrollToCurrentChapter();
                onDismiss();
              }}
              onDismiss={onDismiss}
            />
          ));
        },
      });
    }

    if (completed) {
      if (inProgress) {
        menuItems.push({
          label: 'Exit review',
          action: () => story.progress.markStoryComplete(),
        });
      }
    } else {
      menuItems.push({
        label: 'Mark story complete…',
        action: () => {
          AppFactory.dialogPresenter.present(onDismiss => (
            <MarkStoryCompleteDialog
              onReset={() => {
                story.progress.markStoryComplete();
                scrollToCurrentChapter();
                onDismiss();
              }}
              onDismiss={onDismiss}
            />
          ));
        },
      });
    }

    if (cardFlavor) {
      if (unqueued) {
        menuItems.push({
          label: 'Study later',
          action: () => story.progress.markStudyLater(),
        });
      }

      if (queued) {
        menuItems.push({
          label: 'Remove from Study later',
          action: () => story.progress.unmarkStudyLater(),
        });
      }
    }

    if (story.progressMayBeNull?.showVocabExportUi) {
      menuItems.push({
        label: 'Export vocab...',
        // action: () => handleExportVocab(story.progress),
        action: () => {
          presentVocabExportDialog(story);
        },
      });
    }

    return (
      <Menu
        trigger={
          <IconButton presentation={presentation} icon={<OverflowIcon />} />
        }
        items={menuItems}
      />
    );
  }
);
