import * as React from 'react';
import { AppFactory } from 'app/app-factory';
import { createLogger } from 'app/logger';
import { track } from 'app/track';
import { Navigate, useParams } from 'react-router-dom';
import { homePath } from 'components/nav/path-helpers';
import { SoundbiteView } from 'soundbite/views/soundbite-view';
import { bugsnagNotify } from '@app/notification-service';
import { SoundbiteGatekeeper } from 'components/soundbites/soundbite-gatekeeper';
import { useSoundbiteInterstitial } from './use-soundbite-interstitial';

const log = createLogger('story-soundbite-screen');

// this flavor handles soundbites accessed from the story detail screen or calendar view

export const SlugSoundbiteScreen = () => {
  log.debug(`render`);
  const { showOrientation, orientationPath } = useSoundbiteInterstitial();

  const { storySlug, soundbiteSlug } =
    useParams<{ storySlug?: string; soundbiteSlug: string }>();

  // resolve agaist catalog data
  const soundbite = AppFactory.root.storyManager.soundbite(soundbiteSlug);

  const dataUrl = soundbite.dataUrl;

  React.useEffect(() => {
    if (!showOrientation) {
      track('soundbite__player_opened', {
        soundbiteSlug,
        storySlug,
        url: dataUrl,
        screenType: 'slug',
      }); // redundant with generic page tracking, but looks easier to explore event data vs page view date
    }
  }, [showOrientation, storySlug, soundbiteSlug, dataUrl]);

  if (showOrientation) {
    return <Navigate to={orientationPath} replace />;
  }

  if (soundbite) {
    return (
      <>
        <SoundbiteView dataUrl={soundbite.dataUrl} />;
        <SoundbiteGatekeeper soundbite={soundbite} />
      </>
    );
  } else {
    bugsnagNotify(
      `slug-soundbite failed to resolve sb for slug: ${soundbiteSlug}, redirecting to homePath`
    );
    return <Navigate to={homePath()} replace />;
  }
};
