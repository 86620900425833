import * as React from 'react';

import { styled } from '@naan/stitches.config';
import { JwLogo } from 'components/branding/jw-logo';
import { DashboardAuthButtons } from './dashboard-auth-buttons';
import { PointsNav } from './points-nav';
import { StoriesByRaNav } from './sbra-nav';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { subBrand } from '@core/lib/app-util';

const Wrapper = styled('div', {
  '& .top': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 0',
    borderBottom: '1px solid $gray-100',
  },
  '& .bottom': {
    height: 144,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const TrialDashboardNavMedium = () => {
  return (
    <CenterColumnLayout>
      <Wrapper>
        <div className="top">
          <JwLogo subBrand={subBrand()} />
          <DashboardAuthButtons />
        </div>
        <div className="bottom">
          <StoriesByRaNav />
          <PointsNav />
        </div>
      </Wrapper>
    </CenterColumnLayout>
  );
};
