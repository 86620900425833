import * as React from 'react';
import classNames from 'classnames';
import { Soundbite } from '@core/models/story-manager/soundbite';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { calendarSoundbitePath } from 'components/nav/path-helpers';
import { styled } from '@naan/stitches.config';
import { icons } from 'components/soundbites/soundbites-icons';
import { CheckmarkHeavySmallIcon } from '@naan/icons/checkmark-heavy-icon';
import __ from '@core/lib/localization';
import { Responsive } from '@naan/primitives/responsive';

const Wrapper = styled(Link, {
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: '56px',
  background: '$colors$gray-25',
  borderRadius: 8,
  padding: 16,
  paddingRight: 60,
  position: 'relative',
  color: '$colors$textPrimary',

  '&.finished': {
    color: '$colors$gray-500',
    background: '$colors$gray-50',
  },

  '&.current': {
    border: '4px solid $colors$purple-200',
    paddingTop: 36,
    '@medium': {
      paddingTop: 40,
    },
    '& > .current-label': {
      color: '$colors$purple-600',
    },
    '&.finished': {
      borderColor: '$colors$gray-200',
      '& > .current-label': {
        color: '$colors$textSecondary',
      },
    },
  },

  '& .current-label': {
    position: 'absolute',
    left: 0,
    top: 0,
    textStyle: 'small-caps',
    padding: '8px 12px',
  },

  '& .checkmark': {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 60,
    color: '$colors$gray-400',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      width: 20,
      height: 20,
    },
  },

  '& .icon svg': {
    width: 56,
    height: 56,
  },

  '& > .body': {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',

    '& .chapter-pos': {
      textStyle: 'small-text',
      color: '$colors$textSecondary',
    },
    '& .title': {
      textStyle: 'body-bold',
    },
  },

  '@medium': {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 16px',

    '& .checkmark': {
      position: 'absolute',
      right: 16,
      top: 16,
      bottom: 'auto',
      width: 'auto',
      color: '$colors$gray-400',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        width: 20,
        height: 20,
      },
    },

    '& .icon': {
      marginBottom: 8,
      '& svg': {
        width: 72,
        height: 72,
      },
    },

    '& > .body': {
      margin: 0,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },

  a: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    textDecoration: 'none',
  },
  variants: {
    current: {
      true: {
        border: '2px solid $colors$gray-200',
      },
    },
  },
});

export const SoundbiteCardsContainer = styled('div', {
  display: 'grid',
  gap: 8,
  '@medium': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
});

export const SoundbiteCard = observer(
  ({ soundbite }: { soundbite: Soundbite }) => {
    const iconSet = icons[soundbite.category as keyof typeof icons] ?? null;
    const LargeIcon = iconSet?.large ?? null;
    const finished = soundbite.completed;
    const current = soundbite.isFeatured;

    const iconPresentation = finished ? 'grayscale' : 'color';

    return (
      <Wrapper
        to={calendarSoundbitePath(soundbite.slug)}
        className={classNames({ finished, current })}
        id={current ? 'current-soundbite' : undefined}
      >
        {finished ? (
          <span className="checkmark">
            <CheckmarkHeavySmallIcon />
          </span>
        ) : null}
        {current ? (
          <Responsive
            renderDefault={() => (
              <span className="current-label">
                {__("Today's Soundbite", 'todaysSoundbite')}
              </span>
            )}
            renderMediumAndUp={() => (
              <span className="current-label">{__('today', 'today')}</span>
            )}
          />
        ) : null}
        <div className="icon">
          <span className="icon">
            {LargeIcon && <LargeIcon presentation={iconPresentation} />}
          </span>
        </div>
        <div className="body">
          <span className="chapter-pos">
            Chapter {soundbite.chapterPos ?? '#'}
          </span>
          <span className="title">{soundbite.title}</span>
        </div>
      </Wrapper>
    );
  }
);
