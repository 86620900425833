// import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { soundbiteOrientationPath } from 'components/nav/path-helpers';
import { useSearchParams } from 'react-router-dom';

// import { createLogger } from 'app/logger';
// const log = createLogger('use-soundbite-interstitial');

export const useSoundbiteInterstitial = () => {
  const [queryParams] = useSearchParams();
  const orientationParam = queryParams.get('orientation');

  const showOrientation =
    orientationParam !== 'skip' &&
    AppFactory.root.userManager.userData.needsSoundbiteOrientation;

  const orientationPath = soundbiteOrientationPath(window.location.pathname);

  return { showOrientation, orientationPath };
};
