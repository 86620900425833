import { globalCss } from 'naan/stitches.config';
//@ts-expect-error
import { normalize } from 'normalize-stitches';

const boxSizing = {
  '*, *:before, *:after': {
    boxSizing: 'border-box',
  },
};

export const markdownListStyles = {
  '& ul[data-markdown]': {
    marginBottom: '16px',
    listStyleType: 'none',
    '& li': {
      paddingLeft: '1.25em',
      position: 'relative',
      '&:before': {
        content: '',
        position: 'absolute',
        top: '.5em',
        left: '0',
        width: '.4em',
        height: '.4em',
        background: 'currentColor',
        opacity: '.4',
        borderRadius: '50%',
      },
    },
  },
  '& ol[data-markdown]': {
    marginBottom: '16px',
    counterReset: 'ol-counter',
    listStyleType: 'none',

    '& li': {
      counterIncrement: 'ol-counter',
      paddingLeft: '1.25em',
      position: 'relative',
      '&:before': {
        content: 'counter(ol-counter)',
        color: 'currentColor',
        opacity: '.5',
        float: 'left',
        marginLeft: '-1.25em',
        width: '1.25em',
      },
    },
  },
};

const globalStyles = globalCss({
  ...normalize,
  ...boxSizing,
  '@font-face': [
    {
      fontFamily: 'larsseit-medium',
      src: `
      url("/assets/fonts/5454002/7a60ab9e-f836-4a67-893d-81a589724598.eot?#iefix") format("eot"),
      url("/assets/fonts/5454002/4cb7676d-9352-4367-9e09-4643861168a8.woff2") format("woff2"),
      url("/assets/fonts/5454002/da0e4157-a84a-4e59-bf79-85090e987cd9.woff") format("woff"),
      url("/assets/fonts/5454002/39916207-e1d1-4b02-ad96-3947023333fb.ttf") format("truetype")
      `,
    },
    {
      fontFamily: 'larsseit-bold',
      src: `
      url("/assets/fonts/5454014/5ebbda2e-6878-4494-ab4d-112eeea23ac0.eot?#iefix") format("eot"),
      url("/assets/fonts/5454014/38f62b25-9bb5-4b8f-ab6d-8a5d73286ec1.woff2") format("woff2"),
      url("/assets/fonts/5454014/5fb439fe-d913-4b7d-9727-20ba5bfe48ff.woff") format("woff"),
      url("/assets/fonts/5454014/7b8b3e80-242b-4f48-9ed6-d0dc4175a61c.ttf") format("truetype")`,
    },
  ],

  ':root': {
    '--sat': 'env(safe-area-inset-top)',
    '--sar': 'env(safe-area-inset-right)',
    '--sab': 'env(safe-area-inset-bottom)',
    // '--sab': '0px',
    '--sal': 'env(safe-area-inset-left)',
    '@media all and (display-mode: standalone)': {
      /* CSS specific to standalone PWA */
      '@supports (-webkit-touch-callout: none)': {
        /* CSS specific to iOS devices */
        '--sab': '20px',
      },
    },
  },

  '.__no-scroll': {
    overflow: 'hidden',
  },

  '.__with-nag .site-footer': {
    paddingBottom: '56px',
  },

  html: {
    height: '100%',
    $$tabbarHeight: '60px',
    $$sidebarWidth: '240px',
    $$maxWidth: '960px',

    '&.__simulate-statusbar': {
      '--statusbarHeight': '24px',
      '--statusbarBgColor': 'black',
      '--statusbarTextColor': 'white',
      paddingTop: 'var(--statusbarHeight, 0px)',

      '&::before': {
        content: 'status bar',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: 'var(--statusbarHeight, 0px)',
        background: 'var(--statusbarBgColor, black)',
        color: 'var(--statusbarTextColor, white)',
        textAlign: 'center',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      },
    },

    '@large': {
      $$sidebarWidth: '248px',
    },
  },
  body: {
    height: '100%',
    margin: 0,
    textStyle: 'body',
    color: '$textPrimary',
    textSizeAdjust: '100%',
    // overflow: 'hidden', /// removing this temprarily
    overscrollBehaviorY: 'contain',
    minHeight: '-webkit-fill-available',
  },
  '#root': {
    height: '100%',
  },
  '*': {
    margin: 0,
    padding: 0,
    border: 'none',
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: '0',
  },
  svg: {
    display: 'block',
  },
  img: {
    display: 'block',
  },
  '.fsportal': {
    position: 'fixed',
    inset: 0,
    zIndex: 200,
  },
  '[type="button"]': {
    appearance: 'none',
  },
  ...markdownListStyles,
});

export function simulateStatusBar() {
  if (window.location.search.includes('simulate-statusbar')) {
    document.querySelector('html')?.classList.add('__simulate-statusbar');
  }
}

export const GlobalStyles: React.FC = () => {
  globalStyles();
  simulateStatusBar();
  return null;
};
