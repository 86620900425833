import * as React from 'react';
import { observer } from 'mobx-react';
import { ProgressPie } from 'components/ui/progress-pie';
import { ClockIcon } from '@naan/icons/clock-icon';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { useCurrentStory } from 'components/story-detail/current-story-context';
import { ListIcon } from '@naan/icons/list-icon';
import { styled } from '@naan/stitches.config';

const PieWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
});

export const StoryTabIcon = observer(() => {
  const story = useCurrentStory();

  const { studyProgressPercentage, inProgress, queued, completed } = story;

  if (inProgress) {
    return (
      <PieWrapper>
        <ProgressPie current={studyProgressPercentage / 100.0} size={20} />
      </PieWrapper>
    );
  }

  if (queued) {
    return <ClockIcon />;
  }

  if (completed) {
    return <CheckmarkCircleIcon />;
  }

  return <ListIcon />;
});
