import * as React from 'react';

export const defaultBarColor = '#ffffff';

function rootElement() {
  return document.querySelector('html');
}

export function setThemeColor(color: string | null) {
  const metaThemeColor = document.querySelector('meta[name="theme-color"]');
  const statusBarStyle = document.querySelector(
    'meta[name="apple-mobile-web-app-status-bar-style"]'
  );

  metaThemeColor.setAttribute('content', color ?? defaultBarColor);
  statusBarStyle.setAttribute(
    'content',
    color ? 'black-translucent' : 'default'
  );

  const root: HTMLHtmlElement = rootElement();

  root?.style.setProperty('--statusbarBgColor', color ?? defaultBarColor);
  root?.style.setProperty(
    '--statusbarTextColor',
    color ? '#000000' : '#ffffff'
  );
}

export function getThemeColor() {
  const metaThemeColor = document.querySelector('meta[name="theme-color"]');
  return metaThemeColor.getAttribute('content');
}

/**
 * Caution: this will cause re-rendering
 */
export function useThemeColor(color: string, restoreColor?: string) {
  // const [color, setColor] = React.useState<string | null>(null);
  React.useEffect(() => {
    const oldColor = restoreColor ?? getThemeColor();
    setThemeColor(color);
    return () => {
      setThemeColor(oldColor);
    };
  }, [color, restoreColor]);
}
