import * as DialogPrimitive from '@radix-ui/react-dialog';
import { keyframes, styled } from 'naan/stitches.config';

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.75)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const css = {
  $$radius: '12px',
  $$maxWidth: '360px',
  backgroundColor: '$white',
  borderRadius: '$$radius',
  boxShadow: `0px 1px 4px $colors$black-alpha-20`,
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(calc(100% - 32px), $$maxWidth)',
  // maxWidth: 'fit-content',
  maxHeight: '85vh',
  display: 'flex',
  flexDirection: 'column',

  // padding: 16,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 0.5s cubic-bezier(0.16, 1, 0.3, 1)`,
    willChange: 'transform',
  },
  '&:focus': { outline: 'none' },
};

export const Content = styled(DialogPrimitive.Content, css);
export const HtmlContent = styled('div', css);
