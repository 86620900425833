import React from 'react';
import useSWRImmutable from 'swr/immutable';

import { ErrorBoundary } from './components/error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';

import { useSyncOnForeground } from '@common/hooks/use-sync-on-foreground';
import {
  version,
  curEnv,
  commit,
  buildTimestampIso,
  // @ts-expect-error
} from '@jw-spa-version';
import { SpaRoutes } from './routes';
// import { firestoreDb } from '@core/services/firebase-init';
import { AppRoot } from '@core/models/app-root';
import { AppFactory } from '@app/app-factory';
import { alertSevereError } from '@app/notification-service';
import { FullScreenError } from 'components/error-boundary';
import { FullScreenLoader } from 'components/ds/modals';
import { createLogger } from '@common/log';
import { useOfflineAwareness } from '@common/hooks/use-offline-awareness';
import { NotificationService } from 'lib/services/notification-service';
import { LegacySpaToastTarget } from 'components/ui/spa-toast-target';

// import { useLocation } from 'react-router-dom';
// import { FirestoreInvokerImpl } from '@core/services/firestore-invoker-impl';
// import { CatalogMetaSyncImpl } from '@core/services/catalog-meta-sync-impl';
// import { SettingsSyncImpl } from '@core/services/settings-sync-impl';

const log = createLogger('app');

const buildTimestampLocal = new Date(buildTimestampIso).toString();

log.info(`${curEnv}; ${version}; ${commit}; ${buildTimestampLocal}`);

const initializeAppRoot = async (dummy: string): Promise<AppRoot> => {
  log.info('initializeAppRoot');
  if (AppFactory.root) {
    log.error('app root unexpectedly already initialized');
  } else {
    const root = AppRoot.create({});
    AppFactory.setRoot(root);
    (window as any).root = root;
    // important to initialize after setting into app factor
    // because of factory dependencies during user data migration
    await root.appInitialize();
  }
  return AppFactory.root;
};

export const App: React.FC = () => {
  // without the 'immutable' flavor SWR was refreshing the data upon on evey tab focus
  let { data: root, error } = useSWRImmutable('run-once', initializeAppRoot);

  /// disable the unexpected error handler
  React.useEffect(() => {
    const jw: any = (window as any).jw || {};
    jw.unexpectedError = null; // disables the unexpected error handler
    // @armando, FYI, I was seeing fatal errors that jw.resetError wasn't defined sometimes
    // not sure the exact condition. might have been related to hot-loading
    if (typeof jw.resetError === 'function') {
      jw.resetError(); // clears the DOM node that contains the error page
    }
  });

  if (error) {
    alertSevereError({ error, note: 'AppRootWrapper' });
    return <FullScreenError />;
  }

  if (!root) {
    return <FullScreenLoader />;
  }

  return <AppBody />;
};

const AppBody = () => {
  useOfflineAwareness();
  useSyncOnForeground();

  return (
    <ErrorBoundary>
      <Router>
        <SpaRoutes />
      </Router>
      <LegacySpaToastTarget service={NotificationService} />
    </ErrorBoundary>
  );
};

export default App;
