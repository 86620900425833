import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { SwitchButton } from 'components/ds/switch-button';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { VStack } from '@naan/primitives/stack';
// import { ExternalLinkIcon } from '@naan/icons/external-link-icon';
import { Row } from './settings-row';

import __ from '@core/lib/localization';

export const VocabRow = observer(() => {
  const { userSettings } = AppFactory.root.userManager.userData;
  const toggle = () => userSettings.toggleVocabExport();
  return (
    <Row
      label={__('Vocabulary export', 'vocabularyExport')}
      hint={
        <>
          <VStack>
            {__(
              `(Experimental) Exposes an option on
              story vocabulary list that emails a data
              file for importing into a flashcard program.`,
              'vocabularyExportHint'
            )}
            <ExternalLink href={'http://go.jw.app/about-vocab-export'}>
              {__('Learn more', 'learnMore')}
              {/* @armando, todo */}
              {/* {' '}<ExternalLinkIcon width={12} height={12} /> */}
            </ExternalLink>
          </VStack>
        </>
      }
      control={
        <SwitchButton
          onChange={toggle}
          active={userSettings.showVocabListExportOption}
        />
      }
    />
  );
});
