import { germanMode } from '@core/lib/app-util';
import { ModelTreeNode } from 'ts-state-tree/tst-core';
// import { createLogger } from '@common/log';
// const log = createLogger('local-state');

//
// global configuration data owned by firestore singleton doc (scoped to server environment)
//

export const DEFAULT_CATALOG_SLUG = germanMode()
  ? 'halbe-review' /* <-- update this */
  : 'lupa';

export const FALLBACK_CATALOG_URL =
  'https://storage.googleapis.com/jw-editorial-pub/cali/ra/catalog/catalog-en-lupa-4973.json';

export class GlobalSettings extends ModelTreeNode {
  static CLASS_NAME = 'GlobalSettings' as const;

  catalogSlug: string = DEFAULT_CATALOG_SLUG; // default catalog unless overridden within userData
  catalogUrl: string = FALLBACK_CATALOG_URL; // default url for catalog slug - denormalized data to avoid extra roundtrip to node server

  // drives embedded mode update banners
  appStoreBuildNumber: number = 0;
  playStoreBuildNumber: number = 0;

  debugBuildNumber: number = 0; // triggers apple review mode when matched

  // still driven by rails accountData
  // debugBuildNumber?: number; // drives special apple review behavior

  // don't need to restrict yet beyond the apple review mode handling
  // iosNosCreate: boolean;
  // androidNoCreate: boolean;

  static create(snapshot: any = {}): GlobalSettings {
    return super.create(GlobalSettings, snapshot) as GlobalSettings;
  }

  // expect to get the latest catalog url from the node server, but fallback to a hardwired
  // catalog url if that fails or is unexpectedly missing
  get defaultCatalogUrl(): string {
    return this.catalogUrl || FALLBACK_CATALOG_URL;
  }
}
