import * as React from 'react';
import { ZeroSearchResults } from './zero-search-results';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PrimaryFilterKeys } from '@core/models/story-manager/story-manager';
import { isClassroomFilterKey } from '@core/lib/constants/vars';
import { ZeroStateView } from 'components/ui/zero-state-view';
import __ from '@core/lib/localization';
import { VStack } from '@naan/primitives/stack';
import { VSpacer } from '@naan/primitives/spacer';
import { Button } from '@naan/primitives/button';
import { storiesPrimaryFiltered } from 'components/nav/path-helpers';

export const ZeroStories = () => {
  const navigate = useNavigate();

  const [queryParams] = useSearchParams();

  const primaryParam = queryParams.get('primary');
  const filterTypeParam = queryParams.get('filter_type');

  let title: string;
  let description: string;

  if (filterTypeParam === 'search') {
    return <ZeroSearchResults />;
  } else if (primaryParam && isClassroomFilterKey(primaryParam)) {
    title = __('No assignments', 'zeroState.classroom.title');
    description = __(
      'This classroom currently has no assignments.',
      'thisClassroomHasNoAssignments'
    );
  } else {
    switch (primaryParam) {
      case PrimaryFilterKeys.QUEUED:
        title = __('Your queue is empty', 'zeroState.studyLater.title');
        description = __(
          'Tap "Study later" on a story to add it here.',
          'tapStudyLaterOnAStoryToAddItHere'
        );
        break;
      case PrimaryFilterKeys.IN_PROGRESS:
        title = __('No stories in progress', 'zeroState.inProgress.title');
        description = __(
          'Stories will appear here when you start listening to them.',
          'storiesWillAppearHereWhenYouStart'
        );
        break;
      case PrimaryFilterKeys.COMPLETED:
        title = __('No completed stories', 'zeroState.completed.title');
        description = __(
          "Stories appear here when you've reached the end. Meanwhile, you've got some listening to do.",
          'storiesAppearHereWhenYouveReachedTheEnd'
        );
        break;

      default:
        return null;
    }
  }

  return (
    <ZeroStateView title={title} description={description}>
      {' '}
      <VStack align={'center'}>
        <VSpacer size={5} />
        <Button
          label="Show all stories"
          presentation={'secondary'}
          onClick={() =>
            navigate(storiesPrimaryFiltered(PrimaryFilterKeys.ALL))
          }
        />
      </VStack>
    </ZeroStateView>
  );
};
