import * as React from 'react';
import { observer } from 'mobx-react';
import { TableFieldButtonSet, TableFieldWrapper } from './table-field';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { useComboForm } from 'common/hooks/use-comboform';
import { pick } from 'lodash';
import { nameSchema } from 'core/lib/validation-rules';
import { ToastService } from 'common/notifications/toast-service';
import { Input } from 'naan/primitives/input';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';
import __ from 'core/lib/localization';
import { AppFactory } from '@app/app-factory';

type FormInput = {
  name: string;
};

export const NameForm = observer(() => {
  const editSwitch = useSwitch2(false);
  const { userManager } = AppFactory.root;
  const { accountData } = userManager;
  const defaultValues = pick(accountData, ['name']);

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
  } = useComboForm<FormInput>({ validationSchema: nameSchema, defaultValues });

  const handleUpdate = React.useCallback(
    ({ name }: FormInput) => {
      clearValidationErrors();
      userManager.updateName(name).then(result => {
        editSwitch.off();
        ToastService.open({
          message: result.message,
          type: 'success',
        });
      }, handleValidationErrors);
    },
    [editSwitch, userManager, clearValidationErrors, handleValidationErrors]
  );

  return (
    <TableFieldWrapper
      label={__('First name', 'firstName')}
      testId="first-name"
      value={accountData.name}
      editing={editSwitch.value}
      onStartEditing={editSwitch.on}
    >
      <form onSubmit={handleSubmit(handleUpdate)} noValidate>
        <Input
          name={'name'}
          data-test-id={'edit-input'} // the current cypress scripts are currently factored to expect the same test id for all the account page from fields
          type="text"
          {...register('name')}
        />
        <CatchAllFormErrors errors={errors} data-test-id="error-message" />
        <TableFieldButtonSet
          updateLabel={__('Update', 'update')}
          cancelAction={editSwitch.off}
        />
      </form>
    </TableFieldWrapper>
  );
});
