/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { PlayIcon, PonderIcon, RevealIcon } from './icons';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { TryFirstSoundbiteButton } from './try-first-soundbite-button';

import __ from '@core/lib/localization';

const Wrapper = styled('section', {
  display: 'flex',
  padding: '32px 0 48px 0',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '@medium': {
    padding: '56px 0 80px 0',
  },

  '& .learn-title': {
    textStyle: 'medium-heading',
    textAlign: 'center',
    '@medium': {
      textStyle: 'extra-large-heading',
    },
  },

  '& .learn-description': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    listStyle: 'none',
    gap: 8,
    marginTop: 32,
    marginBottom: 48,
    width: 'min( 512px, 100% )',
    '@medium': {
      gap: 24,
    },

    '& li': {
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
      textStyle: 'body-bold',
      '@medium': {
        textStyle: 'small-heading',
      },

      '& .icon': {
        width: 72,
        height: 72,

        '@medium': {
          width: 96,
          height: 96,
        },
        '& svg': {
          width: '100%',
          height: '100%',
        },
      },

      '& span.first-word': {
        display: 'block',
        textStyle: 'medium-heading',
      },
    },
  },
});

export const LearnSection = ({ continuePath }: { continuePath: string }) => {
  return (
    <CenterColumnLayout>
      <Wrapper className="learn">
        <h2 className="learn-title">
          {__(
            'Learn real-world Spanish... in 4 minutes a day',
            'learnRealWorldSpanishIn4MinutesADay'
          )}
        </h2>
        <ul className="learn-description">
          <li>
            <div className="icon">
              <PonderIcon />
            </div>
            <span className="first-word">Ponder</span> a challenge
          </li>
          <li>
            <div className="icon">
              <PlayIcon />
            </div>
            <span className="first-word">Play</span> the Soundbite
          </li>
          <li>
            <div className="icon">
              <RevealIcon />
            </div>
            <span className="first-word">Reveal</span> the insights
          </li>
        </ul>
        <div className="learn-cta">
          <TryFirstSoundbiteButton
            id="spa-sb-btn-1"
            continuePath={continuePath}
          />
        </div>
      </Wrapper>
    </CenterColumnLayout>
  );
};
