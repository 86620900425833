import * as React from 'react';
// import { SmTwitterIcon } from 'naan/icons/sm-twitter-icon';
// import { SmInstagramIcon } from 'naan/icons/sm-instagram-icon';
import { styled } from 'naan/stitches.config';
//@ts-expect-error
import { version } from '@jw-spa-version';
import { ExternalLink } from '@naan/primitives/text/external-link';
import {
  contactUs,
  eulaUrl,
  privacyUrl,
  tosUrl,
} from 'components/nav/path-helpers';
import { buildInfoDisplay } from '@core/lib/app-util';
import { JiveworldLogo } from 'components/branding/jiveworld-logo';

const Wrapper = styled('footer', {
  // position: 'sticky',
  // bottom: 0,
  display: 'grid',
  gridTemplateColumns: 'repeat(4,1fr)',
  gridTemplateRows: 'repeat(2,auto)',
  color: '$textSecondary',
  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
  '& .logos': {
    // background: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gridColumn: '1/-1',
    gridRow: '1/2',
    borderBottom: '1px solid $colors$gray-100',
    py: '$space$5',
    color: '$gray-300',
  },
  '& .links': {
    // background: 'yellow',
    alignItems: 'center',
    display: 'flex',
    gridColumn: '1/3',
    gridRow: '2/3',
    py: '$space$5',
    textStyle: 'tiny-text',
    a: {
      flexShrink: 0,
      paddingRight: '6px',
      marginRight: '6px',
      borderRight: '1px solid $colors$gray-100',
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
  '& .support-links': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    gridColumn: '3/5',
    gridRow: '2/3',
    py: '$space$5',
    textStyle: 'tiny-text',
    a: {
      flexShrink: 0,
      paddingRight: '6px',
      marginRight: '6px',
      borderRight: '1px solid $colors$gray-100',
      '&:last-child': {
        paddingRight: '0',
        marginRight: '0',
        borderRight: 'none',
      },
    },
    span: {
      color: '$colors$gray-200',
    },
  },
  '& .socials': {
    // background: 'orange',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gridColumn: '3/5',
    gridRow: '2/3',
    py: '$space$5',
    a: {
      marginLeft: '$space$3',
    },
  },
  '@small': {
    '& .links': {
      textStyle: 'small-text',
      a: {
        paddingRight: '$space$2',
        marginRight: '$space$2',
      },
    },
    '& .support-links': {
      textStyle: 'small-text',
      a: {
        paddingRight: '$space$2',
        marginRight: '$space$2',
      },
    },
  },
  '@medium': {
    gridTemplateColumns: 'repeat(3,1fr)',
    gridTemplateRows: '1',
    borderTop: '1px solid $colors$gray-100',

    '& .logos': {
      gridColumn: '2/3',
      border: 'none',
    },
    '& .links': {
      gridColumn: '1/2',
      gridRow: '1',
    },
    '& .support-links': {
      gridColumn: '3/4',
      gridRow: '1',
    },
    '& .socials': {
      gridColumn: '3/4',
      gridRow: '1',
    },
    margin: '0 auto',
    width: 'min(100%, 960px)',
  },
});

export const GlobalFooter = () => (
  <>
    <Wrapper>
      <div className="logos">
        <JiveworldLogo />
      </div>

      <div className="links">
        {/* beware, very similar but slightly different from auth-small-print version of these */}
        <ExternalLink href={tosUrl()}>Terms&nbsp;of&nbsp;service</ExternalLink>
        <ExternalLink href={privacyUrl()}>Privacy</ExternalLink>
        <ExternalLink href={eulaUrl()}>EULA</ExternalLink>
      </div>
      <div className="support-links">
        <ExternalLink href={contactUs()}>Contact &amp; support</ExternalLink>
        <span title={version}>{buildInfoDisplay()}</span>
        {/* {appConfig<>('globalFooterLink') ? (
          <span>
            | <a href="/try-soundbites">sb</a>
          </span>
        ) : null} */}
      </div>
      {/* <div className="socials">
        <ExternalLink href="https://twitter.com/LupaApp" >
          <SmTwitterIcon />
        </ExternalLink>
        <ExternalLink
          href="https://www.instagram.com/lupa.app/"
        >
          <SmInstagramIcon />
        </ExternalLink>
      </div> */}
    </Wrapper>
  </>
);
