import * as React from 'react';
import { VStack } from 'naan/primitives/stack';

import {
  version,
  versionCheck,
  hash,
  commit,
  commitTimestampIso,
  buildTimestampIso,
  // @ts-expect-error
} from '@jw-spa-version';

import { VSpacer } from 'naan/primitives/spacer';
import { AppFactory } from 'app/app-factory';
import { ActionLink } from 'common/ui/action-link';
import { observer } from 'mobx-react';
import {
  embeddedBuildNumber,
  embeddedPlatform,
  postNativeMessage,
  setEmbeddedBuildNumber,
  setEmbeddedPlatform,
} from '@core/lib/app-util';

import { UserManager } from '@core/models/user-manager';
import {
  // attemptReregistration,
  hasServiceWorker,
  hasSwController,
  trySkipWaiting,
} from '../../pwa/window/update-manager';
import { flushWorkerLogs } from '../../pwa/window/log-relay';

import { Definition } from './components/definition';
import { MediumHeading } from '@naan/primitives/text';
import { checkForUpdate } from 'pwa/window/update-checker';
import { CRASH_TEST_MESSAGE_TYPE } from 'pwa/shared';
import { appConfig } from '@app/config';
import { LocaleToggle } from './dev-toggles';
import { EmbeddedUrlSelection } from 'components/admin/embedded-url-selection';
import { Insets, resetInsetsLocalStorage } from 'native-support/insets';

export const path = 'general';
export const label = 'General info';

export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager } = root;

  const buildTimestampLocal = new Date(buildTimestampIso).toString();
  const commitTimestampLocal = new Date(commitTimestampIso).toString();

  // strip down for easier browsing
  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  return (
    <VStack>
      <LocaleToggle />
      <VSpacer size={4} />
      <MediumHeading>General Info</MediumHeading>
      <VSpacer size={6} />
      <Definition label={'website'} value={appConfig.website.baseUrl} />
      {hasServiceWorker() ? (
        <>
          <Definition
            label={'service worker'}
            value={
              <>
                <p>
                  <ActionLink onPress={() => checkForUpdate({ poll: false })}>
                    [Check for update (SW)]
                  </ActionLink>
                  {' - '}
                  <ActionLink onPress={trySkipWaiting}>
                    [Skip waiting]
                  </ActionLink>
                  {' - '}
                  <ActionLink onPress={async () => flushWorkerLogs()}>
                    [Drain SW logs]
                  </ActionLink>
                  {' - '}
                  {/* <ActionLink onPress={async () => attemptReregistration()}>
          [Attempt reregister]
        </ActionLink> */}
                  <ActionLink
                    onPress={async () =>
                      AppFactory.workbox.messageSW({
                        type: CRASH_TEST_MESSAGE_TYPE,
                      })
                    }
                  >
                    [SW crash test]
                  </ActionLink>
                </p>
                <p>
                  {root.serviceWorkerLogs.map((log, index) => (
                    <React.Fragment key={index}>
                      {log}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </>
            }
          />
          <Definition
            label="controller"
            value={
              hasSwController()
                ? 'true (offline ready)'
                : 'false (either still installing or unsupported api)'
            }
          />
        </>
      ) : (
        <Definition
          label={'no service worker'}
          value={
            <ActionLink onPress={() => checkForUpdate({ poll: false })}>
              [Check for update (version.txt)]
            </ActionLink>
          }
        />
      )}
      <Definition label={'app slug'} value={appConfig.appSlug} />
      <Definition label={'package.json version'} value={version} />
      <Definition label={'version.txt'} value={versionCheck} />
      <Definition
        label={'last commit'}
        value={
          <ul>
            <li>{hash}</li>
            <li>{commit}</li>
            <li>{commitTimestampIso}</li>
            <li>{commitTimestampLocal}</li>
          </ul>
        }
      />
      <Definition
        label={'build timestamp'}
        value={
          <ul>
            <li>{buildTimestampIso}</li>
            <li>{buildTimestampLocal}</li>
          </ul>
        }
      />
      <Definition
        label={'env'}
        value={`config: __CONFIG_ENV__; api: ${appConfig.apiEnv}`}
      />
      <Definition
        label={'user manager'}
        value={
          <>
            Insets: &uarr; {Insets.top} / &darr; {Insets.bottom}
            <br />
            <ActionLink onPress={() => resetInsetsLocalStorage()}>
              [Reset Safe Area insets (and reload)]
            </ActionLink>
          </>
        }
      />
      <Definition
        label={'embedded platform'}
        value={
          <>
            {String(embeddedPlatform())} - {String(embeddedBuildNumber())} -{' '}
            <ActionLink onPress={() => setEmbeddedPlatform('ios')}>
              set ios
            </ActionLink>
            {' - '}
            <ActionLink onPress={() => setEmbeddedPlatform('android')}>
              set android
            </ActionLink>{' '}
            {' - '}
            <ActionLink onPress={() => setEmbeddedBuildNumber('7000008')}>
              set 7.0.0-08
            </ActionLink>{' '}
            {' - '}
            <ActionLink onPress={() => setEmbeddedBuildNumber('8000008')}>
              set 8.0.0-08
            </ActionLink>{' '}
            {' - '}
            <ActionLink onPress={() => root.localState.resetEmbedded()}>
              reset
            </ActionLink>{' '}
            (not reactive)
            <br />
            {embeddedPlatform() ? (
              <>
                <ActionLink
                  onPress={() => postNativeMessage({ type: 'SHOW_DEBUG' })}
                >
                  show wrapper debug screen
                </ActionLink>
                {' - '}
                <ActionLink
                  onPress={() => postNativeMessage({ type: 'RELOAD' })}
                >
                  reload
                </ActionLink>{' '}
                {' - '}
                <ActionLink
                  onPress={() => postNativeMessage({ type: 'RERENDER' })}
                >
                  rerender
                </ActionLink>{' '}
                {' - '}
                <ActionLink
                  onPress={() => postNativeMessage({ type: 'CRASH' })}
                >
                  crash
                </ActionLink>{' '}
                <br />
                <EmbeddedUrlSelection />
              </>
            ) : null}
            purchaseFlowDisabled: {String(userManager.purchaseFlowDisabled)}
            <br />
          </>
        }
      />
    </VStack>
  );
});
