import * as React from 'react';
import { observer } from 'mobx-react';
import { useTitle } from 'common/hooks/use-title';
import { PointsProgressChart } from 'components/ui/points-progress-chart';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { AppFactory } from '@app/app-factory';
import { SimpleStat } from 'components/stats/simple-stat';
import { StreakIcon } from '@naan/icons/streak-icon';
import { TrophyIcon } from '@naan/icons/trophy-icon';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { PageHeading } from 'components/page-heading';
import { PointsIcon } from '@naan/icons/points-icon';
import { HeadphonesIcon } from '@naan/icons/headphones-icon';
import { ListIcon } from '@naan/icons/list-icon';
import { SoundbiteIcon } from '@naan/icons/soundbite-icon';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { styled } from '@naan/stitches.config';
import { useScrollContainerToTop } from 'lib/scroll-utils';

import __ from 'core/lib/localization';

const TopStatsGrid = styled('div', {
  display: 'grid',
  gap: 16,
  marginBottom: 16,
  '@medium': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 24,
    marginBottom: 24,
  },
});

const BottomStatsGrid = styled('div', {
  display: 'grid',
  gap: 16,

  '@medium': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: '224px',
    gap: 24,
  },
  '@large': {
    // gridTemplateColumns: 'repeat(2, 1fr)',
    // gridAutoRows: '296px',
    // gap: 24,
  },
});

export const PointsStats = observer(() => {
  const { userData } = AppFactory.root.userManager;
  const { totalPoints } = userData;
  return (
    <TopStatsGrid>
      <PointsProgressChart />
      <SimpleStat
        icon={<PointsIcon />}
        stat={totalPoints.toString()}
        label={__('Total points', 'totalPoints')}
        presentation="yellow"
        isLarge={true}
      />
    </TopStatsGrid>
  );
});

export const SoundbiteStats = observer(() => {
  const { userManager, storyManager } = AppFactory.root;

  // omit soundbite progress row if our current catalog has no current day soundbite
  if (!storyManager.featuredSoundbite) {
    return null;
  }

  const { currentStreak, longestStreak, completedSoundbitesCount } =
    userManager.userData;

  return (
    <>
      <SimpleStat
        icon={<StreakIcon />}
        stat={__(
          {
            one: '%{count} day',
            other: '%{count} days',
          },
          'pluralize.streak',
          { count: currentStreak }
        )}
        label={__('Current streak', 'currentStreak')}
        presentation="orange"
      />
      <SimpleStat
        icon={<TrophyIcon />}
        stat={__(
          {
            one: '%{count} day',
            other: '%{count} days',
          },
          'pluralize.streak',
          { count: longestStreak }
        )}
        label={__('Longest streak', 'longestStreak')}
        presentation="orange"
      />
      <SimpleStat
        icon={<SoundbiteIcon />}
        stat={completedSoundbitesCount.toString()}
        label={__('Soundbites completed', 'soundbitesCompleted')}
        presentation="purple"
      />
    </>
  );
});

export const StoryStats = observer(() => {
  const { userData } = AppFactory.root.userManager;

  const { totalListenedPretty, completedStories, completedChapters } =
    userData.storyStats;

  return (
    <>
      <SimpleStat
        icon={<CheckmarkCircleIcon />}
        stat={completedStories.toString()}
        label={__('Stories completed', 'storiesCompleted')}
        presentation="teal"
      />
      <SimpleStat
        icon={<ListIcon />}
        stat={completedChapters.toString()}
        label={__('Chapters completed', 'chaptersCompleted')}
        presentation="teal"
      />
      <SimpleStat
        icon={<HeadphonesIcon />}
        stat={totalListenedPretty}
        label={__('Listened', 'listened')}
        presentation="teal"
      />
    </>
  );
});

export const ProgressScreen = observer(() => {
  useTitle(__('My stats', 'progress.my-stats'));
  useScrollContainerToTop();
  // const { hasAdminAccess, userData } = AppFactory.root.userManager;

  return (
    <CenterColumnLayout>
      <PageHeading title={__('My stats', 'myStats')} />
      <PointsStats />
      <BottomStatsGrid>
        <SoundbiteStats />
        <StoryStats />
      </BottomStatsGrid>
      <MediumGap />
    </CenterColumnLayout>
  );
});
