import { elementIds } from 'components/dom-utils/element-ids';
import * as React from 'react';
import { soundbitePlayerUiModel } from './soundbite-player-ui-model';
import { getPlayerModel } from 'player/views/player-model-context';
import {
  SCRIPT_SCROLLING_CONTAINER_ID,
  SOUNDBITE_TITLE_CONTAINER,
} from 'player/views/player-ui-components';

class ScrollObserver {
  protected observer: IntersectionObserver;
  protected targets: Element[] = [];
  protected options: IntersectionObserverInit;

  constructor(options?: IntersectionObserverInit) {
    const root = document.getElementById(SCRIPT_SCROLLING_CONTAINER_ID);

    this.options = {
      root,
      ...options, // Merge the provided options with the default options
    };

    this.observer = new IntersectionObserver(
      this.callback.bind(this),
      this.options
    );
  }

  observeElement(element: Element) {
    if (element) {
      this.targets.push(element);
      this.observer.observe(element);
    }
  }

  unobserve() {
    this.targets.forEach(element => {
      this.observer.unobserve(element);
    });
  }

  disconnect() {
    this.observer.disconnect();
  }

  protected callback(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) {}
}

class QuestionScrollObserver extends ScrollObserver {
  constructor() {
    const options = {
      threshold: 0.05, // let's just require 5 percent
    };

    super(options);
    const element = document.getElementById(elementIds.AFTER_QUESTION_BEACON);

    this.observeElement(element);
  }

  protected callback(entries: IntersectionObserverEntry[]) {
    const playerModel = getPlayerModel();
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        playerModel.forceDisablePlayback = false;
      }
    });
  }
}

class TitleScrollObserver extends ScrollObserver {
  constructor() {
    const options = {
      threshold: 0.9, // Override the threshold value
    };
    super(options);
    const element = document.getElementById(SOUNDBITE_TITLE_CONTAINER);
    this.observeElement(element);
  }

  protected callback(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        soundbitePlayerUiModel.setShowTitleInHeader(false);
      } else {
        soundbitePlayerUiModel.setShowTitleInHeader(true);
      }
    });
  }
}

class AnswerScrollObserver extends ScrollObserver {
  constructor() {
    const options = {
      threshold: 0.1,
    };
    super(options);
    const element = document.getElementById(elementIds.END_AREA);
    this.observeElement(element);
  }

  protected callback(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        soundbitePlayerUiModel.setShowScrollToAnswerButton(false);
      } else {
        soundbitePlayerUiModel.setShowScrollToAnswerButton(true);
      }
    });
  }
}

export function useScrollingObservers() {
  React.useEffect(() => {
    /// this is for the answer observer
    const questionScrollObserver = new QuestionScrollObserver();

    const titleScrollObserver = new TitleScrollObserver();
    const answerScrollObserver = new AnswerScrollObserver();
    // scrollObserver.observeTitleElement();
    // scrollObserver.observeAnswerElement();

    return () => {
      questionScrollObserver.unobserve();
      titleScrollObserver.unobserve();
      answerScrollObserver.unobserve();
    };
  }, []);
}

/* Puts the current sentence in the middle of the screen – we do this during playback */
/// this function will end up doing a lot more than just centering the sentence
/// but I'm keeping the name for now
export function scrollTo(element: HTMLElement) {
  const scriptPanel = document.getElementById(SCRIPT_SCROLLING_CONTAINER_ID);
  if (scriptPanel) {
    if (element) {
      scriptPanel.scrollTo({
        top: element.offsetTop - 72,
        behavior: 'smooth',
      });
    }
  }
}
