import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { ChevronDownExtraSmallIcon } from '@naan/icons/chevron-down-icon';
import { SoundbiteIconExtraSmall } from '@naan/icons/soundbite-icon';
import { AppFactory } from 'app/app-factory';
import { SoundbiteStoryInfoModal } from './soundbite-story-info-modal';
import { track } from 'app/track';
import { MarkSimple } from '@naan/primitives/text';
import { stripUnderscores } from '@utils/content-string-utils';
import { formatDate } from '@utils/format-date';
import { SoundbiteCategory } from 'components/soundbites/soundbite-categories';
import { ScriptHeaderCategoryInfo } from './script-header-category-info';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';

import {
  headerColors,
  headerImages,
} from 'components/soundbites/soundbite-headers';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  position: 'relative',
  $$backgroundColor: '$colors$white',
  $$backgroundImage: '',

  backgroundColor: '$$backgroundColor',
  backgroundImage: '$$backgroundImage',
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

  '& > .inner': {
    paddingTop: 80,
    paddingBottom: 16,
    color: '$colors$white',
    height: '100%',
    width: 'min(600px, 100% - 32px)',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '@widerThanScript': {
      paddingTop: 104,
      paddingBottom: 24,
    },
    '& > .soundbite-meta': {
      textStyle: 'small-caps',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
      paddingBottom: 12,
      marginBottom: 8,

      '& > .label': {
        display: 'flex',
        alignItems: 'center',

        '& > .icon': {
          marginRight: 4,
        },
      },
    },

    '& > .soundbite-title': {
      textStyle: 'medium-heading',
      marginBottom: 4,
      '@widerThanScript': {
        textStyle: 'extra-large-heading',
      },
    },

    '& > .story-button': {
      all: 'unset',
      display: 'flex',
      alignItems: 'center',
      textStyle: 'small-text',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
      '& > .icon': {
        marginLeft: 3,
      },
      '@widerThanScript': {
        textStyle: 'body',
      },
    },

    '& > .category-container': {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: 40,
      '@widerThanScript': {
        marginTop: 64,
      },
    },
  },
});

Wrapper.displayName = 'ScriptHeaderWrapper';

export const ScriptHeader = observer(({ model }: { model: SoundbiteModel }) => {
  const { excerptData: excerpt, story, soundbite } = model;

  const presentStoryInfo = React.useCallback(() => {
    track('soundbite__story_info_shown', {
      soundbiteSlug: excerpt?.slug,
      storySlug: story?.slug,
    });
    AppFactory.dialogPresenter.present(onDismiss => (
      <SoundbiteStoryInfoModal model={model} onDismiss={onDismiss} />
    ));
  }, [model, excerpt, story]);

  const date = soundbite?.releaseDate;
  // important to get the category from the excerpt data so the masala preview links works
  const category = excerpt.category as SoundbiteCategory;

  const isFeatured = soundbite?.isFeatured;

  const $$backgroundColor = headerColors[category] ?? '#000';
  const $$backgroundImage = headerImages[category];

  return (
    <Wrapper
      css={{
        $$backgroundColor,
        $$backgroundImage,
      }}
    >
      <div className="inner">
        <div className="soundbite-meta">
          <span className="label">
            <span className="icon">
              <SoundbiteIconExtraSmall />
            </span>
            {isFeatured
              ? __("Today's Soundbite", 'todaySoundbite')
              : __('Soundbite', 'soundbite')}
          </span>
          {date ? <span className="date">{formatDate(date)}</span> : null}
        </div>
        <h2 className="soundbite-title" id="soundbite-title-container">
          <MarkSimple source={excerpt.title} />
        </h2>
        <button className="story-button" onClick={presentStoryInfo}>
          {__('from %{title} • %{countries}', 'fromTitleCountries', {
            /* beware, MarkSimple didn't work here because it munched the spacing around italics,
                   but not going to worry about it yet */
            title: stripUnderscores(story?.title) ?? '',
            countries: story?.countries.join(', ') ?? '',
          })}
          <span className="icon">
            <ChevronDownExtraSmallIcon width={12} height={12} />
          </span>
        </button>
        <div className="category-container">
          <ScriptHeaderCategoryInfo soundbite={soundbite} />
        </div>
      </div>
    </Wrapper>
  );
});
