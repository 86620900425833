import * as React from 'react';
import { observer } from 'mobx-react';
import { VSpacer } from '@naan/primitives/spacer';
import { AppFactory } from '@app/app-factory';
import { AnonymousFlow } from 'components/account/subscription-flow/anonymous-flow';
import { AuthenticatedFlow } from 'components/account/subscription-flow/authenticated-flow';
import { SubscriptionLayout } from 'routes/layouts/subscription-layout';
import { Footer } from 'components/account/subscription-flow/footer';
import { homePath, profileHomePath } from 'components/nav/path-helpers';
import { Navigate } from 'react-router-dom';
import { bugsnagNotify } from '@app/notification-service';
import { presentPurchaseDisabledAlert } from 'components/account/account-cta/purchase-flow-disabled';

import __ from 'core/lib/localization';
import { SubscriptionBenefits } from 'components/subscribe/benefits';
import { useThemeColor } from '@common/pwa/set-theme-color';

export const SubscribeScreen = observer(() => {
  const { userManager } = AppFactory.root;
  const { authenticated, purchaseFlowDisabled, accountData } = userManager;
  const { fullAccess } = accountData;

  useThemeColor('#18879E');

  if (fullAccess) {
    return <Navigate to={profileHomePath()} replace />;
  }

  // not expected to be reachable, but do something better than a dead-end screen just in case
  if (purchaseFlowDisabled) {
    bugsnagNotify(
      'unexpected SubscribeScreen render with purchaseFlowDisabled'
    );
    presentPurchaseDisabledAlert();
    return <Navigate to={homePath()} />;
  }

  return (
    <SubscriptionLayout>
      <h3 className="title">
        {__('Unlock Jiveworld Español', 'subscribe.unlockJiveworldEsp')}
      </h3>
      <SubscriptionBenefits />
      {authenticated ? <AuthenticatedFlow /> : <AnonymousFlow />}
      <VSpacer size="8" />
      <Footer />
    </SubscriptionLayout>
  );
});
