import React from 'react';
import { observer } from 'mobx-react';
import { MediumHeading } from 'naan/primitives/text';
import {
  Table,
  TableRow,
  TableLabelCell,
  TableValueCell,
} from 'components/account/account-form/table';
import { PricingCards } from 'components/account/pricing-cards';
import { BlueLink } from 'components/ui/buttons/link-button';
import { prevent } from 'common/prevent';
import { useSwitch } from '@naan/hooks/use-switch';
import { SectionBreak } from '@naan/primitives/spacer/gaps';
import { VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import __ from 'core/lib/localization';
import { formatDate } from 'utils/format-date';
import { AppFactory } from '@app/app-factory';

export const FullNoRenew = observer(() => {
  const [changing, changingSwitch] = useSwitch(false);

  const { userManager } = AppFactory.root;
  const { accountData } = userManager;

  if (userManager.purchaseFlowDisabled) {
    return null;
  }

  return (
    <>
      <MediumHeading>{__('My membership', 'myMembership')}</MediumHeading>
      <VSpacer size="4" />
      <Table>
        <tbody>
          <TableRow>
            <TableLabelCell>
              {__('Membership type', 'membershipType')}
            </TableLabelCell>
            <TableValueCell>{accountData.membershipDisplay}</TableValueCell>
          </TableRow>

          <TableRow>
            <TableLabelCell>
              {__('Recurring billing', 'recurringBilling')}
            </TableLabelCell>
            <TableValueCell>
              {accountData.recurringBillingDisplay}
            </TableValueCell>
          </TableRow>

          <TableRow>
            <TableLabelCell>{__('Expires', 'expires')}</TableLabelCell>
            <TableValueCell>
              {formatDate(accountData.fullAccessUntil)}
              {!userManager.purchaseFlowDisabled &&
              accountData?.shouldPromoteRenewal ? null : (
                <BlueLink onClick={prevent(changingSwitch.toggle) as any}>
                  {changing ? 'Cancel' : 'Extend'}
                </BlueLink>
              )}
            </TableValueCell>
          </TableRow>
          {changing ? (
            <TableRow noBorder={true}>
              <td colSpan={2}>
                <VStack>
                  <VSpacer size={10} />
                  <PricingCards />
                  <VSpacer size={10} />
                </VStack>
              </td>
            </TableRow>
          ) : null}
        </tbody>
      </Table>
      <SectionBreak />
    </>
  );
});
