import * as React from 'react';
import { ElementId } from './element-ids';

/**
 * this hook delays the rendering of a React component until specified DOM elements are ready.
 *
 * @param elementIds - An array of element IDs to wait for before rendering the component.
 * @returns A boolean value indicating whether the required elements are ready.
 */
export const useDomReady = (elementIds: ElementId[]) => {
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    const checkElements = () => {
      const allElementsExist = elementIds.every(
        id => document.getElementById(id) !== null
      );
      setIsReady(allElementsExist);
    };

    checkElements();

    const observer = new MutationObserver(checkElements);
    const observerConfig = { childList: true, subtree: true };

    elementIds.forEach(id => {
      const targetNode = document.getElementById(id);
      if (targetNode) {
        observer.observe(targetNode, observerConfig);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [elementIds]);

  return isReady;
};
