/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { TryFirstSoundbiteButton } from './try-first-soundbite-button';

import featuresCategoriesImgSrc from './assets/features-categories.png';
import raFeaturePanelImgSrc from './assets/ra-feature-panel-image.png';
import unwrapImgSrc from './assets/unwrap.png';

import __ from '@core/lib/localization';

const Wrapper = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  paddingTop: 40,
  paddingBottom: 40,

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    '& > img': {
      width: '100%',
      margin: '0 auto 8px',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      alignItems: 'flex-start',
      justifyContent: 'center',
      '& > h2': {
        all: 'unset',
        textStyle: 'medium-heading',
      },
      '& > p': {
        textStyle: 'serif',
      },
    },
  },

  '& > .cta': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '@medium': {
    gap: 56,
    paddingTop: 80,
    paddingBottom: 64,
    '& > div:not(.cta)': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 24,

      '&:nth-child(odd)': {
        '& > img': {
          gridRow: 1,
          gridColumn: '2/3',
        },
        '& > div': {
          gridColumn: '1/2',
        },
      },
      '& h2': {
        textStyle: 'large-heading',
      },
    },
  },
});

export const FeaturesSection = ({ continuePath }: { continuePath: string }) => {
  return (
    <CenterColumnLayout>
      <Wrapper className="features">
        <div className="categories">
          <img src={featuresCategoriesImgSrc} role="presentation" />
          <div>
            <h2 className="categories-title">
              {__('Go beyond the dictionary', 'goBeyondTheDictionary')}
            </h2>
            <p className="categories-body">
              {__(
                "Grammar and vocabulary are important, but there's so much more to true fluency. Soundbites explore the language and culture across eight dimensions.",
                'grammarAndVocabularyAreImportantBut'
              )}
            </p>
          </div>
        </div>
        <div className="ra">
          <img src={raFeaturePanelImgSrc} role="presentation" />
          <div>
            <h2 className="ra-title">
              {__(
                'Hear real spoken Spanish from over 20 countries',
                'hearRealSpokenSpanishFromOver20Countries'
              )}
            </h2>
            <p className="ra-body">
              {__(
                "Soundbites are excerpted from uniquely Latin American stories by the award-winning Radio Ambulante podcast. Each week you'll explore a different story and the language and culture inside it.",
                'soundbitesAreExcerptedFromUniquelyLatinAmericanStories'
              )}
            </p>
          </div>
        </div>
        <div className="puzzle">
          <img src={unwrapImgSrc} role="presentation" />
          <div>
            <h2 className="puzzle-title">
              {__('Unravel the mystery', 'unravelTheMystery')}
            </h2>
            <p className="puzzle-body">
              {__(
                "Each Soundbite is a language puzzle with several clues to the solution. Even when you know the answer, you'll discover plenty new in the insights and examples — all while your Spanish listening gets a workout.",
                'eachSoundbiteIsALanguagePuzzleWithSeveralCluesToTheSolution'
              )}
            </p>
          </div>
        </div>
        <div className="cta">
          <TryFirstSoundbiteButton
            id="spa-sb-btn-2"
            continuePath={continuePath}
          />
        </div>
      </Wrapper>
    </CenterColumnLayout>
  );
};
