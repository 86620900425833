import React from 'react';
import { AppFactory } from '@app/app-factory';
import { observer } from 'mobx-react';

import { Responsive } from '@naan/primitives/responsive';
import { TrialDashboardNavLarge } from './trial-dashboard-nav-large';
import { TrialDashboardNavMedium } from './trial-dashboard-nav-medium';
import { TrialDashboardNavSmall } from './trial-dashboard-nav-small';

export const UnauthenticatedDashboardNav = observer(() => {
  const { authenticated } = AppFactory.root.userManager;

  if (authenticated) {
    return null;
  }

  return (
    <Responsive
      renderDefault={() => <TrialDashboardNavSmall />}
      renderMediumAndUp={() => <TrialDashboardNavMedium />}
      renderLargeAndUp={() => <TrialDashboardNavLarge />}
    />
  );
});
