import * as React from 'react';

import { MarkSimple } from '@naan/primitives/text';
import { styled } from '@naan/stitches.config';
import { VSpacer } from '@naan/primitives/spacer';
import { paletteKey } from '../study-palette';
import { observer } from 'mobx-react';
import { GlobalRedactionButton } from '../global-redaction-button';
import { AppFactory } from 'app/app-factory';
import { ChapterNotes } from '../controls/chapter-notes';

const ScriptHeaderContainer = styled('div', {
  textStyle: 'medium-heading',
  textAlign: 'center',
  padding: '152px 24px 80px',
  color: paletteKey('$$chapterTitle__color'),
  position: 'relative',

  '& > .position': {
    color: paletteKey('$$chapterTitle__positionColor'),
  },

  '& > .redaction-btn': {
    position: 'absolute',
    right: 0,
    bottom: -28,
    zIndex: 1,
  },

  '@medium': {
    padding: '192px 24px 120px',
  },
});

export const ObserverRedactionButtonContainer = observer(() => {
  const model = AppFactory.studyModel;
  return (
    <div className="redaction-btn">
      {model.studyMode ? <GlobalRedactionButton /> : null}
    </div>
  );
});

export const StartOfChapter = () => {
  const model = AppFactory.studyModel;
  return (
    <ScriptHeaderContainer>
      <span className="position">{model.chapter.position}</span>
      <div>
        <MarkSimple source={model.chapter.title} />
      </div>
      <VSpacer size={'6'} />
      <ChapterNotes notes={model.chapterNotes} />
      <ObserverRedactionButtonContainer />
    </ScriptHeaderContainer>
  );
};
