import * as NotificationService from 'app/notification-service';
import { appConfig } from 'app/env';
import { createLogger } from '@common/log';
import { HandleableError } from '@core/lib/errors';
import { alertLevels } from '@core/lib/errors/alert-levels';
import { sanitizeErrorMessage } from 'app/notification-service';

const { ERROR, WARN /*, NONE*/ } = alertLevels;

const log = createLogger('global-error-handler');

// expected to be called with the 'originalException' property of the sentry 'hint'
// or the raw unhandled error if sentry is disabled
export const globalErrorHandler = (originalError: unknown) => {
  // gratuitous logging until we make sure we're sure everything we care about gets into bugsnag
  try {
    log.error(`originalError: ${originalError}`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`error logging error:`, e);
  }
  // eslint-disable-next-line no-console
  console.trace();

  // let error: HandleableError = null;
  // try {
  //   error =
  //     event.originalError || (event.errors ? event.errors[0] : Error(event));
  // } catch (e) {
  //   log.error(`failed to extract error from bugsnag event`);
  //   return;
  // }
  const error = originalError as HandleableError; // not strictly a HandleableError, but usage already safe

  // this doesn't work!! bugsnag seems to flatten our rich error objecte into a string before it gets here
  // bottom line is warnings should be directly alerted, not sent through as unhandled
  const alertLevel = error?.alertLevel || ERROR;
  /* the 'message' prop seems to get munged in some flows so we need to adapt to either here */
  let message: string =
    error?.message ?? (error as any)?.errorMessage ?? 'Unhandled error';
  message = sanitizeErrorMessage(error);

  log.info(`alertLevel: ${alertLevel}, message: ${message}`);

  if (appConfig.showUnhandledErrors) {
    switch (alertLevel) {
      case ERROR:
        NotificationService.alertError(message, {
          report: false, // do not re-send to bugsnag - todo: refactoring this circular API!!
        });
        break;

      case WARN:
        NotificationService.alertWarning(message);
        break;

      default:
        log.info('no toast');
    }
  }

  // support for low-level error handling
  const jw: any = (window as any).jw || {};

  if (typeof jw.unexpectedError === 'function') {
    jw.unexpectedError(message);
  }
};
