import * as React from 'react';
import { BookIcon } from '@naan/icons/book-icon';
import { SoundbiteIcon } from '@naan/icons/soundbite-icon';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
  marginBottom: 56,
  '.item': {
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
    gap: 16,
    '& .icon': {
      '& svg': {
        width: 40,
        height: 40,
      },
      '&.soundbite': {
        color: '$colors$purple-300',
      },
      '&.stories': {
        color: '$colors$yellow-300',
      },
    },
    '& .text': {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      color: '$colors$white',
      textStyle: 'body',
      '& > h3': {
        textStyle: 'small-heading',
      },
    },
  },
});

export const SubscriptionBenefits = () => {
  return (
    <Wrapper>
      <div className="item">
        <span className="stories icon">
          <BookIcon />
        </span>
        <div className="text">
          <h3>
            {__(
              'All stories, from charming vignettes to epic tales',
              'allStoriesFromCharmingVignettesToEpicTales'
            )}
          </h3>
          <p>
            {__(
              `These Radio Ambulante stories capture every facet of life in Latin America. You'll listen and understand real Spanish as spoken by 100s of people — young and old, famous and everyday — from over 20 countries.`,
              'theseRadioAmbulanteStoriesCaptureEveryFacetOfLifeInLatinAmerica'
            )}
          </p>
        </div>
      </div>
      <div className="item">
        <span className="soundbite icon">
          <SoundbiteIcon />
        </span>
        <div className="text">
          <h3>
            {__('Subscriber-only Soundbites', 'subscriberOnlySoundbites')}
          </h3>
          <p>
            {__(
              `If you like our free daily mini-lessons in real-world Spanish, you'll love exploring the full back catalog and the extra Soundbites that only subscribers can see.`,
              'ifYouLikeOurFreeDailyMiniLessons'
            )}
          </p>
        </div>
      </div>
    </Wrapper>
  );
};
