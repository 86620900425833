import { AppFactory } from '@app/app-factory';
// import { EMPTY_USER_ID } from '@common/analytics/analytics-manager';
import { notEmpty } from '@utils/conditionals';
import { CLASSROOM_FILTER_KEY_PREFIX } from 'core/lib/constants/vars';
import __ from 'core/lib/localization';
import { isEmpty, pick } from 'lodash';
import { frozen, ModelTreeNode } from 'ts-state-tree/tst-core';
import { Story } from '../story-manager/story';
import { Assignment } from './assignment';
import { Classroom } from './classroom';
import { PaymentData } from './payment-data';
import { Plan } from './plan';
import { PurchasedCoupon } from './purchased-coupon';
import { getInstallationId } from '../installation-id';

export class AccountData extends ModelTreeNode {
  static CLASS_NAME = 'AccountData' as const;

  static create(snapshot: any = {}): AccountData {
    return super.create(AccountData, snapshot) as AccountData;
  }

  userId: string = ''; // EMPTY_USER_ID;
  userDataUuid: string; // firestore user data doc id
  email: string = ''; // displayed in the account screen if changed after first registration and not yet reconfirmed.does not block login ?? string
  name: string = '';
  schoolName?: string = null;
  isClassroomActivated: boolean = false;
  // not currently relevant. was only true when an account was not active until the initial email was confirmed
  // emailConfirmationRequired: boolean = false;
  unconfirmedEmail?: string = null;
  mailingListOptIn: boolean = false;
  mailingListPromptNeeded: boolean = false; // set by rails server during account creation. reset when opt in pref set

  memberSinceYear: number = null;
  lastSyncedVersion: number = 0; // incremented by server last when ever sync'ed in or out
  group: string = null; // nullable - metrics tracking group
  membershipState: string = 'trial';
  fullAccess: boolean = false; // if true, then has access to all stories, if false, then only 'trial == true' stories,
  canAccessAllStoryScripts: boolean = false;
  autoRenew: boolean = false;
  // anonymous: boolean = false;
  fullAccessUntil: string = null; // TODO: how to best handle dates?

  // these probably should have been encoded as numbers in the account data json payload
  // these will eventually move to the global firestore settings doc, we can fix the typing then
  appStoreBuildNumber: string = null;
  playStoreBuildNumber: string = null;
  // debugBuildNumber: string = null; // apple review - coming from firestore now, not
  // betaMode: boolean = false; // rails server flag. drives deltas to dashboard banners

  daysLeft: number = 0;

  // story/volume level access control should not be currently relevant
  unlockedStorySlugs: string[] = [];
  unlockedVolumeSlugs: string[] = [];

  hasEverPaid: boolean = false;
  promoteEnrollment: boolean = false;
  // promoteDownload: boolean = false;
  hasAffiliatePricing: boolean = false;
  affiliateWelcomeHeading: string = null;
  // welcomeUrl: string = null;
  // whatsNewUrl: string = null;
  showFutureStories: boolean = false; // if true, then forward dated stores will be not be omitted from story list views

  // applePaymentsDisabled: boolean = true;
  // this is now only build time flag
  // inviteCodeRequired: boolean = false; // triggers beta website invitation gate
  // hideIosCouponUi: boolean = false;
  hasApplePaidAccess: boolean = false;

  paymentData: PaymentData = null;

  plans: Plan[] = [];
  // applePlans: Plan[] = [];
  giftPlans: Plan[] = [];
  purchasedCoupons: PurchasedCoupon[] = [];

  classroomPortalWelcomePending: boolean = false;
  joinedClassrooms: Classroom[] = [];

  // drives teacher classroom portal view within spa
  managedClassrooms: Classroom[] = [];
  hasManagedClassrooms: boolean = false;
  licensedClassroomLabel: string = null; // name to display on account page if group-access

  // none of these should really be needed by spa, but useful to confirm what account data api returns
  appHomeUrl: string;
  manageAccountUrl: string;
  appSiteBaseUrl: string;
  marketingSiteBaseUrl: string;

  // deprecated, useful only for handy confirmation of what 5.5.3 native app sees
  accountPageUrl: string = null; // fully formed URL
  // accountSiteBaseUrl: string = null; // obsolete
  accountPageLinkName: string = null;

  // used for server driven debugging of startup failure flows (most likely no longer used)
  debugStatus: string = null;

  // affiliate/utm and referrer attribution as captured from the jw-traffic-source cookie
  // by the rails create-account logic.
  // not currently used aside from testing
  @frozen
  signupTrafficSource: object;

  // get catalogSlug() {
  //   return this.catalogV4Slug;
  // }

  // get catalogUrl() {
  //   return this.catalogV4Url;
  // }

  get reportingContextData(): object {
    return pick(this, [
      'userId',
      'email',
      'name',
      'errorReportingUserId',
      'errorReportingName',
      'schoolName',
      'group',
      'fullAccess',
      'fullAccessUntil',
      'autoRenew',
      'membershipState',
      'memberSinceYear',
      'isClassroomActivated',
      'canAccessAllStoryScripts',
      'hasManagedClassrooms',
      'licensedClassroomLabel',
      'mailingListOptIn',
      'mailingListPromptNeeded',
      'resolvedCatalogUrl',
    ]);
  }

  get errorReportingUserId(): string {
    if (this.hasUserId) {
      return this.userId;
    } else {
      // return AppFactory.root?.localState?.installationId;
      return getInstallationId();
    }
  }

  get errorReportingName(): string {
    if (this.hasUserId) {
      return this.name;
    } else {
      return '(anonymous)';
    }
  }

  get hasUserId(): boolean {
    return notEmpty(this.userId); // && this.userId !== EMPTY_USER_ID;
  }

  classroom(id: string) {
    return this.managedClassrooms.find(classroom => classroom.id === id);
  }

  get membershipDisplay() {
    // @elliottjf
    // maybe membershipState should be a string enum?
    switch (this.membershipState) {
      case 'trial':
        return __('Trial', 'account.membership.trial');
      case 'suspended':
        return __(
          'Suspended (monthly subscription)',
          'account.membership.suspended'
        );
      case 'full-no-renew':
        return __('Full access', 'account.membership.fullAccess');
      case 'full-auto-renew':
        return __('Full access', 'account.membership.fullAccess');
      case 'group-access':
        return __('Group access', 'account.membership.groupAccess');
      default:
        return this.membershipState; // unexpected, should probably log error
    }
  }

  // for now only show affiliate welcome when anonymous
  // todo: confirm what's desired
  get showAffiliateWelcome(): boolean {
    // return this.affiliateWelcomeHeading && !this.fullAccess;
    return this.affiliateWelcomeHeading && isEmpty(this.name);
  }

  get showRecurringBilling(): boolean {
    return (
      this.membershipState === 'full-no-renew' ||
      this.membershipState === 'full-auto-renew'
    );
  }

  get recurringBillingDisplay(): string {
    // @armando, I'm not sure the history of driving this display via the membershipState,
    // but it was in correct for the 'suspended' state, so I thnk this is better.
    // if (this.membershipState === 'full-auto-renew') {
    if (this.autoRenew) {
      return __('On', 'on');
    } else {
      return __('Off', 'off');
    }
  }

  get showClassroomLicenseNag() {
    return !!this.managedClassrooms?.find(c => c.license?.isOverSubscribed);
  }

  // this should be used now instead of the raw server 'isClassroomActivated' flag
  get classroomEnabled() {
    return this.isClassroomActivated && this.schoolName !== 'n/a'; // hack around current server logic
  }

  // get hasJoinedClassrooms() {
  //   return this.joinedClassrooms && this.joinedClassrooms.length > 0;
  // }

  get joinedClassroomsWithAssignments(): Classroom[] {
    return this.joinedClassrooms.filter(
      classroom => classroom.assignmentCount > 0
    );
  }

  joinedClassroomForFilterKey(key: string) {
    const id = key.replace(CLASSROOM_FILTER_KEY_PREFIX, '');
    return this.joinedClassrooms.find(classroom => classroom.id === id);
  }

  get assignmentMap() {
    return this.managedClassrooms
      .map(c => c.assignments)
      .flat()
      .map(a => a.episodeSlug)
      .reduce((acc, slug) => {
        if (!acc[slug]) {
          acc[slug] = 1;
        } else {
          acc[slug] = acc[slug] + 1;
        }
        return acc;
      }, {} as { [key: string]: number });
  }

  joinedClassroomAssignmentForStory(story: Story): Assignment {
    for (const classroom of this.joinedClassrooms) {
      for (const assignment of classroom.assignments) {
        if (story.matchesVolumeOrUnitSlug(assignment.episodeSlug)) {
          return assignment;
        }
      }
    }
    return null;
  }

  get hasSpecialPricing(): boolean {
    return this.plans[0]?.hasSpecialPricing;
  }

  // todo: clean this up and move to app-root
  get debugBuildNumber(): string {
    return String(AppFactory.root.globalSettings.debugBuildNumber);
  }

  // moved to userManager.hasAdminAccess
  // get showDevTools() {
  //   return this.showFutureStories;
  // }

  //
  // TODO
  //

  get currentPlan(): any {
    return null;
  }

  get remainingFullAccessInDays() {
    if (this.fullAccessUntil) {
      const now = new Date();
      const fullAccessUntil = new Date(this.fullAccessUntil);
      const diff = fullAccessUntil.getTime() - now.getTime();
      const days = diff / (1000 * 3600 * 24);
      return days;
    }
    return 0;
  }

  get shouldPromoteRenewal() {
    if (this.autoRenew) {
      return false;
    }

    const daysOfFullAccess = this.remainingFullAccessInDays;

    if (daysOfFullAccess < 30) {
      return true;
    }

    return false;
  }

  get accessExpired() {
    return !this.fullAccess && this.hadFullAccess;
  }

  get hadFullAccess() {
    return notEmpty(this.fullAccessUntil);
  }
}
