import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { ClassroomBrand } from './classroom-brand';
import { JwSymbol, SymbolPresentation } from './jw-symbol';
import { subBrand } from '@core/lib/app-util';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // img: {
  //   width: '100%',
  // },
  '& .sub-brand': {
    color: '$gray-500',
    textStyle: 'small-caps',
    userSelect: 'none',
    marginLeft: 4,
  },
  '&:not(.presentation-color) .sub-brand': {
    color: '$white',
  },
  '& .sub-brand:has(svg)': {
    color: '$textPrimary',
  },
});

const subBrandTypes = {
  none: 'none',
  espanol: subBrand(),
  es: 'es',
  classrooms: 'classrooms',
} as const;

type SubBrandType = typeof subBrandTypes[keyof typeof subBrandTypes];

export const JwLogo = ({
  presentation = 'color',
  subBrand = subBrandTypes.none,
}: {
  presentation?: SymbolPresentation;
  subBrand?: SubBrandType;
}) => {
  return (
    <Wrapper className={`presentation-${presentation}`}>
      <JwSymbol presentation={presentation} />
      {subBrand === subBrandTypes.classrooms ? (
        <span className={'sub-brand'}>
          <ClassroomBrand />
        </span>
      ) : subBrand !== subBrandTypes.none ? (
        <span className="sub-brand">{subBrand}</span>
      ) : null}
    </Wrapper>
  );
};
