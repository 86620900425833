import __ from '@core/lib/localization';

export type SoundbiteCategory =
  | 'accents'
  | 'vernacular'
  | 'vocabulary'
  | 'grammar'
  | 'patterns'
  | 'idioms'
  | 'strategies'
  | 'culture';

type SoundbiteCategoryInfo = {
  label: string;
  tooltip: string;
};

export const SoundbiteCategoriesInfo: Record<
  SoundbiteCategory,
  SoundbiteCategoryInfo
> = {
  accents: {
    label: __('Speech & Accents', 'speechAccents'),
    tooltip: __(
      'Appreciating the distinctive sound and style of different speakers',
      'distinctiveSound'
    ),
  },
  vernacular: {
    label: __('Vernacular & Slang', 'vernacularSlang'),
    tooltip: __('Colloquial usage by region or demographic', 'colloquialUsage'),
  },
  vocabulary: {
    label: __('Vocabulary', 'vocabulary'),
    tooltip: __('Interesting words and expressions', 'interestingWords'),
  },
  grammar: {
    label: __('Grammar', 'grammar'),
    tooltip: __('Rules for how the language fits together', 'languageRules'),
  },
  patterns: {
    label: __('Useful Patterns', 'usefulPatterns'),
    tooltip: __(
      'Versatile language structures that boost your fluency',
      'versatileStructures'
    ),
  },
  idioms: {
    label: __('Idioms & Sayings', 'idiomsSayings'),
    tooltip: __(
      'Weird and wonderful expressions with interesting cultural roots',
      'weirdExpressions'
    ),
  },
  strategies: {
    label: __('Strategies', 'strategies'),
    tooltip: __(
      'Hacks for navigating social situations (e.g. offering condolences), and how to study more effectively.',
      'socialHacks'
    ),
  },
  culture: {
    label: __('History & Culture', 'historyCulture'),
    tooltip: __(
      'Famous people and institutions, music and movies, celebrations and beliefs',
      'famousPeople'
    ),
  },
};
