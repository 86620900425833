import * as React from 'react';

import { observer } from 'mobx-react';
import { Button } from 'naan/primitives/button';
import { useTitle } from 'common/hooks/use-title';
import { useAfterAuthRedirection } from 'lib/hooks/use-after-auth-redirection';
import { VStack } from 'naan/primitives/stack';
import { Input, PasswordInput } from 'naan/primitives/input';
import { Label } from 'naan/primitives/forms/label';
import { useComboForm } from 'common/hooks/use-comboform';
import { VSpacer } from 'naan/primitives/spacer';
import { useNavigate } from 'react-router-dom';
import { styled } from 'naan/stitches.config';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';
import { FormError } from 'naan/primitives/forms';
import { omit } from 'lodash';
import { AppFactory } from 'app/app-factory';
import { intentionallyResetAllScrollStorageKeys } from 'lib/scroll-utils/deep-scroll-restoration';

import __ from 'core/lib/localization';
import { createLogger } from '@common/log';
import { ActionLink } from '@common/ui/action-link';
import { getSignInModel } from './sign-in-model';
import * as Dialog from '@naan/primitives/modals/dialog';
import { GoogleButtonIfEnabled, SignInWithCodeLink } from './sign-in-layout';

const log = createLogger('sign-in-password-screen');

const LinkStyled = styled(ActionLink, {
  textStyle: 'small-text',
  color: '$teal-500',
  textDecoration: 'underline',
  textAlign: 'right',
  position: 'absolute',
  right: 0,
});

const LabelWrapper = styled('div', {
  position: 'relative',
});

type FormInput = {
  email: string;
  password: string;
  code: string;
};

export const SignInPasswordScreen = observer(() => {
  useTitle(__('Welcome back', 'welcomeBack'));
  useAfterAuthRedirection();
  const navigate = useNavigate();
  const { userManager } = AppFactory.root;

  const signInModel = getSignInModel();
  const defaultValues = { email: signInModel.email };

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
  } = useComboForm<FormInput>({ defaultValues });

  /// This is the callback that actually handles the log in.
  const handleLogin = React.useCallback(
    ({ email, password }: FormInput) => {
      log.info(`handleLogin`);
      clearValidationErrors();
      userManager.login(email, password, null /*code*/).then(() => {
        signInModel.reset();
        intentionallyResetAllScrollStorageKeys();
        /// this could be a toast!
      }, handleValidationErrors);
    },
    [userManager, signInModel, handleValidationErrors, clearValidationErrors]
  );

  const handleForgotPassword = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => {
      return (
        <ForgotPasswordDialog
          onDismiss={onDismiss}
          onOk={() => {
            navigate('/auth/email-code');
            onDismiss();
          }}
        />
      );
    });
  }, [navigate]);

  return (
    <>
      <form onSubmit={handleSubmit(handleLogin)}>
        <CatchAllFormErrors
          errors={omit(errors, ['email', 'password'])}
          data-test-id="error-message"
        />
        <VStack>
          <Label htmlFor="email">{__('Email', 'email')}</Label>
          <VSpacer size={2} />
          <Input
            name={'email'}
            data-test-id={'email-address'}
            type="email"
            tabIndex={1}
            {...register('email')}
            required
            onBlur={e => {
              // reacting to onBlur keeps our nav links simple and
              // doesn't seem to interfere with the validation
              // log.info(`email blur: ${e.currentTarget.value}`);
              signInModel.email = e.currentTarget.value;
            }}
          />
          <FormError
            error={errors['email']}
            data-test-id="email-error-message"
          />

          <VSpacer size={6} />

          <LabelWrapper>
            <Label htmlFor="password">{__('Password', 'password')}</Label>
            {/* <LinkStyled
              to="/auth/reset-password"
              data-test-id="forgot-password"
              tabIndex={3}
            >
              {__('Forgot password?', 'forgotPassword')}
            </LinkStyled> */}
            <LinkStyled onPress={handleForgotPassword}>
              {__('Forgot password?', 'forgotPassword')}
            </LinkStyled>
          </LabelWrapper>

          <VSpacer size={2} />

          <PasswordInput
            name={'password'}
            data-test-id={'password'}
            tabIndex={2}
            {...register('password')}
            placeholder={''}
            required
          />
          <FormError
            error={errors['password']}
            data-test-id="password-error-message"
          />
          <VSpacer size={6} />
        </VStack>
        <div style={{ width: '100%', display: 'flex' }}>
          <Button
            label={__('Sign in', 'signIn')}
            onClick={handleSubmit as any}
            presentation="teal"
            size="large"
            fullWidth
            tabIndex={4}
          />
        </div>
      </form>

      <SignInWithCodeLink />

      <GoogleButtonIfEnabled />
    </>
  );
});

const ForgotPasswordDialog = observer(
  ({ onDismiss, onOk }: { onDismiss: () => void; onOk: () => void }) => {
    return (
      <Dialog.Container onDismiss={onDismiss} open>
        <Dialog.Heading>Forgot password</Dialog.Heading>
        <Dialog.Body>
          <p>
            To reset your password, we'll first email you a one-time code. You
            can then set a new password from your account screen.
          </p>
        </Dialog.Body>
        <Dialog.ButtonsContainer direction={'row'}>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Go back', 'goBack')}
            presentation="grayLight"
          />
          <Dialog.Button
            onClick={onOk}
            label={__('Continue', 'continue')}
            presentation="teal"
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);
