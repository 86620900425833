import * as React from 'react';
import { Text } from 'naan/primitives/text';
import { styled } from 'naan/stitches.config';
import { Story } from 'core/models/story-manager';
import { SoundbitesStoryBadge } from './soundbite-story-badge';
import { ShortsBadge } from './shorts-badge';

const StoryCardMetaWrapper = styled('div', {
  marginBottom: '$1',
  lineHeight: '20px',
  textStyle: 'small-text',
  display: 'flex',
  alignItems: 'center',
  gap: 6,
});

export const StoryCardMeta = ({ story }: { story: Story }) => {
  return (
    <StoryCardMetaWrapper>
      {story.isShort ? <ShortsBadge /> : null}
      <Text as="span" textStyle={'small-text'} color={'textSecondary'}>
        {story.durationDescription}
      </Text>
      <SoundbitesStoryBadge story={story} />
    </StoryCardMetaWrapper>
  );
};
