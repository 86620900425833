import * as React from 'react';
import { ChapterBreadcrumbs } from '../../story/chapter-breadcrumbs';
import { ChapterButtons } from 'story/chapter-buttons';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { MarkSimple } from '@naan/primitives/text';
import { Story } from '@core/models/story-manager';
// import { Responsive } from '@naan/primitives/responsive';

// import {
//   columnGap,
//   thumbnailSize,
// } from 'components/volume-layouts/volume-card-layout';

const ChapterCardWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '$gray-25',
  padding: '12px 16px',
  borderRadius: 8,
  marginBottom: 16,
  marginTop: 16,
  gap: 20,

  '@medium': {
    padding: '16px 16px 16px 24px',
    borderRadius: 12,
  },

  '& > .chapter': {
    all: 'unset',
    flex: 1,
    '& > .part-chapter': {
      whiteSpace: 'nowrap',
      textStyle: 'small-text',
      color: '$textSecondary',
    },
    '& > .title-duration': {
      display: 'flex',
      cursor: 'pointer',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
      '& > .title': {
        textStyle: 'body-bold',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '1',
        overflow: 'hidden',
      },
      '& > .duration': {
        whiteSpace: 'nowrap',
        textStyle: 'small-text',
        color: '$textSecondary',
        display: 'none',
        '@medium': {
          display: 'block',
          marginTop: 1,
        },
      },
    },
  },

  '& > .crumbs': {
    all: 'unset',
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  '& > .actions': {
    display: 'none',
    '@medium': {
      display: 'block',
    },
  },
});

export const StoryChapterCard = observer(
  ({ story, viaDetail = false }: { story: Story; viaDetail: boolean }) => {
    const permalink = `../stories/${story.slug}`;
    const { progressMayBeNull } = story;
    const chapter = progressMayBeNull?.currentChapter;

    return (
      <ChapterCardWrapper>
        <Link to={permalink} className="chapter">
          <div className="part-chapter">{chapter.partChapterLabel}</div>
          <div className="title-duration">
            <div className="title">
              <MarkSimple source={chapter.title} />
            </div>
            <div className="duration">{chapter.clockLikeDuration}</div>
          </div>
        </Link>
        <Link className="crumbs" to={permalink}>
          <ChapterBreadcrumbs
            breadcrumbState={chapter.currentPoint?.iteration || 1}
            compact
          />
        </Link>
        <div className="actions">
          <ChapterButtons chapter={chapter} viaDetail={viaDetail} />
        </div>
      </ChapterCardWrapper>
    );
  }
);
