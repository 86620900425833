import * as React from 'react';
import { styled } from 'naan/stitches.config';

const Wrapper = styled('div', {
  $$backgroundColor: 'transparent',
  $$centerColumnGap: '$space$4',

  backgroundColor: '$$backgroundColor',
  display: 'grid',
  gridTemplateColumns:
    '1fr min($$maxWidth, calc( 100% - calc( $$centerColumnGap * 2) )) 1fr',

  '& > .center-column-inner': {
    gridColumn: '2',
    // background: 'rgba(0,0,0,.4)',
  },

  '@medium': {
    $$centerColumnGap: '$space$6',
  },

  variants: {
    isolate: {
      true: {
        isolation: 'isolate',
      },
      false: {
        isolation: 'auto',
      },
    },
  },

  defaultVariants: {
    isolate: true,
  },
});

Wrapper.displayName = 'CenterColumnLayout';

export const CenterColumnLayout: React.FC<{
  backgroundColor?: string;
  isolate?: boolean;
}> = ({ isolate, children, backgroundColor = 'transparent' }) => {
  return (
    <Wrapper
      isolate={isolate}
      css={{
        $$backgroundColor: backgroundColor,
      }}
    >
      <div className="center-column-inner">{children}</div>
    </Wrapper>
  );
};
