import { isObject } from 'lodash';
import Dayjs from 'dayjs';
// import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { createLogger } from 'app/logger';

import { CLASSROOM_FILTER_KEY_PREFIX } from 'core/lib/constants/vars';
import { normalizedEqual, sortBy } from 'utils/util';
import { Assignment, EMPTY_DATE } from './assignment';
import { Student } from './student';
import { License } from './license';
import { UserManager } from './user-manager';
import { ModelTreeNode } from 'ts-state-tree/tst-core';
import { Root } from '../root';
import { ApiInvoker } from 'core/services/api-invoker';
import { ValidationError } from 'core/lib/errors';
import { getBaseRoot } from '../app-root';
// import __ from '../lib/localization';

Dayjs.extend(localizedFormat);

const log = createLogger('classroom');

// const { ERROR } = alertLevels;

/**
 * Classroom
 *
 * for 'managedClassroom's:
 * the teacher's view of created classrooms and student progress
 *
 * for 'joinedClassroom's:
 * the students view of assigned episodes
 */

export class Classroom extends ModelTreeNode {
  static CLASS_NAME = 'Classroom' as const;

  static create(snapshot: any) {
    return super.create(Classroom, snapshot) as Classroom;
  }

  id: string = ''; //safeTypes.identifierDefaultBlank, // db key for now, might be changed to a GUID in the future
  label: string = ''; // class display name
  code: string = null; // classroom join code
  archived: boolean = false; // for now, always filtered out by the server
  studentCount: number = 0;
  trialStudentCount: number = 0;
  assignments: Assignment[] = [];
  students: Student[] = [];
  license: License = null; // classroom join code

  get root(): Root {
    return getBaseRoot(this);
  }

  get userManager(): UserManager {
    return this.root.userManager;
  }

  get apiInvoker(): ApiInvoker {
    return this.root.apiInvoker;
  }

  async archive() {
    log.info('archive');

    const result = await this.apiInvoker.put(
      `classrooms/${this.id}`,
      {
        archived: true,
      },
      { networkIndicator: true }
    );

    const {
      // message,
      // messageKey,
      accountData,
    } = result;

    await this.userManager.applyNewAccountData(accountData, {});
    return result;
  }

  async updateLabel(label: string) {
    log.info('updateLabel');
    label = label?.trim();
    // allow whitespace/casing change of existing label
    if (!normalizedEqual(label, this.label)) {
      this.userManager.validateClassroomLabelAvailable(label);
    }

    const result = await this.apiInvoker.put(
      `classrooms/${this.id}`,
      {
        label: label,
      },
      { networkIndicator: true }
    );
    const {
      // message,
      // messageKey,
      accountData,
    } = result;

    await this.userManager.applyNewAccountData(accountData, {});
    return result;
  }

  async createAssignmentWithProps(episodeSlug: string, props: any) {
    // todo: server api needs reworking

    const story = this.root.storyManager.story(episodeSlug);
    if (!story) {
      throw new ValidationError({
        key: 'episodeSlug',
        message: 'Invalid episodeSlug',
      });
    }

    log.info('createAssignmentWithProps');
    const slugs = this.assignments.map(a => a.episodeSlug);

    // let's first create the assignment
    if (!slugs.includes(episodeSlug)) {
      slugs.push(episodeSlug);
      await this.updateAssignmentList(slugs, {
        /// this fixes a nasty bug:
        /// if we reload account data, the whole MST tree gets rebuilt
        /// and `self` gets detached which means that operations below will fail.
        skipAccountReload: true,
      });
    }

    const hasProps = isObject(props) && Object.keys(props).length > 0;

    if (hasProps) {
      await this.updateAssignmentProps(episodeSlug, props);
    }

    // return {
    //   undo: () => {
    //     // TODO: write undo function
    //     // eslint-disable-next-line no-console
    //     console.log('UNDO not implemented');
    //   },
    // };
    return { message: 'Assigned' };
  }

  async removeAssignmentWithSlug(slug: string) {
    const newAssignmentsList = this.assignments
      .filter(a => a.episodeSlug !== slug)
      .map(a => a.episodeSlug);

    await this.updateAssignmentList(newAssignmentsList);
    // console.log(`result: ${JSON.stringify(result)}`);
    return { message: 'Assignment removed' };
  }

  async updateAssignmentList(slugs: string[], options: any = {}) {
    const { skipAccountReload = false } = options;

    log.info('updateAssignments');
    const result = await this.apiInvoker.put(
      `classrooms/${this.id}`,
      {
        episodeSlugs: slugs.join(','),
      },
      { networkIndicator: true }
    );
    const { /* message, */ /*messageKey,*/ accountData } = result;

    if (skipAccountReload === false) {
      await this.userManager.applyNewAccountData(accountData, {});
    }
    return result;
  }

  async updateAssignmentProps(episodeSlug: string, props: any) {
    log.info('updateAssignmentDetails');
    if (!episodeSlug) {
      throw new Error('episodeSlug required');
    }

    /// the server needs this to mark a duedate as empty
    if (props.dueDate === null) {
      props.dueDate = EMPTY_DATE;
    }

    const result = await this.apiInvoker.put(
      `classrooms/${this.id}/assignments/${episodeSlug}`,
      props,
      { networkIndicator: true }
    );

    const { /* message, */ /*messageKey,*/ accountData } = result;

    await this.userManager.applyNewAccountData(accountData, {});
    return result;
  }

  async dropStudent(email: string) {
    log.info('dropStudent');
    const result = await this.apiInvoker.post(
      `classrooms/${this.id}/drop_student`,
      {
        email,
      },
      { networkIndicator: true }
    );
    const { /*message, messageKey,*/ accountData } = result;

    await this.userManager.applyNewAccountData(accountData, {});
    return result;
  }

  // allows a student to remove themself from a class
  async drop() {
    log.info('drop');
    const result = await this.apiInvoker.post(
      `classrooms/${this.id}/drop`,
      {},
      { networkIndicator: true }
    );
    const { /*message, messageKey,*/ accountData } = result;

    await this.userManager.applyNewAccountData(accountData, {});
    return result;
  }

  async assignSeat(email: string) {
    log.info('assignSeat', email);

    const result = await this.apiInvoker.post(
      `classrooms/${this.id}/assign_seat`,
      {
        email,
      },
      { networkIndicator: true }
    );
    const { /*message, messageKey,*/ accountData } = result;

    await this.userManager.applyNewAccountData(accountData, {});
    return result;
  }

  get requiresFullAccess() {
    return (
      this.assignments.filter(assignment => {
        return assignment?.story?.trial === false;
      }).length > 0
    );
  }

  // if we have trial access students but non-trial assignments
  get needsAccessWarning() {
    return this.trialStudentCount > 0 && this.requiresFullAccess;
  }

  // removes any orphaned assignments
  get sanitizedAssignments() {
    return this.assignments.filter(assignment => assignment.story);
  }

  // list of all assignment stories
  get stories(): any /*Story*/[] {
    const stories = this.assignments
      .map(assignment => {
        return this.root.storyManager.storyForVolumeOrUnitSlug(
          assignment.episodeSlug
        );
      }) // todo: unit test to confirm graceful handling of unmatched episodeSlug
      .filter(story => story); // ignore any unmatched stories - @armando is there a better JS idiom for this?
    const unique = [...new Set(stories)];
    return unique;
  }

  get storiesWithDueDate(): any /*Story*/[] {
    const sortFn = sortBy('dueDate');
    const stories = this.stories.filter(
      assignedStory => assignedStory.assignment.dueDate !== null
    );
    return sortFn(stories);
  }

  // // list stories to feature on dashboard
  // get dashboardStories() {
  //   // Soonest date after today with an assignment
  //   const today = Dayjs(); // new Date()); // todo: confirm this is 'today'
  //   const soonestDueDateAfterToday = this.storiesWithDueDate.find(story => {
  //     const dueDate = Dayjs(story.assignment.dueDate);
  //     return dueDate.isAfter(today, 'date');
  //   })?.assignment.dueDate;

  //   // Most recent date (today or before) with an assignment
  //   let mostRecentDueDate = EMPTY_DATE;
  //   this.storiesWithDueDate.forEach(story => {
  //     const dueDate = Dayjs(story.assignment.dueDate);
  //     if (
  //       (dueDate.isBefore(today, 'date') || dueDate.isSame(today, 'date')) &&
  //       dueDate.isAfter(Dayjs(mostRecentDueDate))
  //     ) {
  //       mostRecentDueDate = story.assignment.dueDate;
  //     }
  //   });

  //   const assignments = this.storiesWithDueDate.filter(
  //     story =>
  //       story.assignment.dueDate === soonestDueDateAfterToday ||
  //       story.assignment.dueDate === mostRecentDueDate
  //   );
  //   return assignments.reverse();
  // }

  // the most relevant assignment, to be displayed on dashboard
  get featuredAssignment(): Assignment {
    // Soonest date after today with an assignment
    // const today = Dayjs(); // new Date()); // todo: confirm this is 'today'
    // const soonestDueDateAfterToday = this.storiesWithDueDate.find(story => {
    //   const dueDate = Dayjs(story.assignment.dueDate);
    //   return dueDate.isAfter(today, 'date');
    // })?.assignment.dueDate;

    var candidate: Assignment;
    const withDueDate = this.assignmentsByDueDate;
    if (withDueDate.length === 0) {
      // if none with a duedate, return end of array
      return this.assignments[this.assignments.length - 1];
    }

    // Soonest date after today with an assignment
    const today = Dayjs(); // new Date()); // todo: confirm this is 'today'
    candidate = withDueDate.find(assignment => {
      const dueDate = Dayjs(assignment.dueDate);
      return dueDate.isAfter(today, 'date');
    });
    if (candidate) {
      return candidate;
    }

    withDueDate.reverse();
    // should now be sorted descending and first in list should be most recent.
    return withDueDate[0];
  }

  get assignmentsByDueDate(): Assignment[] {
    const sortFn = sortBy('dueDate');
    const stories = this.assignments.filter(
      assignment => assignment.dueDate !== null
    );
    return sortFn(stories);
  }

  assignmentForSlug(slug: string) {
    return this.assignments.find(assignment => assignment.episodeSlug === slug);
  }

  get assignmentCount() {
    return this.assignments.length;
  }

  // dotColor = (assignments > 0 && students > 0) ? green500 : yellow500;
  get isActive() {
    return this.assignmentCount > 0 && this.studentCount > 0;
  }

  get isDeletable() {
    // return !!this.license ? this.license?.isExpired ?? false : true;
    return !this.license || this.license.isExpired;
  }

  // used to drive selected filter view
  get filterKey() {
    return `${CLASSROOM_FILTER_KEY_PREFIX}${this.id}`;
  }

  hasAssignment(episodeSlug: string) {
    return !!this.assignments.find(a => a.episodeSlug === episodeSlug);
  }
}
