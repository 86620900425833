import {
  ModelTreeNode,
  getParentOfType,
  volatile,
} from 'ts-state-tree/tst-core';
import Dayjs from 'dayjs';
import { StoryManager } from './story-manager';
import { Story } from './story';
import { SoundbiteEdition } from './soundbite-edition';
import { dayjsToIsoDate } from '@utils/date-utils';
import { getBaseRoot } from '../app-root';
import { Root } from '../root';

export class Soundbite extends ModelTreeNode {
  static CLASS_NAME = 'Soundbite' as const;

  static create(snapshot: any) {
    return super.create(Soundbite, snapshot) as Soundbite;
  }

  slug: string; // primary key
  volumeSlug: string;
  unitSlug: string;
  unitNumber: number;
  chapterPos: number;
  startMillis: number;
  dataUrl: string;
  audioUrl: string;

  // category: string;
  ingestVersion: number;
  ingestedAt: string;

  // // will be used by client to resolve "today's" soundbite from the catalog list
  // releaseDate: string; // iso8601 date

  title: string;
  prompt: string;

  @volatile
  _edition: SoundbiteEdition;

  // todo: support distinction between primary category and auxilary catagories
  category: string;

  // get root(): Root {
  //   return getBaseRoot(this);
  // }

  get storyManager(): StoryManager {
    const result = getParentOfType(this, StoryManager);
    return result;
  }

  get root(): Root {
    return getBaseRoot(this);
  }

  // remove - temp
  get soundbiteUserData(): unknown /* SoundbiteUserData*/ {
    // return this.root.userManager.userData.soundbiteUserData;
    return {};
  }

  // todo: consider preresolving these
  get edition(): SoundbiteEdition {
    if (this._edition === undefined) {
      this._edition = this.storyManager.editionForSoundbite(this);
    }
    return this._edition;
  }

  get story(): Story {
    return this.storyManager.story(this.volumeSlug);
  }

  // releaseDate: string; // iso8601 date
  get visibilityDayjs(): Dayjs.Dayjs {
    return this.edition?.visibilityDayjs;
  }

  get visible(): boolean {
    return this.storyManager.isTodaySameOrAfter(this.visibilityDayjs);
  }

  get featuredDayjs(): Dayjs.Dayjs {
    return this.edition?.featuredDayjs(this);
  }

  // iso8601 date, for now match original schema
  get releaseDate(): string {
    return dayjsToIsoDate(this.featuredDayjs);
  }

  get isFeatured(): boolean {
    // return this.releaseDate && isDateToday(this.releaseDate);
    return this.releaseDate === this.storyManager.currentDate;
  }

  // get engagement(): SoundbiteEngagement {
  //   return this.soundbiteUserData.engagementBySlug(this.slug, {
  //     ensure: false,
  //   });
  // }

  // get engagementStatus(): SoundbiteEngagementStatus {
  //   return (
  //     this.engagement?.engagementStatus || SoundbiteEngagementStatus.UNVISITED
  //   );
  // }

  get completed(): boolean {
    return !!this.root.userManager.userData.soundbiteCompleted(this.slug);
  }

  get completedDayjs(): Dayjs.Dayjs | null {
    return this.root.userManager.userData.soundbiteCompletedDayjs(this.slug);
  }

  recordCompletion() {
    this.root.userManager.userData.recordSoundbiteCompletion(this.slug);
  }
}
