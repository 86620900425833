// not sure if there's a way to re-export the default export this way
// export * from './localization__LOCALIZATION_MODE__';

// note, eithier form of the tsc level verified import will work
// import { store } from './localization-__LOCALIZATION_MODE__';
import { store } from './localization-__LOCALIZATION_DISABLED__';

export const {
  getLocale,
  setLocale,
  onLocaleChange,
  addTranslations,
  getAvailableTranslations,
  disabled: localizationDisabled,
  translateWithDefault: __,
} = store;

// todo: migrate away from the default export usage
export default __;
