/* eslint-disable no-unused-vars */

import {
  observable,
  isObservable,
  $mobx,
  keys,
  runInAction,
  entries,
  isBoxedObservable,
  makeObservable,
  isObservableArray,
  isObservableMap,
  flow,
} from 'mobx';

// conventionally used to cast model initialization data
export function snap(a: any): any {
  return a as any;
}

export enum TSTType {
  MODEL = 'model',
  UNPROCESSED_MODEL = 'unprocessed_model',
  ARRAY = 'array',
  MAP = 'map',
  PRIMITIVE = 'primitive',
  OBJECT = 'object',
}

export function applySnapshot(dst: any, snapshot: any) {
  // TODO handle case where parts of snapshot ares nodes not plain json
  // and validate
  runInAction(() => {
    __applySnapshot(dst, snapshot, null, null);
  });
}

export function getSnapshot(obj: any): any {
  const treenode = getTreenode(obj);
  const type = treenode ? treenode.tstType : null;

  if (type === TSTType.MODEL) {
    return getModelSnapshot(obj);
  } else if (type === TSTType.ARRAY) {
    return getArraySnapshot(obj);
  } else if (type === TSTType.MAP) {
    return getMapSnapshot(obj);
  }
  return obj;
}

export function getParent(val: any): any {
  const treenode = getTreenode(val);
  if (!treenode) {
    throw Error('getParent - called on non part of tree.');
  }
  if (treenode?.parent) {
    return treenode.parent.value;
  } else {
    // console.log(`getParent - null`);
    return null;
  }
}

export function getRoot(val: any): any {
  let treenode = getTreenode(val);
  if (!treenode) {
    throw Error('getRoot - called on non part of tree.');
  }
  while (treenode.parent) {
    treenode = treenode.parent;
  }
  return treenode.value;
}

export function getParentOfType(val: any, type: any): any {
  let treenode = getTreenode(val);
  if (!treenode) {
    throw Error('getParentOfType - called on non part of tree.');
  }
  while (treenode.parent) {
    treenode = treenode.parent;
    if (treenode.typeInfo.classConstructor === type) {
      return treenode.value;
    }
  }
  return null;
}

export const volatile = (target: Object, property: string) => {
  getVolatileSetFromConstructor(target.constructor).add(property);
};

export const frozen = (target: Object, property: string) => {
  getFrozenSetFromConstructor(target.constructor).add(property);
};

export const identifier = (target: Object, property: string) => {
  getAnnotationRecord(target.constructor).identifierProperty = property;
};

export interface TSTMap<K = any, V = any> extends Map<K, V> {
  __$$item: V;
}

export interface TSTStringMap<V = any> extends TSTMap<string, V> {}

export const $treenode = Symbol('TREENODE');

export interface TSTTypeDefinition {
  definitions?: { [index in string]: TSTTypeDefinition }; // TODO think only in root not individual definitions
  $ref?: string;
  properties?: { [index in string]: TSTTypeDefinition };
  items?: TSTTypeDefinition;
  required?: string[];
  type?: string;

  // model node info
  classConstructor?: any;
  createModel?: any; // (parent: ITreeNode) => any;
  computedMobxAnnotations?: any;
  computedThisBindingKeyList?: string[];

  modelPersistentPropertyTypes?: { [index in string]: TSTTypeDefinition }; // this one is a reference
  defaultSnapshots?: { [index in string]: any };
  tstType?: TSTType;

  // map and array node info
  itemType?: TSTTypeDefinition;
}

function validTSTType(type: TSTTypeDefinition) {
  return !!(type && type.type && type.tstType);
}

type TSTTypeProperties = {
  [x: string]: TSTTypeDefinition;
};

export interface ITreeNode {
  typeInfo?: TSTTypeDefinition;
  parent?: ITreeNode;
  value?: any;
  tstType?: TSTType;
}

export class BasicNode implements ITreeNode {
  typeInfo: TSTTypeDefinition;
  parent: ITreeNode;
  value: any;
  tstType: TSTType;
}

// TODO or extends BasicNode??
export class ModelTreeNode implements ITreeNode {
  static typeInfo?: TSTTypeDefinition;
  typeInfo?: TSTTypeDefinition;
  parent?: ITreeNode;
  value?: any;
  tstType?: TSTType = TSTType.MODEL;

  [$treenode]?: any;

  static create($class: any, snapshot: any): any {
    if (!$class.typeInfo) {
      throw Error(`[tst] schema not bound for ${$class.name}`);
    }
    const instance = new $class.typeInfo.classConstructor();
    instance.typeInfo = this.typeInfo;
    instance.initModel();
    // console.log(
    //   `create(${$class.name}), snapshot: ${JSON.stringify(snapshot)}`
    // );

    instance.init();
    __applyModelSnapshot(instance, snapshot);
    instance.afterCreate();
    return instance;
  }

  constructor() {
    this.value = this;
    this[$treenode] = this;
  }

  // lifecycle hook - before initial snapshot applied
  init() {}

  // lifecycle hook - after initial snapshot applied
  afterCreate() {}

  initModel() {
    this.snapshotDefaults();
    this.applySnapshots();
    this.initMobx();
    this.initTSTNodes();
  }

  snapshotDefaults() {
    // init the default snapshots for properties the type if not done already
    let snapshots = this.typeInfo.defaultSnapshots;
    if (snapshots) {
      return;
    }
    snapshots = {};
    this.typeInfo.defaultSnapshots = snapshots;

    if (!this.typeInfo) {
      throw Error(
        `snapshotDefaults - typeInfo misssing for ${JSON.stringify(this)}`
      );
    }
    const persistentProperties = getModelPersistentProperties(this.typeInfo);
    const self = this as any;
    for (const prop of Object.keys(persistentProperties)) {
      const value = self[prop];
      if (
        typeof value === 'object' &&
        !(isObjectLiteral(value) || Array.isArray(value))
      ) {
        continue;
      }
      snapshots[prop] = value;
    }
  }

  getDefault(property: string) {
    // should this be function takeing type like other stuff for consistency at the moment?
    return this.typeInfo.defaultSnapshots[property];
  }

  applySnapshots() {
    // not volatile, no frozen which are as is, not primitive which are as is, not array which mobx will enhance
    const persistentProperties = getModelPersistentProperties(this.typeInfo);
    const frozenSet = getFrozenSet(this.typeInfo);
    const self = this as any;
    for (const [prop, propType] of Object.entries(persistentProperties)) {
      if (!frozenSet.has(prop)) {
        const value = self[prop];
        if (!isObjectLiteral(value)) {
          continue;
        }
        if (propType.tstType === TSTType.MODEL) {
          const model = propType.createModel(this);
          __applyModelSnapshot(model, value);
          self[prop] = model;
        } else if (propType.tstType === TSTType.MAP) {
          const map = createEmptyTSTMap(this, propType);
          __applyMapSnapshot(map, value);
          self[prop] = map;
        }
      }
    }
  }

  get snapshot(): any /*todo: type this*/ {
    return getSnapshot(this);
  }

  get stringify(): string {
    return JSON.stringify(this.snapshot);
  }

  initTSTNodes() {
    const self = this as any;
    const persistentProperties = getModelPersistentProperties(this.typeInfo);
    const frozenSet = getFrozenSet(this.typeInfo);
    for (const [key, box] of self[$mobx].values_.entries()) {
      const propTypeInfo = persistentProperties[key];
      if (propTypeInfo && isBoxedObservable(box)) {
        if (!frozenSet.has(key)) {
          const value = box.get();
          // TODO check for not observable.ref, which might cause problems??
          if (isObservable(value)) {
            if (propTypeInfo.tstType === TSTType.OBJECT) {
              throw new Error(
                'initTSTNodes - cannot process observable value not a model, array, map'
              );
            }
            let treenode = getTreenode(value);
            if (!treenode) {
              treenode = new BasicNode();
              value[$treenode] = treenode;
              treenode.typeInfo = propTypeInfo;
              if (!validTSTType(treenode.typeInfo)) {
                throw Error('initTSTNodes - invalid typeInfo');
              }
              treenode.value = value;
              treenode.tstType = getTstType(treenode.typeInfo);
              if (isObservableArray(value)) {
                const admin = (value as any)[$mobx];
                admin.enhancer_ = treeEnhancerFactory(
                  treenode,
                  admin.enhancer_
                );
              } else if (isObservableMap(value)) {
                value.enhancer_ = treeEnhancerFactory(
                  treenode,
                  value.enhancer_
                );
              }
            }
            treenode.parent = this;
          }
          const vbox = box as any;
          vbox.enhancer = treeEnhancerFactory(this, vbox.enhancer); // TODO use 3 param enhancer instead?
        }
      }
    }
  }

  computedMobxAnnotations() {
    if (this.typeInfo.computedMobxAnnotations) {
      return this.typeInfo.computedMobxAnnotations;
    }

    const self = this as any;
    const computedAnnotations = {} as any;
    const annotationsSymbol = getMobxStoredAnnotationSymbol();
    let decoratorAnnotations = self[annotationsSymbol] || {};
    decoratorAnnotations = { ...decoratorAnnotations };
    const frozenSet = getFrozenSet(this.typeInfo);
    const volatileSet = getVolatileSet(this.typeInfo);
    const persistentSet = new Set(
      Object.keys(getModelPersistentProperties(this.typeInfo))
    );
    const allPropsSet = new Set(Object.getOwnPropertyNames(this));
    // necessary to iterate over functions
    for (const k of Object.getOwnPropertyNames(Object.getPrototypeOf(this))) {
      if (k === 'constructor') {
        continue;
      }
      allPropsSet.add(k);
    }
    const allProps = allPropsSet.values();
    for (const prop of allProps) {
      if (volatileSet.has(prop)) {
        if (decoratorAnnotations[prop]) {
          computedAnnotations[prop] = decoratorAnnotations[prop];
        } else {
          computedAnnotations[prop] = observable;
        }
        continue;
      }
      if (frozenSet.has(prop)) {
        if (decoratorAnnotations[prop]) {
          computedAnnotations[prop] = decoratorAnnotations[prop];
        } else {
          computedAnnotations[prop] = observable.ref;
        }
        continue;
      }
      if (persistentSet.has(prop)) {
        if (decoratorAnnotations[prop]) {
          computedAnnotations[prop] = decoratorAnnotations[prop];
        } else {
          computedAnnotations[prop] = observable;
        }
        continue;
      }
      if (decoratorAnnotations[prop]) {
        computedAnnotations[prop] = decoratorAnnotations[prop];
      } else {
        /// if we run the isGenerator check on a getter prop, the getter will be called
        /// which will cause unexpected bugs because the tree will not be ready at that point
        /// so, propdesc will return null for getter props. I don't know if this is the best approach
        /// but it works for now.
        const propdesc = Object.getOwnPropertyDescriptor(this, prop);
        /// Also, are we even using generators anywhere?
        if (propdesc && isGenerator(self[prop])) {
          computedAnnotations[prop] = flow;
        }
      }
    }
    this.typeInfo.computedMobxAnnotations = computedAnnotations;
    return computedAnnotations;
  }

  computedThisBindingKeyList(annotations: any = {}) {
    if (this.typeInfo.computedThisBindingKeyList) {
      return this.typeInfo.computedThisBindingKeyList;
    }

    const thisBindingsKeyList: (any | string)[] = [];

    const allProps = new Set<[any, string | symbol]>();
    let obj = this.constructor.prototype as any;

    do {
      for (const key of Reflect.ownKeys(obj)) {
        allProps.add([obj, key]);
      }
    } while ((obj = Reflect.getPrototypeOf(obj)) && obj !== Object.prototype);

    const excludeProps = [
      'init',
      'afterCreate',
      'initModel',
      'snaphotDefaults',
      'getDefault',
      'applySnapshots',
      'initTSTNodes',
      'computedMobxAnnotations',
      'computedThisBindingKeyList',
      'initMobx',
    ] as (any | string)[];

    for (const [obj, key] of allProps) {
      if (
        key === 'constructor' ||
        excludeProps.includes(key) ||
        annotations[key]
      ) {
        continue;
      }

      const descriptor = Reflect.getOwnPropertyDescriptor(obj, key);
      if (descriptor && typeof descriptor.value === 'function') {
        thisBindingsKeyList.push(key);
      }
    }

    this.typeInfo.computedThisBindingKeyList = thisBindingsKeyList;

    return this.typeInfo.computedThisBindingKeyList;
  }

  initMobx() {
    const ObjAdmin = getMobxObjectAdminConstructor();
    const name = 'ObservableObject'; // TODO put name on type and use here

    const adm = new ObjAdmin(this, new Map(), String(name), undefined);

    addHiddenProp(this, $mobx, adm);
    const annotations = this.computedMobxAnnotations();

    // autobind, so function references can be safely passed and used
    const thisBindingsKeyList = this.computedThisBindingKeyList(annotations);
    const self = this as any;
    for (const key of thisBindingsKeyList) {
      self[key] = self[key].bind(self);
    }

    const annotationsSymbol = getMobxStoredAnnotationSymbol();
    // mask this from lookup on prototype, workaround for now
    addHiddenProp(this, annotationsSymbol, {});

    for (const [prop, annotation] of Object.entries(annotations)) {
      adm.make_(prop, annotation);
    }
  }
}

function isModelType(type: TSTTypeDefinition) {
  const properties = type.properties;
  if (!properties) {
    return false;
  }
  return !!(
    properties.parent &&
    properties.value &&
    properties.tstType &&
    properties.typeInfo
  );
}

export class Bindery {
  schema: TSTTypeDefinition = { definitions: {} };
  models: Set<TSTTypeDefinition> = new Set();

  mergeSchemaDefinitions(schema: TSTTypeDefinition) {
    if (schema.definitions) {
      this.schema.definitions = {
        ...this.schema.definitions,
        ...schema.definitions,
      };
    }
  }

  bindWithName(ctor: any, name: string) {
    const type = this.schema.definitions[name];
    if (!type) {
      throw Error(
        `[tst] schema definition not found for '${name}' (${ctor?.name})`
      );
    }
    type.classConstructor = ctor;
    type.createModel = (parent: ITreeNode) => {
      const instance = new ctor();
      instance.typeInfo = type;
      instance.parent = parent;
      instance.initModel();
      instance.init();
      return instance;
    };
    (ctor as any).typeInfo = this.schema.definitions[name];
    type.tstType = TSTType.UNPROCESSED_MODEL;
    getModelPersistentProperties(type);
    this.models.add(type);
  }

  bind<T extends { CLASS_NAME: string; name: string }>(ctor: T) {
    const name = ctor.CLASS_NAME;
    if (typeof name === 'string') {
      this.bindWithName(ctor, name);
    } else {
      throw Error(`[tst] CLASS_NAME not defined for ${String(ctor.name)}`);
    }
  }

  resolvedTypeInfo(info: TSTTypeDefinition): TSTTypeDefinition {
    const prefix = '#/definitions/';
    if (info.$ref) {
      if (info.$ref.startsWith(prefix)) {
        return this.schema.definitions[info.$ref.slice(prefix.length)];
      }
    }
    return info;
  }

  compileBindings() {
    const processing = [...this.models.values()];

    const isProcessed = (type: TSTTypeDefinition) => {
      return type.tstType === TSTType.UNPROCESSED_MODEL
        ? false
        : !!type.tstType;
    };

    const pushIfUnprocessed = (type: TSTTypeDefinition) => {
      if (!isProcessed(type)) {
        processing.push(type);
      }
    };

    const processIfNeeded = (type: TSTTypeDefinition) => {
      if (isProcessed(type)) {
        return;
      }
      if (type.tstType === TSTType.UNPROCESSED_MODEL) {
        type.tstType = TSTType.MODEL;
        const properties = getModelPersistentProperties(type);
        for (const propType of Object.values(properties)) {
          pushIfUnprocessed(propType);
        }
        return;
      }

      if (type.type === 'array') {
        type.tstType = TSTType.ARRAY;
        type.itemType = this.resolvedTypeInfo(type.items) as any; // TODO deal with typing
        pushIfUnprocessed(type.itemType);
        return;
      }

      if (type.type === 'object') {
        const mapItemType = type?.properties?.__$$item;
        if (mapItemType) {
          type.tstType = TSTType.MAP;
          type.itemType = this.resolvedTypeInfo(mapItemType) as any; // TODO deal with typing
          pushIfUnprocessed(type.itemType);
          return;
        }
        if (isModelType(type)) {
          // todo: any way to provide a more useful error message?
          throw Error(`unbound model type: ${JSON.stringify(type.properties)}`);
        }
        type.tstType = TSTType.OBJECT;
        return;
      }
      type.tstType = TSTType.PRIMITIVE;
    };

    while (processing.length) {
      processIfNeeded(processing.pop());
    }
  }
}

export const bindery = new Bindery();

class AnnotationRecord {
  frozen = new Set<string>([]);
  volatile = new Set<string>([]);
  identifierProperty: string = null;
}

const excludePropertySet = new Set(['parent', 'tstType', 'value', 'typeInfo']);

const propertyAnnotations = new Map<Function, AnnotationRecord>();

function getAnnotationRecord(ctor: Function): AnnotationRecord {
  if (typeof ctor !== 'function') {
    throw Error('getAnnotationRecord - invalid ctor passed.');
  }
  let record = propertyAnnotations.get(ctor);
  if (!record) {
    record = new AnnotationRecord();
    propertyAnnotations.set(ctor, record);
  }
  return record;
}

function getFrozenSetFromConstructor(ctor: Function) {
  return getAnnotationRecord(ctor).frozen;
}

function getFrozenSet(type: TSTTypeDefinition): Set<string> {
  return getFrozenSetFromConstructor(type.classConstructor);
}

function getVolatileSetFromConstructor(ctor: Function) {
  return getAnnotationRecord(ctor).volatile;
}

function getVolatileSet(type: TSTTypeDefinition): Set<string> {
  return getVolatileSetFromConstructor(type.classConstructor);
}

function getIdentifierFromConstructor(ctor: Function) {
  return getAnnotationRecord(ctor).identifierProperty;
}

function getIdentifierProperty(type: TSTTypeDefinition): string {
  return getIdentifierFromConstructor(type.classConstructor);
}

function getItemType(type: TSTTypeDefinition): TSTTypeDefinition {
  return type.itemType;
}

function getTstType(type: TSTTypeDefinition) {
  return type.tstType;
}

function getModelPersistentProperties(
  type: TSTTypeDefinition
): TSTTypeProperties {
  if (!type) {
    throw Error(`getModelPersistentProperties - type required`);
  }
  if (
    !(
      type.tstType === TSTType.MODEL ||
      type.tstType === TSTType.UNPROCESSED_MODEL
    )
  ) {
    throw Error('getModelPersistentProperties - non model type passed.');
  }
  if (!type.properties) {
    // note, this condition happened when Root was aliased and the wrong root type was used
    // export type Root = BaseRoot;

    throw Error(
      `getModelPersistentProperties - properties missing for type: ${JSON.stringify(
        type
      )}`
    );
  }

  let result = type.modelPersistentPropertyTypes; // TODO type is not exactly right

  if (!result) {
    result = {} as any;
    const volatileSet = getVolatileSet(type);
    for (const [prop, propType] of Object.entries(type.properties)) {
      if (volatileSet.has(prop) || excludePropertySet.has(prop)) {
        continue;
      }
      if (prop.startsWith('__@$treenode')) {
        continue;
      }
      result[prop] = bindery.resolvedTypeInfo(propType);
    }
    type.modelPersistentPropertyTypes = result;
  }
  return result;
}

function isContainer(val: any) {
  return typeof val === 'object';
}

function isObjectLiteral(obj: any) {
  if (typeof obj !== 'object' || !obj) {
    return false;
  }

  return Object.getPrototypeOf(obj) === Object.prototype;
}

function getArraySnapshot(arr: any[]): any[] {
  const result = [];
  const rt = typeof arr;
  if (
    !(
      arr === null ||
      rt === 'undefined' ||
      Array.isArray(arr) ||
      isObservableArray(arr)
    )
  ) {
    throw Error('getArraySnapshot - called with incompatible type.');
  }
  for (const item of arr) {
    // TODO optimize
    result.push(getSnapshot(item));
  }
  return result;
}

function getMapSnapshot(map: Map<string, any>): object {
  const result = {} as any;
  const rt = typeof map;
  if (
    !(
      map === null ||
      rt === 'undefined' ||
      map instanceof Map ||
      isObservableMap(map)
    )
  ) {
    throw Error('getMapSnapshot - called with incompatible type.');
  }
  for (const [key, value] of entries(map)) {
    result[key] = getSnapshot(value);
  }
  return result;
}

function getModelSnapshot(mod: any): object {
  const result = {} as any;
  const persistentProps = getModelPersistentProperties(mod.typeInfo);
  for (const prop of Object.keys(persistentProps)) {
    // TODO only save if different from default snapshot per type or property record
    const value = getSnapshot(mod[prop]);
    if (value !== undefined) {
      result[prop] = getSnapshot(mod[prop]);
    }
  }
  return result;
}

function getTreenode(val: any): ITreeNode {
  if (val === null || typeof val === 'undefined') {
    return null;
  }
  return val[$treenode];
}

const treeEnhancerFactory = (parent: ITreeNode, currentEnhancer: Function) => {
  return (newV: any, oldV: any) => {
    let treenode = getTreenode(newV);
    if (treenode) {
      treenode.parent = parent;
    }
    return currentEnhancer(newV, oldV);
  };
};

function createEmptyTSTArray(
  parent: ITreeNode,
  type: TSTTypeDefinition
): any[] {
  const treenode = new BasicNode();
  if (!type) {
    throw Error('createEmptyTSTArray - called without type.');
  }
  if (type.tstType !== TSTType.ARRAY) {
    throw Error('createEmptyTSTArray - called with non array type info.');
  }
  treenode.parent = parent;
  treenode.typeInfo = type;
  treenode.tstType = TSTType.ARRAY;
  const arr = observable.array([]);
  const val = arr as any;
  val[$treenode] = treenode;
  const admin = val[$mobx];
  admin.enhancer_ = treeEnhancerFactory(treenode, admin.enhancer_); // TODO optimize not use if primitive item type?
  treenode.value = val;
  return arr;
}

function createEmptyTSTMap(parent: ITreeNode, type: TSTTypeDefinition): any {
  const treenode = new BasicNode();
  if (!type) {
    throw Error('createEmptyTSTMap - called without type.');
  }
  if (type.tstType !== TSTType.MAP) {
    throw Error('createEmptyTSTMap - called with non map type info.');
  }
  treenode.parent = parent;
  treenode.typeInfo = type;
  treenode.tstType = TSTType.MAP;
  const map = observable.map({});
  const val = map as any;
  val[$treenode] = treenode;
  val.enhancer_ = treeEnhancerFactory(treenode, val.enhancer_); // TODO optimize not use if primitive item type?
  treenode.value = val;
  return map;
}

function __applyArraySnapshot(dst: any, snapshot: any[]): any[] {
  const adm = dst[$mobx];
  const treenode = getTreenode(dst);
  const dstType = treenode.typeInfo;
  const itemType = getItemType(dstType);
  if (!itemType) {
    // this was failing too fast and obscuring the more useful 'unbound model type' error
    // eslint-disable-next-line no-console
    console.log(
      `WARNING missing itemType for dstType: ${JSON.stringify(
        dstType
      )} - short circuiting applyArraySnapshot`
    );
    return dst;
  }
  const identifier =
    itemType.tstType === TSTType.MODEL ? getIdentifierProperty(itemType) : null;
  const reconcile = !!identifier;
  const existingMap = new Map<string, any>();
  const values: any[] = adm.values_;
  const working: any[] = [];

  if (reconcile) {
    for (const val of values) {
      existingMap.set(val[identifier], val);
    }
  }

  const rt = typeof snapshot;
  if (!(snapshot === null || rt === 'undefined' || Array.isArray(snapshot))) {
    throw Error('__applyArraySnapshot - called with incompatible snapshot.');
  }
  for (const itemSnapshot of snapshot) {
    if (!itemSnapshot) {
      // eslint-disable-next-line no-console
      console.error('unexpectedly missing array item');
      continue;
    }
    if (reconcile) {
      const itemId = itemSnapshot[identifier];
      if (itemId) {
        const existing = existingMap.get(itemId);
        if (existing) {
          __applyModelSnapshot(existing, itemSnapshot);
          working.push(existing);
          continue;
        }
      }
    }
    const item = __applySnapshot(null, itemSnapshot, null, itemType);
    const itemTreenode = getTreenode(item);
    if (itemTreenode) {
      itemTreenode.parent = treenode;
    }
    working.push(item);
  }
  // TODO assuming values in TST node array never need enhancing to mobx value, array enhancer is not run, verify correct
  values.splice(0, values.length, ...working);
  if (!adm?.atom_) {
    throw Error(
      '__applyArraySnapshot - called with target not mobx observable array.'
    );
  }
  adm.lastKnownLength_ = values.length;
  adm.atom_.reportChanged();
  return dst;
}

function __applyMapSnapshot(dst: any, snapshot: any): any {
  const rt = typeof snapshot;
  if (!(snapshot === null || rt === 'undefined' || rt === 'object')) {
    throw Error('applyMapSnapshot - called with incompatible snapshot type.');
  }
  const treenode = getTreenode(dst);
  if (!treenode) {
    throw Error('applyMapSnapshot - target not part of tree');
  }
  const dstType = treenode.typeInfo;
  if (!validTSTType(dstType)) {
    throw Error('applyMapSnapshot - typeInfo not valid');
  }
  if (Array.isArray(snapshot)) {
    throw Error('applyMapSnapshot - called with array data');
  }
  const itemType = getItemType(dstType);
  if (!validTSTType(itemType)) {
    throw Error('applyMapSnapshot - called with non map type');
  }
  const identifier =
    itemType.tstType === TSTType.MODEL ? getIdentifierProperty(itemType) : null;
  const reconcile = !!identifier;
  const dstKeys = keys(dst);
  const leftKeys = new Set<string>(dstKeys as string[]);

  // when loading the catalog data, we seemed to sometimes receive Map's instead of
  // objects when applying the snapshot data
  const entries =
    // is there a better way to handle Map's vs objects?
    typeof snapshot.entries === 'function' // check for a Map instead of an object
      ? (Array.from(snapshot.entries()) as [string, unknown][])
      : Object.entries(snapshot);

  for (const [key, value] of entries) {
    const itemSnapshot = value as any;
    const existing = dst.get(key);
    if (reconcile) {
      const itemId = itemSnapshot[identifier];
      if (existing !== undefined && existing[identifier] === itemId) {
        __applyModelSnapshot(existing, itemSnapshot);
        leftKeys.delete(key);
        continue;
      }
    }
    const item = __applySnapshot(null, itemSnapshot, null, itemType);
    const itemTreenode = getTreenode(item);
    if (itemTreenode) {
      itemTreenode.parent = treenode;
    }
    dst.set(key, item);
    if (existing !== undefined) {
      leftKeys.delete(key);
    }
  }

  for (const key of leftKeys.values()) {
    dst.delete(key);
  }

  return dst;
}

function __applyModelSnapshot(dst: any, snapshot: any): any {
  const treenode = getTreenode(dst);
  const properties = getModelPersistentProperties(treenode.typeInfo);
  for (const prop of Object.keys(properties)) {
    // console.log(`prop: ${prop}, value: ${JSON.stringify(snapshot[prop])}`);
    // TODO exclude volatile properties, may need to be known before instance is created
    __applySnapshot(dst, snapshot[prop], prop, null);
  }
  return dst;
}

function __applySnapshot(
  dst: any, // can be model, tst array, tst map, null
  snapshot: any,
  dstProperty: string,
  dstType: TSTTypeDefinition
): any {
  let dstValue: any;
  const treenode = getTreenode(dst);
  if (!dstType && !treenode) {
    throw Error(
      `applySnapshot - missing treenode for: ${dst}, data: ${JSON.stringify(
        snap
      )}`
    );
  }
  dstType = dstType || treenode.typeInfo;
  const frozenProperty = dstProperty
    ? getFrozenSet(dstType).has(dstProperty)
    : false;

  let srcValue = snapshot;

  if (
    (srcValue === null || typeof srcValue === 'undefined') &&
    dst &&
    dstProperty
  ) {
    srcValue = dst.getDefault(dstProperty);
  }

  if (frozenProperty || !isContainer(srcValue)) {
    dstValue = srcValue;
  } else {
    if (dst && dstProperty) {
      dstValue = dst[dstProperty];
    } else {
      dstValue = dst;
    }
  }

  if (dstValue !== srcValue) {
    // not frozen property and srcValue must be container type either array or object
    // TODO need use func to get property type, cache might be empty
    // console.log(`dstProp: ${dstProperty}`);
    const valueType = dstProperty
      ? dstType.modelPersistentPropertyTypes[dstProperty]
      : dstType;
    if (Array.isArray(srcValue)) {
      if (dstValue) {
        __applyArraySnapshot(dstValue, srcValue);
      } else {
        // create dstValue as empty array node
        dstValue = createEmptyTSTArray(treenode, valueType);
        __applyArraySnapshot(dstValue, srcValue);
      }
    } else {
      if (dstValue) {
        const valueTreenode = getTreenode(dstValue);
        if (!valueTreenode) {
          // simple object case
          dstValue = srcValue;
        } else if (valueTreenode.tstType === TSTType.MODEL) {
          __applyModelSnapshot(dstValue, srcValue);
        } else if (valueTreenode.tstType === TSTType.MAP) {
          __applyMapSnapshot(dstValue, srcValue);
        } else {
          // TODO error
        }
      } else {
        // need to use type info to decide what to do
        if (valueType.tstType === TSTType.MODEL) {
          dstValue = valueType.createModel(treenode);
          __applyModelSnapshot(dstValue, srcValue);
        } else if (valueType.tstType === TSTType.MAP) {
          dstValue = createEmptyTSTMap(treenode, valueType);
          __applyMapSnapshot(dstValue, srcValue);
        } else if (valueType.tstType === TSTType.OBJECT) {
          dstValue = srcValue;
        } else {
          // TODO error
        }
      }
    }
  }

  if (dstProperty && dst && dst[dstProperty] !== dstValue) {
    dst[dstProperty] = dstValue;
  }
  return dstValue;
}

const defineProperty = Object.defineProperty;

function addHiddenProp(object: any, propName: PropertyKey, value: any) {
  defineProperty(object, propName, {
    enumerable: false,
    writable: true,
    configurable: true,
    value,
  });
}

class MobxDummyClass {
  @observable a = 'dummy';

  constructor() {
    makeObservable(this);
  }
}

let mobxObjectAdminConstructor: any = null;
let mobxStoredAnnotationSymbol: any = null;

function getMobxObjectAdminConstructor() {
  if (mobxObjectAdminConstructor) {
    return mobxObjectAdminConstructor;
  }

  const dummy = new MobxDummyClass() as any;
  mobxObjectAdminConstructor = dummy[$mobx].constructor;
  return mobxObjectAdminConstructor;
}

function getMobxStoredAnnotationSymbol() {
  if (mobxStoredAnnotationSymbol) {
    return mobxStoredAnnotationSymbol;
  }

  const dummy = new MobxDummyClass() as any;
  for (const sym of Object.getOwnPropertySymbols(dummy)) {
    if (sym.toString().includes('mobx-stored-annotations')) {
      mobxStoredAnnotationSymbol = sym;
    }
  }
  return mobxStoredAnnotationSymbol;
}

export function isGenerator(obj: any): boolean {
  const constructor = obj?.constructor;
  if (!constructor) return false;
  if (
    'GeneratorFunction' === constructor.name ||
    'GeneratorFunction' === constructor.displayName
  )
    return true;

  return false;
}
