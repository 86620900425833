/// FIXME: did the bare minimum to get this compiling,
/// but this is the kind of thing we have to properly design.
/// and maybe let the client decide how to error handle.
// import { IAnyStateTreeNode } from 'mobx-state-tree';
import { createLogger } from 'app/logger';
import { isDiskSpaceError, NetworkError, fail, alertLevels } from './errors';
import { AppFactory } from 'app/app-factory';
import { Root } from 'core/models/root';

import __ from './localization';
import { bugsnagNotify } from '@app/notification-service';

const log = createLogger('mst-error-handler');

const { ERROR, WARN } = alertLevels;

// type CustomError = Error &
//   NetworkError & {
//     key: string;
//     message?: string;
//     alertLevel?: string;
//     report?: boolean;
//     expected?: boolean;
//     userMessage?: string;
//   };

// 'unexpectedAlertLevel' is applied to non validation or network errors before sending along to the main error handler
export const safelyHandleError = (
  branch: any, //?? IAnyStateTreeNode,
  error: any,
  options: {
    ignoreNetworkErrors?: boolean;
    unexpectedAlertLevel?: keyof typeof alertLevels;
  } = {}
) => {
  const root: Root = AppFactory.root;
  const { ignoreNetworkErrors = false, unexpectedAlertLevel = WARN } = options;
  // const { createLogger = () => {}, notifications = {} } = getEnv(root);
  // const { alertError = () => {} } = notifications;
  // const { createLogger = () => {} } = getEnv(root);

  // const log = createLogger('mst-error-handler');

  // wrap a thrown string (i.e. 'crash3' coupon code test)
  if (!(error instanceof Error)) {
    error = new Error(error);
  }

  if (isDiskSpaceError(error)) {
    // todo: cleanup redundancy with errors.js handling
    log.info('Disk error');
    error.alertLevel = ERROR;
    // not really our fault, but would be curious to see stats
    error.report = true;
    error.expected = true;

    // fail(error); // let react handle it
  }

  // if (error instanceof ValidationError) {
  //   if (root) {
  //     root.setValidationError(error);
  //     return;
  //   } else {
  //     log.error(`unexpectedly missing root with error: ${error}`);
  //     // error.alertLevel = ERROR; // validation errors are always intended to be end-user seen
  //     // error.expected = true; // not really expected, but not need to prefix the message
  //     // error.report = true;
  //     // fail(error); // let react handle it
  //   }
  // }

  if (error.key === 'account_closed') {
    // alertError(error.message);
    error.alertLevel = ERROR; // force to be visible
    error.expected = true;
    if (root) {
      root.userManager.reset().catch(bugsnagNotify);
    } else {
      // not sure what to do in this edge case.
    }
    error.report = false;
    // return;
  }

  // todo: will this string match fail on non-english locale devices? is it even needed?
  if (
    !(error instanceof NetworkError) &&
    error.message?.includes('Network request failed')
  ) {
    log.warn(`wrapping string matched network error: ${error}`);
    error = new NetworkError(error);
  }

  if (error instanceof NetworkError) {
    if (ignoreNetworkErrors) {
      log.info(`network error ignored`);
      return;
    } else {
      /// This is the ugliest of hacks!!
      error = error as any;
      error.report = false;
      error.alertLevel = ERROR;
      error.expected = true;
      error.userMessage = __(
        'Network request failed',
        'errors.networkRequestFailed'
      );
    }
  }

  if (error.expected !== true) {
    error.userMessage = __(
      'Unexpected error: %{message}',
      'errors.unexpectedErrorMessage',
      { message: error.message }
    );
    error.alertLevel = unexpectedAlertLevel;
    error.report = true;
  }

  // const message =
  //   error.message && error.message.includes('Network request failed')
  //     ? 'Network request failed'
  //     : `Unexpected error: ${error.message}`;

  // alertError(message, {
  //   exception: error,
  //   source: 'genericallyHandleError',
  //   handlerTag: 'generically-alerted-error',
  // });

  fail(error); // fall through to global handler
};

export const isNetworkError = (error: any /*Error | string*/): boolean => {
  const message = (error as Error)?.message || String(error) || '';
  return (
    error instanceof NetworkError ||
    message.includes('Network request failed') || // chrome
    message.includes('Failed to fetch') || // chrome fetch api
    message.includes('Network') || // firefox (NetworkError)
    message.includes('Load failed') // safari
  );
};
