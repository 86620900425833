import { observable } from 'mobx';
import {
  applySnapshot,
  getRoot,
  ModelTreeNode,
  snap,
  volatile,
} from 'ts-state-tree/tst-core';
import { camelCasify, objectFromUrlQuery } from 'common/object-from-url-query';
import { bindery } from 'ts-state-tree/tst-core';
import { stringToBool } from '@utils/string-utils';
import * as loggly from 'legacylib/loggly';
import {
  alertWarningError,
  bugsnagNotify,
  // notifySuccess,
} from 'app/notification-service';
import { AssetCacher } from 'lib/asset-cacher';
import { AppFactory } from '@app/app-factory';
import { AppStateCacher } from 'lib/app-state-cacher';
import { appConfig } from 'app/env';
import { ApiInvoker } from '../services/api-invoker';
import { UserManager } from './user-manager/user-manager';
import { StoryManager } from './story-manager';
import { alertSevereError } from '@app/notification-service';
import tstSchema from '@app/tst-schema.json';
import { createLogger } from 'app/logger';
import { LocalState, UtmData } from './local-state';
import { track } from '@app/track';
import { setVideoAutoplay } from 'components/ui/video-player/player-view-controller';
import { notEmpty } from '@utils/conditionals';
import { setErrorContext } from '@common/error-reporting';
import {
  buildInfo,
  embeddedAndroid,
  embeddedBuildNumber,
  embeddedIos,
  embeddedMode,
  germanMode,
} from '@core/lib/app-util';
import { setAnalyticsContext } from 'app/track';
import { DEFAULT_CATALOG_SLUG, GlobalSettings } from './global-settings';
import { UserDataSyncImpl } from '@core/services/user-data-sync-impl';
import { SettingsSyncImpl } from '@core/services/settings-sync-impl';
import { CatalogMetaSyncImpl } from '@core/services/catalog-meta-sync-impl';
import { initializeFirebaseConnection } from '@core/services/firebase-init';
import { getInstallationId, hasBeenVisited } from './installation-id';
import { isNetworkError } from '@core/lib/error-handling';
import { decorateUrl, UrlOptions } from 'components/nav/decorate-url';
// import rg4js from 'raygun4js';
// import { GlobalSettings } from '@core/services/settings-sync';
// import minibus from 'common/minibus';
// import { sleep } from '@utils/util';

const log = createLogger('root');

// @armando, do you understand why our use of enums trigger lint errors?
export const enum AppInitStatus {
  // eslint-disable-next-line no-unused-vars
  INITIALIZING = 'INITIALIZING',
  // eslint-disable-next-line no-unused-vars
  READY = 'READY',
  // eslint-disable-next-line no-unused-vars
  STARTUP_FAILURE = 'STARTUP_FAILURE',
  // OFFLINE = 'OFFLINE';
}

/**
 * removes the query string from the location bar address
 */
const clearQuery = (preservedParams: UrlOptions) => {
  var baseUrl = window.location.href.split('?')[0];
  const newUrl = decorateUrl(baseUrl, preservedParams);
  window.history.replaceState({}, '', newUrl);
};

const APP_INIT_SHORT_TIMEOUT_MS = 5000; // report mixpanel if takes too long to init
const APP_INIT_LONG_TIMEOUT_MS = 30000;

export class AppRoot extends ModelTreeNode {
  static CLASS_NAME = 'AppRoot' as const;

  private static bound: boolean = false;

  public static create(
    snapshot: any = {} /*, dependencies: any = {}*/
  ): AppRoot {
    AppRoot.ensureBound();
    const model = super.create(AppRoot, snapshot) as AppRoot;
    return model;
  }

  public static ensureBound(): void {
    if (!AppRoot.bound) {
      AppRoot.bound = true;
      bindery.mergeSchemaDefinitions(tstSchema as any);
      bindery.bind(AppRoot);
      bindery.bind(LocalState);
      bindery.bind(GlobalSettings);
      UserManager.bindModels(bindery);
      StoryManager.bindModels(bindery);
      bindery.compileBindings();
    }
  }

  @volatile
  @observable.ref // @observable.not
  apiInvoker: ApiInvoker; // delegate for rails server REST API invocations

  localState: LocalState = snap({});

  globalSettings: GlobalSettings = snap({});

  userManager: UserManager = snap({});

  // unified with the catalog data
  storyManager: StoryManager = snap({});

  @volatile
  status: AppInitStatus;

  @volatile
  offline: boolean = false;

  // arbitrary text to show in the pie menu
  @volatile
  debugStatus: string = null;

  // hack to make it easy to see the mostly recently consumed logs
  @volatile
  serviceWorkerLogs: string[] = [];

  @volatile
  standalone: boolean = false;

  // true when no persisted state exists
  // used to direct first time native app users to the branded welcome screen instead of dashboard
  @volatile
  firstVisit: boolean = false;

  @volatile
  forceHidePiMenu: boolean = false;

  async appInitialize() {
    log.debug('appInitialize');
    this.status = AppInitStatus.INITIALIZING;

    this.initializeSyncManagers();

    this.checkIfStandalone();
    this.startPwaListeners();

    try {
      this.firstVisit = !hasBeenVisited();
      log.debug(`firstVisit: ${String(this.firstVisit)}`);

      // capture what we can as soon as we have the anonymous installationId
      this.setReportingContext();

      this.apiInvoker = new ApiInvoker({
        apiEnv: appConfig.apiEnv,
        authToken: this.userManager.token, // todo: cleanup, this is probably always null at this stage
        // revisit once we look again at marketing campaigns
        // appInstallAttribution: this.globalConfig.appInstallAttribution, // not sure if relevant
      });

      track('system__app_init_start');
      setTimeout(() => {
        if (this.status === AppInitStatus.INITIALIZING) {
          track('system__app_init_short_timeout', {
            timeout: APP_INIT_SHORT_TIMEOUT_MS,
          });
        }
      }, APP_INIT_SHORT_TIMEOUT_MS);
      setTimeout(() => {
        if (this.status === AppInitStatus.INITIALIZING) {
          track('system__app_init_long_timeout', {
            timeout: APP_INIT_LONG_TIMEOUT_MS,
          });
        }
      }, APP_INIT_LONG_TIMEOUT_MS);
      await this.initState();
      // todo: consider more carefully when we can safely render the dashboard
      this.status = AppInitStatus.READY;
      track('system__app_init_ready');

      // perform non-critical initialization steps asynchronously
      this.postInit().catch(bugsnagNotify);
    } catch (error) {
      // will get triggered due to firebase error if offline and no local storage, which is appropriate
      log.info(`app init error: ${error}`);
      this.status = AppInitStatus.STARTUP_FAILURE;
      alertSevereError({ error, note: 'app-root - appInitialize' });
      track('system__app_init_failure', { error: String(error) });
    }
    log.info('root store created');
  }

  async postInit() {
    log.info('postInit');

    // particularly important in the short-term to extend the expiry of the one-week ios cookies
    this.userManager.refreshUserTokenCookieIfNeeded().catch(bugsnagNotify);

    // if (this.userManager.userData.hasBorkedMigrationData) {
    //   await this.userManager.userData.repairBorkedProgressData();
    // }

    // // don't auto migrate all yet, it can be very slow for some users
    // // and i saw some firebase write stream queue errors when testing.
    // // todo: run migration in small batches
    // if (this.userManager.userData.hasVocabMigrationPendingProgresses) {
    //   this.migrateAllPendingBogotaVocabs().catch(bugsnagNotify);
    // }

    // a/b test skipping of firebase connection init
    if (this.firebaseForceDisconnected) {
      AppFactory.firebaseConnection.status = 'DISCONNECTED';
      log.info(`firebase force disconnected`);
      track('system__firebase_init_disconnected');
      return;
    }

    try {
      log.info('before initializeFirebaseConnection');
      await this.initializeFirebaseConnection();
      this.setReportingContext();
      track('system__firebase_init_ready');
      log.info('after initializeFirebaseConnection');
    } catch (error) {
      log.error(`initializeFirebaseConnection error: ${error}`);
      log.error((error as Error).stack);
      // expecting firebase to fail right now for some users, so just log to mixpanel, not sentry
      // bugsnagNotify(error as Error);
      AppFactory.firebaseConnection.status = 'ERROR';
      track('system__firebase_init_error', { error: String(error) });
      this.setReportingContext();
    }
  }

  // called when we were previously online and become offline
  setOffline(offline: boolean) {
    this.offline = offline;
    if (offline) {
      AppFactory.analyticsManager.offline();
    } else {
      AppFactory.analyticsManager.online();
      AppFactory.assetCacher.attemptReenable();
      // proactively flush changes when disconnected and coming back online
      this.userManager.syncIfDeferred().catch(bugsnagNotify);
    }
  }

  // different behaviors when we startup offline
  initializeOffline(offline: boolean) {
    this.offline = offline;
    if (offline) {
      AppFactory.analyticsManager.offline();
    }
  }

  get firebaseForceDisconnected(): boolean {
    const sampleRate = appConfig.firebase?.forceDisconnectedSampleRate || 0;
    return Math.random() < sampleRate;
  }

  checkIfStandalone() {
    if (window.matchMedia?.('(display-mode: standalone)').matches) {
      this.standalone = true;
    }
  }

  startPwaListeners() {
    window.addEventListener('appinstalled', evt => {
      track('system__pwa_installed');
      this.standalone = true;
    });
  }

  get installFlavor(): 'browser' | 'native' | 'pwa' {
    if (embeddedMode()) {
      return 'native';
    }
    if (this.standalone) {
      return 'pwa';
    }
    return 'browser';
  }

  /**
   * automatically called after creation
   */
  async initState(): Promise<void> {
    log.debug(`initState`);

    // this.setStatus('initializing');

    const appStateCacher = await AppStateCacher.create('jw:app-state');
    AppFactory.setAppStateCacher(appStateCacher);

    try {
      await this.localState.load();
      // todo: consider also persisting and loading global settings
    } catch (error) {
      alertWarningError({ error });
    }

    // needed so review mode is honored before dashboard is first rendered
    await this.refreshGlobalSettings(); // has own try/catch

    try {
      await this.storyManager.loadLocal();
    } catch (error) {
      alertWarningError({
        error,
        note: 'root.initState - storyManager.loadLocal',
      });
    }

    try {
      const loaded = await this.userManager.loadLocal();
      if (loaded) {
        this.setReportingContext(); // update with user info when available locally
      }
    } catch (error) {
      alertWarningError({
        error,
        note: 'root.initState - userManager.loadLocal',
      });
    }

    const { forceError } = await this.handleQueryParams();

    if (this.userManager.authenticated) {
      // execute the account data refresh asynchronously from the rest of the app init when we have cached user data
      this.userManager.initAuthenticatedWithLocalData().catch(error => {
        log.error(`error during async initWithLocalData - will reset auth`);
        alertSevereError({ error });
        this.userManager.resetAuthentication();
      });
    } else {
      try {
        // attempt from server cookie if available
        await this.authFromStoredToken();
      } catch (error) {
        alertSevereError({ error, note: 'root.initState - userManager.init' });
        this.userManager.resetAuthentication();
      }
    }

    if (!this.userManager.authenticated) {
      if (
        notEmpty(this.userManager.resolveAffiliateCode()) &&
        !this.userManager.accountData.hasSpecialPricing
      ) {
        log.info('pending affiliate code found, refreshing account data');
        await this.userManager.refreshAccountData();
      }

      log.info('initState - anonymous reporting context');
    }
    // setup anonymous context when not auto-logged in, redundantantly set after fetching account data when authenticated
    this.setReportingContext();

    // make sure we have at least some version of the catalog before rending the dashboard
    // await this.storyManager.ensureCatalogNoFirestore(this.catalogSlug);
    if (this.storyManager.isEmpty) {
      log.info(`empty catalog data - loading globalSettings default`);
      await this.storyManager.ensureCatalogUrl(
        this.globalSettings.defaultCatalogUrl
      );
    }

    // // make sure we're subscribed (might be redundant)
    // this.storyManager.subscribeToCatalogSlug(this.catalogSlug);

    // // simple schema updates checked here and by userManager.applyAuthentication
    // try {
    //   const dirty = this.userManager.userData.migrateSimpleSchemaChanges();
    //   if (dirty) {
    //     log.info(`persisting simple schema updates`);
    //     await this.userManager.persistUserData();
    //   }
    // } catch (error) {
    //   alertWarningError({
    //     error,
    //     note: 'root.init - migrateSimpleSchemaChanges',
    //   });
    // }

    if (this.localState.logglyEnabled) {
      loggly.activate();
    }

    if (this.localState.videoAutoplay) {
      setVideoAutoplay(true);
    }

    // todo: consider merging into a single prop
    if (this.localState.embeddedPlatform) {
      window.embeddedPlatform = this.localState.embeddedPlatform;
    }
    if (this.localState.embeddedBuildNumber) {
      window.embeddedBuildNumber = this.localState.embeddedBuildNumber;
    }

    if (forceError) {
      throw Error('Debugging: forceError triggered');
    }

    // delay the asset cacher init until we hopefully have more reporting context
    AppFactory.setAssetCacher(await AssetCacher.create('jw:story-assets'));

    // needed to trigger new soundbite each midnight
    this.storyManager.refreshDateAtMidnight();

    // roll back the caching aggressiveness until we ensure stability
    // /*async*/ this.storyManager.ensureCacheState();
    // } catch (error) {
    //   if (error instanceof NetworkError) {
    //     this.setOffline();
    //   } else {
    //     alertWarningError({ error, note: 'root.initState' });
    //     this.setStartupFailure();
    //   }
    // }
  }

  /**
   * if there's a query var in the url like
   * `token=eyJabc123.eyJabc123456`
   * it will grab that and store it locally
   */
  async handleQueryParams() {
    const {
      token = null,
      invite,
      debug,
      locale,
      forceError,
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
      embeddedPlatform,
      embeddedBuildNumber,
      flow,
      pi,
    } = objectFromUrlQuery<{
      token?: string;
      invite?: string;
      debug?: string;
      locale?: string;
      forceError?: string;
      utmSource?: string;
      utmMedium?: string;
      utmCampaign?: string;
      utmTerm?: string;
      utmContent?: string;
      embeddedPlatform?: string;
      embeddedBuildNumber?: string;
      flow?: string;
      pi?: string;
    }>();

    if (token) {
      log.info(`url token: ${token}`);

      if (token !== this.userManager.token) {
        log.warn(
          `location token mismatched from local store data - resetting local store`
        );
        // todo: should perhaps move this logic back up to the main appInit function
        log.debug(`saving token into cookie: ${token}`);
        await this.userManager.setUserTokenCookie(token);
        // const confirmSaved = await this.userManager.getServerCookieUserToken();
        // log.debug('refetched server cookie token: ${confirmSaved');
        // if (confirmSaved !== token) {
        //   log.error(`beware failed to save server cookie`);
        // }
        await this.userManager.resetLocalData();
      }
    }

    if (locale) {
      await this.localState.storeLocale(locale);
    } else {
      this.localState.applyLocale();
    }
    // todo: cleanup redundancy with app-util logic
    if (embeddedPlatform) {
      window.embeddedPlatform = embeddedPlatform;
      this.localState.embeddedPlatform = embeddedPlatform;
    }
    if (embeddedBuildNumber) {
      window.embeddedBuildNumber = embeddedBuildNumber;
      this.localState.embeddedBuildNumber = embeddedBuildNumber;
      await this.localState.persist();
    }
    if (debug !== undefined) {
      await this.localState.storeForceDevToolsEnabled(stringToBool(debug));
    }
    if (invite !== undefined) {
      await this.validateInvite(invite);
    }
    const referrer = document.referrer;
    const hasUtm =
      utmSource || utmMedium || utmCampaign || utmTerm || utmContent;
    if (hasUtm) {
      const utmData: UtmData = {
        utmSource,
        utmMedium,
        utmCampaign,
        utmTerm,
        utmContent,
        referrer,
      };
      // drives mixpanel event properties
      await this.localState.storeLatestUtmData(utmData);
      // used by rails server to attribute newly registered users
      await this.userManager.setTrafficSourceCookie(utmData);
    } else {
      if (referrer) {
        const existing = this.userManager.getTrafficSourceCookie();
        if (existing) {
          log.info(
            `ignoring referrer: ${referrer}, existing trafficSource: ${JSON.stringify(
              existing
            )}`
          );
          if (this.localState.emptyLatestUtmData) {
            const utmData = camelCasify(existing) as UtmData;
            log.debug(
              `pulling traffic source cookie data into local state: ${JSON.stringify(
                utmData
              )}`
            );
            await this.localState.storeLatestUtmData(utmData);
          }
        } else {
          await this.userManager.setTrafficSourceCookie({ referrer });
        }
      }
    }

    if (hasUtm || token || invite || debug || forceError !== undefined) {
      // the 'flow' param is passed in for some links from the marketing site and need to
      // preserved into the query string so that it can get included in the page tracking props
      const preservedParams = { flow }; // empty value will get stripped out later // !!flow ? { flow } : {};
      clearQuery({ search: preservedParams });
    }

    if (pi && pi !== 'true') {
      this.forceHidePiMenu = true;
    }

    return { token, invite, debug, forceError };
  }

  // this has gotten a bit convoluted, but flip both flags for convenience
  togglePiMenu() {
    this.toggleForceHidePiMenu();
    this.localState
      .storeForceDevToolsEnabled(!this.forceHidePiMenu)
      .catch(bugsnagNotify);
  }

  toggleForceHidePiMenu() {
    this.forceHidePiMenu = !this.forceHidePiMenu;
  }

  /**
   * if we have a locally stored token we use that to log the user in.
   * only expected now the first time a user loads the new site
   */
  async authFromStoredToken() {
    // const serverCookieToken = await this.userManager.getServerCookieUserToken();
    const token = this.userManager.getUserTokenCookie();
    if (token) {
      try {
        log.info(`auto login with server cookie user token`);
        await this.userManager.autoLogin(token);
        return;
      } catch (error) {
        alertWarningError({ error, note: 'root.authFromStoredToken' });
        await this.userManager.reset();
      }
    }
  }

  get inviteNeeded(): boolean {
    const { inviteGateEnabled } = appConfig;

    return (
      inviteGateEnabled &&
      !this.localState.validatedInviteCode &&
      !this.userManager.authenticated
      // !this.userManager.validatedInviteCode // legacy state
    );
  }

  get loadingData() {
    return this.apiInvoker?.loadingData || this.userManager?.loadingUserData;
    // tried to use this to drive loading indicator just after logging in, but didn't seem to work
    // (this.userManager.authenticated && !this.userManager.loggedInAndReady)
  }

  setReportingContext() {
    const data: any = {
      website: appConfig.website.baseUrl,
      apiEnv: appConfig.apiEnv,
      // installationId: this.localState.installationId,
      installationId: getInstallationId(),
      userManager: this.userManager.reportingContextData,
      accountData: this.userManager.accountData.reportingContextData,
      localState: this.localState.snapshot,
      buildInfo: buildInfo(),
      miscInfo: {
        installFlavor: this.installFlavor,
        standalone: this.standalone,
        firstVisit: this.firstVisit,
        apiEnv: appConfig.apiEnv,
        website: appConfig.website.baseUrl,
        // could be stale, should figure out best way to add this just-in-time
        firebaseStatus: AppFactory.firebaseConnection.status,
        catalogUrl: this.storyManager.catalogUrl,
      },
    };

    log.info(`setReportingContext - ${data?.accountData?.email}}`);

    setErrorContext(data);

    // provides context for implicity properties with future event tracking
    // (no longer side effects indentity operations)
    setAnalyticsContext(data);
  }

  get catalogSlug() {
    // todo: should probably just completely remove the userData level catalog override.
    // note, there appears to be some bleed between the user and local state here.
    if (germanMode()) {
      return /*this.localState.defaultCatalogSlug ||*/ DEFAULT_CATALOG_SLUG;
    }
    return (
      this.userManager.userData.overrideCatalogSlug ||
      this.localState.defaultCatalogSlug ||
      DEFAULT_CATALOG_SLUG
    );
  }

  get shouldUpdateNative(): boolean {
    if (!embeddedMode()) {
      return false;
    }

    const currentBuildNumber = parseInt(embeddedBuildNumber());
    const newBuildNumber = this.storeBuildNumber;
    log.debug(
      `shouldUpdateNative - current: ${String(
        currentBuildNumber
      )}, new: ${String(newBuildNumber)}`
    );
    if (!currentBuildNumber || !newBuildNumber) {
      // // [old comment] this apparently can get triggered somehow when logging out and was breaking the unit tests
      // bugsnagNotify(
      //   `shouldUpdateNative - current: ${String(
      //     currentBuildNumber
      //   )}, new: ${String(newBuildNumber)}`
      // );

      // ignore until we've fetched our global settings
      return false;
    }
    return newBuildNumber > currentBuildNumber;
  }

  get storeBuildNumber(): number {
    if (embeddedIos()) {
      // return parseInt(this.userManager.accountData.appStoreBuildNumber);
      return this.globalSettings.appStoreBuildNumber;
    }
    if (embeddedAndroid()) {
      // return parseInt(this.userManager.accountData.playStoreBuildNumber);
      return this.globalSettings.playStoreBuildNumber;
    }
    return 0;
  }

  // for now these are both just synonyms for 'apple review mode'
  get accountCreationDisabled(): boolean {
    return (
      this.userManager.purchaseFlowDisabled || appConfig.accountCreationDisabled
    );
  }

  get defaultToWelcome() {
    return (
      !this.userManager.authenticated &&
      (this.accountCreationDisabled || (embeddedMode() && this.firstVisit))
    );
  }

  get disableGoogleAuth(): boolean {
    return (
      embeddedMode() ||
      appConfig.accountCreationDisabled ||
      appConfig.disableGoogleAuth
    );
  }

  async validateInvite(code: string) {
    track('account__validate_invite', { code });

    const result = await this.apiInvoker.post<{
      status: string;
      code: string;
      message: string;
    }>(
      'users/validate_invite',
      {
        code,
      },
      { networkIndicator: true }
    );

    log.info(`validate invite result: ${JSON.stringify(result)}`);
    await this.localState.storeValidatedInviteCode(result.code);
    return result;
  }

  setDebugStatus(message: string) {
    this.debugStatus = message;
  }

  clearDebugStatus() {
    this.debugStatus = null;
  }

  // async migrateAllPendingBogotaVocabs() {
  //   try {
  //     if (!this.userManager.userData.hasVocabMigrationPendingProgresses) {
  //       return;
  //     }
  //     track('system__migrate_all_pending_bogota_vocabs');
  //     notifySuccess('Migrating saved vocab data...');
  //     log.warn('migrateAllPendingBogotaVocabs');
  //     let count = 0;

  //     // paranoia loop limit
  //     for (let i = 0; i < 250; i++) {
  //       const progress =
  //         this.userManager.userData.nextVocabMigrationPendingProgress;
  //       if (!progress) {
  //         break;
  //       }
  //       const success = await progress.migratePendingBogotaVocabs({
  //         persist: true,
  //       });
  //       if (success) {
  //         count++;
  //       }
  //     }
  //     log.warn(
  //       `migrateAllPendingBogotaVocabs - updated progress records: ${count}`
  //     );
  //     notifySuccess('Migration complete');
  //   } catch (error) {
  //     log.error(`migrateAllPendingBogotaVocabs - failed: ${error}`);
  //     bugsnagNotify(error as Error);
  //   }
  // }

  initializeSyncManagers() {
    log.debug(`initializeSyncManagers`);
    // firebase connection expected to be uninitialized at this stage, but provides
    // a handle to the future initialized state.
    const { firebaseConnection, caliServerInvoker } = AppFactory;

    if (AppFactory.userDataSync) {
      bugsnagNotify('userDataSync unexpectedly already initialized');
    }

    AppFactory.setUserDataSync(
      new UserDataSyncImpl({
        firebaseConnection,
        caliServerInvoker,
      })
    );

    AppFactory.setSettingsSync(
      new SettingsSyncImpl({ firebaseConnection, caliServerInvoker })
    );

    AppFactory.setCatalogMetaSync(
      new CatalogMetaSyncImpl({ firebaseConnection, caliServerInvoker })
    );
  }

  async initializeFirebaseConnection() {
    log.debug('initializeFirebaseConnection');
    const { firebaseConnection } = AppFactory;

    if (firebaseConnection.db) {
      log.error('firebase unexpectedly already initialized');
    } else {
      const status = await initializeFirebaseConnection({
        firebaseConnection,
        apiEnv: appConfig.apiEnv,
      });
      log.info(`initializeFirebase - status: ${status}`);
    }

    this.storyManager.subscribeToCatalogSlug(this.catalogSlug);

    // todo: subscribe to catalog and user data updates

    // GlobalSettings drives the native build update banner
    AppFactory.settingsSync.subscribeGlobal(data => {
      log.debug(`globalSettings updated: ${JSON.stringify(data)}`);
      applySnapshot(this.globalSettings, data);
      // defaultCatalogSlug is just hardwired for now
      // this.localState
      //   .storeDefaultCatalogSlug(data?.catalogSlug)
      //   .catch(bugsnagNotify);
    });

    if (this.userManager.authenticated) {
      this.userManager.startListen();
    }
  }

  async refreshGlobalSettings() {
    try {
      if (this.offline) {
        log.info(`refreshGlobalSettings - offline, skipping`);
        return;
      }
      const settingsData = await AppFactory.settingsSync.fetchGlobal();
      log.info('before globalSettings applySnapshot');
      applySnapshot(this.globalSettings, settingsData);
      log.info('after globalSettings applySnapshot');
    } catch (error) {
      if (isNetworkError(error as Error)) {
        log.warn(`refreshGlobalSettings network error: ${error} - ignoring`);
      } else {
        alertWarningError({ error, note: 'refreshGlobalSettings' });
      }
    }
  }
}

export const getBaseRoot = (node: any): AppRoot => {
  const root = getRoot(node);
  if (root && root instanceof AppRoot) {
    return root;
  } else {
    // need fallback for soundbite stories loaded outside of catalog
    log.debug('using AppFactory.root');
    return AppFactory.root;
  }
};

if (window.location?.search?.includes('force-embedded=t')) {
  window.embeddedPlatform = 'ios';
}

if (window.location?.search?.includes('force-death=t')) {
  throw Error('testing hard error during module imports');
}

if (window.location?.search?.includes('force-unhandled=t')) {
  const nonFatal = async () => {
    // eslint-disable-next-line no-console
    console.log('triggering unhandled promise rejection');
    throw Error(
      'testing unhandled promise rejection triggered during module imports'
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  nonFatal();
  // this seemed to work in a dev build, but was fatal to the standalone build
  // @armando: can you figure out how to test the unhandled promise flow in a deployed build?
  // await sleep(0);
}
