import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@naan/primitives/button';
import { ArrowRightSmallIcon } from '@naan/icons/arrow-right-icon';
// import { track } from '@app/track';

import __ from '@core/lib/localization';
import { safelyConcatenateParams } from 'components/nav/decorate-url';

export const TryFirstSoundbiteButton = ({
  continuePath,
  id,
}: {
  continuePath: string;
  id: string;
}) => {
  /// I was going to do a custom event, but I'll just do wat frank did with he query param
  // const trackEvent = React.useCallback(() => {
  //   track('soundbite__orientation__try_first_click', {
  //     buttonId: id,
  //   });
  // }, [id]);

  const path = safelyConcatenateParams(continuePath, `flow=${id}`);

  return (
    <>
      <Link to={path} replace>
        <Button
          presentation="purple"
          label={__('Try your first Soundbite', 'tryFirstSoundbite')}
          rightIcon={<ArrowRightSmallIcon />}
          size="large"
        />
      </Link>
    </>
  );
};
