import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@naan/primitives/button';
import { usePlayerModel } from '../player-model-context';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  background: '$white',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  borderRadius: 24,
  '& > * + *': {
    borderLeft: '1px solid $gray-100',
  },
});

export const CancelPendingPauseButton = observer(() => {
  const model = usePlayerModel();
  const { player } = model;

  if (player.transportState.pendingPause) {
    return (
      <Wrapper>
        <Button
          onClick={() => model.cancelPendingPause()}
          label={__('Cancel auto pause', 'cancelAutoPaus')}
          size="small"
          presentation={'white'}
        />
      </Wrapper>
    );
  }

  return null;
});
