import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Navigate,
  /*NavLink,*/ Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
// import { VSpacer } from 'naan/primitives/spacer';
import { styled } from '@naan/stitches.config';
import { StoryHeader } from 'story/story-header';
import {
  learnHomePath /*, storySoundbitePath*/,
} from 'components/nav/path-helpers';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { StoryDetailCard } from 'components/story-detail/story-detail-card';
import { CurrentStoryProvider } from 'components/story-detail/current-story-context';
import { NotFoundScreen } from 'routes/not-found-screen';
import { StoryOverflowMenu } from 'components/story-detail/story-overflow-menu';
import { StoryDetailCardAssignment } from 'components/stories/story-detail-card-assignment';
import { DownloadControlTemp } from 'components/stories/download-control-temp';
import { Box } from '@naan/primitives/box';
import { bugsnagNotify } from '@app/notification-service';
import { hideBottomTabBar, showBottomTabBar } from 'components/global-layout';
import { StoryTab } from './story-tab';
import { SoundbitesTab } from './soundbites-tab';
import { StoryTabsBar } from './story-tabs-bar';
// import { LegacyStoryDetailScreen } from '../legacy-story-detail-screen';
import { StoryLockedActions } from 'story/story-locked-actions';
import { useStoryTitleScrollingFx } from '../scrolling-fx';
import { StoryNavState } from './story-nav-state';
import { elementIds } from 'components/dom-utils/element-ids';

const StoryDetailScreenStyles = styled('div', {
  $$storyHeaderHeight: '56px',
  $$assignmentBannerHeight: '0px',
  $$tabbarHeight: '0px',
  $$tabbarTop: 'calc($$assignmentBannerHeight + $$storyHeaderHeight)',

  position: 'relative',
  isolation: 'isolate',

  '@medium': {
    $$storyHeaderHeight: '64px',
  },

  '& > .story-header-container': {
    position: 'sticky',
    top: 0,
    right: 0,
    left: 0,
    '@large': {
      left: '$$sidebarWidth',
    },
    zIndex: 99,
  },
  '& .unitHeader': {
    textStyle: 'small-heading',
    marginBottom: '$space$4',
    marginTop: '$space$10',
  },
  '& .unitDuration': {
    textStyle: 'small-text',
    marginLeft: '$space$2',
    color: '$colors$textSecondary',
  },
  '& .tabs-content': {
    minHeight:
      'calc(100vh - $$storyHeaderHeight - $$assignmentBannerHeight - $$tabbarHeight)',
  },
  '&:has(.story-assignment)': {
    $$assignmentBannerHeight: '64px',
  },
  '&:has(.story-tab-bar)': {
    // extra 1px on each of these is for bottom border
    $$tabbarHeight: '81px',
    '@small': {
      $$tabbarHeight: '65px',
    },
  },
});

StoryDetailScreenStyles.defaultProps = {
  id: 'story-detail-screen',
};

export const StoryDetailScreen = () => {
  // const { soundbitesEnabled } = AppFactory.root.storyManager;
  // if (soundbitesEnabled) {
  return <SoundbiteStoryDetailScreen />;
  // } else {
  //   return <LegacyStoryDetailScreen />;
  // }
};

const SoundbiteStoryDetailScreen = observer(() => {
  // track when it's safe to pop back from study view
  ReturnNavState.storyDetailVisited = true;

  const navigate = useNavigate();
  const { storyManager } = AppFactory.root;
  const { slug } = useParams<{ slug: string }>();
  const story = storyManager.story(slug);
  (window as any).currentStory = story; // console convenience

  useStoryTitleScrollingFx();

  React.useEffect(() => {
    if (story) {
      story.ensureCacheState().catch(bugsnagNotify);
      // story.progress
      //   .migratePendingBogotaVocabs({ persist: true })
      //   .catch(bugsnagNotify);
    }
    StoryNavState.externalLanding = false;
    hideBottomTabBar();

    return () => {
      StoryNavState.externalLanding = true;
      showBottomTabBar();
    };
  }, [story]);

  if (!story) {
    // return <>loading…</>;
    return <NotFoundScreen reportError={false} />;
  }

  const canGoBack = ReturnNavState.canPopFromStoryDetail();
  const assignment = story.joinedClassroomAssignment;
  const showTempDownloadControls = AppFactory.root.userManager.showUglyDevUI;

  return (
    <CurrentStoryProvider story={story}>
      <StoryDetailScreenStyles>
        <div
          className="story-header-container"
          id={elementIds.STORY_HEADER_CONTAINER}
        >
          <StoryHeader
            backAction={() =>
              canGoBack ? navigate(-1) : navigate(learnHomePath())
            }
            overflowMenu={<StoryOverflowMenu story={story} />}
          />
        </div>
        <StoryDetailCard story={story} />
        <StoryDetailCardAssignment assignment={assignment} />
        {/* <StoryDownloadedBadge story={story} /> */}
        {showTempDownloadControls ? (
          <Box css={{ padding: '$space$2' }}>
            <DownloadControlTemp story={story} />
          </Box>
        ) : null}

        <StoryLockedActions />
        <StoryTabsBar />

        <div className="tabs-content">
          <Routes>
            <Route index element={<Navigate to="story" replace />} />
            <Route path="story" element={<StoryTab />} />
            <Route path="soundbites" element={<SoundbitesTab />} />
          </Routes>
        </div>
      </StoryDetailScreenStyles>
    </CurrentStoryProvider>
  );
});
