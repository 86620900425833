import * as React from 'react';
import * as RichMessage from 'components/ui/rich-message';

import classroomImageDesktop from './images/classroom-d.png';
import classroomImageMobile from './images/classroom-m.png';
import partyImageDesktop from './images/party-d.png';
import partyImageMobile from './images/party-m.png';

import { Button } from 'naan/primitives/button';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { MediumGap } from '@naan/primitives/spacer/gaps';
// import { HSpacer } from '@naan/primitives/spacer';
// import { HStack } from '@naan/primitives/stack';
// import { VideoModal } from '../dashboard/helplets-widget/video-modal';
// import { VideoPlayerModel } from '../dashboard/helplets-widget/video-player-model';

import __ from '@core/lib/localization';

// const presentVideoModal = () => {
//   const { videoGuideUserData } = AppFactory.root.userManager.userData;
//   const video = videoGuideUserData.featuredVideo;
//   const model = new VideoPlayerModel(videoGuideUserData, video);

//   AppFactory.dialogPresenter.present(onDismiss => {
//     return <VideoModal model={model} onDismiss={onDismiss} />;
//   });
// };

// const GuidedWelcomeMessage = observer(() => {
//   const { userManager } = AppFactory.root;

//   const onClose = () => userManager.dismissLearnMessage();

//   return (
//     <>
//       {/* <WelcomeMessageWrapper> */}
//       <RichMessage.Container>
//         <RichMessage.CloseButton onClick={onClose} />
//         <RichMessage.Body data-test-id="full-access-banner">
//           <RichMessage.Title>
//             {__(
//               'Before you dive into a story…',
//               'beforeYouDiveIntoAStory'//             )}
//           </RichMessage.Title>
//           <div>
//             <p>
//               {__(
//                 'You’ll learn faster and retain more if you know a few basics about Jiveworld. Watch our short Getting Started videos to get the most out of your valuable study time.',
//                 'youllLearnFasterAndRetainMoreIf'//               )}
//             </p>
//           </div>
//           <RichMessage.ButtonContainer>
//             <HStack>
//               <Button
//                 onClick={presentVideoModal}
//                 label={__('Watch now', 'dashboard.watchNow')}
//                 presentation={'teal'}
//               />
//               <HSpacer size={1} />
//               <Button
//                 onClick={onClose}
//                 label={__('Later', 'dashboard.later')}
//                 presentation={'whiteDarken'}
//               />
//             </HStack>
//           </RichMessage.ButtonContainer>
//         </RichMessage.Body>
//         <RichMessage.Figure bleed="bottom">
//           <RichMessage.Image src={partyImageDesktop} size="medium" />
//           <RichMessage.Image src={partyImageMobile} size="small" />
//         </RichMessage.Figure>
//       </RichMessage.Container>
//       {/* </WelcomeMessageWrapper> */}
//     </>
//   );
// });

const OnboardedWelcomeMessage = observer(() => {
  const { userManager } = AppFactory.root;

  const onClose = () => userManager.dismissLearnMessage();

  return (
    <>
      {/* <WelcomeMessageWrapper> */}
      <RichMessage.Container>
        <RichMessage.CloseButton onClick={onClose} />
        <RichMessage.Body data-test-id="full-access-banner">
          <RichMessage.Title>
            {__('¡Que te diviertas!', 'dashboard.queTeDiviertas')}
          </RichMessage.Title>
          <div>
            <p>
              {__(
                'Browse stories by country and theme, and dive into anything that catches your eye. Maybe start with a shorter story, and try longer ones as your confidence increases.',
                'browseStoriesByCountryAndTheme'
              )}
            </p>
          </div>
          <RichMessage.ButtonContainer>
            <Button
              onClick={onClose}
              label={__('Start listening', 'dashboard.startListening')}
              presentation={'teal'}
            />
          </RichMessage.ButtonContainer>
        </RichMessage.Body>
        <RichMessage.Figure bleed="bottom">
          <RichMessage.Image src={partyImageDesktop} size="medium" />
          <RichMessage.Image src={partyImageMobile} size="small" />
        </RichMessage.Figure>
      </RichMessage.Container>
      {/* </WelcomeMessageWrapper> */}
    </>
  );
});

export const LearnWelcomeMessage = observer(() => {
  const { userManager } = AppFactory.root;
  const onboarded = !Boolean(
    userManager.userData.videoGuideUserData.featuredVideo
  );

  const show = userManager.showLearnMessage;
  if (!show) {
    return null;
  }

  if (onboarded) {
    return <OnboardedWelcomeMessage />;
  } else {
    // return <GuidedWelcomeMessage />;
    return null;
  }
});

export const TeacherWelcomeMessage = observer(() => {
  const { userManager } = AppFactory.root;

  const show = userManager.showTeacherMessage;
  if (!show) {
    return null;
  }

  const onClose = () => userManager.dismissTeacherMessage();

  return (
    <>
      {/* <WelcomeMessageWrapper> */}
      <RichMessage.Container>
        <RichMessage.CloseButton onClick={onClose} />
        <RichMessage.Body data-test-id="full-access-banner">
          <RichMessage.Title>
            {__('Getting Started', 'dashboard.gettingStarted')}
          </RichMessage.Title>
          <div>
            <p>
              {__(
                `This is the Dashboard your students will see when studying with Jiveworld Español.
                Use the Go to Classrooms link to access the classroom portal and other teachers’ resources.
                Hint: on mobile it‘s under the "Me" menu.`,
                'thisIsTheDashboardYourStudentsWillSee'
              )}
            </p>
          </div>
          <RichMessage.ButtonContainer>
            <Button
              onClick={onClose}
              label={__('Got it', 'dashboard.gotIt')}
              presentation={'teal'}
            />
          </RichMessage.ButtonContainer>
        </RichMessage.Body>
        <RichMessage.Figure bleed="top">
          <RichMessage.Image src={classroomImageDesktop} size="medium" />
          <RichMessage.Image src={classroomImageMobile} size="small" />
        </RichMessage.Figure>
      </RichMessage.Container>
      <MediumGap />
      {/* </WelcomeMessageWrapper> */}
    </>
  );
});

// export const LearnWelcomeMessage = observer(() => {
//   const { userManager } = AppFactory.root;

//   if (userManager.classroomEnabled) {
//     return <TeacherWelcomeMessage />;
//   }

//   return <StudentWelcomeMessage />;
// });
