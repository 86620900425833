import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { createLogger } from '@common/log';

const log = createLogger('offline-awareness');

export const useOfflineAwareness = () => {
  log.debug('hook body');
  const root = AppFactory.root;

  React.useEffect(() => {
    // log.debug('useEffect');

    const offlineListener = () => {
      log.debug(`event: 'offline'`);
      root.setOffline(true);
    };
    window.addEventListener('offline', offlineListener);

    const onlineListener = () => {
      log.debug(`event: 'online'`);
      root.setOffline(false);
    };

    root.initializeOffline(!navigator.onLine); // populate initial state, seems to still only work sometimes
    log.info(`initial offline state: ${String(root.offline)}`);
    window.addEventListener('online', onlineListener);

    return () => {
      log.debug('cleaning up');
      document.removeEventListener('offline', offlineListener);
      document.removeEventListener('online', onlineListener);
      root.userManager.stopListen();
    };
  }, [root]);
};
