import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { VocabRow } from './vocab-row';

import __ from '@core/lib/localization';
import { KeepAwakeRow } from './keep-awake-row';
import { observer } from 'mobx-react';

const Container = styled(Dialog.Container, {
  width: 'min( 600px, calc(100% - 32px) )',
});

const ContentWrapper = styled('div', {
  maxHeight: '70vh',
  overflowY: 'auto',
  marginBottom: '$4',

  '& > .table': {
    '& > * + *': {
      borderTop: '1px solid $gray-100',
    },
  },
});

const HeadingWrapper = styled('div', {
  background: '$gray-50',
  color: '$textPrimary',
  padding: '$3 0',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: '12px 12px 0 0',
  '& > .title': {
    marginLeft: '$4',
    textStyle: 'small-heading',
  },
  '& > .button': {
    marginLeft: 'auto',
    marginRight: '$1',
  },
});

export const SettingsModal = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    return (
      <Container open onDismiss={onDismiss}>
        <HeadingWrapper>
          <span className="title">
            {__('Advanced Settings', 'advancedSettings')}
          </span>

          <div className="button">
            <Button
              onClick={onDismiss}
              label={__('Close', 'close')}
              presentation="grayTransparent"
            />
          </div>
        </HeadingWrapper>
        <ContentWrapper>
          <div className="table">
            <VocabRow />
            <KeepAwakeRow />
          </div>
        </ContentWrapper>
      </Container>
    );
  }
);
