import * as React from 'react';
import { observer } from 'mobx-react';
import { StoryCollection } from '@core/models/catalog/featured-release';
import { FullyStyledMarkdown } from '@naan/primitives/text';
import { VSpacer } from '@naan/primitives/spacer';
import { StoryCard } from 'components/stories/story-card';
import { styled } from '@naan/stitches.config';
import {
  ParentScreenLayoutWrapper,
  ParentScreenLink,
} from 'components/ui/parent-screen-link';
import { collectionsPath } from 'components/nav/path-helpers';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { Responsive } from '@naan/primitives/responsive';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import {
  getMainScrollingContainer,
  scrollContainerToTop,
} from 'lib/scroll-utils';
import { Link, useNavigate } from 'react-router-dom';
import { clearSavedScrollPosition } from 'lib/scroll-utils/deep-scroll-restoration';
import { Image } from '@naan/primitives/image';

import __ from '@core/lib/localization';

const Figure = styled('div', {
  maxWidth: '100%',
  '& img': {
    width: '100%',
    display: 'block',
  },
  '@medium': {
    marginTop: 16,
    '& img': {
      // 9/12 columns
      width: 'calc( 100% / 12 * 9 )',
      borderRadius: 12,
      aspectRatio: '375 / 211',
      backgroundColor: '$black-alpha-10',
    },
  },
});

const Heading = styled('div', {
  '& > .title': {
    textStyle: 'medium-heading',
  },
  '& > .n-stories': {
    all: 'unset',
    color: '$teal-500',
    textStyle: 'body-bold',
    display: 'block',
    marginTop: '$space$2',
    cursor: 'pointer',
  },
  '@medium': {
    '& > .title': {
      textStyle: 'extra-large-heading',
      // 9/12 columns
      width: 'calc( 100% / 12 * 9 )',
    },
  },
});

const Wrapper = styled('div', {
  '& > .body': {
    maxWidth: '580px',
  },
  '& > .stories': {
    '& > *': {
      // paddingTop: 16,
      // paddingBottom: 16,
      borderTop: '1px solid $colors$black-alpha-10',
    },
  },
});

const BottomLink = styled(Link, {
  textStyle: 'body-bold',
  color: '$teal-500',
  textDecoration: 'none',
  display: 'block',
  margin: '16px 0 40px',
  textAlign: 'center',
  '@medium': {
    margin: '56px 0 80px',
  },
});

const scrollToStoriesContainer = () => {
  const storiesContainer = document.getElementById('stories');
  if (storiesContainer) {
    const scrollingContainer = getMainScrollingContainer();
    if (scrollingContainer) {
      scrollingContainer.scrollTo({
        top: storiesContainer.offsetTop - scrollingContainer.offsetTop - 56,
        behavior: 'smooth',
      });
    }
  }
};

export const CollectionDetail = observer(
  ({ collection }: { collection: StoryCollection }) => {
    const navigate = useNavigate();

    React.useEffect(scrollContainerToTop, []);

    if (!collection) return null;

    return (
      <>
        <ParentScreenLayoutWrapper>
          <CenterColumnLayout>
            <ParentScreenLink
              to={collectionsPath()}
              title={__('Collections', 'dashboard.collections')}
            />
          </CenterColumnLayout>
        </ParentScreenLayoutWrapper>
        <Responsive
          renderDefault={() => (
            <Figure>
              <Image src={collection.illustrationUrl} alt={collection.title} />
            </Figure>
          )}
          renderMediumAndUp={() => (
            <CenterColumnLayout>
              <Figure>
                <Image
                  src={collection.illustrationUrl}
                  alt={collection.title}
                />
              </Figure>
            </CenterColumnLayout>
          )}
        />
        <VSpacer size={4} />
        <CenterColumnLayout>
          <Heading>
            <h4 className="title">{collection.title}</h4>
            <button className="n-stories" onClick={scrollToStoriesContainer}>
              {__('%{n} stories', 'collection.nStories', {
                n: collection.stories.length,
              })}
            </button>
          </Heading>
        </CenterColumnLayout>
        <VSpacer size={6} />
        <CenterColumnLayout>
          <Wrapper>
            <div className="body">
              <FullyStyledMarkdown
                source={collection.article}
                textStyle="serif"
              />
            </div>
            <MediumGap />
            <div className="stories" id="stories">
              {collection.stories.map(story => (
                <div key={story.slug}>
                  <StoryCard story={story} />
                </div>
              ))}
            </div>
          </Wrapper>
          <BottomLink
            to={collectionsPath()}
            onClick={() => {
              clearSavedScrollPosition(collectionsPath());
              navigate(collectionsPath());
            }}
          >
            {__('View all collections', 'collection.viewAllCollections')}
          </BottomLink>
        </CenterColumnLayout>
      </>
    );
  }
);
