import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { Button, IconButton } from '@naan/primitives/button';
import { Responsive } from '@naan/primitives/responsive';
import { ArrowRightSmallIcon } from '@naan/icons/arrow-right-icon';

import __ from '@core/lib/localization';

const Link = styled(RouterLink, {
  color: 'inherit',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  textDecoration: 'none',
});

export const ViewStoryButton = observer(
  ({ permalink }: { permalink: string }) => {
    return (
      <Link to={permalink}>
        <Responsive
          renderDefault={() => (
            <IconButton
              icon={<ArrowRightSmallIcon />}
              size="small"
              presentation="grayLight"
            />
          )}
          renderMediumAndUp={() => (
            <Button
              label={__('View story', 'viewStory')}
              size="small"
              presentation="grayLight"
            />
          )}
        />
      </Link>
    );
  }
);
