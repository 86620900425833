import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { Story } from '@core/models/story-manager';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { styled } from '@naan/stitches.config';
import { VolumeTagLinks } from 'components/volume-layouts/volume-tag-links';
import { StoryVoicesCreditsModal } from 'components/story-detail/story-voices-credits-modal';
// import { StoryStatusBar } from 'components/story-status/story-status-bar';
// import { Responsive } from '@naan/primitives/responsive';
import { VSpacer } from '@naan/primitives/spacer';
import { StoryDebugInfo } from 'components/admin/masala-admin';
import { observer } from 'mobx-react';
// import { Sticky } from './sticky.styles';
import { MarkSimple } from '@naan/primitives/text';
import { ArrowTopRightExtraSmallIcon } from '@naan/icons/arrow-top-right-icon';
import { Button } from '@naan/primitives/button';
import { discoverStoryPath } from 'components/nav/path-helpers';
import { useNavigate } from 'react-router-dom';
import { defaultBarColor, useThemeColor } from '@common/pwa/set-theme-color';
import { Image } from '@naan/primitives/image';
import { StoryDownloadedBadge } from 'components/stories/story-downloaded-badge';
import { ShortsBadge } from 'components/stories/shorts-badge';

import __ from '@core/lib/localization';

const StoryDetailCardWrapper = styled('div', {
  paddingBottom: '40px',
  color: '$white',
  '& .thumbnail': {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 8,
    paddingBottom: 12,
    '& img': {
      width: 192,
      height: 192,
      borderRadius: 8,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
      aspectRatio: 1,
    },
  },

  '& .text': {
    '& > header': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '40px',
      '& > h1': {
        margin: 0,
        textStyle: 'medium-heading',
        '& > p': {
          display: 'inline',
        },
      },
      '& > .story-meta': {
        margin: 0,
        marginTop: 4,
        textStyle: 'small-text',
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
      },
    },
    '& > .tagline': {
      textStyle: 'small-heading',
      marginBottom: '$space$3',
    },
    '& .tags': {
      marginTop: '20px',
    },
  },

  '@medium': {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    paddingTop: 24,
    paddingBottom: 56,
    '& .thumbnail': {
      padding: 0,
    },
    '& > .text': {
      maxWidth: 624,
      paddingRight: 24,
      '& > header': {
        alignItems: 'flex-start',
        '& > h1': {
          textStyle: 'extra-large-heading',
        },
      },
    },
  },

  '@extraLarge': {
    '& .thumbnail img': {
      width: 304,
      height: 304,
    },
  },
});

// const StoryStatusWrapper = styled(Sticky, {
//   top: '$$smallHeaderHeight',
// });

export const STORY_TITLE_ID = 'story-title';

export const StoryDetailCard = observer(({ story }: { story: Story }) => {
  const navigate = useNavigate();
  const { classroomEnabled } = AppFactory.root.userManager;
  useThemeColor(story.themeColor, defaultBarColor);

  return (
    <>
      <CenterColumnLayout backgroundColor={story.themeColor}>
        <StoryDetailCardWrapper>
          <div className="thumbnail t-x">
            <Image
              src={story.listImageUrl}
              alt={story.title}
              style={{ backgroundColor: story.themeColor }}
            />
          </div>
          <div className="text">
            <header id={STORY_TITLE_ID}>
              <h1>
                <span className="todo story-accessory" />
                <MarkSimple source={story.title} />
              </h1>
              <span className="story-meta">
                {story.isShort ? <ShortsBadge onDark /> : null}
                {story.durationDescription}
                <span className="downloaded-badge">
                  <StoryDownloadedBadge story={story} withLabel />
                </span>
              </span>
            </header>
            <p className="tagline">
              <MarkSimple source={story.tagline} />
            </p>

            <MarkSimple source={story.description} />
            <StoryVoicesCreditsModal story={story} />
            <div className="tags">
              <VolumeTagLinks
                tags={story.allTagsExceptShorts}
                linkPrefix="/stories"
                mode={'dark'}
              />
            </div>
            {classroomEnabled ? (
              <>
                <VSpacer size={6} />
                <Button
                  rightIcon={<ArrowTopRightExtraSmallIcon />}
                  size="small"
                  label={__('View in Classrooms', 'viewInClassrooms')}
                  presentation="whiteDarken"
                  onClick={() => {
                    navigate(discoverStoryPath(story)); // TODO: confirm desired behavior with @daniel
                  }}
                />
              </>
            ) : null}

            {/* <StoryCacheStateUgly story={story} /> */}
            <StoryDebugInfo story={story} />
          </div>
        </StoryDetailCardWrapper>
      </CenterColumnLayout>
      {/** todo: refactor <StoryStatusBar /> and move this lot in */}
      {/* story.started ? (
        <StoryStatusWrapper>
          <CenterColumnLayout backgroundColor={story.themeColor}>
             <StoryStatusBar />
            <VSpacer size={4} />
          </CenterColumnLayout>
        </StoryStatusWrapper>
      ) : null */}
    </>
  );
});
