import React from 'react';
import { observer } from 'mobx-react';
import { StoryCard } from 'components/stories/story-card';
import { AppFactory } from '@app/app-factory';
import { SectionHeading } from './dashboard-section-heading';
import { Divider } from '@naan/primitives/divider';

import __ from '@core/lib/localization';

export const SampleStories = observer(() => {
  const { storyManager } = AppFactory.root;

  return (
    <>
      <span id="free"></span>
      <SectionHeading
        title={__('Sample stories', 'dashboard.sampleStories')}
        description={__(
          'Here are a couple of our favorite stories for you to try out. Dive in, and learn to listen like a native.',
          'coupleOfFavorites'
        )}
      />
      {storyManager.trial.map(story => (
        <React.Fragment key={story.slug}>
          <Divider />
          <StoryCard story={story} truncateDescription={true} />
        </React.Fragment>
      ))}
    </>
  );
});
