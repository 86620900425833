import * as React from 'react';
import { FeedbackLink } from './feedback-link';
import { AccountData } from 'core/models/user-manager';

export type Message = {
  title: React.ReactNode;
  body: React.ReactNode | (() => React.ReactNode);
  type?: 'error' | 'success' | 'warning';
  showIcon?: boolean;
};

export const getMessages = (accountData: AccountData) => {
  const paymentData = accountData.paymentData;

  const messages: Record<string, Message> = {
    'extending-subscribe-success': {
      type: 'success',
      title: 'Subscription successful!',
      // body: `You're ready to go. Your next billing date will be ${accountData.fullAccessUntil}.`,
      body: "You're ready to go. You can manage your subscription from your account page.",
    },

    'extending-full-access': {
      type: 'success',
      title: 'Success!',
      // body: `Your full access membership has been extended until ${accountData.fullAccessUntil}.`,
      body: "You're ready to go. You can manage your subscription from your account page.",
    },

    'auto-renew-cancelled-with-days-left': {
      type: 'success',
      title: 'Your subscription has been canceled.',
      body: () => (
        <>
          Thank you for trying Jiveworld. You will continue to have full access
          until {accountData.fullAccessUntil}. Please take a moment to share
          your <FeedbackLink>your feedback</FeedbackLink> — we'd love to know
          what we could improve.
        </>
      ),
    },

    'auto-renew-cancelled-no-days-left': {
      type: 'success',
      title: 'Your subscription has been canceled.',
      body: () => (
        <>
          Thank you for trying Jiveworld. Please take a moment to share your{' '}
          <FeedbackLink>your feedback</FeedbackLink> — we'd love to know what we
          could improve.
        </>
      ),
    },

    // @frank, here's another confirmation modal which I assume you would similarly trimmed.
    // actually, i'm not sure if that can still be reached or not. applying a coupon resulted
    // in a different message.
    'first-full-access': {
      type: 'success',
      title: "You're ready to go!",
      body: `Congratulations! You're on the last leg of your journey to real-world Spanish fluency. All stories are unlocked on your account.`, //: so pick something that catches your eye, dive in, and start listening like a native.`,
    },

    'first-subscribe-success': {
      type: 'success',
      title: "You're subscribed!",
      body: `Congratulations! You're on the last leg of your journey to real-world Spanish fluency. All stories are unlocked on your account.`, //: so pick something that catches your eye, dive in, and start listening like a native.`,
    },

    'card-update-success': {
      type: 'success',
      title: 'Update successful!',
      body: `You're ready to go.`,
    },

    'auto-renew-failure': {
      type: 'error',
      title: 'Payment unsuccessful',
      body: '',
    },

    'auto-renew-failure-with-access': {
      type: 'error',
      title: 'Payment unsuccessful',
      body: `We were unable to charge your card ending ${paymentData?.cardLastFour} on ${accountData.fullAccessUntil}. To ensure uninterrupted access to all stories please check that the card information is correct and valid. Your access will expire after ${accountData.fullAccessUntil}`,
    },

    'checkout-failure': {
      type: 'error',
      title: 'Payment unsuccessful',
      body: () => (
        <>
          Your credit card payment was not processed. Please{' '}
          <FeedbackLink>let us know</FeedbackLink> if you encountered an
          unexpected error.
        </>
      ),
    },

    'new-account-email-verified': {
      type: 'success',
      title: "You're ready to go",
      body: 'Thank you for verifying your email.',
    },

    'student-pricing': {
      type: 'success',
      title: 'Success!',
      body: 'Student discount pricing activated.',
    },

    'discount-pricing': {
      type: 'success',
      title: 'Success!',
      body: 'Discount pricing activated.',
    },

    'purchase-gift': {
      type: 'success',
      title: 'Purchase complete',
      body: `Instructions to redeem gift coupon will appear shortly in your ${accountData.email} inbox. You can forward it to the giftee. The status of the coupon will update below when it is redeemed.`,
    },
  };

  return messages;
};
