import * as React from 'react';
import { styled } from '@naan/stitches.config';
import __ from '@core/lib/localization';

const threeLetterDayOfweekNames = [
  __('Mon', 'mon'),
  __('Tue', 'tue'),
  __('Wed', 'wed'),
  __('Thu', 'thu'),
  __('Fri', 'fri'),
  __('Sat', 'sat'),
  __('Sun', 'sun'),
];

const twoLetterDayOfweekNames = [
  __('Mo', 'mo'),
  __('Tu', 'tu'),
  __('We', 'we'),
  __('Th', 'th'),
  __('Fr', 'fr'),
  __('Sa', 'sa'),
  __('Su', 'su'),
];

const Wrapper = styled('div', {
  position: 'sticky',
  top: 0,
  backgroundColor: '$white',
  padding: '8px 0',
  marginTop: -8,
  marginBottom: 16,
  zIndex: 1,
  '@medium': {
    marginBottom: 32,
  },

  '& .inner': {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridGap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    backgroundColor: '$gray-25',
    padding: '6px 0',
    textStyle: 'small-caps',
    userSelect: 'none',

    '& .dow': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '$gray-500',

      '& .d3l': {
        display: 'none',
      },
      '@medium': {
        '& .d2l': {
          display: 'none',
        },
        '& .d3l': {
          display: 'block',
        },
      },
    },
  },
});

export const CalendarHeader = () => {
  return (
    <Wrapper>
      <div className="inner">
        {twoLetterDayOfweekNames.map((dayName, index) => (
          <div className="dow" key={dayName}>
            <span className="d2l">{dayName}</span>
            <span className="d3l">{threeLetterDayOfweekNames[index]}</span>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};
