import React from 'react';
import * as Dialog from '@naan/primitives/modals';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { MarkSimple } from '@naan/primitives/text';
import { VSpacer } from '@naan/primitives/spacer';
import { Link, useNavigate } from 'react-router-dom';
import { learnStoryPath } from 'components/nav/path-helpers';
import { Image } from '@naan/primitives/image';
import { ArrowRightCircleIcon } from '@naan/icons/arrow-right-circle-icon';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import __ from '@core/lib/localization';

const SmallDialogHeading = styled('div', {
  textStyle: 'body-bold',
  color: '$textSecondary',
  margin: '16px 16px 0',
  paddingBottom: 16,
  borderBottom: '1px solid $colors$gray-100',
  display: 'flex',
  justifyContent: 'center',
});

const StoryHeaderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  paddingTop: 24,
  gap: 16,
  '& > .thumb': {
    width: 224,
    height: 224,
    objectFit: 'cover',
    borderRadius: 8,
    border: '1px solid $colors$gray-100', //@frankharrison the stories I had to work with didn't have a solid background
  },
  '& > .info': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
    '& > .title': {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      textStyle: 'small-heading',
      color: '$textPrimary',
    },
    '& > .meta': {
      textStyle: 'small-text',
      color: '$textSecondary',
    },
  },
});

const LinkToStory = styled(Link, {
  display: 'block',
  textDecoration: 'none',
  color: '$colors$gray-300',
  '&:hover': {
    color: '$colors$gray-400',
  },
});

export const SoundbiteStoryInfoModal = observer(
  ({ model, onDismiss }: { model: SoundbiteModel; onDismiss: () => void }) => {
    const navigate = useNavigate();
    const { story, soundbite } = model;
    return (
      <Dialog.Container onDismiss={onDismiss} open>
        <SmallDialogHeading>
          {__(
            "This week's Soundbites are selected from",
            'thisWeeksSoundbitesAreSelectedFrom'
          )}
        </SmallDialogHeading>
        <Dialog.Body>
          <StoryHeaderWrapper>
            <Image
              src={story.listImageUrl}
              alt={story.title}
              className="thumb"
            />

            <div className="info">
              <div className="title">
                <MarkSimple source={story.title} />
                <LinkToStory
                  to={learnStoryPath(story)}
                  onClick={e => {
                    e.preventDefault();
                    onDismiss();
                    requestAnimationFrame(() => {
                      navigate(learnStoryPath(story));
                    });
                  }}
                >
                  <ArrowRightCircleIcon />
                </LinkToStory>
              </div>
              <div className="meta">
                {story.countries.join(', ')} • {story.durationDescription}
              </div>
            </div>
          </StoryHeaderWrapper>
          <VSpacer size={8}></VSpacer>
          <MarkSimple source={soundbite?.edition?.calendarSummary} />
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            label={__('Continue', 'continue')}
            presentation="teal"
            type="button"
            onClick={onDismiss}
            fullWidth
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);
