//
// version of the localization sub-system which simply bypass the dynamic logic
//

import i18n from 'i18n-js';
import Dayjs from 'dayjs';
import { createLogger } from '@common/log';
import { LocalizatonStore } from './localization-types';

const log = createLogger('localization-disabled');

export const systemDefaultLocale = 'en';
/**
 * This creates a Mobx observable store for the current locale.
 * This allows us to change the locale at runtime and have the UI react to it.
 */
function createLocalizationStore(): LocalizatonStore {
  log.debug('createLocalizationStore');

  function addTranslations(lang: string, strings = {}) {
    log.debug('addTranslations');
  }

  function setLocale(newLocale: string) {
    log.debug('setLocale');
    Dayjs.locale(systemDefaultLocale);
    // getBus('localization').emit('setLocale', resolvedLocale);
  }

  function getLocale() {
    return systemDefaultLocale;
  }

  function onLocaleChange(callback: (...args: any[]) => boolean | void) {
    log.debug('onLocaleChange');
    // return getBus('localization').subscribe('setLocale', callback);
  }

  function translateWithDefault(
    defaultValue:
      | string
      | Record</* 'zero' | 'one' | 'other' */ string, string>,
    scope: string,
    options: i18n.TranslateOptions = {}
  ) {
    return i18n.t(scope, {
      locale: systemDefaultLocale,
      defaultValue: defaultValue as any,
      ...options,
    });

    /// this was the old 'bypass' mode logic which seems to pass through the placeholders
    // if (typeof defaultValue === 'string') {
    //   return defaultValue;
    // }
    // /// we need to always return a string, otherwise react components that accept strings
    // /// will fail typechecking --or worse--, will crash in runtime
    // return defaultValue.one ?? defaultValue.other ?? defaultValue.zero ?? '';
  }

  function getAvailableTranslations() {
    return [systemDefaultLocale];
  }

  return {
    addTranslations,
    setLocale,
    getLocale,
    translateWithDefault,
    onLocaleChange,
    getAvailableTranslations,
    disabled: true,
  };
}

export const store = createLocalizationStore();
