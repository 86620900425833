import * as React from 'react';
import { PointsIcon } from 'naan/icons/points-icon';
import { colors } from 'naan/tokens/colors';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textStyle: 'medium-heading',
  gap: 4,
  color: '$textPrimary',
  '@medium': {
    gap: 6,
    textStyle: 'large-heading',
  },
});

export const TotalPoints = ({ points }: { points: number }) => (
  <Wrapper>
    <PointsIcon color={colors['yellow-500']} />
    <span>{points.toLocaleString()}</span>
  </Wrapper>
);
