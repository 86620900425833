import React from 'react';
import { StoryGuideLicense } from './story-guide-license';
import { styled } from '@naan/stitches.config';
import { Image } from '@naan/primitives/image';

import logos from './logos.png';

const Wrapper = styled('footer', {
  borderTop: '1px solid var(--gray100)',
  padding: '40px 0',

  '@media print, screen and (min-width: 850px)': {
    position: 'relative',
  },

  '& > .logos': {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: 309,
      height: 48,
    },
  },
  '& .copyright': {
    font: 'var(--tiny-text-font)',
    color: 'var(--text-secondary)',
    marginTop: '32px',
    textAlign: 'center',
    '@media print, screen and (min-width: 850px)': {
      position: 'absolute',
      top: '50px',
      right: '0',
      margin: '0',
    },
  },
  '& .license': {
    marginTop: '40px',
  },
});

export const StoryFooter = ({ basic = false }) => {
  return (
    <Wrapper>
      <div className="logos">
        <Image src={logos} alt="Jiveworld" />
      </div>
      <p className="copyright">
        © Jiveworld, SPC {new Date().getFullYear()} — All rights reserved
      </p>
      {basic ? null : (
        <div className="license">
          <StoryGuideLicense learnMore />
        </div>
      )}
    </Wrapper>
  );
};
