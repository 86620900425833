import { createLogger } from 'app/logger';
import { BaseCacher } from './base-cacher';
import { bugsnagNotify } from './services/notification-service';

const log = createLogger('app-state-cacher');

const keyToCacheUrl = (key: string): string => {
  return `/app-state/${key}`;
};

export class AppStateCacher extends BaseCacher {
  static async create(name: string) {
    log.debug(`create(${name})`);
    const instance = new AppStateCacher(name);
    await instance.init();
    return instance;
  }

  async storeObject<T>(key: string, obj: T) {
    if (this.enabled) {
      try {
        const request = keyToCacheUrl(key);
        const text = JSON.stringify(obj);
        log.debug(`storeObject(${key}) - ${text.length} bytes`);
        const response = new Response(text);
        await this.cache.put(request, response);
      } catch (error) {
        this.disable();
        bugsnagNotify(error as Error);
        bugsnagNotify('Cache store failed');
      }
    }
  }

  async fetchObject<T>(
    key: string
    // { force = false /* force attempt even if disabled*/ }: { force?: boolean } = {}
  ): Promise<T> {
    // for now, alwasy attempt to fetch even if our canary test failed
    // if (this.enabled || force) {
    try {
      if (this.cache) {
        // avoid firefox private mode error
        const request = keyToCacheUrl(key);
        const response = await this.cache.match(request);
        if (response) {
          const obj = await response.json();
          return obj as T;
        } else {
          log.info(`getObject(${key}) - not found`);
        }
      }
    } catch (error) {
      this.disable();
      bugsnagNotify(error as Error);
      bugsnagNotify('Cache fetch failed');
    }
    // }
    return undefined;
  }

  async storeRawJson(key: string, text: string): Promise<void> {
    if (this.enabled) {
      try {
        const request = keyToCacheUrl(key);
        const response = new Response(text);
        log.debug(`storeText(${key}) - ${text.length} bytes`);
        await this.cache.put(request, response);
      } catch (error) {
        this.disable();
        bugsnagNotify(error as Error);
        bugsnagNotify('Cache store failed');
      }
    }
  }

  async remove(key: string) {
    // allow the removal attempt even if disabled
    log.debug(`remove(${key})`);
    try {
      const request = keyToCacheUrl(key);
      await this.cache.delete(request);
    } catch (error) {
      // this.disable();
      bugsnagNotify(error as Error);
      bugsnagNotify('Cache remove failed');
    }
  }

  // not currently needed
  // async getText(key: string) {
  //   const request = keyToCacheUrl(key);
  //   const response = await this.cache.match(request);
  //   if (response) {
  //     const text = await response.text();
  //     log.info(`getText(${key}) - ${text.length} bytes`);
  //     return text;
  //   } else {
  //     log.info(`getText(${key}) - not found`);
  //     return undefined;
  //   }
  // }
}

// async verifySupport(): Promise<boolean> {
//   const key = 'canary';
//   const value = 'tweet';
//   await this.storeObject(key, value);
//   const check = await this.fetchObject<string>(key);
//   await this.remove(key);
//   if (value === check) {
//     log.info('support verified');
//     return true;
//   } else {
//     log.warn(`cache check mismatch : ${String(check)}`);
//     this.disable();
//     bugsnagNotify(new Error('Cache verify failed'));
//     return false;
//   }
// }

// more hassle than it's worth without a concrete need

// const JSON_RESPONSE_OPTIONS = {
//   status: 200,
//   statusText: 'OK',
//   headers: new Headers({
//     'Content-Type': 'text/json',
//   }),
// };

// const TEXT_RESPONSE_OPTIONS = {
//   status: 200,
//   statusText: 'OK',
//   headers: new Headers({
//     'Content-Type': 'text/plain',
//   }),
// };
