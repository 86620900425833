// import { nanoid } from 'nanoid';
import { createLogger } from 'app/logger';
import { appConfig } from 'app/env';
import { getProductVersion } from 'app/platform';
import { AnalyticsAdapter } from './analytics-adapter';
import { MixpanelAnalytics } from './adapters/mixpanel-analytics';
import { GoogleAnalytics } from './adapters/ga-analytics';
import {
  embeddedMode,
  embeddedBuildNumber,
  embeddedPlatform,
} from '@core/lib/app-util';
import { AppFactory } from '@app/app-factory';
import { getInstallationId } from '@core/models/installation-id';
import { LocalState } from '@core/models/local-state';
// import rg4js from 'raygun4js';
// import { pick } from 'lodash';

const log = createLogger('analytics');

// export const EMPTY_USER_ID = '_empty_'; // default initializer for UserManager. paranoid avoidance of falsy value

// export const anonymousUserId = '_anonymous_';
// const sessionId = nanoid(); // beware, uuid was barfing with a 'global is not defined' error
const { sessionId } = appConfig;
// const raygunEnabled = appConfig.raygun.enabled;

export class AnalyticsManager {
  private adapters: AnalyticsAdapter[] = [];
  private contextData: Record<string, any> = {};

  constructor() {
    // bind all methods to this
    this.gatherProperties = this.gatherProperties.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    // this.trackEventInAdapters = this.trackEventInAdapters.bind(this);
    // this.trackPageInAdapters = this.trackPageInAdapters.bind(this);
    // this.identifyInAdapters = this.identifyInAdapters.bind(this);
    // this.setContextData = this.setContextData.bind(this);
  }

  // get installationId(): string | null {
  //   return this.contextData.installationId ?? null;
  // }

  get userId(): string | null {
    return this.contextData.accountData?.userId ?? null;
  }

  get hasUserId(): boolean {
    // return (
    //   !!this.contextData.accountData?.userId &&
    //   this.contextData.accountData?.userId !== EMPTY_USER_ID
    // );
    return !!this.contextData.accountData?.userId;
  }

  public addAdapter(service: AnalyticsAdapter) {
    if (service.isEnabled) {
      this.adapters.push(service);
    }
  }

  public trackEvent(eventName: string, eventProperties: any = {}) {
    // log.debug(`trackEvent: ${eventName}`); // adapter logging should be enough
    const properties = this.gatherProperties(eventProperties);
    for (const service of this.adapters) {
      service.track(eventName, properties);
    }
  }

  public trackPage = (pageName: string, pageData?: any) => {
    if (pageName === '/') pageName = 'index';
    const properties = this.gatherProperties(pageData);
    for (const service of this.adapters) {
      service.page(pageName, properties);
    }
  };

  // not currently used
  public startTimeEvent(eventName: string, eventProperties?: any) {
    const start = new Date().getTime();
    return (extraProperties = {}) => {
      const end = new Date().getTime();
      const duration = end - start;
      const props = {
        ...eventProperties,
        ...extraProperties,
        $duration: duration,
      };
      this.trackEvent(eventName, props);
    };
  }

  public identify(userId: string) {
    for (const service of this.adapters) {
      service.identify(userId); // assuming only enabled adapters are included in list
    }
  }

  // needed by current mixpanel api to map new user's distinct_id to previous anonymous events in same session
  public aliasNewAccount(userId: string) {
    for (const service of this.adapters) {
      service.aliasNewAccount(userId);
    }
  }

  // should be called when logging out to treat subsequent events as anonymous with a new anonymous id
  public reset() {
    for (const service of this.adapters) {
      service.reset();
    }
  }

  public offline() {
    for (const service of this.adapters) {
      service.offline();
    }
  }

  public online() {
    for (const service of this.adapters) {
      service.online();
    }
  }

  // // not currently needed
  // public setProfileData(data: object) {
  //   for (const service of this.adapters) {
  //     service.setProfileData(data);
  //   }
  // }

  //
  // no longer side affects with identify calls
  // just sets context for implicit props with subsequent events
  //
  public setContextData(data: any) {
    log.trace(`setContextData, userId: ${data.accountData?.userId}`);
    this.contextData = data;
  }

  // note, this need can also be addressed with direct mixpanel and GA apis (but probably simpler to handle generically here)
  public gatherProperties(eventProperties: any = {}) {
    const date = Date.now();
    const localState: LocalState = this.contextData?.localState;
    const utmData = localState?.latestUtmData || {};
    // const releaseChannel = 'dev_build'; // this is not

    // need rails side support for account age
    // const { email, membershipState, accountAgeDays } = this.contextData?.accountData || {};

    const properties = {
      email: this.contextData?.accountData?.email, // redundant data, but makes live event feed easier to browse
      server_env: this.contextData?.globalConfig?.apiEnv,
      config_env: appConfig.VITE_CONFIG_ENV,
      appSlug: appConfig.appSlug,
      embeddedMode: embeddedMode(),
      embeddedPlatform: embeddedPlatform(),
      embeddedBuildNumber: embeddedBuildNumber(),
      installation_id: getInstallationId(), // todo: remove, redundant $device_id
      user_agent: window.navigator.userAgent,
      session_id: sessionId, // todo: remove
      $ae_session: sessionId,
      event_timestamp: date,
      // Note, the proper Mixpanel key here should be $app_version_string, but this value seems to get
      // stomped by Segment even when we provide here, so using a more distinctly named key for our purposes.
      // jw_app_version: getProductVersion(),
      $app_version_string: getProductVersion(),
      // anonymous_track: this.hasUserId, // this was backwards for eons
      anonymous: !this.hasUserId,
      // membershipState,
      standalone: this.contextData?.miscInfo?.standalone,
      firstVisit: this.contextData?.miscInfo?.firstVisit,
      firebaseStatus: AppFactory.firebaseConnection.status,
      offline: AppFactory.root.offline,
      ...utmData,
      ...eventProperties,
    };
    return properties;
  }

  // not currently used
  public autoTrackBefore<T>(
    fn: (...args: T[]) => void,
    eventName: string,
    ...eventArgs: any[]
  ) {
    return (...args: T[]) => {
      this.trackEvent(eventName, ...eventArgs);
      fn(...args);
    };
  }

  // not currently used
  public autoTrackAfter<T>(
    fn: (...args: T[]) => void,
    eventName: string,
    ...eventArgs: any[]
  ) {
    return (...args: T[]) => {
      fn(...args);
      this.trackEvent(eventName, ...eventArgs);
    };
  }

  //
  // console convenience
  //

  get mixpanelNode(): MixpanelAnalytics {
    return this.adapters.find(
      adapter => adapter instanceof MixpanelAnalytics
    ) as MixpanelAnalytics;
  }

  get mixpanel(): MixpanelAnalytics {
    return this.adapters.find(
      adapter => adapter instanceof MixpanelAnalytics
    ) as MixpanelAnalytics;
  }

  get google(): GoogleAnalytics {
    return this.adapters.find(
      adapter => adapter instanceof GoogleAnalytics
    ) as GoogleAnalytics;
  }
}
