import * as React from 'react';

import { AppFactory } from '@app/app-factory';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';
import { AvatarLargeIcon } from '@naan/icons/avatar-icon';
import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { createAccountPath, homePath } from 'components/nav/path-helpers';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { Link, useNavigate } from 'react-router-dom';

import __ from '@core/lib/localization';
import { getPageBackTracker } from '@app/track';

const Wrapper = styled('div', {
  backgroundColor: '$blue-600',
  color: '$white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: 16,
  paddingTop: 32,
  gap: 8,
  borderRadius: 12,
  a: {
    textDecoration: 'none',
  },
  '& > .icon': {
    marginBottom: 4,
  },
  '& > .title': {
    textStyle: 'small-heading',
  },
  '& > .copy': {
    marginBottom: 16,
  },
  '@widerThanScript': {
    borderRadius: 8,
  },
});

const promptStrings = {
  pumpkin: __(
    'This Daily Soundbite turns into a pumpkin at midnight, unless you have an account.',
    'thisDailySoundbiteTurnsIntoAPumpkin'
  ),
  createFreeAccount: __(
    'Create a free account to save your progress and access your Soundbites and Stories from any device.',
    'createAFreeAccountToSaveYourProgress'
  ),
};

const FLOW = 'spa-sb-endcard';

export const AnonSection = ({
  usePumpkinRemark = false,
}: {
  usePumpkinRemark?: boolean;
}) => {
  const navigate = useNavigate();
  const trackBack = getPageBackTracker();

  // runs when this component unmounts
  React.useEffect(() => {
    return () => {
      AppFactory.root.userManager.userData.stopShowingStreakInterstitial();
    };
  }, []);

  return (
    <Wrapper>
      <div className="icon">
        <AvatarLargeIcon width={40} height={40} />
      </div>
      <div className="title">Want to save your progress?</div>
      <div className="copy">
        {usePumpkinRemark
          ? promptStrings.pumpkin
          : promptStrings.createFreeAccount}
      </div>

      <Link
        to={createAccountPath({
          flow: FLOW,
        })}
      >
        <Button
          label={__('Yes, create my free account', 'yesCreateMyFreeAccount')}
          presentation={'teal'}
          size={'large'}
          fullWidth
          rightIcon={<ArrowRightIcon />}
        />
      </Link>
      <Button
        label={__('No, continue without account', 'noContinueWithoutAccount')}
        presentation={'whiteTransparent'}
        size={'large'}
        fullWidth
        onClick={() => {
          if (ReturnNavState.canPopFromStudy()) {
            trackBack();
            navigate(-1);
          } else {
            const path = homePath();
            trackBack(path);
            navigate(path);
          }
        }}
      />
    </Wrapper>
  );
};
