import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { ClassroomWidget } from './classroom-widget';
import { LastListenedWidget } from './started-widget';
import { LastQueuedWidget } from './queued-widget';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { LearnDashboardHeading } from './dashboard-heading';
import { QuickLinks } from 'components/account/quick-links';
import { MediumGap, LargeGap } from '@naan/primitives/spacer/gaps';
import { SoundbiteEtAlWidgetGroup } from './soundbite-et-al-widget-group';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';
import { useLocation } from 'react-router-dom';
import { FeaturedContent } from './featured-content';
import { DashboardFooter } from './dashboard-footer';
import {
  LearnWelcomeMessage,
  TeacherWelcomeMessage,
} from '../welcome-message/full-access-welcome-message';

// import { NewsletterDialog } from 'components/account/newsletter-dialog';
import { AboutRadioAmbulante } from './about-ra';
import { useThemeColor } from '@common/pwa/set-theme-color';

// full-access flavor
export const LearnDashboard = observer(() => {
  useThemeColor('white');
  const { storyManager, userManager } = AppFactory.root;
  const { accountData } = userManager;
  const location = useLocation();

  const classrooms = accountData.joinedClassroomsWithAssignments;
  const { featuredInProgress } = storyManager;
  const featuredQueued = !featuredInProgress
    ? storyManager.featuredQueued
    : null;

  return (
    <>
      <DeepScrollRestoration pageKey={location.pathname} />
      <LearnDashboardHeading />
      <CenterColumnLayout isolate>
        <LearnWelcomeMessage />
        <TeacherWelcomeMessage />
        <SoundbiteEtAlWidgetGroup />
        {/* gap included in prior widget group */}
        {/* <MediumGap /> */}
        {classrooms.map(classroom => (
          <React.Fragment key={classroom.id}>
            <ClassroomWidget classroom={classroom} />
            <MediumGap />
          </React.Fragment>
        ))}
        {/* // only one of these two will be non-null */}
        {featuredInProgress ? (
          <>
            <LastListenedWidget story={featuredInProgress} />
            <MediumGap />
          </>
        ) : null}
        {featuredQueued ? (
          <>
            <LastQueuedWidget story={featuredQueued} />
            <MediumGap />
          </>
        ) : null}
        <FeaturedContent />
        <LargeGap />
        <AboutRadioAmbulante />
        <MediumGap />
        <QuickLinks />
        <LargeGap />
        <DashboardFooter />
        {/* <NavLink to="/logout">[logout]</NavLink> */}
      </CenterColumnLayout>
      {/* <NewsletterDialog /> */}
    </>
  );
});
