import * as React from 'react';
import { Image } from '@naan/primitives/image';

import raLogoNormal from './sbra-normal.png';
import raLogoOnDark from './sbra-ondark.png';

type RaLogoPresentation = 'normal' | 'onDark';

export const RaLogo = ({
  presentation = 'normal',
  ...props
}: {
  presentation?: RaLogoPresentation;
} & React.ComponentProps<typeof Image>) => {
  const src = presentation === 'normal' ? raLogoNormal : raLogoOnDark;
  return <Image src={src} {...props} />;
};
