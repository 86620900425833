import * as React from 'react';

export const PonderIcon = () => (
  <svg
    width={73}
    height={72}
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47787 40.598C5.96611 38.1604 3.66675 34.0988 3.66675 29.5001C3.66675 22.0443 9.7109 16.0001 17.1667 16.0001C17.6372 16.0001 18.1021 16.0242 18.5602 16.0712C22.137 10.0236 28.4984 6 35.7501 6C40.492 6 44.8532 7.7204 48.304 10.6021C49.6909 10.21 51.1543 10.0001 52.6667 10.0001C61.5033 10.0001 68.6667 17.1636 68.6667 26.0001C68.6667 31.5003 65.8915 36.3522 61.6649 39.232C62.315 41.2036 62.6667 43.3107 62.6667 45.5C62.6667 56.5457 53.7124 65.5 42.6667 65.5C38.3506 65.5 34.3539 64.1328 31.0859 61.8079C28.9293 62.8905 26.4942 63.5 23.9168 63.5C15.0802 63.5 7.91676 56.3366 7.91676 47.5C7.91676 45.0284 8.47718 42.6877 9.47787 40.598Z"
      fill="#FFE5E5"
    />
    <path
      d="M26.9165 29.2573C26.9165 28.0441 27.3035 26.8181 28.0775 25.5793C28.8515 24.3278 29.9808 23.2933 31.4654 22.476C32.9499 21.6587 34.6819 21.25 36.6613 21.25C38.5012 21.25 40.1253 21.5948 41.5338 22.2844C42.9422 22.9613 44.0271 23.8872 44.7884 25.0621C45.5624 26.237 45.9494 27.5141 45.9494 28.8933C45.9494 29.9788 45.7274 30.9303 45.2833 31.7476C44.8518 32.5649 44.3316 33.2737 43.7226 33.8739C43.1262 34.4614 42.0477 35.4575 40.487 36.8623C40.0555 37.2582 39.7066 37.6094 39.4402 37.9159C39.1864 38.2096 38.9961 38.4842 38.8692 38.7396C38.7423 38.9823 38.6408 39.2313 38.5646 39.4867C38.5012 39.7294 38.3997 40.1636 38.2601 40.7893C38.019 42.1175 37.2641 42.7816 35.9952 42.7816C35.3354 42.7816 34.7771 42.5645 34.3203 42.1303C33.8762 41.6961 33.6541 41.0511 33.6541 40.1955C33.6541 39.1227 33.8191 38.1969 34.149 37.4178C34.4789 36.626 34.9167 35.9364 35.4623 35.349C36.0079 34.7487 36.7438 34.04 37.6701 33.2226C38.4822 32.5075 39.0658 31.9711 39.4211 31.6135C39.7891 31.2432 40.0936 30.8345 40.3347 30.3875C40.5885 29.9405 40.7154 29.4552 40.7154 28.9316C40.7154 27.91 40.3347 27.0479 39.5734 26.3456C38.8248 25.6432 37.8541 25.292 36.6613 25.292C35.2656 25.292 34.2378 25.6495 33.578 26.3647C32.9182 27.0671 32.3599 28.1079 31.9031 29.4872C31.4717 30.9303 30.6533 31.6518 29.4479 31.6518C28.7373 31.6518 28.1346 31.4028 27.6398 30.9047C27.1576 30.3939 26.9165 29.8448 26.9165 29.2573ZM36.2046 50.2525C35.4306 50.2525 34.7517 50.0035 34.168 49.5054C33.5971 48.9946 33.3116 48.2858 33.3116 47.3791C33.3116 46.5745 33.5907 45.8977 34.149 45.3485C34.7073 44.7994 35.3925 44.5248 36.2046 44.5248C37.0039 44.5248 37.6764 44.7994 38.222 45.3485C38.7677 45.8977 39.0405 46.5745 39.0405 47.3791C39.0405 48.273 38.755 48.9754 38.184 49.4862C37.613 49.9971 36.9532 50.2525 36.2046 50.2525Z"
      fill="#FF534F"
    />
  </svg>
);

export const PlayIcon = () => (
  <svg
    width={73}
    height={72}
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.5 64.5C52.2401 64.5 65 51.7401 65 36C65 20.2599 52.2401 7.5 36.5 7.5C20.7599 7.5 8 20.2599 8 36C8 51.7401 20.7599 64.5 36.5 64.5Z"
      fill="#DAF3DF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.1398 36.994L31.6817 48.9589C31.1567 49.3187 30.4524 49.1651 30.1086 48.6158C29.9874 48.4222 29.9229 48.1958 29.9229 47.9644L29.9229 24.0346C29.9229 23.378 30.4316 22.8457 31.0591 22.8457C31.2803 22.8457 31.4966 22.9132 31.6817 23.0401L49.1398 35.0049C49.6648 35.3647 49.8116 36.1016 49.4678 36.6509C49.3822 36.7877 49.2705 36.9045 49.1398 36.994V36.994Z"
      fill="#44C560"
    />
  </svg>
);

export const RevealIcon = () => (
  <svg
    width={73}
    height={73}
    viewBox="0 0 73 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 28.2861C18.5 41.2294 29 44.0129 29 51.25H44.75C44.75 44.0129 55.25 41.2294 55.25 28.2861C55.25 16.5024 45.9017 10.75 36.6638 10.75C27.4259 10.75 18.5 16.5024 18.5 28.2861Z"
      fill="#FDDC66"
    />
    <path
      d="M34.3955 51.5822V37.5155C34.3486 36.6246 33.7766 34.8428 31.8635 34.8428C28.4875 34.8428 27.9248 40.0475 32.7075 40.0475H41.0412C45.8239 40.0475 45.2612 34.8428 41.8852 34.8428C39.9721 34.8428 39.4 36.6246 39.3532 37.5155V51.5822"
      stroke="#F5832B"
      strokeWidth={2}
    />
    <path
      d="M29 51.25H44.75V55V58.75V62.5L41.75 66.25H32L29 62.5V58.75V55V51.25Z"
      fill="#B3B3B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.625 5.5V0.25H34.625V5.5H37.625ZM14 30.25H8.75V27.25H14V30.25ZM65 30.25H59.75V27.25H65V30.25ZM24.1385 9.68958L21.5135 5.14294L24.1115 3.64294L26.7365 8.18958L24.1385 9.68958ZM49.6115 9.68982L52.2365 5.14319L49.6385 3.64319L47.0135 8.18982L49.6115 9.68982ZM11.768 15.9864L16.3147 18.6114L17.8147 16.0133L13.268 13.3883L11.768 15.9864ZM55.9353 41.4864L60.482 44.1114L61.982 41.5133L57.4353 38.8883L55.9353 41.4864ZM17.8147 41.4864L13.268 44.1114L11.768 41.5133L16.3147 38.8883L17.8147 41.4864ZM61.982 15.9864L57.4353 18.6114L55.9353 16.0133L60.482 13.3883L61.982 15.9864Z"
      fill="#F9D23B"
    />
    <rect x={29} y="51.25" width="15.75" height="3.75" fill="#E6E6E6" />
    <rect x={29} y="58.75" width="15.75" height="3.75" fill="#E6E6E6" />
  </svg>
);
