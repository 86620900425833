/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { track } from 'app/track';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@naan/stitches.config';
import { HeroSection } from './hero-section';
import { TopBar } from './top-bar';
import { EndorsementSection } from './endorsement-section';
import { LearnSection } from './learn-section';
import { FeaturesSection } from './features-section';
import { TakeStepsSection } from './take-steps-section';

const Wrapper = styled('div', {
  // $$maxWidth: '1296px',
});

export const SoundbiteOrientationScreen = () => {
  const [queryParams /*, setSearch*/] = useSearchParams();
  const continueBasePath = queryParams.get('continue');
  const continuePath = `${continueBasePath}?orientation=skip`;

  React.useEffect(() => {
    track('soundbite__orientation', {
      path: continueBasePath,
    }); // mostly redundant with generic page tracking, needed?
  }, [continueBasePath]);

  return (
    <Wrapper>
      <TopBar />
      <HeroSection continuePath={continuePath} />
      <LearnSection continuePath={continuePath} />
      <EndorsementSection />
      <FeaturesSection continuePath={continuePath} />
      <TakeStepsSection continuePath={continuePath} />
    </Wrapper>
  );
};
