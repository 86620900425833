import { nanoid } from 'nanoid';
import { LogLevel, logLevels } from '@common/log/logger-types';

const l = window.location;
const baseUrl = l.protocol + '//' + l.hostname + (l.port ? `:${l.port}` : '');
const accountUrl = `${baseUrl}/app/profile/account`;
const learnDashboardUrl = `${baseUrl}/app/learn/es/home`;

export const sessionId = nanoid(); // beware, uuid was barfing with a 'global is not defined' error

//
// base config data
//
// any supported property should have a default value provide here
//

export const config = {
  VITE_CONFIG_ENV: import.meta.env.VITE_CONFIG_ENV, // for debug reference

  appName: 'MyLupa',
  appSlug: 'cali',
  // productFlavor: null as string, // TODO: confirm what this was intended for
  // appTitle: import.meta.env.VITE_SITE_TITLE ?? '',

  apiEnv: import.meta.env.VITE_DEFAULT_API_ENV ?? 'devtest',
  caliServerEnv:
    import.meta.env.VITE_DEFAULT_CALI_SERVER_ENV || // allow override for local dev, but usually mirrors main apiEnv
    import.meta.env.VITE_DEFAULT_API_ENV ||
    'devtest',

  // couldn't figure out a way to disable the lint error for this, but don't really need
  // internalBuild: '__INTERNAL__' !== 'noop',

  // endpoints for which CORS is disabled
  // needed for for the preview deploys to work, but we can't use cross domain cookie
  promiscuousApiEnvs: ['devtest', 'jfedev'],

  importApiEnv: 'prod', // used for user data migration testing
  showImportAction: true, // for now true except in prod

  swCheckEnabled: true, // false implies legacy version.txt checker (except neither on DEV builds)
  updateCheckIntervalMs: 1 * 60 * 1000, // 1 min check interval for internal deployments

  // todo: reorganize all these top level flags
  simulateMissingServiceWorker: false, // override to true to test dump poll flows (ios webview)

  // no longer a config, will used automatically if swCheck disabled or unavailable (but never on dev builds)
  // legacyVersionCheckerEnabled: false,
  // legacyVersionCheckerIntervalMs: 1 * 60 * 1000, // for testing for now (only relevant to ios wrapper)

  inviteGateEnabled: false, // switching this now to only be enabled for beta
  piMenuEnabled: true, // true except beta and prod

  // hideDashboardOnboarding: false, // true on beta, will hide the dashboard onboarding flow until ready for prime-time

  manifestVersion: 'proto',

  // putting this in config to it's accessible by both analytics and loggly without cross dependency
  // perhaps belongs in a dedicated source file
  sessionId,

  // volumeMode: true,

  jiveworldApiKey: {
    website: 'QowNmFgzVLu4WnattXzN', // for now, shared for all deployment environments
  },

  enableSoundbitesOrientationVideo: true,

  // bugsnag: {
  //   apiKey: '56ed11fed6bfebab28223aa62b630786',
  //   enabled: !import.meta.env.DEV, // disable by default for local dev builds
  // },

  sentry: {
    enabled: !import.meta.env.DEV,
    /// spa-internal dsn
    dsn: 'https://c490631093aa4f42b6cd8929b706a766@o260366.ingest.sentry.io/1523389',
    enableOffline: true,

    tracesSampleRate: 0.25, // performance metrics

    // this is expensive, so keep off by default and for development
    enableReplay: false,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0,
  },

  // raygun: {
  //   enabled: true, // enable for all environments for more burn-in. (in future will probably limit to staging/beta/LIVE)
  //   apiKey: 'SJ6IEsfULknED9ju08wvlA', // spa-test
  // },

  invariants: {
    log: false, // simple console log, not needed when 'alert' enabled
    report: true, // to bugsnag
    alert: true, // attempt red toast
    // will possibly want to turn this on by default for dev, but want to clean up a few things first in a separate PR
    throw: false, // hard error (likely render failure) // !!import.meta.env.DEV
  },

  // enables red toast for unexpected errors
  showUnhandledErrors: true, // on by default except for lupa-prod, beta
  // include raw error message in toasts for generically handled exceptions
  showErrorDetails: true, // on by default except for lupa-prod, beta
  showWarningAlerts: true, // yellow toasts are enabled for dev and test, but disabled for beta, staging, live

  // localization sub-system may be disabled via spa.vite.config.ts
  i18n: {
    showBrackets: false,
  },

  firebase: {
    // probability to bypass firebase connection initialization during app-init
    // (and use node rest api instead for duration of app session)
    // 0.0 -> always attempt to initialize firebase
    // 1.0 -> never use client-side firebase connection
    // 0.5 -> 50/50 split
    // forceDisconnectedSampleRate: 0.5,
    forceDisconnectedSampleRate: 0, // turn off a/b test until disconnected mode data sync is more stable
  },

  google: {
    clientId:
      '903123262392-86hnsj69ds3oatf615tuf7q4vf4kajh7.apps.googleusercontent.com',
  },

  stripe: {
    publishableKey: 'pk_test_Zri5LbMMapbrkavc5yW3Z1fV00rJUj8DZw',
    ignoreCancelAutoRenewErrors: true, // must be true currently to cancel autorenew on suspended accounts
  },

  analytics: {
    mixpanelNode: {
      enabled: true,
      debug: false,
      // note, typical event payloads are about 1k, so this config allows about 1000 events to be queued while offline
      persistBytesLimit: 1000000, // limit bytes of total storage before we start truncating events
      truncationCount: 100, // number of records at a time to discard
    },

    mixpanel: {
      projectToken: null as string,
      debug: false, // https://developer.mixpanel.com/docs/javascript#debug-mode
    },

    //'HP0iVsUQB6F9CawQ6uVEZLD9kpcrZcJ3', // internal testing
    // segmentWriteKey: null as string, // segment is disabled if no key provided, enabled when provided
    enableSimulatedAnalytics: false,
    // this was poorly named. it's actually forcing all anonymous users to have the fixed '_anonymous_' id
    // instead of the using a default behavior of a local storage peristed unique id
    // enableAnonymous: true,
    // segmentCoalesceAnonymous: false, // turning this off to see how things behave with the new segment.js library
    eventPrefix: 'w',
    googleAnalyticsKey: null as string, //'UA-143472151-6', // set to null to disable
  },

  logging: {
    // `enableConsoleLog` when true, the logger‘s use of console.log is short-circuited
    enableConsoleLog: !!import.meta.env.DEV,

    minimumLogLevel: logLevels.debug as LogLevel,

    // note, even when enabled, the current session still needs to be activated to start sending data
    enableLoggly: true, // allow to always be enabled (but not active) by default
    logglyToken: 'f3cc9f4c-d6fe-453c-8f16-8141da497da3',
    logKeyDowns: false,
  },

  // if true, then partially support affiliate attribution via local state instead of server scoped cookies
  crossSiteCookiesSupported: false,

  brownListedBrowserWarningEnabled: true,

  website: {
    baseUrl,
    staticPath: `/assets`,
    accountUrl,
    // checkoutSuccessUrl: `${accountUrl}?checkout=success`,
    checkoutSuccessUrl: `${learnDashboardUrl}?checkout=success`,
    checkoutFailureUrl: `${accountUrl}?checkout=error`,

    // domain scope for sharing cookies between the marketing site, spa and rails
    // only work for staging, beta, LIVE (because of loose CORS rules on devtest needed for preview deploys)
    cookieDomain: '.staging.jiveworld.app',

    urls: {
      marketingSite: 'https://www.staging.jiveworld.app',
      support: 'https://support.jiveworld.com',
      jwPressServer: 'https://resources.staging.jiveworld.app',
      facebookTeachersGroup: 'https://www.facebook.com/groups/jiveworld',

      // link from global warning banner for desktop safari
      browserSupport: 'https://go.jw.app/browser-support',

      // downloadApp: 'https://get.lupa.app', // will likely be obsolete
      // spa: 'https://my.lupa.app',
      // ourMethod: 'https://www.jiveworld.com/en/our-method/',
    },
    emails: {
      feedback: 'feedback@jiveworld.com',
      support: 'support@jiveworld.com',
      educationalSales: 'sales@jiveworld.com',
    },
    emailOptions: {
      subject: 'My feedback on Jiveworld',
      body: "Please explain what worked, what didn't work, and what you'd like to see improved",
    },
    // trust to be overridden for staging, beta, live
    downloadUrls: {
      appStore:
        // we don't have a good way to link to test flight builds, so just always
        // link to the public build for testing the update banner flows.
        //
        // ls=1 -> LS – Load Store: This parameter, with the value of "1", tells the Preview Page
        // to also load the item in the iTunes Store. If you don't use either an affiliate parameter
        // or the LS parameter, the link will only take the user to the Preview Page in their browser.
        // (not 100% if this is still relevant)
        'https://apps.apple.com/us/app/jiveworld-language-learning/id1480744663?ls=1',
      playStore: 'https://go.jiveworld.com/cali-android-black',
    },
  },

  // looks obsolete
  classrooms: {
    exampleLessonPlansUrl: 'https://go.lupa.app/lupa-lessons',
  },

  soundbites: {
    disabled: false, // if true, force hide all soundbite UI (guarantee not bled to LIVE until ready)

    // base date to resolve edition visibility
    // (will need to be part of catalog data in the future)
    epochDate: '2023-05-29',

    // probably not really needed, drive old admin raw list view
    listEndpoint: 'https://masala-v2-server.editorial.jiveworld.com/excerpts',
    // listEndpoint: 'https://masala-v2-server.devtest.jiveworld.com/excerpts',

    // obsolete config from original trial
    // formUrl: 'https://go.lupa.app/sb-signup',
    // demoDataUrl: 'https://storage.googleapis.com/jw-devtest-pub/ra/ra-luna-llena-sobre-chiapas/excerpt/ra-luna-llena-sobre-chiapas-1-ra-luna-llena-sobre-chiapas-1-qlj-v5.json',
  },

  player: {
    debug: true, // turns on the '~' debug mode by default when either study or soundbite player is loaded
    enableOldIosScrollFix: false,
  },

  masalaBaseUrl: 'https://masala.editorial.jiveworld.com',

  // by default, relative to hosted url
  // TODO: test out aliasing to '/api'
  lambdaFunctions: {
    enabled: false, // TODO: sort our best local dev solution
    // baseUrl: '/.netlify/functions',
    baseUrl: '/api',
  },

  admin: {
    // urls from which to sync latest catalog slug to url mappings
    catalogSyncDataUrls: [
      // 'https://masala-v2-server.devtest.jiveworld.com/allCatalogUrls',
      // 'https://masala-v2-server.editorial.jiveworld.com/allCatalogUrls',
    ] as string[],
  },

  mailingList: {
    // except will always exclude `@jiveworld.com` email unless `+ml` also included
    autoOptInEnabled: false,
  },

  authShortCodeEnabled: true,

  // allow disabling of the purchase flows when embedded to native app
  iosNoPurchase: false, // todo: flip these to server driven flags
  androidNoPurchase: false,
  forceNoPurchase: false, // simulate disallowed purchase for easier testing
  accountCreationDisabled: false, // simulate review mode auth restriction
  disableGoogleAuth: true, // will probably force google auth off entire once we remove from native wrapper

  includeShowcase: true, // turning this back on by default for now since it doesn't yet reduce the build size
};
