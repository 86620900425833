import * as React from 'react';
import { styled } from '@naan/stitches.config';

const Container = styled('div', {
  $$iconSize: '72px',
  $$columns: 3,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '$space$8 $space$10 $space$8 $space$10',
  backgroundColor: '$$backgroundColor',
  height: '100%',

  borderRadius: '8px',

  '@medium': {
    py: 'unset',
    justifyContent: 'center',
    // height: 224,
  },

  '@large': {
    // height: 296,
  },

  '& > .icon svg': {
    width: '$$iconSize',
    height: '$$iconSize',
    color: '$$iconColor',
  },
  '& > .stat': {
    textStyle: 'medium-heading',
    marginTop: '$space$2',
    marginBottom: '$space$1',
  },
  '& > .label': {
    textStyle: 'small-caps',
    color: '$black-alpha-50',
    textAlign: 'center',
  },

  variants: {
    presentation: {
      yellow: {
        $$backgroundColor: '$colors$yellow-100',
        $$iconColor: '$colors$yellow-500',
      },
      orange: {
        $$backgroundColor: '$colors$orange-100',
        $$iconColor: '$colors$orange-500',
      },
      green: {
        $$backgroundColor: '$colors$green-100',
        $$iconColor: '$colors$green-500',
      },
      blue: {
        $$backgroundColor: '$colors$blue-100',
        $$iconColor: '$colors$blue-500',
      },
      purple: {
        $$backgroundColor: '$colors$purple-50',
        $$iconColor: '$colors$purple-500',
      },
      teal: {
        $$backgroundColor: '$colors$teal-100',
        $$iconColor: '$colors$teal-500',
      },
    },
    isLarge: {
      true: {
        $$height: '296px',
        $$columns: 2,
        '@medium': {
          $$iconSize: '96px',
        },
      },
    },
  },
  defaultVariants: {
    presentation: 'yellow',
    isLarge: false,
  },
});

type ContainerProps = React.ComponentProps<typeof Container>;

export const SimpleStat = ({
  icon,
  stat,
  label,
  isLarge = false,
  ...props
}: {
  icon: React.ReactNode;
  stat: string;
  label: string;
  isLarge?: boolean;
} & ContainerProps) => {
  return (
    <Container isLarge={isLarge} {...props}>
      <div className="icon">{icon}</div>
      <div className="stat">{stat}</div>
      <div className="label">{label}</div>
    </Container>
  );
};
