import { Image } from '@naan/primitives/image';
import { styled } from 'naan/stitches.config';
import { Link } from 'react-router-dom';

export const columnGap = '16px';

export const thumbnailSize = {
  extraSmall: '80px',
  medium: '104px',
  large: '148px',
};

export const Figure = styled(Link, {
  width: '$$thumbnailSize',
  height: '$$thumbnailSize',
});

export const Thumbnail = styled(Image, {
  width: '$$thumbnailSize',
  height: '$$thumbnailSize',
  borderRadius: '$space$2',
});

export const Wrapper = styled('div', {
  $$thumbnailSize: thumbnailSize.extraSmall,
  $$columnGap: columnGap,
  position: 'relative',
  [`& > ${Figure}`]: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
  },
  '@extraSmall': {
    // backgroundColor: colors['yellow-50'],
  },
  '@small': {
    // backgroundColor: colors['teal-50'],
    $$thumbnailSize: thumbnailSize.medium,
    minHeight: '$$thumbnailSize',
    paddingLeft: 'calc( $$thumbnailSize + $$columnGap )',
  },
  '@medium': {
    // backgroundColor: colors['red-50'],
  },
  '@large': {
    // backgroundColor: colors['blue-50'],
    $$thumbnailSize: thumbnailSize.large,
  },
});

export const HeaderWrapper = styled('div', {
  minHeight: '$$thumbnailSize',
  paddingLeft: 'calc( $$thumbnailSize + $$columnGap )',
  paddingRight: '40px',
  position: 'relative',

  '@small': {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 'auto',
    display: 'flex',
  },
});

export const HeaderTools = styled('div', {
  position: 'absolute',
  right: 0,
  top: 0,

  '@small': {
    display: 'flex',
    position: 'static',
    right: 0,
    top: 0,
    '& > * + *': {
      marginLeft: '8px',
    },
  },
});

export const Header = styled('header', {
  display: 'grid',
  gridTemplateColumns: '1fr 40px',
  '& > *': {
    gridColumn: 1,
  },
  // temporal
  '& > .tools': {
    gridColumn: 2,
    position: 'absolute',
    right: 0,
    top: 0,
    width: 40,
    height: 40,
  },
  '& > * + *': {
    marginTop: '8px',
  },
  '@small': {
    flex: 1,
  },
});

export const Meta = styled('div', {
  '& > * + * ': {
    marginLeft: '6px',
  },
});

export const Description = styled('div', {
  marginTop: '8px',
  marginBottom: '8px',
  maxWidth: '580px',
  '&.truncate div': {
    /// this next lines are not standard, so it may not work in all browsers
    /// or stop working on some point. It is to limit the height of the description.
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const TeacherTags = styled('div', {
  // marginTop: '8px',
  // marginBottom: '8px',
  maxWidth: '580px',
});
