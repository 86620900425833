import * as React from 'react';
import * as PC from './pricing-card';
import { observer } from 'mobx-react';
import { PricingCardsWrapper } from './pricing-cards-wrapper';
import { Plan } from '@core/models/user-manager';
import { AppFactory } from '@app/app-factory';

export const AnonPricingCards = observer(() => {
  const { accountData } = AppFactory.root.userManager;
  const plans: Plan[] = accountData.plans;

  if (plans[0]?.hasSpecialPricing) {
    // for now assume anonymous special pricing is always the affiliate plan
    return <AffiliatePricingCard plans={plans} />;
  } else {
    return <RetailPricingCards />;
  }
});

const RetailPricingCards = observer(() => {
  return (
    <>
      <PricingCardsWrapper>
        <PC.Container presentation={'anonymous'}>
          <PC.Heading>{'Monthly'}</PC.Heading>
          <PC.PriceContainer>
            <PC.PricePrefix>US</PC.PricePrefix>
            <PC.PriceAmount>$12</PC.PriceAmount>
            <PC.PriceInterval>{'/MO'}</PC.PriceInterval>
          </PC.PriceContainer>
          <PC.Description>Billed monthly</PC.Description>
        </PC.Container>
        <PC.Container presentation={'anonymous'}>
          <PC.Ribbon ribbonTitle={'Save 31%'} />
          <PC.Heading>{'1 year'}</PC.Heading>
          <PC.PriceContainer>
            <PC.PricePrefix>US</PC.PricePrefix>
            <PC.PriceAmount>$8.50</PC.PriceAmount>
            <PC.PriceInterval>{'/MO'}</PC.PriceInterval>
          </PC.PriceContainer>

          <PC.Description>
            <PC.Bold>
              <PC.Scratch className="cross">$144 </PC.Scratch>
            </PC.Bold>
            <PC.Bold>$99 </PC.Bold>
            one-time purchase
          </PC.Description>
        </PC.Container>
      </PricingCardsWrapper>
    </>
  );
});

const AffiliatePricingCard = observer(({ plans }: { plans: Plan[] }) => {
  const [monthly /*, year*/] = plans;
  const affiliateDescription = monthly?.pricingDescription;
  return (
    <>
      {/* // todo: refactor with normal pricing cards to make fully data driven */}
      <PricingCardsWrapper>
        <PC.Container presentation={'anonymous'}>
          <PC.Ribbon ribbonTitle={`${affiliateDescription} Save 17%`} />
          <PC.Heading>{'Monthly'}</PC.Heading>
          <PC.PriceContainer>
            <PC.PricePrefix>US</PC.PricePrefix>
            <PC.PriceAmount>$10</PC.PriceAmount>
            <PC.PriceInterval>{'/MO'}</PC.PriceInterval>
          </PC.PriceContainer>
          <PC.Description>Usually $12/mo</PC.Description>
        </PC.Container>

        <PC.Container presentation={'anonymous'}>
          <PC.Ribbon ribbonTitle={`${affiliateDescription} Save 42%`} />
          <PC.Heading>{'1 year'}</PC.Heading>
          <PC.PriceContainer>
            <PC.PricePrefix>US</PC.PricePrefix>
            <PC.PriceAmount>$7</PC.PriceAmount>
            <PC.PriceInterval>{'/MO'}</PC.PriceInterval>
          </PC.PriceContainer>

          <PC.Description>
            <PC.Bold>
              <PC.Scratch className="cross">$144 </PC.Scratch>
            </PC.Bold>
            <PC.Bold>$84 </PC.Bold>
            one-time purchase
          </PC.Description>
        </PC.Container>
      </PricingCardsWrapper>
    </>
  );
});
