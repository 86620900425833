import { appConfig } from '@app/config';
import { ChapterCatalogData } from '@core/models/catalog/chapter-catalog-data';
import { Story } from '@core/models/story-manager/story';
import { PrimaryFilterKeys } from '@core/models/story-manager/story-manager';
import { AppFactory } from 'app/app-factory';
import { decorateUrl, UrlOptions } from './decorate-url';
import { ReturnNavState } from './return-nav-state';

export const baseTeachPath = () => `/app/teach/${ReturnNavState.l2}`;
export const baseLearnPath = () => `/app/learn/${ReturnNavState.l2}`;

export const discoverPath = () => `${baseTeachPath()}/discover`;
export const favoritesPath = () => `${baseTeachPath()}/favorites`;
export const classroomsPath = () => `${baseTeachPath()}/classrooms`;

export const teachHomePath = () =>
  AppFactory.root.userManager.accountData.managedClassrooms?.length
    ? classroomsPath()
    : discoverPath();

export const learnHomePath = (urlOptions: UrlOptions = {}) =>
  decorateUrl(`${baseLearnPath()}/home`, urlOptions);
export const learnStoriesPath = (urlOptions: UrlOptions = {}) =>
  decorateUrl(`${baseLearnPath()}/stories`, urlOptions);
export const learnProgressPath = () => `${baseLearnPath()}/progress`;

export const soundbitesPath = (urlOptions: UrlOptions = {}) =>
  decorateUrl(`${baseLearnPath()}/soundbites`, urlOptions);
export const calendarSoundbitePath = (
  slug: string,
  urlOptions: UrlOptions = {}
) => decorateUrl(`${baseLearnPath()}/soundbites/${slug}`, urlOptions);
export const storySoundbitePath = (
  storySlug: string,
  soundbiteSlug: string,
  urlOptions: UrlOptions = {}
) =>
  decorateUrl(
    `${learnStorySlugPath(storySlug)}/soundbites/${soundbiteSlug}`,
    urlOptions
  );
export const soundbiteOrientationPath = (continuePath: string) =>
  `/soundbite-orientation?continue=${continuePath}`;

export const collectionsPath = () => `${baseLearnPath()}/collections`;
export const collectionDetailPath = (slug: string) =>
  `${collectionsPath()}/${slug}`;

// todo: figure out jason's fancy string match typing
export const storiesPrimaryFiltered = (filterKey: PrimaryFilterKeys | string) =>
  `${learnStoriesPath()}?primary=${filterKey}`;

export const storiesTagFiltered = (type: string, value: string) =>
  `${learnStoriesPath()}?filter_type=${type}&filter_value=${value}`;

export const learnStoryPath = (story: Story) => learnStorySlugPath(story?.slug);

export const learnStorySlugPath = (
  storySlug: string,
  tab?: 'story' | 'soundbites'
) => `${learnStoriesPath()}/${storySlug}${tab ? `/${tab}` : ''}`;

export const discoverStoryPath = (story: Story) =>
  `${discoverPath()}/v/${story.slug}`;

// tmp
export const vocabDevPath = (story: Story) =>
  `${learnStoryPath(story)}/vocab-dev`;

export const studyBasePath = (chapter: ChapterCatalogData) =>
  `${learnStoriesPath()}/${chapter.storySlug}/study/${chapter.unitNumber}/${
    chapter.position
  }`;

export const soundbitePreviewByUrl = (url: string) =>
  `/admin/preview?url=${url}`;
export const soundbitePreviewBySlug = (slug: string) =>
  `/admin/preview?slug=${slug}`;

export const giftCouponsPath = () => '/app/profile/gift-coupons';
export const profileHomePath = () => '/app/profile/account';

export const devToolsPath = () => '/app/profile/dev-tools';

// will need to update cypress tests to change the default teacher mode home path
export const homePath = () => {
  // // probably don't need this logic here also now
  // const { userManager, accountCreationDisabled } = AppFactory.root;
  // if (!userManager.authenticated && accountCreationDisabled) {
  //   return welcomePath();
  // }

  // const { classroomEnabled } = AppFactory.root.userManager;
  // if (classroomEnabled) {
  //   return teachHomePath();
  // } else {
  return learnHomePath();
  // }
};

export const defaultPath = () => {
  if (AppFactory.root.defaultToWelcome) {
    return welcomePath();
  } else {
    return homePath();
  }
};

// where the welcome screen 'get started' button navigates to
export const getStartedPath = () => {
  // const { accountCreationDisabled } = AppFactory.root;
  return AppFactory.root.accountCreationDisabled ? signInPath() : homePath();
};

/// @elliottjf I think we'll need to do something special to track the marketing parameters in the auth pages
const authPath = (path: string, opts: { after?: string }) => {
  return decorateUrl(path, { search: opts });
};

type AuthSearchParams = {
  after?: string;
  flow?: string;
};

export const createAccountPath = (opts: AuthSearchParams = {}) =>
  authPath('/auth/create-account', opts);

export const signInPath = (opts: AuthSearchParams = {}) =>
  authPath('/auth/sign-in', opts);

export const welcomePath = (opts: AuthSearchParams = {}) =>
  authPath('/welcome', opts);

// for now we simply send anonymous users to the create account page when attempting to follow
// links to destinations which require an authenticated context
export const noAuthRedirect = createAccountPath;

// just sent beta users to the create account screen since we haven't designed the subscribe interstitial yet
// export const subscribePath = createAccountPath; // () => `/app/profile/subscribe`;

export const subscribePath = (options: { flow?: string } = {}) => {
  // if (AppFactory.root?.userManager?.accountData?.betaMode) {
  //   return createAccountPath(); // hack out for beta users until designed
  // } else {
  return decorateUrl(`/app/profile/subscribe`, { search: options });
  // }
};

export const dailySoundbitePath = (
  options: {
    orientation?: 'skip';
    flow?: string;
  } = {}
) => {
  return decorateUrl('/daily-soundbite', {
    search: options,
  });
};

export const marketingSite = () => appConfig.website.urls.marketingSite;

export const contactUs = () => `${marketingSite()}/contact-us`;

export const tosUrl = () => `${marketingSite()}/legal/terms-of-service`;
export const privacyUrl = () => `${marketingSite()}/legal/privacy-policy`;
export const eulaUrl = () => `${marketingSite()}/legal/eula`;
