import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { IconButton } from '@naan/primitives/button';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { observer } from 'mobx-react';
import { ChapterButtons } from '../chapter-buttons';
import { ChapterListItemPresentationWrapper } from './chapter-list-item-presentation-wrapper';

export const CurrentAndFurthest = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const overflowMenu = (
      <Menu
        trigger={
          <IconButton presentation="grayTransparent" icon={<OverflowIcon />} />
        }
      >
        {chapter.isStarted ? (
          <MenuItem
            label="Restart chapter..."
            action={
              () =>
                chapter.restartChapter() /* todo: open confirmation dialog */
            }
          />
        ) : null}
        {chapter.isCompleted ? null : (
          <MenuItem
            label="Mark chapter complete"
            action={() => chapter.markComplete()}
          />
        )}
      </Menu>
    );

    return (
      <ChapterListItemPresentationWrapper
        chapter={chapter}
        number={chapter.position}
        title={chapter.title}
        presentation="current"
        durationText={chapter.prettyDuration}
        breadcrumbState={chapter.currentPoint?.iteration || 1}
        overflowMenu={overflowMenu}
        buttons={<ChapterButtons chapter={chapter} viaDetail={false} />}
      />
    );
  }
);
