import * as React from 'react';
import { PageLayout } from 'components/guides/page-layout';
import { PageHeader } from 'components/guides/page-header';
import { StoryOverview } from 'components/guides/story-overview';
import { StoryFooter } from 'components/guides/story-footer';
// import { Box } from '@naan/primitives/box';
import { BasicChapter } from './basic-chapter';
import { ScriptModel } from 'components/guides/cali/script-model';
import { createLogger } from 'app/logger';
import { StoryOutline } from '../story-outline';
import { ScaffoldedChapter } from '../story-tree/scaffolded-chapter';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';

const log = createLogger('script-ui');

const PartHeading = styled('h3', {
  font: 'var(--large-heading-font)',
  marginBottom: '16px',
});

// interface Props {
//   children: React.ReactNode;
// }

// interface State {
//   error?: any;
//   info?: any;
//   hasError: boolean;
// }

//
// @armando, the ErrorBoundry stuff is pretty generic right? where's the best home for it?
//
// export class ErrorBoundary extends React.Component<Props, State> {
//   public state: State = {
//     error: null,
//     info: null,
//     hasError: false,
//   };

//   public static getDerivedStateFromError(error: Error): State {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true, error: error };
//   }

//   public componentDidCatch(error: Error, info: React.ErrorInfo) {
//     // eslint-disable-next-line no-console
//     console.log(error, info);
//   }

//   public render() {
//     if (this.state.hasError) {
//       return (
//         <Box css={{ color: '$red-500' }}>{this.state.error.toString()}</Box>
//       );
//     }
//     return this.props.children;
//   }
// }

export const CaliStoryScript = React.memo(
  ({ model }: { model: ScriptModel }) => {
    log.debug('CaliStoryScript - render');

    const basic = model.isBasic;
    const { story } = model;

    return (
      <PageLayout>
        <PageHeader
          title={__(
            '%{title} story guide (v%{version})',
            'storyGuide.headerVersion',
            {
              title: story.title,
              version: story.version,
            }
          )}
        />
        <main className="content">
          <StoryOverview story={story} basic={basic} />
          {basic ? null : <StoryOutline story={story} />}
          {model.unitModels.map(unitModel => (
            <React.Fragment key={unitModel.slug}>
              {story.multiUnit ? (
                <PartHeading>{unitModel.partLabel}</PartHeading>
              ) : null}
              {unitModel.chapterModels.map(model =>
                basic ? (
                  <BasicChapter key={model.key} model={model} />
                ) : (
                  <ScaffoldedChapter chapterModel={model} key={model.key} />
                )
              )}
            </React.Fragment>
          ))}
        </main>

        <StoryFooter basic={basic} />
      </PageLayout>
    );
  }
);
