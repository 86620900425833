import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { useCurrentStory } from 'components/story-detail/current-story-context';

const VocabListBadge = styled('button', {
  $$iconColor: '$colors$gray-400',
  $$textColor: '$colors$white',
  color: '$$textColor',
  unset: 'all',
  position: 'relative',
  backgroundColor: '$$iconColor',
  height: 'fit-content',
  minWidth: '20px',
  minHeight: '20px',
  borderRadius: '50%',
  textStyle: 'tiny-text',
  textAlign: 'center',
  px: '6px',
  '&::after': {
    $$size: '8px',
    position: 'absolute',
    right: '-2px',
    bottom: '-2px',
    borderTop: '$$size solid black',
    borderTopColor: 'inherit',
    borderLeft: 'calc($$size/2) solid transparent',
    borderRight: 'calc($$size/2) solid transparent',
    transform: 'rotate(-50deg)',
    content: '',
    color: '$$iconColor',
  },
});

export const VocabTabIcon = observer(() => {
  const showingModal = useSwitch2(false);
  const { vocabCount } = useCurrentStory();

  return (
    <VocabListBadge onClick={showingModal.toggle}>{vocabCount}</VocabListBadge>
  );
});
