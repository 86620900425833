import * as React from 'react';
import { AppFactory } from 'app/app-factory';
import { track } from 'app/track';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { homePath } from 'components/nav/path-helpers';
import { SoundbiteView } from 'soundbite/views/soundbite-view';
import { bugsnagNotify } from '@app/notification-service';
import { useSoundbiteInterstitial } from './use-soundbite-interstitial';

// import { createLogger } from 'app/logger';
// const log = createLogger('daily-soundbite-screen');

// made an observer to react to the date adjust debug mode keys
export const DailySoundbiteScreen = observer(() => {
  // log.debug(`render`);
  const { showOrientation, orientationPath } = useSoundbiteInterstitial();

  const soundbite = AppFactory.root.storyManager.featuredSoundbite;

  React.useEffect(() => {
    if (!showOrientation) {
      track('soundbite__player_opened', {
        soundbiteSlug: soundbite?.slug,
        storySlug: soundbite?.story?.slug,
        url: soundbite?.dataUrl,
        screenType: 'daily',
      }); // redundant with generic page tracking, but looks easier to explore event data vs page view date
    }
  }, [
    showOrientation,
    soundbite?.slug,
    soundbite?.story?.slug,
    soundbite?.dataUrl,
  ]);

  if (showOrientation) {
    return <Navigate to={orientationPath} replace />;
  }

  if (soundbite) {
    return <SoundbiteView dataUrl={soundbite.dataUrl} />;
  } else {
    bugsnagNotify(
      `daily-soundbite deeplinked w/o featured soundbite, redirecting to homePath`
    );
    return <Navigate to={homePath()} replace />;
  }
});
