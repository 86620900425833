import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { CouponCodeForm } from 'components/gift-coupons/coupon-code-form';
import { AppFactory } from 'app/app-factory';
import { CouponAppliedDialog } from 'components/gift-coupons/coupon-applied-dialog';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';

import __ from 'core/lib/localization';

const CouponDialog = observer(
  ({
    onDismiss,
    onSuccess,
  }: {
    onDismiss: () => void;
    onSuccess: (result: any) => void;
  }) => {
    return (
      <DialogHtmlContainer onDismiss={onDismiss}>
        <Dialog.HtmlHeading>
          {__('Enter your code', 'enterYourCode')}
        </Dialog.HtmlHeading>
        <Dialog.HtmlCloseButton onDismiss={onDismiss} />
        <Dialog.Body>
          <CouponCodeForm
            onCouponCodeSuccess={onSuccess}
            placeholder={__('Coupon code', 'couponCode')}
            buttonLabel={__('Apply', 'apply')}
          />
        </Dialog.Body>
      </DialogHtmlContainer>
    );
  }
);

export function useCouponDialog() {
  const handleCouponCodeSuccess = (result: any) => {
    /// we close the parent dialog before showing the coupon applied dialog
    AppFactory.dialogPresenter.dismiss();
    if (result?.message) {
      setTimeout(() => {
        AppFactory.dialogPresenter.present(onDismiss => {
          return (
            <CouponAppliedDialog
              onDismiss={onDismiss}
              message={result.message}
            />
          );
        });
      }, 100);
    }
  };

  const handleApplyCode = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <CouponDialog onDismiss={onDismiss} onSuccess={handleCouponCodeSuccess} />
    ));
  }, []);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('coupon')) {
      handleApplyCode();
      //clear search params
      window.history.replaceState({}, '', `${window.location.pathname}`);
    }
  }, [handleApplyCode]);

  return handleApplyCode;
}
