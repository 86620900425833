import * as React from 'react';
import {
  ResponsiveGridLayout,
  ResponsiveNavContainer,
  ResponsiveMainContainer,
  ResponsiveParentContainer,
} from 'components/global-layout';
import { LearnNavigation } from 'components/nav/learn-navigation';
import { GlobalMessageTarget } from '../ui/global-message-target';
import { useParams } from 'react-router-dom';
import { ReturnNavState } from 'components/nav/return-nav-state';

export const LearnLayout: React.FC = ({ children }) => {
  const { l2 } = useParams<{ l2: string }>();

  // capture state for when returning from account screen
  ReturnNavState.appMode = 'learn';
  // 'es' fallback currently necessary here to handle missing :l2 path element for app/support page
  ReturnNavState.l2 = l2 ?? 'es';

  return (
    <ResponsiveParentContainer>
      <GlobalMessageTarget />

      <ResponsiveGridLayout className="x-global-grid">
        <ResponsiveMainContainer>{children}</ResponsiveMainContainer>
        <ResponsiveNavContainer>
          <LearnNavigation />
        </ResponsiveNavContainer>
      </ResponsiveGridLayout>
    </ResponsiveParentContainer>
  );
};
