import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { styled } from '@naan/stitches.config';
import { Button, IconButton } from '@naan/primitives/button';
import { PlainMarkdown } from '@naan/primitives/text';
import { AddToVocabLargeIcon } from '@naan/icons/add-to-vocab-icon';
import { InVocabLargeIcon } from '@naan/icons/in-vocab-icon';
import { ChevronLeftSmallIcon } from '@naan/icons/chevron-left-icon';
import { ChevronRightSmallIcon } from '@naan/icons/chevron-right-icon';
import { keyboardService } from 'lib/services/keyboard-service';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  height: '100%',
  position: 'relative',
  padding: '$space$3 $space$6 0 $space$6',
  '& .head': {
    cursor: 'default',
    '& dt': {
      textStyle: 'script--notes',
    },
    '& dd': {
      textStyle: 'small-text',
      color: '$colors$textSecondary',
    },
  },
  '& .controls': {
    position: 'absolute',
    bottom: '0',
    left: '24px',
    width: 'calc(100% - 48px)',
    borderTop: '1px solid $colors$gray-100',
    padding: '12px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > .left': {
      marginLeft: '-8px',
    },
    '& > .center': {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textStyle: 'small-text',
      color: '$colors$textSecondary',
    },
  },
});

export const NotationDetail = observer(() => {
  const model = AppFactory.studyModel;
  const notation = model.selectedNotationElement;
  const { progress } = model.chapter.story;

  const total = model.augmentedNotations.length;
  const current =
    model.augmentedNotations.findIndex(
      notation => notation.id === model.selectedNotationId
    ) + 1;

  // todo: factor with vocab review and notatil detail
  const text = !!notation.usageText
    ? `${notation.usageText} (${notation.headword})`
    : notation.headword;

  React.useEffect(() => {
    const resetEscapeListener = keyboardService.addModalShortcut(
      'escape',
      () => {
        model.selectNotationId(null);
        resetEscapeListener?.();
      }
    );
    const resetRightListener = keyboardService.addModalShortcut('right', () => {
      model.selectNextNotation();
    });

    const resetLeftListener = keyboardService.addModalShortcut('left', () => {
      model.selectPreviousNotation();
    });

    return () => {
      resetEscapeListener?.();
      resetRightListener?.();
      resetLeftListener?.();
    };
  }, [model]);

  return (
    <Wrapper>
      <div className="head">
        <dl>
          <dt>
            <PlainMarkdown source={text} />
          </dt>
          {notation.note ? (
            <dd>
              <PlainMarkdown source={notation.note} />
            </dd>
          ) : null}
        </dl>
      </div>
      <div className="controls">
        <div className="left">
          <Button
            size={'small'}
            presentation={'grayLight'}
            label={__('Close', 'close')}
            onClick={() => model.selectNotationId(null)}
          />
        </div>
        <div className="center">
          <IconButton
            disabled={!model.hasPreviousNotation}
            presentation={'grayTransparent'}
            onClick={() => model.selectPreviousNotation()}
            icon={<ChevronLeftSmallIcon />}
            size={'small'}
          />
          {current}/{total}
          <IconButton
            disabled={!model.hasNextNotation}
            presentation={'grayTransparent'}
            onClick={() => model.selectNextNotation()}
            icon={<ChevronRightSmallIcon />}
            size={'small'}
          />
        </div>
        <div className="right">
          {progress.vocabExists(notation.id) ? (
            <IconButton
              presentation={'grayTransparent'}
              onClick={() =>
                progress.removeVocab(notation.id, { persist: true })
              }
              icon={<InVocabLargeIcon />}
            />
          ) : (
            <IconButton
              presentation={'grayTransparent'}
              onClick={() => progress.addVocab(notation.id, { persist: true })}
              icon={<AddToVocabLargeIcon />}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
});
