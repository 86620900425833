import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { SwitchButton } from 'components/ds/switch-button';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { VStack } from '@naan/primitives/stack';
import { Row } from './settings-row';
import { bugsnagNotify } from '@app/notification-service';

import __ from '@core/lib/localization';
// import { ExternalLinkIcon } from '@naan/icons/external-link-icon';

const DisabledKeepAwakeRow = () => {
  return (
    <Row
      label={__("Don't force keep-awake", 'dontForceKeepAwake')}
      hint={
        <>
          <VStack>
            {__(
              "This setting doesn't apply on this device or browser.",
              'thisSettingDoesntApplyOnThisDevice'
            )}
          </VStack>
        </>
      }
      control={<SwitchButton disabled />}
    />
  );
};

const EnabledKeepAwakeRow = observer(() => {
  const { localState } = AppFactory.root;

  const toggle = () => localState.toggleKeepAwakeHack().catch(bugsnagNotify);

  return (
    <Row
      label={__("Don't force keep-awake", 'dontForceKeepAwake')}
      hint={
        <>
          <VStack>
            {__(
              `The method to prevent the screen from
              turning off during playback may misbehave
              on this device. If you set this switch, use
              your OS setting to keep the screen awake
              for at least 5 minutes.`,
              'theMethodToPreventTheScreen'
            )}
            <ExternalLink href={'http://go.jw.app/about-keep-awake'}>
              {__('Learn more', 'learnMore')}
              {/* @armando, Please figure out how to better factor the link icon.
              // We want to match the style used in the school-activation-screen.tsx
              // Should maybe even have a `<LearnMore>` component?
              // My naive attempt here broke onto a separate line. */}
              {/* {' '}<ExternalLinkIcon width={12} height={12} /> */}
            </ExternalLink>
          </VStack>
        </>
      }
      control={
        <SwitchButton onChange={toggle} active={localState.dontHackKeepAwake} />
      }
    />
  );
});

export const KeepAwakeRow = observer(() => {
  const { localState } = AppFactory.root;
  if (localState.keepAwakeHackApplicable) {
    return <EnabledKeepAwakeRow />;
  } else {
    return <DisabledKeepAwakeRow />;
  }
});
