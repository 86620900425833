import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { Soundbite } from '@core/models/story-manager/soundbite';
import { CompletionState } from './soundbite-completion-states';
import { getRelativeTime } from './get-relative-time';
import { AppFactory } from '@app/app-factory';
import classNames from 'classnames';
import { Countdown } from './countdown';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '$transparent',
  justifyContent: 'center',
  gap: 4,
  height: 288,
  '& >.icon': {
    color: '$green-500',
    '&.previously-completed': {
      color: '$gray-400',
    },
  },
  '& > .title': {
    textStyle: 'medium-heading',
    '&.previously-completed': {
      color: '$textSecondary',
    },
  },
});

export const EndCardHeader = ({ title }: { title: string }) => {
  return (
    <Wrapper>
      <span className={classNames('icon')}>
        <CheckmarkCircleIcon width={80} height={80} />
      </span>
      <span className="title">{title}</span>
    </Wrapper>
  );
};

export const SoundbiteEndCardHeader = ({
  soundbite,
  completionState,
}: {
  soundbite: Soundbite;
  completionState: CompletionState;
}) => {
  if (completionState === 'uncompleted') {
    return null;
  }
  return (
    <Wrapper>
      <span className={classNames('icon', completionState)}>
        <CheckmarkCircleIcon width={80} height={80} />
      </span>
      {completionState === 'previously-completed' ? (
        <span className="title previously-completed">
          {__('Completed {{when}}', 'completedPreviously', {
            when: getRelativeTime(
              soundbite.completedDayjs,
              AppFactory.root.storyManager.today
            ),
          })}
        </span>
      ) : (
        <span className="title">
          {__('Soundbite complete', 'soundbiteComplete')}
        </span>
      )}

      {soundbite.isFeatured ? (
        <span className="countdown">
          <Countdown />
        </span>
      ) : null}
    </Wrapper>
  );
};
