import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@naan/stitches.config';
import bg from './bg.png';
import { dailySoundbitePath } from 'components/nav/path-helpers';

import __ from '@core/lib/localization';

const WidgetContainer = styled('div', {
  position: 'relative',

  flex: 1,

  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',

  isolation: 'isolate',

  borderRadius: '12px',
  padding: '$space$4 $space$4 calc($space$3 + 27%) $space$4',

  backgroundColor: '#a7d8ed',
  backgroundImage: `url(${bg})`,
  backgroundSize: '100% auto',
  backgroundPosition: 'bottom left',
  backgroundRepeat: 'no-repeat',

  '& > .link': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
  },

  '& > .new': {
    backgroundColor: '$colors$red-500',
    textStyle: 'small-caps',
    color: '$white',
    padding: '2px 6px 0px 6px',
    width: 'fit-content',
    marginBottom: 8,
  },

  '& > .text-title': {
    textStyle: 'small-heading',
    marginBottom: 4,
    '@small': {
      textStyle: 'medium-heading',
    },
  },

  '& > .text-body': {
    color: '$black-alpha-50',
    textStyle: 'small-text',
    maxWidth: '21em',
    '@small': {
      textStyle: 'body',
    },
  },

  '@medium': {
    padding: '$space$5 $space$6 $space$6 $space$6',
  },
});

export const ZeroStateSoundbiteWidget = () => {
  return (
    <WidgetContainer>
      <Link to={dailySoundbitePath()} className="link" />
      <span className="new">{__('New', 'new')}</span>
      <h2 className="text-title">{__('Try Soundbites', 'trySoundbites')}</h2>
      <p className="text-body">
        {__(
          'Daily insights into language and culture wrapped inside a Spanish listening challenge.',
          'dailyInsightsSpanishChallenge'
        )}
      </p>
    </WidgetContainer>
  );
};
