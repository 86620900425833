import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'naan/primitives/button';
import { HStack, VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { useCouponDialog } from './use-coupon-dialog';
import { useAnonymousCouponDialog } from './use-anonymous-coupon-dialog';

import __ from 'core/lib/localization';
import { NoLink } from '@naan/primitives/text/no-link';
import { germanMode } from '@core/lib/app-util';

export const CouponButton = observer(
  ({
    label = __('Have a classroom, coupon or promo code?', 'haveACoupon'),
    presentation = 'tealTransparent',
  }: Partial<
    Pick<React.ComponentProps<typeof Button>, 'label' | 'presentation'>
  >) => {
    const handleApplyCode = useCouponDialog();
    return (
      <>
        <VStack>
          <VSpacer size={6} />
          <HStack justify="center">
            <Button
              label={label}
              onClick={handleApplyCode}
              testId="redeem-code"
              presentation={presentation}
              css={{ textDecoration: 'underline' }}
            />
          </HStack>
        </VStack>
      </>
    );
  }
);

export const PlainCouponLink = observer(
  ({
    label = __('Have a classroom, coupon or promo code?', 'haveACoupon'),
  }: {
    label?: string;
  }) => {
    const handleApplyCode = useCouponDialog();
    const handleClick = React.useCallback(
      e => {
        e.preventDefault();
        handleApplyCode();
      },
      [handleApplyCode]
    );

    if (germanMode()) {
      return null;
    }

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <NoLink onClick={handleClick}>{label}</NoLink>
    );
  }
);

export const AnonymousPlainCouponLink = observer(
  ({
    label = __('Have a classroom, coupon or promo code?', 'haveACoupon'),
  }: {
    label?: string;
  }) => {
    const handleApplyCode = useAnonymousCouponDialog();
    const handleClick = React.useCallback(
      e => {
        e.preventDefault();
        handleApplyCode();
      },
      [handleApplyCode]
    );

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <NoLink onClick={handleClick}>{label}</NoLink>
    );
  }
);
