import * as React from 'react';
import { AppFactory } from 'app/app-factory';
import { useReaction } from '@common/hooks/use-reaction';
import { CompletionState } from 'components/ui/player-end-card/soundbite-completion-states';
import { bugsnagNotify } from '@app/notification-service';

export function useSoundbiteCompletionState(): CompletionState {
  const model = AppFactory.soundbiteModel;

  const [cardState, setCardState] = React.useState<CompletionState>(
    model.soundbite.completed ? 'previously-completed' : 'uncompleted'
  );

  useReaction(
    () => model.soundbite.completed,
    completed => {
      if (completed && cardState === 'uncompleted') {
        setCardState('just-completed');
        if (model.story) {
          // trigger caching of story/soundbite data as needed up completion
          model.story.ensureCacheState().catch(bugsnagNotify);
        }
      }
    }
  );

  return cardState;
}
