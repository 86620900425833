import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { VSpacer } from '@naan/primitives/spacer';

import __ from '@core/lib/localization';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { eulaUrl, privacyUrl, tosUrl } from 'components/nav/path-helpers';

const Wrapper = styled('div', {
  $$textColor: '$colors$white-alpha-70',
  textStyle: 'small-text',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  color: '$$textColor',
  '& .links': {
    display: 'flex',
    gap: '4px',
    '& a': {
      color: 'inherit',
    },
  },
  variants: {
    presentation: {
      light: {
        $$textColor: '$colors$textSecondary',
      },
    },
  },
});

type WrapperProps = React.ComponentProps<typeof Wrapper> & {
  showLinks?: boolean;
};

export const Footer = ({ showLinks = true, ...props }: WrapperProps) => (
  <Wrapper {...props}>
    <div className="copy">
      {__(
        `Monthly subscriptions automatically renew on the same day each month.
          When you cancel a monthly subscription your full access continues
          until the end of the current billing period.
          Annual purchases do not automatically renew.
        `,
        'monthlySubscriptionsAutomaticallyRenew'
      )}
    </div>
    <VSpacer size={2} />
    {showLinks ? (
      <div className="links">
        <ExternalLink href={tosUrl()}>
          {__('Terms of service', 'termsOfService')}
        </ExternalLink>
        <span>•</span>
        <ExternalLink href={privacyUrl()}>
          {__('Privacy policy', 'privacyPolicy')}
        </ExternalLink>
        <span>•</span>
        <ExternalLink href={eulaUrl()}>
          {__('User License Agreement', 'userLicenseAgreement')}
        </ExternalLink>
      </div>
    ) : null}
  </Wrapper>
);
