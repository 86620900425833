import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { classroomsPath, profileHomePath } from './nav/path-helpers';
import { AppFactory } from '@app/app-factory';

import __ from 'core/lib/localization';
import { openUrl } from '@naan/primitives/text/external-link';
import { appConfig } from '@app/config';
import { Message } from '@naan/global-messages';
import { embeddedAndroid, updateStoreUrl } from '@core/lib/app-util';

/**
 * This is a temporary hack to create the ONLY global message we currently have.
 */

export function useGlobalMessages() {
  const navigate = useNavigate();
  const { userManager, localState, shouldUpdateNative } = AppFactory.root;

  const { showClassroomLicenseNag, accessExpired, paymentData } =
    userManager.accountData;

  const needsBrownListedBrowserWarning =
    localState.needsBrownListedBrowserWarning;

  const currentMessage = React.useMemo<Message>(() => {
    if (paymentData?.autoRenewFailed) {
      // assign then return for better typing
      const result: Message = {
        type: 'warning',
        message: __(
          'Your card payment could not be charged',
          'yourCardPaymentCouldNotBeCharged'
        ),
        action: {
          label: __('My account', 'myAccount'),
          callback: () => navigate(profileHomePath()),
        },
      };
      return result;
    }

    if (showClassroomLicenseNag) {
      const result: Message = {
        type: 'error',
        message: __(
          'A classroom has exceeded its license quota.',
          'aClassroomHasExceeded'
        ),
        action: {
          label: __('My classes', 'myClasses'),
          callback: () => navigate(classroomsPath()),
        },
      };
      return result;
    }

    if (shouldUpdateNative) {
      const label = embeddedAndroid()
        ? __('Update in Play Store', 'updateInPlayStore')
        : __('Update in App Store', 'updateInAppStore');

      const result: Message = {
        type: 'warning',
        message: __('Lupa is now Jiveworld Español', 'lupaUpdateNeeded'),
        action: {
          label,
          callback: () => openUrl(updateStoreUrl()),
        },
        dismissible: false,
      };
      return result;
    }

    if (accessExpired) {
      const result: Message = {
        type: 'info',
        message: __('Your full access has expired', 'yourFullAccessHasExpired'),
        action: {
          label: __('My account', 'myAccount'),
          callback: () => navigate(profileHomePath()),
        },
      };
      return result;
    }

    if (needsBrownListedBrowserWarning) {
      const result: Message = {
        type: 'warning',
        message: __(
          'See the recommended web browsers for Jiveworld',
          'brownListedWarning'
        ),
        action: {
          label: __('View', 'view'),
          callback: () => openUrl(appConfig.website.urls.browserSupport),
        },
        dismissible: true,
        onDismiss: () => localState.dismissBrownListedBrowserWarning(),
      };
      return result;
    }

    return undefined;
  }, [
    paymentData?.autoRenewFailed,
    showClassroomLicenseNag,
    accessExpired,
    needsBrownListedBrowserWarning,
    shouldUpdateNative,
    navigate,
    localState,
  ]);

  return currentMessage;
}
