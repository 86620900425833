//
// fork of sign-in-screen with functional-but-ugly support for emailed auth code
//

import * as React from 'react';

import { observer } from 'mobx-react';
import { Button } from 'naan/primitives/button';
import { useTitle } from 'common/hooks/use-title';
import { useAfterAuthRedirection } from 'lib/hooks/use-after-auth-redirection';
import { VStack } from 'naan/primitives/stack';
import { Input } from 'naan/primitives/input';
import { Label } from 'naan/primitives/forms/label';
import { useComboForm } from 'common/hooks/use-comboform';
import { VSpacer } from 'naan/primitives/spacer';
// import { Link } from 'react-router-dom';
// import { styled } from 'naan/stitches.config';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';
import { FormError } from 'naan/primitives/forms';
import { omit } from 'lodash';
import { AppFactory } from 'app/app-factory';

import __ from 'core/lib/localization';
import { useNavigate } from 'react-router-dom';
import { createLogger } from '@common/log';
import { notifySuccess } from '@app/notification-service';
import { getSignInModel } from './sign-in-model';
import { SignInWithPasswordLink } from './sign-in-layout';
import { resolveApiMessage } from '@core/models/user-manager/api-messages';

const log = createLogger('email-code-screen');

// const LinkStyled = styled(Link, {
//   textStyle: 'small-text',
//   color: '$teal-500',
//   textDecoration: 'underline',
//   textAlign: 'right',
//   position: 'absolute',
//   right: 0,
// });

// const LabelWrapper = styled('div', {
//   position: 'relative',
// });

type FormInput = {
  email: string;
};

export const EmailCodeScreen = observer(() => {
  useTitle(__('Welcome back', 'welcomeBack'));
  useAfterAuthRedirection();
  const navigate = useNavigate();

  const { userManager } = AppFactory.root;

  const signInModel = getSignInModel(); // needs to be assigned within render for useEffect dependency (vs import)
  const defaultValues = { email: signInModel.email };

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
  } = useComboForm<FormInput>({ defaultValues });

  const handleSend = React.useCallback(
    ({ email }: FormInput) => {
      log.info(`handleSend: ${email}`);
      clearValidationErrors();
      userManager.sendShortAuth(email).then(response => {
        log.info(`server resp: ${JSON.stringify(response)}`); // {messageKey, message}
        const message = resolveApiMessage(response);
        log.info(`resolved message: ${message}`);
        notifySuccess(message);
        signInModel.codeSent = true;
        navigate('/auth/sign-in-code');
      }, handleValidationErrors);
    },
    [
      userManager,
      signInModel,
      handleValidationErrors,
      clearValidationErrors,
      navigate,
    ]
  );

  // handle the flow when the user toggles to the password view and then back to the with code view
  React.useEffect(() => {
    if (signInModel.codeSent) {
      log.info('useEffect - codeSent - redirecting to submit screen');
      navigate('/auth/sign-in-code');
    }
  }, [signInModel.codeSent, navigate]);

  return (
    <>
      <form onSubmit={handleSubmit(handleSend)}>
        <CatchAllFormErrors
          errors={omit(errors, ['email'])}
          data-test-id="error-message"
        />
        <VStack>
          <Label htmlFor="email">{__('Email', 'email')}</Label>
          <VSpacer size={2} />
          <Input
            name={'email'}
            data-test-id={'email-address'}
            type="email"
            tabIndex={1}
            {...register('email')}
            required
            onBlur={e => {
              signInModel.email = e.currentTarget.value;
            }}
          />
          <FormError
            error={errors['email']}
            data-test-id="email-error-message"
          />

          <VSpacer size={6} />
        </VStack>
        <div style={{ width: '100%', display: 'flex' }}>
          <Button
            label={__('Email me a one-time code', 'emailMeACode')}
            onClick={handleSubmit as any}
            presentation="gray"
            size="large"
            fullWidth
            tabIndex={4}
          />
        </div>
      </form>

      <SignInWithPasswordLink />
    </>
  );
});
