import { action, makeObservable, observable } from 'mobx';

export class DialogPresenter {
  private static _instance: DialogPresenter;

  element: JSX.Element | null = null;

  constructor() {
    // this.present = this.present.bind(this);
    makeObservable(
      this,
      {
        element: observable,
        present: action,
        clear: action,
      },
      {
        deep: false,
      }
    );

    this.present = this.present.bind(this);
    this.clear = this.clear.bind(this);
  }

  present(presenter: (onDismiss: () => void) => JSX.Element | null) {
    this.element = presenter(this.clear);
  }

  clear() {
    this.element = null;
  }

  static get instance() {
    if (!DialogPresenter._instance) {
      DialogPresenter._instance = new DialogPresenter();
    }
    return DialogPresenter._instance;
  }

  static get element() {
    return this.instance.element;
  }

  static dismiss() {
    this.instance.clear();
  }

  static present(presenter: (onDismiss: () => void) => JSX.Element | null) {
    this.instance.present(presenter);
  }
}
