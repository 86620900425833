import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { Button } from '@naan/primitives/button';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';
import __ from '@core/lib/localization';

export const ResumeStudyButton = ({
  chapter,
}: {
  chapter: ChapterCatalogData;
}) => {
  return (
    <Button
      leftIcon={<ArrowRightIcon />}
      label={__('Resume study', 'resumeStudy')}
      onClick={() => chapter.resumeStudy()}
      presentation={'secondary'}
      size={'large'}
    />
  );
};
