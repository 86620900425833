import * as React from 'react';

// import { MessageService } from 'common/notifications/message-service';
import { GlobalMessage } from 'naan/global-messages';
// import { AppFactory } from 'app/app-factory';
import { observer } from 'mobx-react';
import { useGlobalMessages } from 'components/use-global-messages';
import { getThemeColor, setThemeColor } from '@common/pwa/set-theme-color';
import { colors } from '@naan/tokens/colors';

const themeColor = {
  info: colors['blue-500'],
  success: colors['green-500'],
  warning: colors['yellow-300'],
  error: colors['red-500'],
};

// @armando: this isn't currently responsive to dismissal. not sure why
export const GlobalMessageTarget = observer(() => {
  const currentMessage = useGlobalMessages();

  React.useEffect(() => {
    if (currentMessage) {
      const oldColor = getThemeColor();
      setThemeColor(themeColor[currentMessage.type]);
      return () => {
        setThemeColor(oldColor);
      };
    }
  }, [currentMessage]);

  if (!currentMessage) {
    return null;
  }

  return (
    <GlobalMessage
      type={currentMessage.type}
      message={currentMessage}
      // onDismiss={MessageService.clear}
      // @armando is this a reasonable approach?
      onDismiss={currentMessage.onDismiss}
    />
  );
});
