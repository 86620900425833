import React from 'react';
import { observer } from 'mobx-react';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { QuickLinks } from 'components/account/quick-links';
import { MediumGap, LargeGap } from '@naan/primitives/spacer/gaps';
import { SoundbiteEtAlWidgetGroup } from './soundbite-et-al-widget-group';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';
import { useLocation, useNavigate } from 'react-router-dom';
import { SampleStories } from './sample-stories';
import { FeaturedContent } from './featured-content';
// import { TrialWelcomeMessage } from '../welcome-message/trial-welcome-message';
import { subscribePath } from 'components/nav/path-helpers';
import { UnauthenticatedDashboardNav } from './unauthenticated-dashboard-nav';
import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { getMainScrollingContainer } from 'lib/scroll-utils';
// import { NewsletterDialog } from 'components/account/newsletter-dialog';
import { DashboardFooter } from './dashboard-footer';
import { TeacherWelcomeMessage } from '../welcome-message';

import __ from '@core/lib/localization';
import { AboutRadioAmbulante } from './about-ra';
import { LearnDashboardHeading } from './dashboard-heading';
import { AppFactory } from '@app/app-factory';
import { presentPurchaseDisabledAlert } from 'components/account/account-cta/purchase-flow-disabled';
import { colors } from '@naan/tokens/colors';
import { useThemeColor } from '@common/pwa/set-theme-color';

const SubscriberHeading = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  padding: '20px 0',
  borderBottom: '1px solid $black-alpha-10',
  gap: 12,
  marginBottom: 24,
  position: 'sticky',
  top: 0,
  backgroundColor: '$red-50',
  zIndex: '1',
  '& .heading': {
    textStyle: 'medium-heading',
  },
  '@medium': {
    marginBottom: 40,
    '& .heading': {
      textStyle: 'large-heading',
    },
  },
});

const maybeScrollToFragment = () => {
  const fragment = window.location.hash;
  if (fragment) {
    const element = document.getElementById(fragment.replace('#', ''));

    if (element) {
      const scrollingContainer = getMainScrollingContainer();
      if (scrollingContainer) {
        scrollingContainer.scrollTo({
          top: element.offsetTop - scrollingContainer.offsetTop - 56,
          behavior: 'smooth',
        });
      }
    }
  }
};

export const TrialDashboard = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { purchaseFlowDisabled } = AppFactory.root.userManager;

  React.useEffect(() => {
    requestAnimationFrame(maybeScrollToFragment);
  }, [location]);

  /// sets the theme color so it matches the blue header in the mobile anonymous dashboard. See trial-dashboard-nav-small.tsx
  useThemeColor(colors['blue-600']);

  return (
    <>
      <DeepScrollRestoration pageKey={location.pathname} />
      <UnauthenticatedDashboardNav />
      <LearnDashboardHeading />
      <CenterColumnLayout>
        {/* <TrialWelcomeMessage /> not currently needed */}
        <TeacherWelcomeMessage />
        <SoundbiteEtAlWidgetGroup />
        {/* gap included in prior widget group */}
        {/* <MediumGap /> */}
        <SampleStories />
        <MediumGap />
      </CenterColumnLayout>
      <CenterColumnLayout backgroundColor="#ffeded">
        <SubscriberHeading>
          <h3 className="heading">
            {__('Available to subscribers', 'dashboard.availableToSubscribers')}
          </h3>
          <Button
            presentation={'teal'}
            label={__('Learn more', 'learnMore')}
            size={'medium'}
            onClick={() => {
              if (purchaseFlowDisabled) {
                presentPurchaseDisabledAlert();
              } else {
                navigate(subscribePath());
              }
            }}
          />
        </SubscriberHeading>
        <FeaturedContent />
        <LargeGap />
      </CenterColumnLayout>
      <CenterColumnLayout>
        <LargeGap />
        <AboutRadioAmbulante />
      </CenterColumnLayout>
      <CenterColumnLayout>
        <LargeGap />
        <QuickLinks />
        <LargeGap />
        <DashboardFooter />
      </CenterColumnLayout>
      {/* <NewsletterDialog /> */}
    </>
  );
});
