import { styled } from '@naan/stitches.config';
import * as React from 'react';
import { Link } from 'react-router-dom';

const Wrapper = styled('div', {
  paddingBottom: '$space$4',

  '@medium': {
    paddingBottom: '$space$5',
  },

  '& .title-container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flexWrap: 'wrap',

    '& > .title': {
      textStyle: 'medium-heading',
      marginRight: '16px',
    },

    '& > .links': {
      textStyle: 'body',
      color: '$gray-200',

      '* + *': {
        '&::before': {
          content: '"•"',
          marginLeft: '$space$1',
          marginRight: '$space$1',
          color: '$gray-200',
          cursor: 'default',
        },
      },
    },
  },

  '& > .description': {
    textStyle: 'body',
    color: '$textSecondary',
    marginTop: 4,
  },
});

export const SectionHeadingLink = styled(Link, {
  textStyle: 'body-bold',
  textDecoration: 'none',
  color: '$teal-500',
});

export const SectionHeading = ({
  title,
  renderLinks,
  description,
}: {
  title: string;
  description?: React.ReactNode;
  renderLinks?: () => React.ReactNode;
}) => {
  return (
    <Wrapper>
      <div className="title-container">
        <h3 className="title">{title}</h3>
        {renderLinks ? <div className="links">{renderLinks()}</div> : null}
      </div>
      {description ? <div className="description">{description}</div> : null}
    </Wrapper>
  );
};
