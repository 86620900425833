import * as React from 'react';

import { observer } from 'mobx-react';
import { PricingCards } from 'components/account/pricing-cards';
import { styled } from '@naan/stitches.config';
import { VSpacer } from '@naan/primitives/spacer';
import { PlainCouponLink } from '../../coupons/coupon-buttons';
// import { Link } from 'react-router-dom';

import __ from 'core/lib/localization';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { marketingSite } from 'components/nav/path-helpers';
import { germanMode } from '@core/lib/app-util';

const Wrapper = styled('div', {
  $$textColor: '$colors$white',
  $$linkColor: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: 632,
  '& .copy': {
    textAlign: 'center',
    color: '$$textColor',
    '& a': {
      color: '$$linkColor',
    },
  },
  variants: {
    presentation: {
      light: {
        $$textColor: '$colors$textPrimary',
        $$linkColor: '$colors$teal-500',
      },
    },
  },
});

type WrapperFlowProps = React.ComponentProps<typeof Wrapper>;

export const AuthenticatedFlow = observer(({ ...props }: WrapperFlowProps) => {
  return (
    <Wrapper {...props}>
      <PricingCards />
      <VSpacer size={12} />
      <div className="copy">
        <PlainCouponLink />
      </div>

      {germanMode() ? null : (
        <>
          <VSpacer size={6} />
          <div className="copy">
            <strong>
              {__('Teaching with Jiveworld?', 'teachingWithJiveworld')}{' '}
            </strong>
            <br />
            <ExternalLink href={`${marketingSite()}/schools/plans`}>
              {__(
                'Learn about our classroom licenses',
                'learnAboutClassroomLicenses'
              )}
            </ExternalLink>
          </div>
        </>
      )}
    </Wrapper>
  );
});
