/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@naan/primitives/button';
import { ArrowLeftSmallIcon } from '@naan/icons/arrow-left-icon';
import { ReturnNavState } from 'components/nav/return-nav-state';
import {
  createAccountPath,
  homePath,
  signInPath,
} from 'components/nav/path-helpers';
import { styled } from '@naan/stitches.config';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { AppFactory } from '@app/app-factory';
import { Insets } from 'native-support/insets';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${Insets.top + 8}px 0 8px 0`,
  marginLeft: -8,
  '& .auth-links': {
    display: 'flex',
    alignItems: 'center',
  },
  '@medium': {
    // We'll add this back in when the video's here
    // marginBottom: 16,
  },
});

// duplicated from sb-header
const Back = () => {
  const navigate = useNavigate();

  return (
    <IconButton
      icon={<ArrowLeftSmallIcon />}
      presentation="grayTransparent"
      noFocus
      tabIndex={-1}
      onClick={() => {
        if (ReturnNavState.canPopFromStudy()) {
          navigate(-1);
        } else {
          navigate(homePath());
        }
      }}
    />
  );
};

const FLOW = 'spa-sb-orientation';

export const TopBar = () => {
  const { authenticated } = AppFactory.root.userManager;

  return (
    <CenterColumnLayout>
      <Wrapper className="top-bar">
        <Back />
        {authenticated ? null : (
          <span className="auth-links">
            <Link
              to={signInPath({
                flow: FLOW,
              })}
            >
              <Button
                presentation="grayTransparent"
                label={__('Sign in', 'signIn')}
              />
            </Link>
            <Link
              to={createAccountPath({
                flow: FLOW,
              })}
            >
              <Button
                presentation="blue"
                size={'small'}
                label={__('Create account', 'createAccount')}
              />
            </Link>
          </span>
        )}
      </Wrapper>
    </CenterColumnLayout>
  );
};
