import { keyboardService } from 'lib/services/keyboard-service';
import {
  isSoundbiteModel,
  isStudyModel,
} from '../../models/player-model-handle';
import { PlayerModel } from 'player/models/player-model';
import React from 'react';
import { AppFactory } from '@app/app-factory';
import { toggleKeyboardOverlay } from 'components/ui/keyboard-help-overlay';
import { scrollToCurrentLine } from 'study/views/fx/scrolling';
import { elementIds } from 'components/dom-utils/element-ids';
import { openSoundbitesHelpDialog } from 'components/soundbites/soundbite-welcome-dialog';
import { NotificationService } from '@app/notification-service';
import __ from '@core/lib/localization';

export function usePlayerKeyboardControls(model: PlayerModel) {
  React.useEffect(() => {
    keyboardService.addShortcutSet('player', {
      space: () => model.playPauseAction(),
      escape: () => model.cancelPendingPause(),
      left: () => model.rewind(),
      right: () => model.forward(),
      up: () => model.seekPreviousLine(),
      down: () => model.seekNextLine(),
      r: () => model.replayCurrentSentence(),
      s: () => model.snailReplayCurrentSentence(),
      'equal, shift+equal, numpadadd': () => {
        model.player.adjustPlaybackRateAction(true);
      },
      'minus, shift+minus, numpadsubtract': () =>
        model.player.adjustPlaybackRateAction(false),
      'delete, backspace': () => model.toggleCurrentSentenceRedaction(),
      enter: () => model.replayCurrentSentence(),
      'shift+enter': () => model.snailReplayCurrentSentence(),
      'shift+tab': () => {
        if (isStudyModel(model)) {
          model.togglePlayerMode();
        }
      },
      tab: () => {
        if (isSoundbiteModel(model)) {
          if (!model.answerRevealed) {
            NotificationService.open({
              type: 'info',
              message: __(
                'Translation available only after "Reveal"',
                'translationAvailableOnlyAfterReveal'
              ),
            });
            /// we return early here, but it's not really necessary since the model will no-op anyway
            return;
          }
        }
        model.toggleTranslations();
      },
      // slash: () => model.toggleHelpOverlay(),
      'shift+backquote': () => model.toggleDebugMode(),
      o: () => model.toggleDebugOverlay(),
      'shift+1': () => model.debugTogglePiMenu(), // flips both the persisted force enabled and transient force hide
      q: () => model.debugReset(),
      i: () => model.debugToggleOnloadModal(),
      z: () => model.debugSeekToEnd(),
      x: () => model.debugSeekToFinalCard(),
      h: () => model.debugNavBack1(),
      l: () => model.debugNavForward1(),
      'k, shift+k': () => toggleKeyboardOverlay(),
      // k: () => model.debugNavBack25(),
      j: () => model.debugNavForward25(),
      v: () => {
        if (isStudyModel(model)) {
          model.toggleNotationPanel();
          scrollToCurrentLine();
        } else if (isSoundbiteModel(model)) {
          const element = document.getElementById(
            elementIds.SOUNDBITE_VOCABULARY_BUTTON
          );
          if (element) {
            element.click();
          }
        }
      },
      'shift+period': () => AppFactory.root.storyManager.adjustCurrentDate(1),
      'shift+comma': () => AppFactory.root.storyManager.adjustCurrentDate(-1),
      'slash, shift+slash': () => openSoundbitesHelpDialog(), // todo: toggle soundbite help modal
      'shift+r': () => window.location.reload(),
    });
    keyboardService.setCurrentShortcutSet('player');

    return () => {
      keyboardService.removeShortcutSet('player');
    };
  });
}
