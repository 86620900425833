import React from 'react';
import { styled } from 'naan/stitches.config';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { eulaUrl, privacyUrl, tosUrl } from 'components/nav/path-helpers';

const Wrapper = styled('div', {
  textStyle: 'small-text',
  color: '$textSecondary',
  textAlign: 'center',
  '& a': {
    color: 'inherit',
    textDecoration: 'underline',
  },
});

export const AuthSmallPrint = () => {
  return (
    <Wrapper>
      By creating an account, you accept our:
      <br />
      <LegalLinks />
    </Wrapper>
  );
};

export const LegalLinks = () => {
  return (
    <>
      <ExternalLink href={tosUrl()}>Terms&nbsp;of&nbsp;service</ExternalLink>
      {', '}
      <ExternalLink href={privacyUrl()}>Privacy&nbsp;policy</ExternalLink>
      {', '}
      <ExternalLink href={eulaUrl()}>
        User&nbsp;License&nbsp;Agreement
      </ExternalLink>
    </>
  );
};
