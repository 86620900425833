import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import __ from 'core/lib/localization';

const InterstitialDialog = observer(
  ({
    onDismiss,
    okAction = () => {},
    cancelLabel = 'Cancel',
    okLabel = 'Ok',
    children,
  }: {
    onDismiss: () => void;
    okLabel?: string;
    okAction: () => void;
    cancelLabel?: string;
    children: React.ReactNode;
  }) => {
    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Body>{children}</Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            presentation={'cancel'}
            label={cancelLabel}
            data-test-id="cancelButton"
            onClick={onDismiss}
          />

          <Dialog.Button
            presentation={'teal'}
            label={okLabel}
            data-test-id="okButton"
            onClick={okAction}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

export function launchCheckoutInterstitial(
  { interstitialMessageKey, ...params }: any,
  checkout: () => void
) {
  /// ¯\_(ツ)_/¯ -- Nothing to see here, just go and redirect to the checkout page
  if (interstitialMessageKey === 'none') {
    return checkout();
  }

  switch (interstitialMessageKey as string) {
    case 'new-subscription-carry-fwd-days':
      return AppFactory.dialogPresenter.present(onDismiss => (
        <InterstitialDialog
          onDismiss={onDismiss}
          okLabel="Continue"
          cancelLabel="Cancel"
          okAction={checkout}
        >
          {__(
            'You have %{fullAccessDays} days left of membership on your account. These will be reflected as "free days" on the next screen, and your card won\'t be charged until after this period.',
            'newSubscriptionCarryFwdDays',
            params
          )}
        </InterstitialDialog>
      ));

    case 'purchase-while-subscribed':
      return AppFactory.dialogPresenter.present(onDismiss => (
        <InterstitialDialog
          onDismiss={onDismiss}
          cancelLabel={__('Go back', 'goBack')}
          okAction={checkout}
        >
          {__(
            'Making this purchase will cancel your monthly subscription. The %{fullAccessDays} day(s) remaining on the current billing cycle will be added to the time you are purchasing.',
            'purchaseWhileSubscribed',
            params
          )}
        </InterstitialDialog>
      ));

    case 'purchase-with-unused-days':
      return AppFactory.dialogPresenter.present(onDismiss => (
        <InterstitialDialog
          onDismiss={onDismiss}
          okLabel={__('Purchase', 'purchase')}
          okAction={checkout}
        >
          {__(
            'You still have %{fullAccessDays} day(s) of membership on this account. After purchasing you will have %{fullAccessDaysAfterPurchase} days of membership.',
            'purchaseWithUnusedDays',
            params
          )}
        </InterstitialDialog>
      ));

    case 'replace-subscription-carry-fwd-days':
      return AppFactory.dialogPresenter.present(onDismiss => (
        <InterstitialDialog
          onDismiss={onDismiss}
          okLabel={__('Update Credit Card', 'updateCreditCard')}
          okAction={checkout}
        >
          {__(
            'Heads up! Updating your credit card will seem like you are starting a new subscription. \n Don\'t worry, you will not be charged twice, and your access and billing date will remain the same. On the next screen you will see "%{fullAccessDays} free days" — this reflects the time left of your current billing cycle. \nWe are working with our payments processor to make this less confusing.',
            'replaceSubscriptionCarryFwdDays',
            params
          )}
        </InterstitialDialog>
      ));

    case 'new-subscription-too-many-days':
      return AppFactory.dialogPresenter.present(onDismiss => (
        <Dialog.Container onDismiss={onDismiss} open={true}>
          <Dialog.Body>
            {__(
              "You have over two years (%{fullAccessDays} days) left of membership on your account. Since we cannot credit more than two years when subscribing, please come back when you're closer to the expiry date.\n\nIf you want to support our efforts further, please consider making a donation at radioambulante.org.",
              'newSubscriptionTooManyDays',
              params
            )}
          </Dialog.Body>
          <Dialog.ButtonsContainer>
            <Dialog.Button
              presentation={'teal'}
              onClick={onDismiss}
              label={__('OK', 'ok')}
              fullWidth
            />
          </Dialog.ButtonsContainer>
        </Dialog.Container>
      ));

    default:
      break;
  }
}
