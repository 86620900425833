import * as React from 'react';
import { Story } from '@core/models/story-manager';
import { styled } from '@naan/stitches.config';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Soundbite } from '@core/models/story-manager/soundbite';
import { icons } from 'components/soundbites/soundbites-icons';
import { AnimatePresence, motion } from 'framer-motion';
import { SoundbiteIconExtraSmall } from '@naan/icons/soundbite-icon';
import { Link } from 'react-router-dom';
import { IconButton } from '@naan/primitives/button';
import { CloseIcon } from '@naan/icons/close-icon';
import { calendarSoundbitePath } from 'components/nav/path-helpers';
import { createLogger } from '@common/log';

import __ from '@core/lib/localization';

const log = createLogger('soundbite-floating-card');

const Wrapper = styled(motion.div, {
  position: 'fixed',
  right: 8,
  bottom: 8,
  background: 'white',
  border: '4px solid $colors$purple-200',
  borderRadius: 12,
  zIndex: 1,
  // padding: 16,
  width: 'min(100% - 16px, 400px)',
});

const ContentWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '0 16px',
  isolation: 'isolate',

  '& > .card-link': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },

  '& > .close-btn': {
    position: 'absolute',
    right: 6,
    top: 10,
    zIndex: 2,
  },

  '& > .card-header': {
    color: '$purple-600',
    textStyle: 'small-caps',
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',

    gap: 6,

    '@medium': {
      paddingBottom: '19px', //The slightly quirky 19px margin below the header compensates for the 1px rule below it, to ensure that the final height is 280px
    },
    borderBottom: '1px solid $gray-100',
  },
  '& > .card-content': {
    display: 'grid',
    gridTemplateColumns: '56px 1fr',
    gap: 16,
    '& > .icon': {
      '& > svg': {
        width: 56,
      },
    },
    '& > .body': {
      padding: '16px 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textStyle: 'body-bold',
      '& > .chapter-pos': {
        color: '$textSecondary',
        textStyle: 'small-text',
      },
    },
  },
});

class State {
  public showingCard = true;

  constructor() {
    makeObservable(this, {
      showingCard: observable,
      hideCard: action,
    });

    // Load persisted state from session storage
    try {
      const persistedState = localStorage.getItem('floating-card-ui-dismissed');
      const today = new Date().getDate().toString();
      if (persistedState === today) {
        this.showingCard = false;
      }
    } catch (error) {
      log.info('localStorage is not available.');
    }
  }

  public hideCard() {
    this.showingCard = false;

    // Persist state to session storage, if possible
    try {
      const today = new Date().getDate().toString();
      localStorage.setItem('floating-card-ui-dismissed', today);
    } catch (error) {
      log.info('localStorage is not available.');
    }
  }
}

const state = new State();

export const SoundbiteCardContent = observer(
  ({ soundbite }: { soundbite: Soundbite }) => {
    const iconSet = icons[soundbite.category as keyof typeof icons] ?? null;
    const LargeIcon = iconSet?.large ?? null;
    const finished = soundbite.completed;

    const iconPresentation = finished ? 'grayscale' : 'color';

    return (
      <ContentWrapper>
        <Link
          to={calendarSoundbitePath(soundbite.slug)}
          className="card-link"
        />
        <span className="close-btn">
          <IconButton icon={<CloseIcon />} onClick={() => state.hideCard()} />
        </span>
        <div className="card-header">
          <SoundbiteIconExtraSmall />
          {__("Today's Soundbite", 'todaysSoundbite')}
        </div>
        <div className="card-content">
          <div className="icon">
            {LargeIcon && <LargeIcon presentation={iconPresentation} />}
          </div>
          <div className="body">
            <span className="chapter-pos">
              Chapter {soundbite.chapterPos ?? '#'}
            </span>
            <span className="title">{soundbite.title}</span>
          </div>
        </div>
      </ContentWrapper>
    );
  }
);

export const SoundbiteFloatingCard = observer(({ story }: { story: Story }) => {
  const soundbite = story.featuredSoundbite;
  if (!soundbite) {
    return null;
  }

  if (soundbite.completed) {
    return null;
  }

  return (
    <AnimatePresence>
      {state.showingCard && (
        <Wrapper
          initial={{ y: '100%' /* opacity: 0 */ }}
          animate={{ y: 0 /* opacity: 1 */ }}
          transition={{ ease: 'easeOut', duration: 0.3 }}
          exit={{ y: '100%' /* opacity: 0 */ }}
        >
          <SoundbiteCardContent soundbite={soundbite} />
        </Wrapper>
      )}
    </AnimatePresence>
  );
});
