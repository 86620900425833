import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { learnHomePath } from 'components/nav/path-helpers';
import { BrandedBackLink } from 'components/branding/branded-back-link';
import { AppFactory } from '@app/app-factory';
import { Insets } from 'native-support/insets';

const Wrapper = styled('div', {
  marginTop: 'env(safe-area-inset-top)',
  marginBottom: 'env(safe-area-inset-bottom)',
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // placeItems: 'center',
  '& > .back-link': {
    paddingTop: Insets.top,
    position: 'absolute',
    top: '16px',
    left: '16px',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    '@medium': {
      top: '28px',
      left: '24px',
    },
  },
  '& > .inner': {
    paddingTop: 'max( 88px, 50vh - 322px + ' + Insets.top / 2 + 'px )',
    width: 'min( 400px, calc(100% - 32px) )',
    '@medium': {
      paddingBottom: 88,
    },
  },
});

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  const { accountCreationDisabled } = AppFactory.root;
  return (
    <Wrapper>
      {accountCreationDisabled ? null : (
        <div className="back-link">
          <BrandedBackLink to={learnHomePath()} presentation={'color'} />
        </div>
      )}

      <div className="inner">{children}</div>
      {/* <WideInnerWrapper>
        <GlobalFooter />
      </WideInnerWrapper> */}
    </Wrapper>
  );
};
