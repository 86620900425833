// Function to parse and save insets to localStorage
const saveInsetsToLocalStorage = (insetTop: number, insetBottom: number) => {
  const insetsString = [insetTop, insetBottom]
    .filter(inset => inset !== 0)
    .join(',');
  localStorage.setItem('insets', insetsString);
};

// Function to retrieve insets from localStorage
const getInsetsFromLocalStorage = (): {
  top: number;
  bottom: number;
} | null => {
  const insetsString = localStorage.getItem('insets');
  if (insetsString) {
    const [insetTop, insetBottom] = insetsString
      .split(',')
      .map(inset => parseInt(inset));
    return { top: insetTop, bottom: insetBottom };
  }
  return null;
};

// Function to get URL query parameters
const getQueryParams = (): { top: number; bottom: number } => {
  const searchParams = new URLSearchParams(window.location.search);
  const insetTop = parseInt(searchParams.get('insetTop') || '0');
  const insetBottom = parseInt(searchParams.get('insetBottom') || '0');
  return { top: insetTop, bottom: insetBottom };
};

// Main code
export const Insets = (() => {
  const { top, bottom } = getQueryParams();

  if (top !== 0 || bottom !== 0) {
    // Save the non-zero insets to localStorage
    saveInsetsToLocalStorage(top, bottom);
  } else {
    // If the search params are not present, check localStorage
    const persistedInsets = getInsetsFromLocalStorage();
    if (persistedInsets !== null) {
      return persistedInsets;
    }
  }

  // Return the final Insets object
  return { top, bottom };
})();

export function resetInsetsLocalStorage() {
  localStorage.removeItem('insets');
  window.location.reload();
}
