import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { IconButton } from '@naan/primitives/button';
import { CloseIcon } from '@naan/icons/close-icon';
import { VSpacer } from '@naan/primitives/spacer';
import { closeKeyboardOverlay, HELP_OVERLAY_ID } from './keyboard-toggles';
import { StudyShortcuts } from './keyboard-study-shortcuts';
import { ListenShortcuts } from './keyboard-listen-shortcuts';
import { SoundbiteShortcuts } from './keyboard-soundbites-shortcuts';
import { Insets } from 'native-support/insets';

import __ from '@core/lib/localization';

const Container = styled('div', {
  $$backgroundColor: '$colors$gray-25',
  $$headerBorderColor: '$colors$gray-100',
  $$headerTextColor: '$colors$textPrimary',
  $$sectionTitleColor: '$colors$textSecondary',
  $$descriptionColor: '$colors$textPrimary',
  $$shortcutColor: '$colors$textSecondary',
  $$shortcutBorderColor: '$colors$black-alpha-10',

  background: '$$backgroundColor',

  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  width: 360,
  zIndex: 10,
  display: 'grid',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s',
  paddingTop: Insets.top,

  transform: 'translateX(-100%)',

  '&.open': {
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.2)',
    transform: 'translateX(0%)',
  },

  '&.listen ': {
    $$backgroundColor: '$colors$gray-800',
    $$headerBorderColor: '$colors$gray-700',
    $$headerTextColor: '$colors$white',
    $$sectionTitleColor: '$colors$gray-300',
    $$descriptionColor: '$colors$white',
    $$shortcutColor: '$colors$gray-300',
    $$shortcutBorderColor: '$colors$gray-700',
  },
});

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: '56px 1fr',
  overflow: 'hidden',
  // flexDirection: 'column',

  '& > .header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 24px',
    height: 56,
    borderBottom: '1px solid $$headerBorderColor',
    color: '$$headerTextColor',
    '& > .title': {
      textStyle: 'small-text-bold',
    },
    '& > button': {
      marginRight: -12,
    },
  },

  '& > .scroller': {
    overflow: 'auto',
    '& > .section': {
      padding: '0 24px',
      '& > .title': {
        margin: '24px 0 16px',
        textStyle: 'small-caps',
        color: '$$sectionTitleColor',
      },
      '& > .shortcut': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '8px',
        '& > .description': {
          color: '$$descriptionColor',
          textStyle: 'small-text',
          marginRight: 'auto',
        },
        '& > .key': {
          color: '$$shortcutColor',
          textStyle: 'small-text-bold',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px 10px',
          borderRadius: 8,
          border: '1px solid $$shortcutBorderColor',
          '&:has(svg)': {
            padding: '7px',
          },
          '& + &': {
            marginLeft: 8,
          },
        },
      },
    },
  },
});

type PanelModes = 'study' | 'listen' | 'soundbites';

export const KeyboardHelpOverlay = ({ mode }: { mode: PanelModes }) => {
  const Component = React.useMemo(() => {
    switch (mode) {
      case 'study':
        return StudyShortcuts;
      case 'listen':
        return ListenShortcuts;
      case 'soundbites':
        return SoundbiteShortcuts;
    }
  }, [mode]);

  return (
    <Container id={HELP_OVERLAY_ID} className={mode}>
      <Wrapper>
        <header className="header">
          <h4 className="title">
            {__('Keyboard Shortcuts', 'keyboardShortcuts')}
          </h4>
          <IconButton
            icon={<CloseIcon />}
            onClick={closeKeyboardOverlay}
            presentation={
              mode === 'listen' ? 'whiteTransparent' : 'grayTransparent'
            }
          />
        </header>
        <div className="scroller">
          <Component />
          <VSpacer size={6} />
        </div>
      </Wrapper>
    </Container>
  );
};
