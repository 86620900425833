import * as React from 'react';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { styled } from '@naan/stitches.config';
import { SoundbiteIcon } from '@naan/icons/soundbite-icon';
import { VocabListModal } from 'components/story-detail/vocab-list';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
// import { VocabListBadge } from 'components/stories/vocab-list-badge-icon';
import { Box } from '@naan/primitives/box';
import { VocabTabIcon } from './vocab-tab-icon';
import { StoryTabIcon } from './story-tab-icon';
import { useCurrentStory } from 'components/story-detail/current-story-context';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';

import {
  elementIds,
  STORY_DETAIL_TOP_ELEMENTS,
} from 'components/dom-utils/element-ids';
import { getElementsCombinedHeight } from 'components/dom-utils';
import { useDomReady } from 'components/dom-utils/use-dom-ready';

import __ from '@core/lib/localization';

const StickyWrapper = styled('div', {
  position: 'sticky',
});

const Sticky = React.memo(
  ({
    top,
    ...props
  }: { top: number } & React.ComponentProps<typeof StickyWrapper>) => {
    return (
      <StickyWrapper
        {...props}
        style={{
          top: top + 'px',
        }}
      />
    );
  }
);

const Wrapper = styled(Sticky, {
  background: '$white',
  zIndex: 1,
  borderBottom: '1px solid $colors$gray-100',

  '& .tabs-inner': {
    display: 'flex',
    '& > *': {
      flex: 1,
    },
    '@small': {
      gap: 32,
      '& > *': {
        flex: 'none',
      },
      // '& > * + *': {
      //   marginLeft: 40,
      // },
      '& .vocab-tab': {
        marginLeft: 'auto',
      },
    },
  },
});

const StyledLink = styled(NavLink, {
  textDecoration: 'none',
});

const Tab = styled('div', {
  $$color: '$colors$gray-400',
  $$activeColor: '$colors$teal-500',

  userSelect: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$$color',
  borderBottom: '4px solid transparent',
  padding: '16px 0 12px 0',

  '.active &': {
    color: '$$activeColor',
    borderBottomColor: '$$activeColor',
  },

  '&:hover': {
    $$color: '$colors$gray-500',
  },

  '& .label': {
    display: 'flex',
    textStyle: 'small-text-bold',
  },

  '& .label--large-only': {
    display: 'none',
  },

  '& .icon': {
    display: 'flex',
    alignItems: 'center',
    height: 24,
    marginBottom: 4,
  },

  '& .icon-text': {
    textStyle: 'body-bold',
    marginLeft: 2,
  },

  '@small': {
    padding: '20px 0 16px 0',
    flexDirection: 'row',
    '& .label': {
      textStyle: 'body-bold',
    },
    '& .label--large-only': {
      display: 'block',
      marginRight: 4,
    },
    '& .icon': {
      marginRight: 6,
      marginBottom: 0,
    },
    '& .icon-text': {
      display: 'none',
    },
  },
});

/*


export const VocabListButton = ({
  textColor = 'currentColor',
  vocabCount,
}: VocabListButtonProps) => {
  const showingModal = useSwitch2(false);

  return (
    <>
      <VocabListBadge onClick={showingModal.toggle} css={{ color: textColor }}>
        {vocabCount}
      </VocabListBadge>
      {showingModal.value && <VocabListModal onDismiss={showingModal.toggle} />}
    </>
  );
};

*/
export const StoryTabsBar = observer(() => {
  const showingModal = useSwitch2(false);
  const story = useCurrentStory();
  const domready = useDomReady(STORY_DETAIL_TOP_ELEMENTS);

  const { unstarted, hasVisibleSoundbites } = story;

  const hideTabbar = unstarted && !hasVisibleSoundbites;

  if (hideTabbar) {
    return null;
  }

  if (!domready) {
    return null;
  }

  const top = getElementsCombinedHeight(STORY_DETAIL_TOP_ELEMENTS);

  return (
    <Wrapper className="story-tab-bar" id={elementIds.STORY_TAB_BAR} top={top}>
      <CenterColumnLayout>
        <div className="tabs-inner">
          <StyledLink
            to="story"
            replace
            className={({ isActive = false }) => (isActive ? 'active' : '')}
          >
            <Tab>
              <span className="icon">
                <StoryTabIcon />
              </span>
              <span className="label">{__('Story', 'storyTabs.story')}</span>
            </Tab>
          </StyledLink>
          {hasVisibleSoundbites && (
            <StyledLink to="soundbites" replace>
              <Tab css={{ $$activeColor: '$colors$purple-500' }}>
                <span className="icon">
                  <SoundbiteIcon />
                  <span className={'icon-text'}>
                    {story.soundbiteDisplayProgress}
                  </span>
                </span>
                <span className="label">
                  <span className={'label--large-only'}>
                    {story.soundbiteDisplayProgress}
                  </span>

                  {__('Soundbites', 'storyTabs.soundbites')}
                </span>
              </Tab>
            </StyledLink>
          )}
          <Box className="vocab-tab" onClick={showingModal.toggle}>
            <Tab>
              <span className="icon">
                <VocabTabIcon />
              </span>
              <span className="label">
                {__('Vocabulary', 'storyTabs.vocabulary')}
              </span>
            </Tab>
          </Box>
          {showingModal.value ? (
            <VocabListModal onDismiss={showingModal.toggle} />
          ) : null}
        </div>
      </CenterColumnLayout>
    </Wrapper>
  );
});
