import React from 'react';
import { styled } from 'naan/stitches.config';
import { CardRibbon } from 'components/card-ribbon';
import { Button as NaanButton } from 'naan/primitives/button';
import { VSpacer } from 'naan/primitives/spacer';
import { CheckmarkIcon } from '@naan/icons/checkmark-icon';
import __ from 'core/lib/localization';

export const Ribbon = CardRibbon;

const PricingCardWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const Container = styled('div', {
  $$textColor: 'white',
  $$backgroundColor: '$colors$gray-100',

  position: 'relative',
  borderRadius: '12px',
  padding: '16px',
  paddingTop: '24px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  flexGrow: '1',
  background: '$$backgroundColor',
  color: '$$textColor',
  '@small': {
    paddingTop: '48px',
  },
  variants: {
    presentation: {
      coupon: {
        $$backgroundColor: 'white',
        $$textColor: 'textPrimary',
        boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.2)',
      },
      'one-year': {
        $$backgroundColor: '$colors$green-500',
      },
      monthly: {
        $$backgroundColor: '$colors$teal-600',
      },
      'free-trial': {
        $$backgroundColor: '$colors$blue-600',
      },
      anonymous: {
        $$backgroundColor: '$colors$black-alpha-10',
        paddingBottom: '24px',
        backdropFilter: 'blur(16px)',
        '@small': {
          paddingBottom: '48px',
        },
      },
    },
  },
});

type ButtonProps = Pick<
  React.ComponentProps<typeof NaanButton>,
  'label' | 'onClick'
>;

export const Button = ({ label, onClick }: ButtonProps) => (
  <NaanButton
    fullWidth
    size="large"
    aria-label={label}
    onClick={onClick}
    label={label}
    presentation="whiteDarken"
  />
);

const round = (n: number) => Math.floor(n);

const cents = (n: number) => {
  const c = n % 1;
  if (c) {
    return '.' + Math.floor(c * 100);
  }
};

export const PriceContainer = styled('div', {
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'center',
});

export const PricePrefix = styled('div', {
  textStyle: 'body-bold',
});

export const PriceAmount = styled('div', {
  textStyle: 'large-heading',
  margin: '0 1px',
});

export const PriceInterval = styled('div', {
  textStyle: 'body-bold',
  textTransform: 'uppercase',
});

export const Description = styled('div', {
  textStyle: 'body',
});

export const Scratch = styled('span', {
  textDecoration: 'line-through',
});

export const Bold = styled('span', {
  textStyle: 'body-bold',
});

const Footnote = styled('div', {
  width: '100%',
  textStyle: 'small-text',
  color: '$textSecondary',
  textAlign: 'center',
  marginTop: '16px',
});

type PricingCardProps = {
  cta: string;
  featureTitle: string;
  interval?: string;
  isCurrent?: boolean;
  isDiscounted?: boolean;
  normalPrice?: number | null;
  price?: number | null;
  ribbonTitle?: string | null;
  showRibbon?: boolean;
  showOriginalPrice?: boolean;
  title: string;
  titleAsterisk?: boolean;
  type: string;
  pricingExplanation?: string;
  footNote?: string;
  onCtaClick?: () => void;
};

const HeadingWrapper = styled('div', {
  textStyle: 'small-heading',
  color: 'inherit',
  '@medium': {
    textStyle: 'medium-heading',
  },
});

export const Heading: React.FC<{ asterisk?: boolean }> = ({
  children,
  asterisk,
}) => {
  return (
    <HeadingWrapper>
      {children}
      {asterisk ? <span className="asterisk">*</span> : null}
    </HeadingWrapper>
  );
};

/*
 * @deprecated use the primitives instead
 */
export const PricingCard = ({
  cta,
  featureTitle,
  // featureSubTitle,
  interval,
  isCurrent = false,
  showOriginalPrice = false,
  normalPrice = 0,
  price = 0,
  ribbonTitle = '',
  showRibbon = false,
  title = '',
  titleAsterisk = false,
  type = '',
  pricingExplanation = '',
  footNote,
  onCtaClick = () => {},
}: PricingCardProps) => {
  const showPricingExplanation = Boolean(pricingExplanation);
  return (
    <PricingCardWrapper>
      <Container presentation={type as any} data-test-id={title}>
        {showRibbon ? <CardRibbon ribbonTitle={ribbonTitle} /> : null}

        <Heading asterisk={titleAsterisk}>{title}</Heading>

        <PriceContainer>
          <PricePrefix>US</PricePrefix>
          <PriceAmount>
            ${round(price ?? 0)}
            {cents(price ?? 0)}
          </PriceAmount>
          {interval ? <PriceInterval>{interval}</PriceInterval> : null}
        </PriceContainer>

        {showPricingExplanation ? (
          <Description>
            {showOriginalPrice ? (
              <Scratch className="cross">${normalPrice} </Scratch>
            ) : null}
            {pricingExplanation}
          </Description>
        ) : null}

        <Description>{featureTitle}</Description>

        <VSpacer size={10} />

        {isCurrent ? (
          <p className="pricing-card__your-plan">
            <CheckmarkIcon />
            {__('My plan', 'myPlan')}
          </p>
        ) : (
          <Button onClick={onCtaClick} label={cta} />
        )}
      </Container>
      {footNote ? <Footnote>* {footNote}</Footnote> : null}
    </PricingCardWrapper>
  );
};
