import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { NotationDetail } from './notation-detail';
import { AppFactory } from 'app/app-factory';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useOnClickOutside } from '@common/hooks/use-onclick-outside';

const Wrapper = styled('div', {
  position: 'fixed',
  bottom: 'calc(84px + var(--sab))',
  left: 0,
  right: 0,
  background: '$white',
  transform: 'translateY(0%)',
  transition: 'transform 300ms ease-in-out',
  zIndex: 3,
  '&.hidden': {
    transform: 'translateY(200%)',
  },
  '& > .inner': {
    height: '276px',
    maxWidth: '616px',
    margin: '0 auto',
    // TODO: Commenting this out until we implement the more compact playerShort notation style
    // '@playerShort': {
    //   height: '228px',
    // },
  },
});

export const NotationsDetailsPanel = observer(() => {
  const ref = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    AppFactory.studyModel.selectNotationId(null);
  });

  const notation = AppFactory.studyModel.selectedNotationElement;
  // console.log(`ndp - notation: ${JSON.stringify(notation)}`);
  return (
    <Wrapper className={classNames({ hidden: !notation })} ref={ref}>
      <div className="inner">{notation ? <NotationDetail /> : null}</div>
    </Wrapper>
  );
});
