import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { BoxIllustration } from './box-illustration';
import { PlayVideoIcon } from 'components/learn/dashboard/helplets-widget/play-video-icon';
import { appConfig } from 'app/env';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  position: 'relative',

  padding: '24px 16px',
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  alignItems: 'center',
  margin: '0 auto',

  '& .hero-title': {
    margin: 0,
    textStyle: 'large-heading',
    color: '$white',
    textAlign: 'center',
    // maxWidth: 280,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '& .box': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '100%',
      height: 'auto',
      // maxWidth: 380,
      margin: '0 10%',
    },
  },

  '@medium': {
    padding: '80px 8px',
    borderRadius: 12,
    aspectRatio: '16 / 9',
    width: 'min( $$maxWidth, 100% - 32px )',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',

    '& .hero-title': {
      maxWidth: '10em',
      textStyle: 'extra-large-heading',
      textAlign: 'left',
      padding: '0 48px 0 0',
    },

    '& .box': {
      '& svg': {
        marginLeft: '16px',
      },
    },
  },
  '@large': {
    '& .hero-title': {
      textStyle: 'huge-heading',
      padding: '0 72px 0 24px',
    },
  },

  '&  .play-btn': {
    '--play-video-button-bg': '$colors$teal-500',
    all: 'unset',
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
    svg: {
      width: '92px',
      height: '92px',
      '@large': {
        width: '128px',
        height: '128px',
      },
    },
    '&:hover': {
      '--play-video-button-bg': '$colors$teal-600',
    },
  },
});

export const HeroSectionPreview = ({
  toggleVideo,
  showingVideo,
}: {
  toggleVideo: () => void;
  showingVideo: boolean;
}) => {
  return (
    <Wrapper className="video">
      <h1 className="hero-title">
        {__(
          'Are you ready for the Soundbites Challenge?',
          'areYouReadyForTheSoundbitesChallenge'
        )}
      </h1>
      <span className="box">
        <BoxIllustration />
      </span>
      {appConfig.enableSoundbitesOrientationVideo && !showingVideo ? (
        <button
          onClick={() => {
            toggleVideo();
          }}
          className="play-btn"
        >
          <PlayVideoIcon />
        </button>
      ) : null}
    </Wrapper>
  );
};
