import * as DialogPrimitive from '@radix-ui/react-dialog';
import React from 'react';
import { styled } from 'naan/stitches.config';
import { CloseSmallIcon } from 'naan/icons/close-icon';
import { IconButton } from 'naan/primitives/button';

const CloseButtonContainer = styled('span', {
  all: 'unset',
  position: 'absolute',
  top: 8,
  right: 8,
});

export const CloseButton = () => {
  return (
    <DialogPrimitive.Close asChild>
      <CloseButtonContainer>
        <IconButton
          icon={<CloseSmallIcon />}
          size={'small'}
          type="button"
          tabIndex={-1}
        />
      </CloseButtonContainer>
    </DialogPrimitive.Close>
  );
};

export const HtmlCloseButton = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <CloseButtonContainer>
      <IconButton
        icon={<CloseSmallIcon />}
        size={'small'}
        type="button"
        tabIndex={-1}
        onClick={onDismiss}
      />
    </CloseButtonContainer>
  );
};
