import * as React from 'react';

import { styled } from '@naan/stitches.config';
import { JwLogo } from 'components/branding/jw-logo';
import { DashboardAuthButtons } from './dashboard-auth-buttons';
import { PointsNav } from './points-nav';
import { StoriesByRaNavSmall } from './sbra-nav';
import { useThemeColor } from '@common/pwa/set-theme-color';
import { colors } from '@naan/tokens/colors';

const Wrapper = styled('div', {
  '& .top': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '$blue-600',
    height: 80,
    padding: '0 16px',
  },
  '& .bottom': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '16px 16px 0 16px',
    height: 88,
  },
});

export const TrialDashboardNavSmall = () => {
  useThemeColor(colors['blue-600']);

  return (
    <Wrapper>
      <div className="top">
        <JwLogo subBrand="es" presentation="colorOnDark" />
        <StoriesByRaNavSmall />
      </div>
      <div className="bottom">
        <PointsNav />
        <DashboardAuthButtons />
      </div>
    </Wrapper>
  );
};
