import * as React from 'react';
import { HStack } from 'naan/primitives/stack';
import { Text } from 'naan/primitives/text';
import { HSpacer } from 'naan/primitives/spacer';
import { useFiltering } from 'common/filtering/use-filtering';

import __ from 'core/lib/localization';
import { SortByMenuButton } from './sort-by-menu-button';
import { Responsive } from 'naan/primitives/responsive';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';

const StoryCount = styled('div', {
  '@medium': {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '120px',
  },
});

export const SortingSection = observer(() => {
  const { filteredUnits, sorting, setSortingKey } = useFiltering();
  const unitsCount = filteredUnits.length;

  return (
    <HStack align="center">
      <StoryCount>
        <Text textStyle="small-text" color="textSecondary">
          {__(
            { one: '1 story', other: '{{count}} stories' },
            'pluralize.stories',
            {
              count: unitsCount,
            }
          )}
        </Text>
      </StoryCount>
      <Responsive
        renderDefault={() => <HSpacer expand />}
        renderMediumAndUp={() => <HSpacer size={3} />}
      />
      {/*
      <FilterButton label={<GearIcon />} iconOnly />
      <HSpacer size={3} />
       */}
      <SortByMenuButton sorting={sorting} setSortingKey={setSortingKey} />
    </HStack>
  );
});
