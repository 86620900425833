import React from 'react';
import { VStack } from 'naan/primitives/stack';

import { AppFactory } from 'app/app-factory';
import { observer } from 'mobx-react';
import { CatalogSelection } from 'components/admin/catalog-selection';

import { UserManager } from '@core/models/user-manager';
import { MediumHeading } from '@naan/primitives/text';
import { Definition } from './components/definition';
import { NavLink } from 'react-router-dom';
import { VSpacer } from '@naan/primitives/spacer';
import { dayjsToIsoDate } from '@utils/date-utils';

export const path = 'catalog';
export const label = 'Catalog stuff';

export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager } = root;

  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  const { storyManager } = AppFactory.root;
  const { calendarEditions } = storyManager;

  return (
    <VStack>
      <MediumHeading>Catalog Stuff</MediumHeading>
      <Definition label={'Catalog (cali)'} value={<CatalogSelection />} />
      {/* <VSpacer size={4} /> */}
      <NavLink to="/admin/sync-catalog-urls">[sync catalog urls]</NavLink>
      <VSpacer size={4} />
      <h3>Soundbite editions</h3>
      {calendarEditions.map(edition => (
        <p>
          {dayjsToIsoDate(edition.visibilityDayjs)} - {edition.story?.slug}
        </p>
      ))}
    </VStack>
  );
});
