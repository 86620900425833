import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { AppFactory } from '@app/app-factory';
import { ChapterCatalogData } from '@core/models/catalog';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { IconButton } from '@naan/primitives/button';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { observer } from 'mobx-react';
import { ChapterButtons } from '../chapter-buttons';
import { ChapterListItemPresentationWrapper } from './chapter-list-item-presentation-wrapper';
import __ from '@core/lib/localization';

const ResumeStudyConfirmationDialog = ({
  onDismiss,
  okAction,
}: {
  onDismiss: () => void;
  okAction: () => void;
}) => {
  const handleOkAndDismiss = React.useCallback(() => {
    onDismiss();
    okAction();
  }, [okAction, onDismiss]);

  return (
    <Dialog.Container open={true} onDismiss={onDismiss}>
      <Dialog.Heading>
        {__('Restart study from here', 'restartStudyFromHere')}
      </Dialog.Heading>
      <Dialog.Body>
        {__(
          'This will move you back to the start of this chapter for studying.',
          'thisWillMoveYouBackToTheStart'
        )}
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation={'cancel'}
          label={__('Cancel', 'cancel')}
          data-test-id="okButton"
          onClick={onDismiss}
        />
        <Dialog.Button
          presentation={'teal'}
          label={__('Restart', 'restart')}
          data-test-id="okButton"
          onClick={handleOkAndDismiss}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

// special review mode
export const CurrentNotFurthest = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const onRestart = React.useCallback(() => {
      AppFactory.dialogPresenter.present(onDismiss => (
        <ResumeStudyConfirmationDialog
          onDismiss={onDismiss}
          okAction={() => {
            chapter.restartChapter();
            onDismiss();
          }}
        />
      ));
    }, [chapter]);

    const overflowMenu = (
      <Menu
        trigger={
          <IconButton presentation="grayTransparent" icon={<OverflowIcon />} />
        }
      >
        <MenuItem
          label={__('Restart study from here…', 'restartStudyFromHereEllipsis')}
          action={() => onRestart()}
        />
      </Menu>
    );

    return (
      <ChapterListItemPresentationWrapper
        chapter={chapter}
        number={chapter.position}
        title={chapter.title}
        presentation="current"
        durationText={chapter.prettyDuration}
        checked={true}
        overflowMenu={overflowMenu}
        buttons={<ChapterButtons chapter={chapter} viaDetail={false} />}
      />
    );
  }
);
