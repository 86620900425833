import * as React from 'react';
import { VideoPlayer } from 'components/ui/video-player';
import { getSourceForId } from 'components/ui/video-player/player-view-controller';
import { track } from '@app/track';
import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { ReplayIcon } from '@naan/icons/replay-icon';
import { Link } from 'react-router-dom';
import { ArrowRightSmallIcon } from '@naan/icons/arrow-right-icon';

import bg from './assets/endcard-bg.svg';
import __ from '@core/lib/localization';
import { safelyConcatenateParams } from 'components/nav/decorate-url';

const videoId = '60ff203fe326eb64bb804cae17869b5b';

const EndCardWrapper = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundImage: `url(${bg})`,
  backgroundSize: 'cover',
  '& > .video-buttons': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
  },
});

export const HeroSectionVideoPlayer = ({
  continuePath,
}: {
  continuePath: string;
}) => {
  const src = getSourceForId(videoId);
  const path = safelyConcatenateParams(continuePath, `flow=spa-video-endcard`);

  return (
    <VideoPlayer
      src={src}
      onEnded={() => {
        track('sb__orientation_video_end');
      }}
      onPlay={() => {
        track('sb__orientation_video_play');
      }}
      onPause={() => {
        track('sb__orientation_video_pause');
      }}
      renderEndCard={player => {
        if (document.fullscreenElement) {
          document.exitFullscreen?.();
        }
        return (
          <EndCardWrapper>
            <div className="video-buttons">
              <Button
                leftIcon={<ReplayIcon />}
                presentation={'whiteDarken'}
                onClick={() => {
                  player.play();
                }}
                label={'Replay'}
                size="large"
              />
              <Link
                to={path}
                replace
                // to={dailySoundbitePath({
                //   orientation: 'skip',
                //   flow: 'spa-video-endcard',
                // })}
              >
                <Button
                  rightIcon={<ArrowRightSmallIcon />}
                  presentation={'purple'}
                  label={__('Try your first Soundbite', 'tryFirstSoundbite')}
                  size="large"
                />
              </Link>
            </div>
          </EndCardWrapper>
        );
      }}
    />
  );
};
