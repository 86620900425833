import * as React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { VStack } from 'naan/primitives/stack';
import { ChapterListItem } from '../../../story/chapter-list-item';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { ChapterCatalogData, UnitCatalogData } from '@core/models/catalog';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { useStoryTabScrollingFx } from '../scrolling-fx';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { StoryCompleteControls } from 'components/story-detail/story-complete-controls';
import { StoryAction } from 'story/story-actions';
import { VSpacer } from '@naan/primitives/spacer';
import { SoundbiteFloatingCard } from 'components/stories/soundbite-floating-card';

const CURRENT_CHAPTER_ID = 'current-chapter';
const CHAPTER_LIST_ID = 'chapter-list';

function maybeCurrentId(chapter: ChapterCatalogData) {
  if (chapter.isCurrent) {
    return { id: CURRENT_CHAPTER_ID };
  }

  return {};
}

const UnitHeader = ({ unit }: { unit: UnitCatalogData }) => {
  return (
    <div className="unitHeader">
      {unit.partLabel}
      <span className="unitDuration">{unit.durationDescription}</span>{' '}
    </div>
  );
};

export const StoryTab = observer(() => {
  // track when it's safe to pop back from study view
  ReturnNavState.storyDetailVisited = true;

  const { storyManager } = AppFactory.root;
  const { slug } = useParams<{ slug: string }>();
  const story = storyManager.story(slug);

  useStoryTabScrollingFx({ unstarted: story.unstarted });

  return (
    <>
      <StoryCompleteControls />

      {/* one of these two will be non-null */}
      <StoryAction />
      {story.multiUnit ? null : <VSpacer size={2} />}
      <CenterColumnLayout>
        <VStack id={CHAPTER_LIST_ID}>
          {story.units.map(unit => (
            <React.Fragment key={unit.slug}>
              {story.multiUnit ? <UnitHeader unit={unit} /> : null}
              <div>
                {unit.chapters.map(chapter => (
                  <div
                    key={unit.slug + '_' + chapter.position}
                    {...maybeCurrentId(chapter)}
                  >
                    <ChapterListItem chapter={chapter} />
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </VStack>
        <MediumGap />
      </CenterColumnLayout>
      <SoundbiteFloatingCard story={story} />
    </>
  );
});
