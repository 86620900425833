import * as React from 'react';
import Cookies from 'js-cookie';
import { HStack, VStack } from 'naan/primitives/stack';
import * as loggly from 'legacylib/loggly';

import { AppFactory } from 'app/app-factory';
import { ActionLink } from 'common/ui/action-link';
import { observer } from 'mobx-react';
import { createLogger } from '@common/log';
import { CurrentDateDevUI } from 'components/admin/current-date-dev-ui';
import { bugsnagNotify } from 'app/notification-service';
import { UserManager } from '@core/models/user-manager';
import {
  // ServerAuthCookieUI,
  StorageCanaryUI,
} from 'components/admin/storage-canary-ui';
import { logLevel, setLogLevel } from '@common/log/logger-config';
import { LogLevel } from '@common/log/logger-types';
import { MediumHeading } from '@naan/primitives/text';
import { appConfig } from '@app/config';
import { embeddedMode } from '@app/app-util';
import { nativeWakeLockSupported } from '@core/lib/app-util';
import { USER_TOKEN_COOKIE_KEY } from '@core/models/user-manager/user-manager';
import {
  getAvailableTranslations,
  getLocale,
  localizationDisabled,
} from '@core/lib/localization';
import {
  getInstallationId,
  resetFirstVisitState,
} from '@core/models/installation-id';

const log = createLogger('dev-tools');

(window as any).jsCookies = Cookies; // console convenience

const MasalaAdminToggle = observer(() => {
  const { userManager } = AppFactory.root;
  // intentionally skipping the await
  const toggle = () => userManager.userData.toggleMasalaAdmin();
  return (
    <HStack gap={'small'}>
      Masala admin (ugly debug) mode: {String(userManager.showUglyDevUI)}
      &nbsp;
      <ActionLink onPress={toggle}>[toggle]</ActionLink>
    </HStack>
  );
});

const LogglyToggle = () => {
  const [dirty, setDirty] = React.useState(0);
  const { root } = AppFactory;
  const { userManager } = root;

  if (!loggly.enabled) return null;

  const updateLevel = (level: LogLevel) => {
    setLogLevel(level);
    setDirty(Date.now());
  };

  log.info(`LogglyToggle dirty: ${dirty}`);
  return (
    <>
      {/* // piggy back the loggly toggle component for the dirty state handling */}
      <HStack gap={'small'}>
        Purchase flow disabled:&nbsp;
        {String(userManager.purchaseFlowDisabled)}
        &nbsp;
        <ActionLink
          onPress={() => {
            appConfig.forceNoPurchase = !appConfig.forceNoPurchase;
            setDirty(Date.now());
          }}
        >
          [toggle forced flag]
        </ActionLink>
      </HStack>

      <HStack gap={'small'}>
        Show error details:&nbsp;
        {String(appConfig.showErrorDetails)}
        &nbsp;
        <ActionLink
          onPress={() => {
            appConfig.showErrorDetails = !appConfig.showErrorDetails;
            setDirty(Date.now());
          }}
        >
          [toggle]
        </ActionLink>
      </HStack>

      <HStack gap={'small'}>
        Show warning alerts:&nbsp;
        {String(appConfig.showWarningAlerts)}
        &nbsp;
        <ActionLink
          onPress={() => {
            appConfig.showWarningAlerts = !appConfig.showWarningAlerts;
            setDirty(Date.now());
          }}
        >
          [toggle]
        </ActionLink>
      </HStack>
      <HStack gap={'small'}>
        Loggly activated:&nbsp;
        {loggly.isActivated() ? <strong>true</strong> : 'false'}
        &nbsp;
        <ActionLink
          onPress={() => {
            loggly.toggleActivation();
            root.localState
              .storeLogglyEnabled(loggly.isActivated())
              .catch(error => bugsnagNotify(error));
            setDirty(Date.now());
          }}
        >
          [toggle]
        </ActionLink>
        {' - '}
        Level: {logLevel()}{' '}
        <ActionLink onPress={() => updateLevel(0)} label="[off-0]" />{' '}
        <ActionLink onPress={() => updateLevel(1)} label="[trace-1]" />{' '}
        <ActionLink onPress={() => updateLevel(2)} label="[debug-2]" />{' '}
        <ActionLink onPress={() => updateLevel(3)} label="[info-3]" />{' '}
        <ActionLink onPress={() => updateLevel(4)} label="[warn-4]" />{' '}
        <ActionLink onPress={() => updateLevel(5)} label="[warn-5]" />
      </HStack>
    </>
  );
};

const ClassroomToggle = observer(() => {
  const { userManager } = AppFactory.root;
  const toggle = () => userManager.toggleClassroomActivation();
  return (
    <HStack gap={'small'}>
      Teacher mode: {String(userManager.classroomEnabled)}&nbsp;
      <ActionLink onPress={toggle}>[toggle]</ActionLink>
    </HStack>
  );
});

const ExportVocabToggle = observer(() => {
  const { userSettings } = AppFactory.root.userManager.userData;
  const toggle = () => userSettings.toggleVocabExport();
  return (
    <HStack gap={'small'}>
      Show vocab export: {String(userSettings.showVocabListExportOption)}&nbsp;
      <ActionLink onPress={toggle}>[toggle]</ActionLink>
    </HStack>
  );
});

const NoSleepHackToggle = observer(() => {
  const { localState } = AppFactory.root;
  const toggle = () => localState.toggleKeepAwakeHack().catch(bugsnagNotify);
  return (
    <HStack gap={'small'}>
      {localState.keepAwakeHackApplicable ? (
        <>
          Don't hack keep awake: {String(localState.dontHackKeepAwake)}
          &nbsp;
          <ActionLink onPress={toggle}>[toggle]</ActionLink>
        </>
      ) : (
        <>
          Keep awake mode: {embeddedMode() ? 'embedded ' : null}
          {nativeWakeLockSupported() ? 'navigator api ' : null}
        </>
      )}
    </HStack>
  );
});

const FirebaseErrorToggle = observer(() => {
  const { localState } = AppFactory.root;
  return (
    <HStack gap={'small'}>
      Force firebase error:&nbsp;
      {String(localState.forceFirebaseError)}
      &nbsp;
      <ActionLink onPress={() => localState.toggleForceFirebaseError()}>
        [toggle]
      </ActionLink>{' '}
      &nbsp; (connection status: {AppFactory.firebaseConnection.status})
    </HStack>
  );
});

export const LocaleToggle = observer(() => {
  if (localizationDisabled) {
    return <HStack gap={'small'}>Locale: disabled</HStack>;
  }

  const { localState } = AppFactory.root;
  const locale = getLocale();
  // const locales = ['en', 'es', 'de', 'pt'];
  const locales = getAvailableTranslations();
  return (
    <HStack gap={'small'}>
      Locale:
      <select
        value={locale}
        onChange={event => {
          // setLocale(event.target.value);
          localState.storeLocale(event.target.value).catch(bugsnagNotify);
        }}
      >
        {locales.map(locale => (
          <option key={locale} value={locale}>
            {locale}
          </option>
        ))}
      </select>
    </HStack>
  );
});

const StatusBarToggle = observer(() => {
  const { localState } = AppFactory.root;
  return (
    <HStack gap={'small'}>
      Show debug status bar:&nbsp;
      {String(localState.debugStatusBarEnabled)}
      &nbsp;
      <ActionLink onPress={() => localState.toggleDebugStatusBar()}>
        [toggle]
      </ActionLink>
    </HStack>
  );
});

const PiToggle = observer(() => {
  const { root } = AppFactory;

  const handleToggle = () => {
    root.togglePiMenu();
  };

  return (
    <HStack gap={'small'}>
      Force hide Pi menu:&nbsp;
      {String(root.forceHidePiMenu)}
      &nbsp;
      <ActionLink onPress={handleToggle}>[toggle]</ActionLink>
    </HStack>
  );
});
const SoundbitesToggle = () => {
  const [dirty, setDirty] = React.useState(0);

  const handleToggle = () => {
    appConfig.soundbites.disabled = !appConfig.soundbites.disabled;
    setDirty(Date.now());
  };

  log.info(`SoundbitesToggle dirty: ${dirty}`);
  return (
    <HStack gap={'small'}>
      Soundbites:&nbsp;
      {appConfig.soundbites.disabled ? 'disabled' : 'enabled'}
      &nbsp;
      <ActionLink onPress={handleToggle}>[toggle]</ActionLink>{' '}
    </HStack>
  );
};

export const path = 'toggles';
export const label = 'Toggles';

export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager } = root;

  const { accountData, userData } = userManager;

  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  const authCookieData = Cookies.get(USER_TOKEN_COOKIE_KEY);

  return (
    <VStack>
      <MediumHeading>Toggles</MediumHeading>
      <PiToggle />
      <SoundbitesToggle />
      <FirebaseErrorToggle />
      <StatusBarToggle />
      <ClassroomToggle />
      <ExportVocabToggle />
      <NoSleepHackToggle />
      <MasalaAdminToggle />
      <LogglyToggle />
      <CurrentDateDevUI />
      <StorageCanaryUI />
      {/* <ServerAuthCookieUI /> */}
      <p>jw-user-token cookie data: {String(authCookieData)}</p>

      <p>
        Mailing list - opt-in: {String(accountData.mailingListOptIn)}{' '}
        <ActionLink onPress={() => userManager.toggleMailingListOptIn()}>
          [toggle]
        </ActionLink>
        {' - '}
        prompt needed: {String(accountData.mailingListPromptNeeded)}, prompt
        enabled: {String(userData.mailingListPromptEnabled)}
        <br />
        installation id: {getInstallationId()} -{' '}
        <ActionLink onPress={() => resetFirstVisitState()}>
          [reset first visited state]
        </ActionLink>
      </p>
    </VStack>
  );
});
